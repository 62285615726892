import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export function CenterContainer({ children }: Props) {
  return (
    <div
      style={{
        background: '#fafafa',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {children}
    </div>
  )
}
