import axios from 'axios'

export default {
  getGenericProducts(shopId) {
    return axios.get('getGenericProducts', { params: shopId })
  },
  getGenericProduct(data) {
    return axios.get('getGenericProduct', { params: data })
  },
  createGenericProduct(data) {
    return axios.post('createGenericProduct', data)
  },
  updateGenericProduct(data) {
    return axios.put('updateGenericProduct', data)
  },
  deleteGenericProduct(id) {
    return axios.delete('deleteGenericProduct', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  getLoadingArms(data) {
    return axios.get('getLoadingArms', { params: data })
  },
  getLoadingArm(id) {
    return axios.get('getLoadingArm', { params: id })
  },
  createLoadingArm(data) {
    return axios.post('createLoadingArm', data)
  },
  updateLoadingArm(data) {
    return axios.put('updateLoadingArm', data)
  },
  deleteLoadingArm(id) {
    return axios.delete('deleteLoadingArm', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  getLoadingArmsUnavailabilities(data) {
    return axios.get('getLoadingArmsUnavailabilities', { params: data })
  },
  getLoadingArmUnavailability(data) {
    return axios.get('getLoadingArmUnavailability', { params: data })
  },
  createLoadingArmUnavailability(data) {
    return axios.post('createLoadingArmUnavailability', data)
  },
  updateLoadingArmUnavailability(data) {
    return axios.put('updateLoadingArmUnavailability', data)
  },
  deleteLoadingArmUnavailability(id) {
    return axios.delete('deleteLoadingArmUnavailability', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  uploadLoadingArmsUnavailability(data) {
    return axios.post('uploadLoadingArmUnavailability', data)
  },
  getCheckpointsUnavailabilities(data) {
    return axios.get('getCheckpointsUnavailabilities', { params: data })
  },
  getCheckpointUnavailability(data) {
    return axios.get('getCheckpointUnavailability', { params: data })
  },
  createCheckpointUnavailability(data) {
    return axios.post('createCheckpointUnavailability', data)
  },
  updateCheckpointUnavailability(data) {
    return axios.put('updateCheckpointUnavailability', data)
  },
  deleteCheckpointUnavailability(id) {
    return axios.delete('deleteCheckpointUnavailability', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  uploadCheckpointsUnavailability(data) {
    return axios.post('uploadCheckpointUnavailability', data)
  },
  getLoadingAuthorizations(data) {
    return axios.get('getLoadingAuthorizations', { params: data })
  },
  uploadLoadingAuthorizations(data) {
    return axios.post('uploadLoadingAuthorizations', data)
  },
  getAppointments(data) {
    return axios.get('getAppointments', { params: data })
  },
  getAppointmentsToCancel(data) {
    return axios.get('getAppointmentsToCancel', { params: data })
  },
  updateAppointmentStatus(data) {
    return axios.post('updateAppointmentStatus', data)
  },
  getTransportCompanies(data) {
    return axios.get('getTransportCompanies', { params: data })
  },
  getTransportCompany(data) {
    return axios.get('getTransportCompany', { params: data })
  },
  createTransportCompany(data) {
    return axios.post('createTransportCompany', data)
  },
  updateTransportCompany(data) {
    return axios.put('updateTransportCompany', data)
  },
  deleteTransportCompany(id) {
    return axios.delete('deleteTransportCompany', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  getTransportCompanyUser(data) {
    return axios.get('getTransportCompanyUser', { params: data })
  },
  createTransportCompanyUser(data) {
    return axios.post('createTransportCompanyUser', data)
  },
  updateTransportCompanyUser(data) {
    return axios.put('updateTransportCompanyUser', data)
  },
  deleteTransportCompanyUser(id) {
    return axios.delete('deleteTransportCompanyUser', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  resetPasswordTransportCompanyUser(data) {
    return axios.get('resetPasswordTransportCompanyUser', { params: data })
  },
  getGenericProductsUnavailabilities(data) {
    return axios.get('getGenericProductsUnavailabilities', { params: data })
  },
  getGenericProductUnavailability(data) {
    return axios.get('getGenericProductUnavailability', { params: data })
  },
  createGenericProductUnavailability(data) {
    return axios.post('createGenericProductUnavailability', data)
  },
  updateGenericProductUnavailability(data) {
    return axios.put('updateGenericProductUnavailability', data)
  },
  deleteGenericProductUnavailability(id) {
    return axios.delete('deleteGenericProductUnavailability', {
      data: id,
      headers: { 'Content-Type': 'application/json' }
    })
  },
  uploadGenericProductUnavailability(data) {
    return axios.post('uploadGenericProductUnavailability', data)
  }
}
