import React, { Component } from 'react'
import { default as ToggleMU } from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default class Toggle extends Component {
  render() {
    return (
      <FormControlLabel
        style={{ width: '100%', margin: 'unset' }}
        labelPlacement={'start'}
        control={
          <div style={{ marginLeft: 'auto' }}>
            <ToggleMU
              disabled={this.props.disabled}
              style={this.props.style}
              checked={this.props.toggled || false}
              value={this.props.toggled}
              onChange={(event) =>
                this.props.onToggle(event, event.target.checked)
              }
            />
          </div>
        }
        label={<div style={{ flex: '1' }}>{this.props.label}</div>}
      />
    )
  }
}
