import React, { Component } from 'react'
import Dropdown from 'dumb/dropdown'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import translate from 'i18n/translate'

class DropdownSubBarFilter extends Component {
  static defaultProps = {
    dataConverter: (item) => ({ value: item.value, text: item.text })
  }

  handleItemChange = (id) => {
    this.props.onChange && this.props.onChange({ id: id })
  }

  render() {
    const { all } = this.props.translation.common
    const items = this.props.items
    const allItem = { id: null, name: all }

    const dropdownItems = items.length > 1 ? [allItem].concat(items) : items

    return (
      <SubBar smallScreen={this.props.smallScreen}>
        {dropdownItems.length > 1 && (
          <SubBarControl title={this.props.title}>
            <Dropdown
              style={{ marginRight: 24 }}
              value={this.props.selectedId}
              data={dropdownItems}
              dataConverter={(item) => {
                return this.props.dataConverter(item)
              }}
              onChange={this.handleItemChange}
            />
          </SubBarControl>
        )}
      </SubBar>
    )
  }
}

export default translate(DropdownSubBarFilter)
