import React, { Component } from 'react'
import Text from 'dumb/text'
import { Form, FormGroup } from 'dumb/form'
import translate from 'i18n/translate'
import { getModName } from './translation'
import Divider from '@material-ui/core/Divider'

class LineView extends Component {
  render() {
    const d = this.props.data
    const {
      name,
      serviceTime,
      timeToReach,
      maxTimeToReach,
      seconds,
      manageAppointments,
      manageAppointmentsValue,
      openingHour,
      closingHour,
      maxSimultaneous,
      maxSimultaneousValue,
      modes,
      allowFutureAppointments,
      attachIcsFile,
      authoriseAppointmentOnWebPortal,
      blindCopyEmails,
      shortName,
      nonStopService,
      nonStopServiceValue,
      nearServicePointDistance,
      nearServicePointDistanceValue,
      parameters,
      appointmentForNextDay,
      openingHours,
      durations,
      servicesCollection
    } = this.props.translation.lineView

    const { booleanValue } = this.props.translation.common

    return (
      <Form>
        <Form title={parameters} expandable expanded>
          <FormGroup>
            <Text type="caption">{name}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {d.name}
            </Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{shortName}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {d.shortName}
            </Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{manageAppointments}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {manageAppointmentsValue(d.manageAppointments)}
            </Text>
          </FormGroup>
          {Boolean(d.requestParticipantsNumber) && (
            <FormGroup>
              <Text type="caption">{maxSimultaneous}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {maxSimultaneousValue(d.maxSimultaneous)}
              </Text>
            </FormGroup>
          )}
          {Boolean(d.calculateApproachByGPS) && (
            <FormGroup>
              <Text type="caption">{nearServicePointDistance}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>{`${
                d.nearServicePointDistance
              } ${nearServicePointDistanceValue(d.nearServicePointDistance)}`}</Text>
            </FormGroup>
          )}
        </Form>
        <Divider />
        <Form title={appointmentForNextDay} expandable>
          <FormGroup>
            <Text type="caption">{allowFutureAppointments}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {manageAppointmentsValue(d.allowFutureAppointments)}
            </Text>
          </FormGroup>
          {Boolean(d.allowFutureAppointments) && (
            <FormGroup>
              <Text type="caption">{attachIcsFile}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {booleanValue(d.attachIcsFile)}
              </Text>
            </FormGroup>
          )}
          {Boolean(d.allowFutureAppointments) && (
            <FormGroup>
              <Text type="caption">{authoriseAppointmentOnWebPortal}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {booleanValue(d.authoriseAppointmentOnWebPortal)}
              </Text>
            </FormGroup>
          )}
          {Boolean(d.blindCopyEmails && d.blindCopyEmails.length) && (
            <FormGroup>
              <Text type="caption">{blindCopyEmails}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.blindCopyEmails}
              </Text>
            </FormGroup>
          )}
        </Form>
        <Divider />
        <Form title={servicesCollection} expandable>
          <FormGroup style={{ marginTop: 0 }}>
            {d.services.map((service, i) => (
              <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }} key={i}>
                <Text>
                  {service.name} / {seconds(service.duration)}
                </Text>
              </div>
            ))}
          </FormGroup>
        </Form>
        <Divider />
        <Form title={openingHours} expandable>
          {Boolean(d.nonStopService) && (
            <FormGroup>
              <Text type="caption">{nonStopService}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {nonStopServiceValue(d.nonStopService)}
              </Text>
            </FormGroup>
          )}
          {!d.nonStopService &&
            Boolean(d.openingHour) &&
            (Boolean(d.manageAppointments) || Boolean(d.allowFutureAppointments)) && (
              <FormGroup>
                <Text type="caption">{openingHour}</Text>
                <Text type="body-1" style={{ color: '#374649' }}>
                  {this.props.timeFromObject(d.openingHour)}
                </Text>
              </FormGroup>
            )}
          {!d.nonStopService &&
            Boolean(d.closingHour) &&
            Boolean(d.manageAppointments || Boolean(d.allowFutureAppointments)) && (
              <FormGroup>
                <Text type="caption">{closingHour}</Text>
                <Text type="body-1" style={{ color: '#374649' }}>
                  {this.props.timeFromObject(d.closingHour)}
                </Text>
              </FormGroup>
            )}
        </Form>

        <Divider />

        <Form title={durations} expandable>
          <FormGroup>
            <Text type="caption">{serviceTime}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {seconds(d.serviceTime)}
            </Text>
          </FormGroup>

          <FormGroup>
            <Text type="caption">{timeToReach}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {seconds(d.timeToReach)}
            </Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{maxTimeToReach}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {seconds(d.maxTimeToReach)}
            </Text>
          </FormGroup>
        </Form>
        <Divider />
        {Boolean(d.modes && d.modes.length) && (
          <React.Fragment>
            <FormGroup>
              <Text type="caption">{modes}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.modes.map((x) => getModName(x, this.props.translation.lineModes)).join(', ')}
              </Text>
            </FormGroup>
            <Divider />
          </React.Fragment>
        )}
        {this.renderLimit(d)}
      </Form>
    )
  }

  renderLimit(d) {
    const { smsCredits } = this.props.translation.lineView

    const modes = d.modes

    if (!modes.some((x) => x.toLowerCase() === 'sms')) {
      return null
    }

    return (
      <FormGroup>
        <Text type="caption">{smsCredits}</Text>
        <Text type="body-1" style={{ color: '#374649' }}>
          {d.smsCredits} out of {d.creditsLimit}
        </Text>
      </FormGroup>
    )
  }
}

export default translate(LineView)
