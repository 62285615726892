import React from 'react'
import { DatePicker } from 'dumb/date'
import translate from 'i18n/translate'
import TimePicker from 'dumb/time/time-picker'
import { TextField } from 'dumb/text-field'
import { ControlLabel } from 'dumb/control-label'
import { produce } from 'immer'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

class CheckpointUnavailabilityEdit extends React.Component {
  state = {
    data: this.props.data
  }

  editState = (fn) => this.setState(produce(fn))

  render() {
    const { comments, beginningOfUnavailability, endOfUnavailability } = this.props.translation.unavailabilityEdit
    const { unavailabilityOfServicePoint } = this.props.translation.totalPlanning

    const d = this.state.data

    return (
      <div style={{ minWidth: 400 }}>
        <DialogTitle id="form-dialog-title">{`${unavailabilityOfServicePoint} '${d.checkpointName}'`}</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 24 }}>
            <ControlLabel>{beginningOfUnavailability}</ControlLabel>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <DatePicker value={d.startDate} onChange={(val) => this.handlePropChange('startDate', +val)} />
              <TimePicker
                date={d.startDate}
                value={d.startTime}
                onChange={(val) => this.handlePropChange('startTime', val)}
                timeZone={this.props.timeZoneId}
              />
            </div>
          </div>
          <div style={{ marginBottom: 24 }}>
            <ControlLabel>{endOfUnavailability}</ControlLabel>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <DatePicker value={d.endDate} onChange={(val) => this.handlePropChange('endDate', +val)} />
              <TimePicker
                value={d.endTime}
                date={d.endDate}
                onChange={(val) => this.handlePropChange('endTime', val)}
                timeZone={this.props.timeZoneId}
              />
            </div>
          </div>
          <div style={{ marginBottom: 24 }}>
            <TextField
              label={comments}
              placeholder={comments}
              value={d.comment}
              onChange={(val) => this.handlePropChange('comment', val)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>{this.props.translation.entityEdit.cancelButton}</Button>
          <Button onClick={this.handleSubmit}>
            {d.id ? this.props.translation.entityEdit.updateButton : this.props.translation.entityEdit.createButton}
          </Button>
        </DialogActions>
      </div>
    )
  }

  handleClose = () => this.props.onClose()

  handleSubmit = () => this.props.handleSubmit(this.state.data)

  handlePropChange = (prop, val) =>
    this.editState((draft) => {
      draft.data[prop] = val
    })
}

export default translate(CheckpointUnavailabilityEdit)
