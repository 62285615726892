import React, { Component } from 'react'
import ResourceCell from './resource-cell'
import { Resource } from 'devextreme-react/scheduler'
import Scheduler from 'devextreme-react/scheduler'
import translate from 'i18n/translate'
import { shallowDiff } from 'utils/object'
import moment from 'moment-timezone'
import 'devextreme/dist/css/dx.light.compact.css'
import 'devextreme/dist/css/dx.common.css'
import './scheduler.scss'

const statuses = [
  {
    id: 'ToBeConfirmed',
    color: '#3F80C1'
  },
  {
    id: 'Valid',
    color: '#228B22'
  },
  {
    id: 'ToBeCancelled',
    color: '#EED202'
  },
  {
    id: 'Arrived',
    color: '#D3D3D3'
  },
  {
    id: 'Absent',
    color: '#E62020'
  },
  {
    id: 'Cancelled',
    color: '#228B22'
  },
  {
    id: 'Finished',
    color: '#228B22'
  },
  {
    id: 'Unknown',
    color: '#ffffff'
  },
  {
    id: 'Taken',
    color: '#b5b3b3'
  }
]

class AppointmentsScheduling extends Component {
  clickTimeout = null

  shouldComponentUpdate(nextProps) {
    if (this.props !== nextProps) {
      if (nextProps.data !== this.props.data || nextProps.timeZoneId !== this.props.timeZoneId) {
        const offset = moment.tz.zone(nextProps.timeZoneId).utcOffset(nextProps.date || Date.now()) * -60 * 1000

        this.component.instance().option('dataSource', this.createDataSource(nextProps.data, offset))
      }
    }

    return shallowDiff(nextProps, this.props, ['date', 'appointmentHeight'])
  }

  cutDataToView(startDate, endDate, offset, timeZone) {
    let minDate = new Date(this.props.date)
    minDate.setHours(6)
    minDate.setMinutes(0)
    minDate.setSeconds(0)

    const minDatetWithTimeZone = +minDate + timeZone - offset

    let maxDate = new Date(this.props.date)
    maxDate.setHours(18)
    maxDate.setMinutes(59)
    maxDate.setSeconds(59)

    const maxDatetWithTimeZone = +maxDate + timeZone - offset

    const startWithTimeZone = startDate + timeZone - offset
    const endWithTimeZone = endDate + timeZone - offset

    const startRounded = Math.max(minDatetWithTimeZone, startWithTimeZone)
    const endRounded = Math.min(maxDatetWithTimeZone, endWithTimeZone)

    return {
      startRounded,
      endRounded,
      startSrc: startWithTimeZone,
      endSrc: endWithTimeZone
    }
  }

  createDataSource(data, timeZone) {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const offset = moment.tz.zone(localTimeZone).utcOffset(this.props.date) * -60 * 1000

    let source = data.appointments.map((x) => ({
      ...x,
      startDate: x.startDate + timeZone - offset,
      endDate: x.endDate + timeZone - offset
    }))

    data.unavailability.forEach((x) => {
      const cuttedData = this.cutDataToView(x.startDate, x.endDate, offset, timeZone)
      source.push({
        ...x,
        _type: 'unavailability',
        startDate: cuttedData.startRounded,
        endDate: cuttedData.endRounded,
        startDateSrc: cuttedData.startSrc,
        endDateSrc: cuttedData.endSrc,
        status: 'Unknown'
      })
    })

    source.forEach((el) => {
      if (el.canView === false) el.status = 'Taken'
    })

    return source
  }

  componentDidMount() {
    const offset = moment.tz.zone(this.props.timeZoneId).utcOffset(this.props.date || Date.now()) * -60 * 1000

    this.component.instance().option('dataSource', this.createDataSource(this.props.data, offset))

    this.component.instance().option('indicatorUpdateInterval', 60000)
  }

  render() {
    const localDateOffset = new Date(this.props.date).getTimezoneOffset() * 60000
    const v = this.props.date ? new Date(this.props.date).getTime() : new Date().getTime()
    const date = new Date(v + localDateOffset)

    return (
      <Scheduler
        currentDate={date}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 8rem)'
        }}
        views={[{ type: 'day' }]}
        ref={(x) => (this.component = x)}
        resourceCellComponent={ResourceCell}
        firstDayOfWeek={1}
        startDayHour={6}
        endDayHour={19}
        showAllDayPanel={false}
        groups={['checkpointId']}
        onAppointmentFormOpening={(ev) => {
          ev.cancel = true
        }}
        appointmentRender={this.appointmentRender}
        onAppointmentRendered={function (e) {
          const cardStyle = {
            ToBeConfirmed: { background: '#3F80C1', color: '#ffffff' },
            Valid: { background: '#228B22', color: '#ffffff' },
            ToBeCancelled: { background: '#EED202', color: '#000000' },
            Arrived: { background: '#D3D3D3', color: '#000000' },
            Absent: { background: '#E62020', color: '#ffffff' },
            Cancelled: { background: '#6D6D6D', color: '#ffffff' },
            Finished: { background: '#6D6D6D', color: '#ffffff' },
            Unknown: {
              background: '#ffffff',
              color: '#000000',
              border: 'solid 1px #333333'
            },
            Taken: {
              background: '#b5b3b3',
              color: '#000000',
              border: 'solid 1px #666'
            }
          }

          const style = {
            ...(cardStyle[e.appointmentData.status] || cardStyle.Unknown),
            borderLeft: e.appointmentData.useFlexiTank ? 'solid 3px #ed0000' : undefined
          }

          Object.keys(style).forEach((prop) => {
            e.appointmentElement.style[prop] = style[prop]
          })
        }}
        recurrenceEditMode={'occurrence'}
        editing={{
          allowAdding: false,
          allowDeleting: false,
          allowDragging: false,
          allowResizing: false,
          allowUpdating: false,
          allowTimeZoneEditing: false
        }}
        onCellClick={(ev) => {
          if (!this.clickTimeout) {
            this.clickTimeout = setTimeout(() => {
              this.clickTimeout = null
            }, 300)
          } else {
            const { canEditAppointments } = this.props

            if (canEditAppointments) this.props.onCellDoubleClick(ev)
          }

          ev.cancel = true
        }}
        onAppointmentDblClick={(ev) => {
          ev.cancel = true
        }}
        onAppointmentUpdating={(ev) => {
          ev.cancel = true
        }}
        onAppointmentClick={(ev) => {
          ev.cancel = true

          if (ev.appointmentData.canView === false) {
            return null
          }

          if (ev.appointmentData._type === 'unavailability') {
            this.props.onOpenPopover(ev.appointmentData, ev.event.currentTarget)

            return null
          }

          this.props.onAppointmentClick(ev.appointmentData)
        }}
        dateCellRender={(dateObj) => {
          const date = new Date(dateObj.date)
          return (
            <div>
              {Intl.DateTimeFormat(this.props.locale, {
                weekday: 'short',
                day: '2-digit'
              }).format(date)}
            </div>
          )
        }}
      >
        <Resource
          allowMultiple={true}
          fieldExpr="checkpointId"
          valueExpr="id"
          displayExpr="name"
          dataSource={this.props.checkpoints}
        />
        <Resource fieldExpr="status" dataSource={statuses} useColorAsDefault={true} />
      </Scheduler>
    )
  }

  appointmentRender = (d) => {
    const data = d.appointmentData

    if (data.canView === false) {
      return (
        <div>
          <div style={{ fontWeight: 600 }}>{this.props.translation.common.taken}</div>
        </div>
      )
    }

    if (data._type === 'unavailability') {
      return (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <div style={{ fontWeight: 600 }}>{this.props.translation.common.unavailable}</div>
          {data.comment && <div>{data.comment}</div>}
        </div>
      )
    }

    return (
      <div>
        <div style={{ fontWeight: 600 }}>{data.loadingAuthorizationId}</div>
        {data.product && (
          <div>
            {data.product.name} / {data.product.code}
          </div>
        )}
        {data.product && (
          <div>
            {data.product.qty} {data.product.measureUnit}
          </div>
        )}
        <div>{data.countryCode}</div>
        <div>
          {data.driverName} {data.driverSurname}
        </div>
        <div style={{ fontSize: '11px' }}>{data.transportCompanyName}</div>
      </div>
    )
  }
}

export default translate(AppointmentsScheduling)
