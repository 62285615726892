import React from 'react'
import CheckpointMonitoring from 'dumb/checkpoint-monitoring'
import { connect } from 'react-redux'
import { getCheckpointMonitoring } from 'store/actions/entity-action-creators'
import BaseView from 'smart/base/base-entity-view'
import translate from 'i18n/translate'
import { checkpoint } from 'provider'
import timeZoneFilter from 'smart/time-zone-filter'
import { showMessage } from 'store/actions/main-layout-action-creators'

class SmartCheckpointMonitoring extends BaseView {
  identity = 'checkpointMonitoring'

  fetchEntity(p) {
    this.props.getEntity(p.checkpointId)
  }

  componentWillUnmount() {
    super.componentWillUnmount()
  }

  getBreadcrumbs(crumb, data, parents) {
    const shop = parents.shop
    const line = parents.line

    return [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shop.id], shop.name),
      crumb.lines([shop.id]),
      crumb.line([shop.id, line.id], line.name),
      crumb.checkpoints([shop.id, line.id]),
      crumb.checkpoints([shop.id, line.id], this.props.entity.data.name),
      crumb.settings()
    ]
  }

  renderForm() {
    return (
      <CheckpointMonitoring
        data={{ ...this.props.entity.data }}
        onHostChange={(val) => this.handleHostChange(val)}
        canGiveUpdateServicePointStartAndEndHours={this.props.canGiveUpdateServicePointStartAndEndHours}
      />
    )
  }

  handleHostChange(val) {
    val.checkpointId = this.props.entity.data.id

    checkpoint
      .updateCheckpointHost(val)
      .then(() => {
        this.props.dispatch(
          showMessage(this.props.translation.checkpointMonitoring.updateCheckpointHostSuccessMessage, 'success')
        )
      })
      .catch(() => {
        this.props.dispatch(
          showMessage(this.props.translation.checkpointMonitoring.updateCheckpointHostFailureMessage, 'error')
        )
      })
  }
}

const stateToProps = (state) => ({
  entity: state.entity,
  canGiveUpdateServicePointStartAndEndHours: state.user.permissions.canGiveUpdateServicePointStartAndEndHours
})

const actionsToProps = (dispatch) => ({
  getEntity: (checkpointId) => dispatch(getCheckpointMonitoring(checkpointId)),
  dispatch
})

export default connect(stateToProps, actionsToProps)(timeZoneFilter(translate(SmartCheckpointMonitoring)))
