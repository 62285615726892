import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from 'provider/auth'
import AppBar, { Title, User, TimeZone } from 'dumb/app-bar'
import css from './main-layout.module.scss'
import { editUser } from 'app/nav'
import translate from 'i18n/translate'
import { changeTimeZone } from 'store/actions/user-action-creators'
import dataProvider from 'smart/isolated-data-provider'
import { getLocalTimeZone, isTimeZoneSupported, setLocalTimeZone, ensureLocalTimeZoneId } from 'utils/date'
import { resetUser } from 'store/actions/user-action-creators'
import { Breadcrumb } from 'features/breadcrumbs'

class SmartTimeZonesComponent extends Component {
  state = {
    timeZones: null,
    shops: null,
    currentShopId: null,
    currentShopTimeZone: null
  }

  constructor(props) {
    super(props)

    if (!isTimeZoneSupported) {
      setLocalTimeZone(props.getTimeZones().find((z) => z.offset === getLocalTimeZone().offset))
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.shops !== props.shops || state.currentShopId != props.currentShopId) {
      const localTimeZoneId = ensureLocalTimeZoneId(props.getTimeZones())

      let timeZonesIds
      let currentShopTimeZoneId

      if (props.currentShopId) {
        currentShopTimeZoneId = props.getShop(props.currentShopId).timeZoneId

        if (currentShopTimeZoneId === localTimeZoneId) {
          timeZonesIds = [currentShopTimeZoneId]
        } else {
          timeZonesIds = [localTimeZoneId, currentShopTimeZoneId]
        }
      } else {
        timeZonesIds = [
          localTimeZoneId,
          ...(props.getShops() || []).map((x) => x.timeZoneId).filter((x) => x !== localTimeZoneId)
        ]
      }

      return {
        currentShopTimeZone: currentShopTimeZoneId && props.getTimeZones().find((z) => z.id === currentShopTimeZoneId),
        currentShopId: props.currentShopId,
        shops: props.shops,
        timeZones: timeZonesIds.map((x) => props.getTimeZones().find((z) => z.id === x))
      }
    }

    return null
  }

  componentDidMount() {
    this.props.onChange(this.state.timeZones[0])
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentShopId, currentShopTimeZone, timeZones } = this.state

    const newShopSelected = currentShopId && currentShopId != prevState.currentShopId

    if (newShopSelected || (this.props.hide && currentShopId)) {
      this.props.onChange(currentShopTimeZone)
      return null
    }

    const timeZonesChangeAndCurrentNotFound =
      timeZones.length !== prevState.timeZones.length && !timeZones.find((x) => x.id === this.props.currentTimeZoneId)

    if (timeZonesChangeAndCurrentNotFound) {
      this.props.onChange(timeZones[0])
    }
  }

  render() {
    const id = this.props.currentShopId

    if (this.props.hide) {
      return null
    }

    return (
      <TimeZone
        timeZone={this.props.user.timeZone}
        timeZones={this.state.timeZones}
        onChange={this.props.onChange}
        currentPlaceTimeZoneId={id ? this.props.getShop(id).timeZoneId : null}
        localTimeZoneId={ensureLocalTimeZoneId(this.props.getTimeZones())}
      />
    )
  }
}

const SmartTimeZones = dataProvider({ shops: true, timeZones: true })(
  connect((state) => ({
    currentShopId: state.shops.currentShopId,
    currentTimeZoneId: state.user?.timeZone?.id
  }))(SmartTimeZonesComponent)
)

class MainLayout extends Component {
  static contextTypes = {
    router: () => null,
    locale: () => null
  }

  componentDidMount() {
    this.setTitle()
  }

  shouldComponentUpdate(nextProps) {
    if (this.props != nextProps) {
      if (nextProps.translation.common) {
        this.setTitle(nextProps.translation.common.title)
      }
    }
    return true
  }

  setTitle(nexTitle) {
    const currentTitle = this.props.translation.common.title
    const isDiff = nexTitle !== currentTitle

    if (nexTitle && isDiff) {
      window.document.title = nexTitle
    } else if (!nexTitle) {
      window.document.title = this.props.translation.common.title
    }
  }

  render() {
    return (
      <section className={css.root}>
        <div className={css.bar}>
          <AppBar>
            <Title>
              <img style={{ height: '3.5rem' }} src="logo-totalenergies.svg" />
            </Title>
            <div className={css.breadcrumbs}>{this.renderBreadcrumbs()}</div>
            <SmartTimeZones
              hide={this.props.mainLayout.hideTimezone}
              user={this.props.user}
              onChange={this.handleChangeTimeZone}
            />
            <User
              firstName={this.props.user.userProfile.firstName}
              secondName={this.props.user.userProfile.secondName}
              email={this.props.user.userProfile.email}
              onSettingsTap={() => this.handleSettingsTap()}
              onExitTap={() => this.handleExitTap()}
            />
          </AppBar>
        </div>
        <div className={css.content}>{this.props.children}</div>
      </section>
    )
  }

  handleChangeTimeZone = (data) => {
    this.props.dispatch(changeTimeZone(data))
  }

  renderBreadcrumbs() {
    const singleShop = this.props.user.singleShop
    const groupShops = this.props.user.groupShops

    let crumbs = this.props.mainLayout.breadcrumbs.filter((c) => {
      const id = c.id
      const hideShop = singleShop && id === 'shop'
      const hideShops = (singleShop || !groupShops) && id === 'shops'

      return !hideShop && !hideShops
    })

    if (!crumbs.length) {
      return null
    }

    return <Breadcrumb crumbs={crumbs} />
  }

  handleExitTap() {
    logout().then(() => {
      this.props.dispatch(resetUser())
    })
  }

  handleSettingsTap() {
    this.props.history.push(editUser(this.props.user.userProfile.id))
  }
}

const stateToProps = (state) => ({
  mainLayout: state.mainLayout,
  user: state.user,
  permissions: state.user.permissions
})

export default dataProvider({ shops: true, timeZones: true })(connect(stateToProps)(translate(withRouter(MainLayout))))
