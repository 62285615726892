import { connect } from 'react-redux'
import { useMemo, ReactNode } from 'react'
import { ITranslationContextProps, TranslateContext } from './translation-context'
import dateFormatter, { timeFromObject } from 'i18n/date-formatter'
import { i18n } from '.'
import moment from 'moment-timezone'

type Props = { children?: ReactNode } & any

function TranslationProvider(props: Props) {
  const locale = props.locale as string
  const timeZoneId = props.timeZone.id as string
  const timeZoneOffset = props.timeZone.offset as number

  function timeFormatter(time: any, noShift: boolean = false, date: number) {
    const offset = (moment.tz.zone(timeZoneId)?.utcOffset?.(date || Date.now()) || 0) * -60 * 1000

    return timeFromObject(time, offset, noShift)
  }

  const translation = useMemo(() => {
    return i18n.getTranslation(locale)
  }, [locale])

  const context: ITranslationContextProps = useMemo(() => {
    return {
      locale,
      tr: translation as any,
      dateFormatter: (date, format, noShift = false) => dateFormatter(locale, date, format, timeZoneId, noShift),
      timeZoneId: timeZoneId || 'Etc/GMT',
      timeZoneOffset: timeZoneOffset || 0,
      timeFromObject: timeFormatter
    }
  }, [locale, timeZoneOffset, timeZoneId, translation])

  return <TranslateContext.Provider value={context}>{translation ? props.children : null}</TranslateContext.Provider>
}

export default connect((state: any) => ({
  locale: state.user.interfaceLocale,
  timeZone: state.user.timeZone
}))(TranslationProvider)
