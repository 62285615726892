import { line, checkpoint, user, staffManagement, shop } from 'provider'
import { i18n } from 'i18n'
import {
  getEntityRequest,
  getEntitySuccess,
  getEntityFailure,
  sendEntityFailure,
  sendEntitySuccess,
  sendEntityRequest,
  resetEntity
} from '../reducers/entity-reducer'

export function createEntityGetter(reqSource: any, identity?: string) {
  return function getEntity(...args) {
    return (dispatch) => {
      dispatch(getEntityRequest())
      reqSource
        .apply(null, args)
        .then((data) => dispatch(getEntitySuccess({ entity: data, identity })))
        .catch((error) => dispatch(getEntityFailure(error)))
    }
  }
}

export function createEntitySender(reqSource: any, { type }: { type: string }) {
  return function sendEntity(...args) {
    return (dispatch) => {
      dispatch(sendEntityRequest(type))
      reqSource
        .apply(null, args)
        .then((data) => dispatch(sendEntitySuccess({ entity: data })))
        .catch((error) => dispatch(sendEntityFailure(error)))
    }
  }
}

export {
  getEntityRequest,
  getEntitySuccess,
  getEntityFailure,
  sendEntityFailure,
  sendEntitySuccess,
  sendEntityRequest,
  resetEntity
}

export const getLine = createEntityGetter(line.getLine, 'line')
export const updateLine = createEntitySender(line.updateLine, { type: 'update' })
export const createLine = createEntitySender(line.createLine, { type: 'create' })
export const deleteLine = createEntitySender(line.deleteLine, { type: 'delete' })

export const updateCheckpoint = createEntitySender(checkpoint.updateCheckpoint, { type: 'update' })
export const createCheckpoint = createEntitySender(checkpoint.createCheckpoint, { type: 'create' })
export const deleteCheckpoint = createEntitySender(checkpoint.deleteCheckpoint, { type: 'delete' })

export const getStaffManagementRecord = createEntityGetter(staffManagement.getStaffManagementRecord)
export const updateStaffManagementRecord = createEntitySender(staffManagement.updateStaffManagementRecord, {
  type: 'update'
})
export const createStaffManagementRecord = createEntitySender(staffManagement.createStaffManagementRecord, {
  type: 'create'
})
export const deleteStaffManagementRecord = createEntitySender(staffManagement.deleteStaffManagementRecord, {
  type: 'delete'
})

export const updateUser = createEntitySender(user.updateUser, { type: 'update' })
export const createUser = createEntitySender(user.createUser, { type: 'create' })
export const deleteUser = createEntitySender(user.deleteUser, { type: 'delete' })

export function getCheckpoint(checkpointId, shopId, lineId) {
  return (dispatch) => {
    dispatch(getEntityRequest())
    checkpoint
      .getCheckpoint(checkpointId, shopId, lineId)
      .then((entity) => dispatch(getEntitySuccess({ identity: 'checkpoint', entity })))
      .catch((error) => dispatch(getEntityFailure(error)))
  }
}

export function getCheckpointMonitoring(checkpointId) {
  return (dispatch) => {
    dispatch(getEntityRequest())
    checkpoint
      .getCheckpointMonitoring(checkpointId)
      .then((data) => dispatch(getEntitySuccess({ entity: data, identity: 'checkpointMonitoring' })))
      .catch((error) => dispatch(getEntityFailure(error)))
  }
}

export function getUser(userId) {
  return (dispatch) => {
    dispatch(getEntityRequest())
    Promise.all([user.getUser(userId), shop.getShopList()])
      .then((values) =>
        dispatch(
          getEntitySuccess({
            identity: 'user',
            entity: values[0],
            associated: { shops: values[1], languages: i18n.getLanguages() }
          })
        )
      )
      .catch((error) => dispatch(getEntityFailure(error)))
  }
}
