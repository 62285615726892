import React from 'react'
import { connect } from 'react-redux'
import * as Nav from 'app/nav'
import translate from 'i18n/translate'
import dataProvider from 'smart/isolated-data-provider'
import timeZoneFilter from 'smart/time-zone-filter'
import Container from 'dumb/container'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { total } from 'provider'
import EditForm from './edit-form'

class SmartCheckpointUnavailabilityEdit extends React.Component {
  static contextTypes = {
    router: () => null
  }

  state = {
    entity: null,
    operation: false
  }

  componentDidMount() {
    total.getCheckpointUnavailability({ shopId: this.getShopId(), recordId: this.getRecordId() || '' }).then((resp) => {
      if (!this.getRecordId() && this.getCheckpointId()) {
        resp.data.checkpointId = this.getCheckpointId()
      }

      let entity = resp

      this.setState({ entity })
      this.setBreadcrumbs(entity)
    })
  }

  setBreadcrumbs(data) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const parents = data.parents
    const shopName = parents.shop.name
    const shopId = this.getShopId()
    const recordId = this.getRecordId()

    let crumbs = [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shopId], shopName),
      crumb.unavailabilityAndAppointments([shopId]),
      crumb.checkpointsUnavailability([shopId]),
      recordId ? crumb.editCheckpointUnavailability() : crumb.newCheckpointUnavailability()
    ]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  shouldNotRenderForm() {
    return this.state.operation || !this.state.entity
  }

  render() {
    if (this.shouldNotRenderForm()) {
      return null
    }

    return (
      <Container>
        <EditForm
          data={{ ...this.state.entity.data }}
          checkpoints={this.props.getCheckpoints(this.getShopId())}
          checkpointId={this.props.match.params.checkpointId}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          canDelete={true}
          canEdit={this.props.canEditShop}
        />
      </Container>
    )
  }

  handleSave = (data) => {
    this.setState({ operation: true })

    if (this.getRecordId()) {
      total
        .updateCheckpointUnavailability(data)
        .then(() => {
          this.props.dispatch(
            showMessage(this.props.translation.shopOperationMessages.updateCheckpointSuccess, 'success')
          )
          this.pushPath(Nav.checkpointsUnavailability(this.getShopId()))
        })
        .catch(() =>
          this.props.dispatch(showMessage(this.props.translation.shopOperationMessages.updateCheckpointError, 'error'))
        )
    } else {
      total
        .createCheckpointUnavailability(data)
        .then(() => {
          this.props.dispatch(
            showMessage(this.props.translation.shopOperationMessages.createCheckpointSuccess, 'success')
          )
          this.pushPath(Nav.checkpointsUnavailability(this.getShopId()))
        })
        .catch(() =>
          this.props.dispatch(showMessage(this.props.translation.shopOperationMessages.createCheckpointError, 'error'))
        )
    }
  }

  handleDelete = (data) => {
    this.setState({ operation: true })

    total
      .deleteCheckpointUnavailability(data)
      .then(() => {
        this.props.dispatch(
          showMessage(this.props.translation.shopOperationMessages.deleteCheckpointSuccess, 'success')
        )
        this.pushPath(Nav.checkpointsUnavailability(this.getShopId()))
      })
      .catch(() =>
        this.props.dispatch(showMessage(this.props.translation.shopOperationMessages.deleteCheckpointError, 'error'))
      )
  }

  pushPath(path) {
    this.props.history.push(path)
  }

  getShopId() {
    return this.props.match.params.shopId
  }

  getRecordId() {
    return this.props.match.params.recordId
  }

  getCheckpointId() {
    return this.props.match.params.checkpointId
  }
}

const stateToProps = (state) => ({ canEditShop: state.user.permissions.canEditShop })

export default dataProvider({ checkpoints: (props) => props.match.params.shopId })(
  connect(stateToProps)(timeZoneFilter(translate(SmartCheckpointUnavailabilityEdit)))
)
