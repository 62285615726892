import React, { Component } from 'react'
import { List } from 'dumb/list'

export default class UserList extends Component {
  render() {
    return (
      <List
        data={this.props.data}
        onSelect={(data) => this.props.onSelect(data)}
        dataConverter={(item) => {
          return {
            id: item.id,
            primaryText: `${item.firstName} ${item.secondName}`,
            secondaryText: item.email
          }
        }}
      />
    )
  }
}
