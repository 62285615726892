import React from 'react'
import translate from 'i18n/translate'
import Link from 'dumb/link'

class LoadingAuthorizationItem extends React.Component {
  render() {
    const {
      validityPeriod,
      appointmentTakenRange,
      noAppointmentYet,
      lanAndClient,
      products,
      singleProducts,
      product,
      appointmentTr,
      appOn,
      appAt
    } = this.props.translation.loadingAuthorizations

    const formatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    const formatHourOptions = { hour: 'numeric', minute: 'numeric', hour12: false }

    const d = this.props.data

    const startValidityDate = this.props.dateFormatter(d.startValidityDate, formatOptions)
    const endValidityDate = this.props.dateFormatter(d.endValidityDate, formatOptions)

    const appointment = d.appointment

    const isSingleAppointment =
      appointment && appointment.productAppointments ? appointment.productAppointments.length === 1 : false

    //single product
    const productAppointments = appointment && appointment.productAppointments
    const appointmentWithPlaceTimezone =
      productAppointments && productAppointments[0]?.appointmentTime + (appointment.placeUtcOffset || 0)
    const appointmentDate =
      productAppointments && this.props.dateFormatter(appointmentWithPlaceTimezone, formatOptions, true)
    const offsetUTCString = `(UTC${appointment && appointment.placeUtcOffset > 0 && '+'}${
      appointment && appointment.placeUtcOffset / 3600000
    })`
    const appointmentHour =
      productAppointments &&
      `${this.props.dateFormatter(appointmentWithPlaceTimezone, formatHourOptions, true)} ${offsetUTCString}`

    const statuses = this.props.translation.totalAppointmentStatuses

    const firstLetterLower = (string) => {
      return string[0].toLowerCase() + string.slice(1)
    }

    const statusesTranslation = {
      ToBeConfirmed: statuses.toBeConfirmed,
      Absent: statuses.absent,
      Arrived: statuses.arrived,
      Cancelled: statuses.cancelled,
      ToBeCancelled: statuses.toBeCancelled,
      Valid: statuses.valid,
      Finished: statuses.finished,
      Unknown: statuses.unknown
    }

    return (
      <div style={this.props.style}>
        <div>{lanAndClient(d.loadingAuthorizationNumber, d.clientName, d.country)}</div>
        <div>{validityPeriod(startValidityDate, endValidityDate)}</div>
        {d.products && d.products.length > 0 && (
          <div style={{ lineHeight: '21px' }}>
            {d.products.length === 1 && (
              <span>
                {singleProducts}{' '}
                {product(d.products[0].qty, d.products[0].measureUnit, d.products[0].name, d.products[0].code)}
              </span>
            )}
            ,
            {d.products.length > 1 && (
              <div>
                {products}
                {d.products.map((el, index) => (
                  <div key={index} style={{ fontSize: '15px', margin: '0 0 0 0.5rem' }}>
                    - {product(el.qty, el.measureUnit, el.name, el.code)}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {appointment ? (
          <>
            <div style={{ lineHeight: '23px', marginTop: '0.5rem' }}>
              {appointmentTr}{' '}
              {`${firstLetterLower(statusesTranslation[appointment.status] || statusesTranslation.Unknown)}`} {appOn}{' '}
              {!this.props.limitedView ? (
                <Link onClick={() => this.props.detailClick(appointment)}>
                  {isSingleAppointment ? appointmentTakenRange(appointmentDate, appointmentHour) : appointmentDate}
                </Link>
              ) : (
                <span>
                  {isSingleAppointment ? appointmentTakenRange(appointmentDate, appointmentHour) : appointmentDate}
                </span>
              )}{' '}
              {isSingleAppointment && (
                <span>
                  {appAt} {productAppointments[0].shopName}
                  <div>
                    {productAppointments[0].servicePointName}
                    {productAppointments[0].armCode && ` - ${productAppointments[0].armCode}`}
                  </div>
                </span>
              )}
            </div>
            {!isSingleAppointment &&
              productAppointments &&
              productAppointments.map((item, index) => (
                <div
                  style={{
                    margin: index === 0 ? '0 0 0 0.5rem' : '0.3rem 0 0 0.5rem',
                    lineHeight: '20px'
                  }}
                  key={index}
                >
                  - {appAt}{' '}
                  {this.props.dateFormatter(
                    item.appointmentTime + (appointment.placeUtcOffset || 0),
                    formatHourOptions,
                    true
                  )}{' '}
                  {offsetUTCString} {appAt} {item.shopName}
                  <div style={{ fontSize: '13px', margin: '0 0.5rem' }}>
                    {item.servicePointName}
                    {item.armCode && ` - ${item.armCode}`}
                    <br />
                    {product(item.product.qty, item.product.measureUnit, item.product.name, item.product.code)}
                  </div>
                </div>
              ))}
          </>
        ) : (
          <div>{noAppointmentYet}</div>
        )}
      </div>
    )
  }
}

export default translate(LoadingAuthorizationItem)
