import React, { Component } from 'react'
import css from './time-zone.module.scss'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import { getTimeZoneShortName } from 'utils/date'

export default class TimeZone extends Component {
  state = {
    open: false
  }

  render() {
    return (
      <div className={css.root}>
        <div className={css.zone} onClick={this.handleTouchTap}>
          <div className={css.name}>
            {getTimeZoneShortName(this.props.timeZone.offset)}
          </div>
        </div>
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.handleRequestClose}
          elevation={2}
        >
          {this.props.timeZones.map((x) => (
            <MenuItem
              key={x.id}
              onClick={() => {
                this.props.onChange(x)
                this.setState({ open: false })
              }}
            >
              {x.name}
            </MenuItem>
          ))}
        </Popover>
      </div>
    )
  }

  handleTouchTap = (ev) => {
    ev.preventDefault()

    this.setState({
      open: true,
      anchorEl: ev.currentTarget
    })
  }

  handleRequestClose = () => {
    this.setState({ open: false })
  }
}
