import React, { Component } from 'react'
import { isFunction } from 'lodash/lang'
import { ControlLabel } from 'dumb/control-label'
import css from './enhanced-input.module.scss'
import { withMods } from 'utils/cssm'

const mod = withMods(css)

export default class EnhancedInput extends Component {
  static defaultProps = {
    placeholder: '',
    invalid: false,
    message: '',
    label: '',
    disabled: false,
    autofocus: false,
    value: '',
    type: 'text'
  }

  constructor(props) {
    super(props)

    this.state = {
      value: this.props.value
    }
  }

  componentDidMount() {
    const { value, defaultValue } = this.props

    if (defaultValue && value === '') {
      this.setState({ value: defaultValue || '' })
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props != nextProps) {
      const { value } = nextProps
      this.setState({ value })
    }

    return true
  }

  render() {
    const className = mod.root({ invalid: this.props.invalid }).add(this.props.className)

    return (
      <div className={className}>
        <div className={css.title}>
          <ControlLabel>{this.props.label}</ControlLabel>
          {this.props.attention && <div className={css.attention} />}
        </div>
        <div className={css.controlWrapper}>
          {this.renderHint()}
          {this.renderControl()}
          <div className={css.bar} />
        </div>
        {this.renderMessage()}
      </div>
    )
  }

  renderHint() {
    const { value } = this.state
    const { hint } = this.props

    if (!value || value.length < 1 || !hint) {
      return null
    }

    return (
      <div className={css.hint}>
        <div className={css.hintIndent}>{value}</div>
        <div className={css.hintText}>{hint(value)}</div>
      </div>
    )
  }

  renderControl() {
    return (
      <input
        onClick={this.props.onClick}
        className={css.control}
        value={this.state.value}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        onChange={this.handleChange.bind(this)}
        onFocus={this.handleFocus.bind(this)}
        onKeyPress={this.handleKeyPress.bind(this)}
        onBlur={this.handleBlur.bind(this)}
        autoFocus={this.props.autofocus}
        type={this.props.type}
        onSelect={this.props.onSelect}
      />
    )
  }

  renderMessage() {
    if (!this.props.message.length) {
      return
    }

    return <div className={css.message}>{this.props.message}</div>
  }

  handleFocus(ev) {
    if (isFunction(this.props.onFocus)) {
      this.props.onFocus(ev, this.state.value)
    }
  }

  handleBlur(ev) {
    if (this.state.value === '' && this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue || '' })
    }

    if (isFunction(this.props.onBlur)) {
      this.props.onBlur(ev, this.state.value)
    }
  }

  handleKeyPress(ev) {
    if (isFunction(this.props.onKeyPress)) {
      this.props.onKeyPress(ev, this.state.value)
    }
  }

  handleChange(ev) {
    const value = ev.currentTarget.value

    if (value && this.props.test && !this.props.test(value)) {
      return
    }

    this.setState({ value })
    this.props.onChange(value, ev)
  }
}
