import uniqueId from 'lodash/uniqueId'
import findIndex from 'lodash/findIndex'
import fakeShops from './fake-shops'

const data = [
  {
    id: uniqueId(),
    firstName: 'Richard',
    secondName: 'Roe',
    email: 'richard.roe@gmail.com',
    interfaceLocale: 'fr',
    shopIds: [fakeShops[0].id],
    allCountries: true,
    igg: '13hah1lh5k1lkj',
    countriesAsString: 'FR;DE;ES',
    isAdmin: true,
    isActive: true,
    canManageUserAccounts: true,
    canAddAndDeleteShops: true,
    canUpdateShops: true,
    canOpenAndCloseCheckpoints: true,
    canUpdateServicePointStartAndEndHours: true,
    hasAccessFromMonitor: true,
    insecurePassword: true
  },
  {
    id: uniqueId(),
    firstName: 'John',
    secondName: 'Doe',
    email: 'john.doe@gmail.com',
    interfaceLocale: 'en-GB',
    shopIds: [fakeShops[0].id],
    allCountries: false,
    countriesAsString: 'FR;DE;ES',
    isAdmin: false,
    isActive: true,
    canManageUserAccounts: true,
    canAddAndDeleteShops: true,
    canUpdateShops: true,
    canOpenAndCloseCheckpoints: false,
    canUpdateServicePointStartAndEndHours: true,
    hasAccessFromMonitor: true,
    insecurePassword: false
  }
]

export function getResponse(data, add) {
  return {
    data: add ? Object.assign({}, data, add) : data
  }
}

export function update(entity) {
  const index = findIndex(data, { id: entity.id })
  data[index] = entity
}

export default data
