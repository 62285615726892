import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import MainLayout from 'smart/main-layout'
import Login from 'smart/login'
import loginClassic from 'smart/login-classic'
import * as Nav from 'app/nav'
import MainRouting from './main-routing'
import storeUser from 'smart/store-user'
import { connect } from 'react-redux'

function Routing(props) {
  const loadedUser = props.loaded

  return (
    <Router>
      <Switch>
        {!loadedUser && <Route path={Nav.login()} component={Login} />}
        {!loadedUser && <Route path={Nav.loginClassic()} component={loginClassic} />}
        {loadedUser && (
          <MainLayout>
            <MainRouting />
          </MainLayout>
        )}
        {!loadedUser && <Redirect to={Nav.login()} />}
      </Switch>
    </Router>
  )
}

export default storeUser()(connect((state: any) => ({ loaded: state.user.loaded }))(Routing))
