import React, { Component } from 'react'
import translate from 'i18n/translate'
import Link from 'dumb/link'
import { Form, FormGroup } from 'dumb/form'
import Text from 'dumb/text'
import { withStyles } from '@material-ui/core/styles'
import TotalAppointmentActions from 'dumb/total-appointment/total-appointment-actions'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import { translateState } from 'provider/total-appointment-statuses'
import { totalScheduler } from 'provider'

class AppointmentScheduling extends Component {
  commaList(list) {
    return list.join(', ')
  }

  openFile(id) {
    totalScheduler.getFile(id)
  }

  render() {
    const {
      deliveredCustomer,
      loadingAuthorisationNumber,
      transportCompany,
      driver,
      state,
      reason,
      internalComment,
      appointmentTakenTime,
      sapCommand,
      relatedGenericProducts,
      orderQuantity,
      flexiTank
    } = this.props.translation.totalAppointments

    const { common } = this.props.translation

    const appointmentStatusesTranslation = this.props.translation.totalAppointmentStatuses

    const data = this.props.data

    const status = data.status

    const cancelledOrAbsent = status === 'Absent' || status === 'Cancelled'

    const formatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    const formatHourOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
    const dateFormat = (date) => this.props.dateFormatter(date, formatOptions)
    const dateHourFormat = (date) => this.props.dateFormatter(date, formatHourOptions)

    return (
      <Form
        style={this.props.style}
        title={`${appointmentTakenTime(
          dateFormat(data.startDate),
          dateHourFormat(data.startDate),
          data.shopName,
          data.servicePointName,
          data.armCode,
          true
        )}`}
      >
        <FormGroup>
          <Text type="caption">{sapCommand}</Text>
          <Text type="body-1">{data.sapCommand}</Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{loadingAuthorisationNumber}</Text>
          <Text type="body-1">
            <Link onClick={() => this.props.onLoadingAuthorizationClick(data.loadingAuthorizationId, data.startDate)}>
              {data.loadingAuthorizationId}
            </Link>
          </Text>
        </FormGroup>

        <FormGroup>
          <Text type="caption">{deliveredCustomer}</Text>
          <Text type="body-1">{`${data.clientName}, ${data.countryCode}`}</Text>
        </FormGroup>

        <FormGroup>
          <Text type="caption">{relatedGenericProducts(1)}</Text>
          <Text type="body-1">
            {data.product.name} / {data.product.code}
          </Text>
        </FormGroup>

        <FormGroup>
          <Text type="caption">{orderQuantity}</Text>
          <Text type="body-1">
            {data.product.qty} {data.product.measureUnit}
          </Text>
        </FormGroup>

        {data.driverName && data.driverSurname && (
          <FormGroup>
            <Text type="caption">{driver}</Text>
            <Text type="body-1">
              {`${data.driverName} `}
              <span style={{ textTransform: 'uppercase' }}>{data.driverSurname}</span>
            </Text>
          </FormGroup>
        )}

        <FormGroup>
          <Text type="caption">{transportCompany}</Text>
          <Text type="body-1">{data.transportCompanyName}</Text>
        </FormGroup>

        <FormGroup>
          <Text type="caption">{flexiTank}</Text>
          <Text type="body-1">{common.booleanValue(data.useFlexiTank)}</Text>
        </FormGroup>

        {data.comment && (
          <FormGroup>
            <Text type="caption">Comment</Text>
            <Text type="body-1">{data.comment}</Text>
          </FormGroup>
        )}

        {data.files && data.files.length > 0 && (
          <FormGroup>
            <Text type="caption">Files</Text>
            <Text type="body-1">
              {data.files.map((el) => (
                <span style={{ marginRight: '0.5rem' }} key={el.id}>
                  <Link
                    onClick={() => this.openFile(el.id, el.name)}
                    style={{ display: 'inline-flex', alignItems: 'flex-end' }}
                  >
                    <FileIcon /> {el.name}
                  </Link>
                </span>
              ))}
            </Text>
          </FormGroup>
        )}

        <FormGroup>
          <Text type="caption">{state}</Text>
          <Text type="body-1">
            {translateState(appointmentStatusesTranslation, status) || appointmentStatusesTranslation.unknown}
          </Text>
        </FormGroup>
        {cancelledOrAbsent && (
          <FormGroup>
            <Text type="caption">{reason}</Text>
            <Text type="body-1">{data.cancelReason}</Text>
          </FormGroup>
        )}
        {data.internalCommentData && (
          <FormGroup>
            <Text type="caption">{internalComment}</Text>
            <Text type="body-1">{data.internalCommentData}</Text>
          </FormGroup>
        )}
        {this.props.canEdit && (
          <TotalAppointmentActions
            onCancelClick={(cancelReason) => this.props.onCancelClick(this.props.data, cancelReason)}
            onUpdateClick={() => this.props.onUpdateClick(this.props.data)}
            onDeclareArrivedClick={() => this.props.onDeclareArrivedClick(this.props.data)}
            onDeclareFinishedClick={() => this.props.onDeclareFinishedClick(this.props.data)}
            onDeclareAbsentClick={() => this.props.onDeclareAbsentClick(this.props.data)}
            appointmentStatus={status}
          />
        )}
      </Form>
    )
  }
}

const styles = (theme) => ({ button: { margin: theme.spacing(1) }, leftIcon: { marginRight: theme.spacing(1) } })

export default withStyles(styles)(translate(AppointmentScheduling))
