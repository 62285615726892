import React, { Component } from 'react'
import css from './user.module.scss'
import Avatar from '@material-ui/core/Avatar'
import Person from '@material-ui/icons/Person'
import Button from '@material-ui/core/Button'
import Settings from '@material-ui/icons/SettingsApplications'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Popover from '@material-ui/core/Popover'
import Text from 'dumb/text'
import translate from 'i18n/translate'

class User extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { firstName, secondName, email } = this.props

    return (
      nextState.open !== this.state.open ||
      nextProps.firstName !== firstName ||
      nextProps.secondName !== secondName ||
      nextProps.email !== email
    )
  }

  render() {
    return (
      <div className={css.root}>
        <div className={css.user} onClick={this.handleTouchTap}>
          <div className={css.name}>
            {this.props.firstName} {this.props.secondName}
          </div>
          <Avatar style={{ backgroundColor: '#FFF', border: 'solid 1px #374649' }}>
            <Person style={{ color: '#374649' }} />
          </Avatar>
        </div>
        <Popover
          id="simple-popper"
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.handleRequestClose}
          elevation={2}
        >
          <div className={css.popover}>
            <div className={css.popoverUser}>
              {this.props.firstName} {this.props.secondName}
              <Text type="body-2">{this.props.email}</Text>
            </div>
            <div className={css.buttonsWrap}>
              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <Button onClick={this.handleSettingsTap}>
                  <Settings className={css.leftButtonIcon} />
                  {this.props.translation.userInAppBar.settingsButton}
                </Button>
                <Button onClick={this.handleExitTap}>
                  <ExitToApp className={css.leftButtonIcon} />
                  {this.props.translation.userInAppBar.exitButton}
                </Button>
              </div>
            </div>
          </div>
        </Popover>
      </div>
    )
  }

  handleSettingsTap = () => {
    this.setState({ open: false })
    this.props.onSettingsTap()
  }

  handleExitTap = () => {
    this.props.onExitTap()
  }

  handleTouchTap = (ev) => {
    ev.preventDefault()

    this.setState({ open: true, anchorEl: ev.currentTarget })
  }

  handleRequestClose = () => {
    this.setState({ open: false })
  }
}

export default translate(User)
