import React, { Component } from 'react'
import css from './text-field-message.module.scss'

const messageTypes = {
  error: 'error',
  info: 'info',
  custom: 'custom'
}

const typeToClass = (type) => {
  switch (type) {
    case messageTypes.error:
      return css.alertMessage
    case messageTypes.info:
      return css.infoMessage
    case messageTypes.custom:
      return css.customMessage
    default:
      return css.customMessage
  }
}

export default class TextFieldMessage extends Component {
  static defaultProps = {
    type: messageTypes.info,
    displayMessage: true,
    check: () => false
  }

  constructor(props) {
    super(props)

    this.state = {
      displayMessage: this.props.displayMessage
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props != nextProps)
      this.setState({ displayMessage: nextProps.displayMessage })

    return true
  }

  render() {
    if (!this.state.displayMessage) return false

    return (
      <div className={typeToClass(this.props.type)}>{this.props.children}</div>
    )
  }
}

TextFieldMessage.displayName = 'TextFieldMessage'
