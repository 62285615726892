import { ThemeOptions } from '@material-ui/core'

export const primaryColor = '#ed0000'
export const accentColor = '#003859'

const theme: ThemeOptions & any = {
  overrides: {
    MuiCard: {
      root: {
        borderRadius: '16px'
      }
    },
    MuiTextField: {
      root: {
        marginTop: 0
      }
    },
    MuiIconButton: {
      root: {
        color: '#000000'
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        '&:hover': {
          color: '#ed0000 !important'
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: '24px',
        border: 'solid 1px #ed0000',
        color: '#FFF !important',
        backgroundColor: '#ed0000 !important',
        '&:hover': {
          color: '#ed0000 !important',
          backgroundColor: '#FFF !important'
        },
        '&:disabled': {
          color: '#a3a3a3 !important',
          backgroundColor: '#d5d5d5 !important',
          border: 'solid 1px #d5d5d5'
        }
      },
      label: {
        fontSize: '13px',
        padding: '0 0.5rem',
        textTransform: 'none'
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Nunito"',
    button: {
      fontWeight: 600
    }
  },
  palette: {
    primary: {
      500: '#ed0000',
      700: '#ed0000'
    },
    secondary: {
      A400: '#374649'
    },
    button: {
      color: '#ffffff !important'
    }
  }
}

export default theme
