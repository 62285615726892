import React from 'react'
import css from './container.module.scss'

export default class Container extends React.Component {
  render() {
    return (
      <div className={css.root} style={this.props.style}>
        <div className={css.container} style={this.props.style}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
