import uniqueId from 'lodash/uniqueId'
import fakeShops from './fake-shops'
import fakeLines from './fake-lines'
import fakeUsers from 'provider/fake-data/fake-users'

const data = [
  {
    id: uniqueId(),
    lineId: fakeLines[0].id,
    lineName: fakeLines[0].name,
    name: 'Piste 1',
    description: '',
    statusChange: {
      user: fakeUsers[0],
      date: Date.now()
    },
    status: 'started',
    loadingOfFluids: false,
    loadingFluidsFlexitank: true,
    loadingOfConventionalCharges: false
  },
  {
    id: uniqueId(),
    lineId: fakeLines[0].id,
    lineName: fakeLines[0].name,
    name: 'Piste 2',
    description: '',
    statusChange: {
      user: fakeUsers[0],
      date: Date.now()
    },
    status: 'starting'
  },
  {
    id: uniqueId(),
    lineId: fakeLines[0].id,
    lineName: fakeLines[0].name,
    name: 'Piste 3',
    description: '',
    statusChange: {
      user: fakeUsers[0],
      date: Date.now()
    },
    status: 'finished'
  },
  {
    id: uniqueId(),
    lineId: fakeLines[0].id,
    lineName: fakeLines[0].name,
    name: 'Piste 4',
    description: '',
    statusChange: {
      user: fakeUsers[0],
      date: Date.now()
    },
    status: 'finished'
  },
  {
    id: uniqueId(),
    lineId: fakeLines[0].id,
    lineName: fakeLines[0].name,
    name: 'Piste 5',
    description: '',
    statusChange: {
      user: fakeUsers[0],
      date: Date.now()
    },
    status: 'finished'
  },
  {
    id: uniqueId(),
    lineId: fakeLines[0].id,
    lineName: fakeLines[0].name,
    name: 'Piste 6',
    description: '',
    statusChange: {
      user: fakeUsers[0],
      date: Date.now()
    },
    status: 'finished'
  }
]

export const monitoring = {
  id: uniqueId(),
  name: 'Checkpoint 1',
  status: 'finished',
  statusChange: {
    user: fakeUsers[0],
    date: Date.now()
  },
  personsComing: 2,
  personsServed: 12,
  personsInService: 1,
  line: {
    name: 'Normal checkout',
    personsServed: 144,
    personsWaiting: 23,
    waitingTime: 33
  },
  closingTime: {
    minutes: '00',
    hours: '00'
  },
  startTime: {
    minutes: '00',
    hours: '00'
  }
}

export function getResponse(data, add, addLine) {
  return {
    data: add ? Object.assign({}, data, add) : data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      },
      line: addLine
        ? {
            id: fakeLines[0].id,
            name: fakeLines[0].name
          }
        : null
    }
  }
}

export default data
