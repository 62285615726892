import React, { Component } from 'react'
import translate from 'i18n/translate'
import TotalAppointmentStatuses from 'provider/total-appointment-statuses'
import { Form, FormGroup } from 'dumb/form'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import DoneIcon from '@material-ui/icons/Done'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from 'dumb/text-field'

class TotalAppointmentActions extends Component {
  static defaultProps = {
    noIcons: false
  }

  state = {
    cancelMode: false,
    cancelReason: ''
  }

  toggleCancelMode = () => {
    this.setState({ cancelMode: !this.state.cancelMode })
  }

  handleCancelAppointment = () => {
    this.toggleCancelMode()
    this.props.onCancelClick(this.state.cancelReason)
  }

  renderConfirmRemoveActions = () => {
    const { cancelAppointmentMessage, reason } = this.props.translation.totalAppointments
    const { yes, no } = this.props.translation.common
    const { classes } = this.props

    return (
      <Form style={{ width: '100%' }}>
        <div style={{ margin: '16px' }}>{cancelAppointmentMessage}</div>
        <FormGroup>
          <TextField
            label={reason}
            placeholder={reason}
            autofocus={true}
            value={this.state.cancelReason}
            onChange={(val) => this.setState({ cancelReason: val })}
          />
        </FormGroup>
        <div style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem' }}>
          <Button onClick={() => this.toggleCancelMode()}>
            {!this.props.noIcons && <CloseIcon className={classes.leftIcon} />}
            {no}
          </Button>
          <Button onClick={this.handleCancelAppointment}>
            {!this.props.noIcons && <DoneIcon className={classes.leftIcon} />}
            {yes}
          </Button>
        </div>
      </Form>
    )
  }

  renderActions = () => {
    const { declareFinished, declareArrived, declareAbsent, update, cancel } = this.props.translation.totalAppointments

    const { classes } = this.props

    const declareFinishedButton = (
      <Button onClick={() => this.props.onDeclareFinishedClick(this.props.data)}>
        {!this.props.noIcons && <CheckIcon className={classes.leftIcon} />}
        {declareFinished}
      </Button>
    )

    const declareArrivedButton = (
      <Button onClick={() => this.props.onDeclareArrivedClick(this.props.data)}>
        {!this.props.noIcons && <CheckIcon className={classes.leftIcon} />}
        {declareArrived}
      </Button>
    )

    const declareAbsentButton = (
      <Button onClick={() => this.props.onDeclareAbsentClick(this.props.data)}>
        {!this.props.noIcons && <CheckIcon className={classes.leftIcon} />}
        {declareAbsent}
      </Button>
    )

    const updateButton = (
      <Button onClick={() => this.props.onUpdateClick(this.props.data)}>
        {!this.props.noIcons && <EditIcon className={classes.leftIcon} />}
        {update}
      </Button>
    )

    const cancelButton = (
      <Button onClick={() => this.toggleCancelMode()}>
        {!this.props.noIcons && <CloseIcon className={classes.leftIcon} />}
        {cancel}
      </Button>
    )

    const status = this.props.appointmentStatus

    switch (TotalAppointmentStatuses[status]) {
      case TotalAppointmentStatuses.ToBeConfirmed:
        return (
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
              padding: '0.5rem',
              flexWrap: 'wrap'
            }}
          >
            {updateButton} {cancelButton}
          </div>
        )
      case TotalAppointmentStatuses.ToBeCancelled:
        return (
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
              padding: '0.5rem',
              flexWrap: 'wrap'
            }}
          >
            {updateButton} {cancelButton}
          </div>
        )
      case TotalAppointmentStatuses.Valid:
        return (
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
              padding: '0.5rem',
              flexWrap: 'wrap'
            }}
          >
            {declareArrivedButton} {declareAbsentButton} {updateButton}
            {cancelButton}
          </div>
        )
      case TotalAppointmentStatuses.Absent:
        return (
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
              padding: '0.5rem',
              flexWrap: 'wrap'
            }}
          >
            {updateButton}
          </div>
        )
      case TotalAppointmentStatuses.Arrived:
        return (
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
              padding: '0.5rem',
              flexWrap: 'wrap'
            }}
          >
            {declareFinishedButton} {updateButton}
          </div>
        )
      default:
        return (
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
              padding: '0.5rem',
              flexWrap: 'wrap'
            }}
          >
            {updateButton}
          </div>
        )
    }
  }

  render() {
    return !this.state.cancelMode ? this.renderActions() : this.renderConfirmRemoveActions()
  }
}

const styles = (theme) => ({
  button: { margin: theme.spacing(1) },
  leftIcon: { marginRight: '0.5rem' }
})

export default withStyles(styles)(translate(TotalAppointmentActions))
