import { Input, IconButton, InputAdornment, InputProps } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'
import { useRef } from 'react'

type Props = {
  onClear: () => void
  clearDisabled?: boolean
} & InputProps

export function SearchInput({ onClear, clearDisabled, ...props }: Props) {
  const inputRef = useRef<HTMLInputElement>()

  function handleClear() {
    inputRef.current?.focus()
    onClear()
  }

  return (
    <Input
      inputRef={inputRef}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            size="small"
            disabled={clearDisabled !== undefined ? clearDisabled : !props.value}
            onClick={handleClear}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      }
      {...props}
    />
  )
}
