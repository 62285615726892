import uniqueId from 'lodash/uniqueId'
import findIndex from 'lodash/findIndex'
import fakeShops from './fake-shops'

const store = [
  {
    id: uniqueId(),
    isActive: false,
    name: 'C Company 3',
    code: 'Code 3',
    users: []
  },
  {
    id: uniqueId(),
    isActive: true,
    name: 'B Company 1',
    code: 'Code 1',
    users: [
      {
        id: uniqueId(),
        name: 'User',
        email: 'user1@gmail.com',
        isActive: true,
        lastConnection: Date.now(),
        language: 'fr'
      },
      {
        id: uniqueId(),
        name: 'User no last connection',
        email: 'user2@gmail.com',
        isActive: true,
        language: 'fr'
      }
    ]
  },
  {
    id: uniqueId(),
    isActive: false,
    name: 'A Company 2',
    code: 'Code 2',
    users: []
  }
]

export function getResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export function getUserResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      },
      transportCompany: {
        id: store[0].id,
        name: store[0].name
      }
    }
  }
}

export function update(entity) {
  const index = findIndex(store, { id: entity.id })
  store[index] = entity
}

export default store
