import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import css from './page-loader.module.scss'

export default class PageLoader extends Component {
  render() {
    return (
      <div className={css.root}>
        <CircularProgress color="secondary" />
      </div>
    )
  }
}
