import React, { Component } from 'react'
import translate from 'i18n/translate'
import css from './login-form.module.scss'
import Button from '@material-ui/core/Button'

class LoginForm extends Component {
  render() {
    const { logIn } = this.props.translation.login

    return (
      <React.Fragment>
        <img style={{ width: '100%', maxWidth: '32rem' }} src="logo-totalenergies.svg" />
        <div style={{ maxWidth: '30rem', textAlign: 'center' }}>
          {this.props.translation.login.conditional}{' '}
          <a href={this.props.translation.login.link}>{this.props.translation.login.here}</a>
        </div>
        <div className={css.form} style={{ marginTop: '1rem' }}>
          <div className={css.formBody}>
            <div className={css.button}>
              <Button onClick={() => this.props.onSubmit()}>{logIn}</Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default translate(LoginForm)
