import React from 'react'
import { connect } from 'react-redux'
import * as Nav from 'app/nav'
import translate from 'i18n/translate'
import dataProvider from 'smart/isolated-data-provider'
import timeZoneFilter from 'smart/time-zone-filter'
import Container from 'dumb/container'
import { shallowDiff } from 'utils/object'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import { total } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import LoadingArmEdit from 'dumb/loading-arm-total/loading-arm-edit'
import { checkpoint } from 'provider'

class SmartLoadingArmEdit extends React.Component {
  static contextTypes = {
    router: () => null
  }

  state = {
    operation: false,
    data: null,
    genericProducts: null,
    servicePoints: null
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      shallowDiff(nextProps, this.props, ['timeZoneId']) ||
      shallowDiff(nextState, this.state, ['servicePoints', 'genericProducts', 'data'])
    )
  }

  fetchData = () => {
    return total.getLoadingArm({ shopId: this.getShopId(), id: this.getLoadingArmId() }).then((resp) => {
      this.setState({ data: resp })
      return resp
    })
  }

  fetchCheckpointList = () => {
    checkpoint.getCheckpointList(this.getShopId()).then((resp) => {
      this.setState({ servicePoints: resp.data })
    })
  }

  fetchGenericProductsList = () => {
    total.getGenericProducts({ shopId: this.getShopId() }).then((resp) => {
      this.setState({ genericProducts: resp.data })
    })
  }

  componentDidMount() {
    this.fetchCheckpointList()
    this.fetchGenericProductsList()

    this.getLoadingArmId()
      ? this.fetchData().then((data) => this.setBreadcrumbsRequest(data))
      : this.setBreadcrumbsStandAlone()
  }

  setBreadcrumbsStandAlone() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const shopId = this.getShopId()
    const shopName = this.props.shops.find((shop) => shop.id == shopId).name
    const crumbs = [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shopId], shopName),
      crumb.loadingArms([shopId]),
      crumb.newLoadingArm([shopId])
    ]
    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  setBreadcrumbsRequest(data) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const shopId = this.getShopId()
    const shopName = data.parents.shop.name
    const loadingArmId = this.getLoadingArmId()
    const loadingArmName = data.data.name
    const crumbs = [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shopId], shopName),
      crumb.loadingArms([shopId]),
      crumb.newLoadingArm([shopId, loadingArmId], loadingArmName)
    ]
    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  render() {
    return (
      <Container>
        <LoadingArmEdit
          genericProducts={this.state.genericProducts}
          servicePoints={this.state.servicePoints}
          data={
            this.state.data
              ? this.state.data.data
              : {
                  name: '',
                  code: '',
                  genericProducts: [],
                  servicePoints: [],
                  priority: '0'
                }
          }
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          canEdit={this.props.canEditShop}
        />
      </Container>
    )
  }

  handleSave = (data) => {
    this.setState({ operation: true })

    const msg = this.props.translation.loadingArmOperationMessages

    const requestData = { ...data, shopId: this.getShopId() }

    if (this.getLoadingArmId()) {
      total
        .updateLoadingArm(requestData)
        .then(() => {
          this.props.dispatch(showMessage(msg.updateSuccess, 'success'))
          this.pushPath(Nav.loadingArms(this.getShopId()))
        })
        .catch(() => this.props.dispatch(showMessage(msg.updateError, 'error')))
    } else {
      total
        .createLoadingArm(requestData)
        .then(() => {
          this.props.dispatch(showMessage(msg.createSuccess, 'success'))
          this.pushPath(Nav.loadingArms(this.getShopId()))
        })
        .catch(() => this.props.dispatch(showMessage(msg.createError, 'error')))
    }
  }

  handleDelete = (id) => {
    this.setState({
      operation: true
    })

    const msg = this.props.translation.loadingArmOperationMessages

    total
      .deleteLoadingArm(id)
      .then(() => {
        this.props.dispatch(showMessage(msg.deleteSuccess, 'success'))
        this.pushPath(Nav.loadingArms(this.getShopId()))
      })
      .catch(() => this.props.dispatch(showMessage(msg.deleteError, 'error')))
  }

  pushPath = (path) => this.props.history.push(path)

  getShopId = () => this.props.match.params.shopId

  getLoadingArmId = () => this.props.match.params.loadingArmId
}

const stateToProps = (state) => ({ canEditShop: state.user.permissions.canEditShop })

export default connect(stateToProps)(
  dataProvider({ shops: true, brands: true, timeZones: true })(timeZoneFilter(translate(SmartLoadingArmEdit)))
)
