import React, { Component } from 'react'
import css from './control-label.module.scss'

export default class Label extends Component {
  static defaultProps = {
    children: ''
  }

  shouldComponentUpdate(nextProps) {
    return this.props.children !== nextProps.children
  }

  render() {
    if (!this.props.children || !this.props.children.length) return null

    return (
      <section className={css.root} style={this.props.style}>
        {this.props.children}
      </section>
    )
  }
}
