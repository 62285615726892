import React, { Component } from 'react'
import { TextField } from 'dumb/text-field'
import translate from 'i18n/translate'
import css from './login-form.module.scss'
import Button from '@material-ui/core/Button'

class LoginForm extends Component {
  state = {
    login: '',
    password: ''
  }

  render() {
    const { logIn, email, password } = this.props.translation.login

    return (
      <React.Fragment>
        <img style={{ width: '100%', maxWidth: '32rem' }} src="logo-totalenergies.svg" />
        <div className={css.form} style={{ marginTop: '1rem' }}>
          <div className={css.formBody}>
            <div className={css.input}>
              <TextField
                value={this.state.login}
                placeholder={email}
                onChange={(val) => this.handleLoginChange(val)}
                onKeyPress={(ev) => this.handleKeyPress(ev)}
                disabled={this.props.disabled}
              />
            </div>
            <div className={css.input}>
              <TextField
                value={this.state.password}
                placeholder={password}
                type="password"
                onChange={(val) => this.handlePasswordChange(val)}
                onKeyPress={(ev) => this.handleKeyPress(ev)}
                disabled={this.props.disabled}
              />
            </div>
            <div className={css.button}>
              <Button
                onClick={this.handleSubmit.bind(this)}
                disabled={!(this.state.password && this.state.login) || this.props.disabled}
              >
                {logIn}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  handleLoginChange(login) {
    this.setState({ login })
  }

  handlePasswordChange(password) {
    this.setState({ password })
  }

  handleKeyPress(ev) {
    if (ev.key === 'Enter') this.handleSubmit()
  }

  handleSubmit() {
    this.props.onSubmit(this.state.login, this.state.password)
  }
}

export default translate(LoginForm)
