import MUIAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { ReactNode } from 'react'

function AppBar(props: { children: ReactNode }) {
  return (
    <MUIAppBar color={'inherit'} position="static" elevation={2}>
      <Toolbar>{props.children}</Toolbar>
    </MUIAppBar>
  )
}

export { AppBar }
