import Dropdown from 'dumb/dropdown'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import { useTranslation } from 'i18n/translate'
import { produce } from 'immer'

type ChangeData = { brandId: number | string }

type Props = {
  brands: any[]
  onChange?: (data: ChangeData) => void
  brandId?: number | string | null
}

function BrandFilter({ brands, onChange, brandId }: Props) {
  const { tr } = useTranslation()

  const dropdownBrands =
    brands?.length > 1
      ? produce(brands, (draft) => {
          draft.unshift({ id: '-1', name: tr.common.all })
        })
      : brands

  function handleBrandChange(brandId?: number | string) {
    const brand = brandId === '-1' ? (null as any) : brandId

    onChange?.({ brandId: brand })
  }

  return (
    <SubBar>
      {dropdownBrands.length > 1 && (
        <SubBarControl title={tr.common.brand}>
          <Dropdown
            value={brandId || '-1'}
            data={dropdownBrands}
            dataConverter={(item) => ({ value: item.id, text: item.name })}
            onChange={handleBrandChange}
          />
        </SubBarControl>
      )}
    </SubBar>
  )
}

export { BrandFilter }
