import axios from 'axios'

export default {
  getTranslationConfig() {
    return axios.get('getTranslationConfig')
  },
  getTranslationList(data) {
    return axios.get('getTranslationList', { params: { term: data.term || '', brandId: data.brandId || '' } })
  },
  getTranslation(id = '') {
    return axios.get('getTranslation', { params: { id } })
  },
  createTranslation(data) {
    return axios.post('createTranslation', data)
  },
  updateTranslation(data) {
    return axios.put('updateTranslation', data)
  },
  deleteTranslation(id) {
    return axios.delete('deleteTranslation', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  getTranslationByBrandAndSourceText(data, config) {
    return axios.get('getTranslationByBrandAndSourceText', { params: data, ...config })
  }
}
