import React from 'react'
import EnhancedInput from './enhanced-input'
import css from './enhanced-input.module.scss'
import { withMods } from 'utils/cssm'

const mod = withMods(css)

class EnhancedTextarea extends EnhancedInput {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    super.componentDidMount()
    this.correctHeight(this.inputRef.current)
  }

  componentDidUpdate() {
    this.correctHeight(this.inputRef.current)
  }

  renderControl() {
    return (
      <textarea
        rows={1}
        style={{ color: '#374649' }}
        className={mod.control({ multiline: true })}
        value={this.state.value}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        onChange={(ev) => this.handleChange(ev)}
        onFocus={this.props.onFocus}
        onBlur={(ev) => this.handleBlur(ev)}
        onKeyPress={this.props.onKeyPress}
        autoFocus={this.props.autoFocus}
        onClick={this.props.onClick}
        onSelect={this.props.onSelect}
        ref={this.inputRef}
      />
    )
  }

  correctHeight(element) {
    if (!element) {
      return
    }

    const offset = element.offsetHeight - element.clientHeight

    element.style = { ...element.style, height: 'auto' }

    const h = element.scrollHeight + offset

    if (h) {
      element.style.height = `${h}px`
    }
  }
}

export default EnhancedTextarea
