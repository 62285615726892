const TotalAppointmentStatuses = {
  Valid: 'valid',
  ToBeCancelled: 'toBeCancelled',
  Cancelled: 'cancelled',
  Arrived: 'arrived',
  Absent: 'absent',
  ToBeConfirmed: 'toBeConfirmed',
  Finished: 'finished',
  Unknown: 'unknown'
}

export function translateState(translation, state) {
  return translation[TotalAppointmentStatuses[state]] || translation[TotalAppointmentStatuses.Unknown]
}

export default TotalAppointmentStatuses
