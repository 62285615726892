import React, { Component } from 'react'
import css from './tile-container.module.scss'
import { withMods } from 'utils/cssm'

const mod = withMods(css)

export default class TileContainer extends Component {
  render() {
    return (
      <div
        className={mod.root({ inline: this.props.inline })}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    )
  }
}
