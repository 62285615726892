import React from 'react'
import Scheduler from './scheduler'
import { DatePicker } from 'dumb/date'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import { EnhancedInput } from 'dumb/text-field'
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts'
import css from './appointments-scheduling.module.scss'
import { totalScheduler } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import translate from 'i18n/translate'
import { connect } from 'react-redux'
import Text from 'dumb/text/text'
import ClearIcon from '@material-ui/icons/Clear'
import debounce from 'lodash/debounce'
import { locale, loadMessages } from 'devextreme/localization'
import frMessages from 'devextreme/localization/messages/fr.json'
import Dialog from '@material-ui/core/Dialog'
import UnavailabilityForm from './unavailability-form'
import { produce } from 'immer'
import { total } from 'provider'
import { compareDates } from 'utils/date'
import IconButton from '@material-ui/core/IconButton'
import ChartIcon from '@material-ui/icons/InsertChartOutlined'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import AppointmentScheduling from './appointment-view-scheduling'
import * as Nav from 'app/nav'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CircularProgress from '@material-ui/core/CircularProgress'
import TotalAppointmentStatuses from 'provider/total-appointment-statuses'
import hideTimeZone from '../hide-zone'
import moment from 'moment-timezone'
import { Popover } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { GlobalStylesDevExtremeScheduler, ZoomContainer, ZoomControl, ZoomValue } from './styled'

const minHeight = 10
const maxHeight = 250
const defaultAppointmentHeight = 40
const zoomStep = Math.floor((defaultAppointmentHeight / 100) * 20)
const zoomStorageKey = `PLANING_APPOINTMENT_HEIGHT`

function calcCapacity(appointments, timeZoneOffset, date) {
  let d = [
    { name: '06 h', count: 0 },
    { name: '07 h', count: 0 },
    { name: '08 h', count: 0 },
    { name: '09 h', count: 0 },
    { name: '10 h', count: 0 },
    { name: '11 h', count: 0 },
    { name: '12 h', count: 0 },
    { name: '13 h', count: 0 },
    { name: '14 h', count: 0 },
    { name: '15 h', count: 0 },
    { name: '16 h', count: 0 },
    { name: '17 h', count: 0 },
    { name: '18 h', count: 0 }
  ]

  appointments.forEach((a) => {
    if (compareDates(date, a.startDate) !== 0 && compareDates(date, a.endDate) !== 0) {
      return null
    }

    const startHours = new Date(a.startDate + timeZoneOffset).getUTCHours()
    const startMinutes = new Date(a.startDate + timeZoneOffset).getUTCMinutes()

    const endHours = new Date(a.endDate + timeZoneOffset).getUTCHours()
    const endMinutes = new Date(a.endDate + timeZoneOffset).getUTCMinutes()

    const start = startMinutes > 50 ? startHours + 1 : startHours
    const end = endMinutes < 10 ? endHours - 1 : endHours

    for (let i = start; i <= end; i++) {
      const index = i - 6
      if (d[index]) {
        d[index].count++
      }
    }
  })

  return d
}

class AppointmentsScheduling extends React.Component {
  constructor(props) {
    super(props)
    loadMessages(frMessages)
    locale(this.props.locale)

    const storeAppointmentHeight = Number(localStorage.getItem(zoomStorageKey)) || 0

    const initialAppointmentHeight =
      storeAppointmentHeight >= minHeight && storeAppointmentHeight <= maxHeight
        ? storeAppointmentHeight
        : defaultAppointmentHeight

    this.state = {
      currentDate: Date.now(),
      schedulerHeight: null,
      config: null,
      data: null,
      searchResult: null,
      searchTerm: '',
      openForm: false,
      formData: null,
      displayChart: localStorage.getItem('scheduling_chart_display') === 'true',
      selectedAppointment: null,
      loading: false,
      appointmentHeight: initialAppointmentHeight
    }
  }

  editState = (fn, cb) => this.setState(produce(fn), cb)

  getPlaceId = () => this.props.match.params.shopId

  fetchConfig() {
    this.editState((draft) => {
      draft.loading = true
    })

    totalScheduler
      .getSchedulerConfig({ placeId: Number(this.getPlaceId()) })
      .then((resp) => {
        this.editState((draft) => {
          draft.config = resp.data
          draft.loading = false
        })

        this.setBreadcrumbs(resp)
      })
      .catch(() => {
        this.editState((draft) => {
          draft.loading = false
        })
      })
  }

  fetchSchedulerData = (cb = () => {}) => {
    this.editState((draft) => {
      draft.loading = true
    })

    totalScheduler
      .getSchedulerData({ date: this.state.currentDate, placeId: Number(this.getPlaceId()) })
      .then((resp) => {
        this.editState((draft) => {
          draft.data = resp
          draft.loading = false

          const { selectedAppointment } = this.state
          if (selectedAppointment) {
            const appointments = resp.appointments
            const responseAppointmentSelected = appointments.find((a) => {
              return (
                a.sapCommand + a.genericProductCode ===
                selectedAppointment.sapCommand + selectedAppointment.genericProductCode
              )
            })
            draft.selectedAppointment = responseAppointmentSelected || null
          }
        })
      })
      .catch(() => {
        this.editState((draft) => {
          draft.loading = false
        })
      })
      .finally(cb)
  }

  timeout = null

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  autoFetchSchedulerData = () => {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => this.fetchSchedulerData(this.autoFetchSchedulerData), 30000)
  }

  search(data) {
    totalScheduler.searchSchedulerAppointments(data).then((resp) => {
      this.editState((draft) => {
        draft.searchResult = resp
      })
    })
  }

  setBreadcrumbs() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const shopId = this.props.match.params.shopId
    const shopName = this.props.shops.find((x) => x.id == shopId).name

    let crumbs = [crumb.home(), crumb.shops(), crumb.shop([shopId], shopName), crumb.appointmentsScheduling()]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  async componentDidMount() {
    const zoom = Number(localStorage.getItem('scheduleZoom'))
    if (zoom && zoom >= 0.6 && zoom <= 1.4) {
      await this.setState({ zoom })
    } else if (zoom) {
      localStorage.removeItem('scheduleZoom')
    }

    this.fetchConfig()
    this.fetchSchedulerData()
    this.autoFetchSchedulerData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.displayChart === false && this.state.displayChart === true) {
      this.editState((draft) => {
        draft.schedulerHeight = this.state.schedulerHeight - 150
      })
    }

    if (prevState.currentDate !== this.state.currentDate) {
      this.autoFetchSchedulerData(this.state.currentDate)
    }
  }

  render() {
    if (this.state.loading && (!this.state.config || !this.state.data))
      return <CircularProgress style={{ marginTop: '100px' }} color="secondary" />

    if (!this.state.config || !this.state.data) return null

    const { canAddAppointments } = this.props
    const popoverData = this.state.popoverData

    return (
      <div className={css.root}>
        <div className={css.bar}>
          <SubBar style={{ width: '100%' }}>
            <SubBarControl>
              <DatePicker
                style={{ marginTop: 0 }}
                onChange={(val) => {
                  this.editState((draft) => {
                    draft.currentDate = val
                  }, this.fetchSchedulerData)
                }}
                value={this.state.currentDate}
              />
            </SubBarControl>
            <SubBarControl>
              <EnhancedInput
                value={this.state.searchTerm}
                onChange={this.handleSearch}
                placeholder={this.props.translation.common.search}
              />
            </SubBarControl>
            <SubBarControl>
              {this.state.loading && <CircularProgress color="secondary" style={{ width: '24px', height: '24px' }} />}
            </SubBarControl>
            <div style={{ marginLeft: 'auto' }} />
            {canAddAppointments && (
              <SubBarControl>
                <a target="_blank" href={'/#' + Nav.appointmentsSchedulingCreate(this.props.match.params.shopId)}>
                  <IconButton>
                    <AddIcon />
                  </IconButton>
                </a>
              </SubBarControl>
            )}
            <SubBarControl>
              <IconButton color={this.state.displayChart ? 'primary' : 'default'} onClick={this.chartToggle}>
                <ChartIcon />
              </IconButton>
            </SubBarControl>
            {Boolean(this.state.searchTerm) && (
              <SubBarControl>
                <IconButton onClick={this.handleClearSearch} aria-label="Clear searcher">
                  <ClearIcon />
                </IconButton>
              </SubBarControl>
            )}
          </SubBar>
        </div>

        {this.renderSearchResult()}

        {this.state.displayChart && (
          <BarChart
            width={600}
            height={150}
            style={{ margin: '1rem auto 0 auto' }}
            data={calcCapacity(
              this.state.data.appointments,
              moment.tz.zone(this.props.timeZoneId).utcOffset(this.state.currentDate) * -60 * 1000,
              this.state.currentDate
            )}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <Bar dataKey="count" tick={{ dx: -115 }} fill="#2F7BB4" style={{ transform: 'translate(23px, 0px)' }} />
          </BarChart>
        )}
        <Popover
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.setState({ anchorEl: null })
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          {popoverData && popoverData._type === 'unavailability' && (
            <div>
              <div style={{ margin: 16 }}>
                <Text type="body-1">
                  {this.props.translation.common.unavailable} {this.formatDate(popoverData.startDateSrc)} -{' '}
                  {this.formatDate(popoverData.endDateSrc)}
                </Text>
              </div>
              {this.props.canEditCheckpoint && (
                <React.Fragment>
                  <div
                    style={{
                      padding: '0.5rem',
                      boxSizing: 'border-box',
                      width: '100%',
                      display: 'flex',
                      gap: '0.5rem',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.setState({ anchorEl: null })
                        this.handleDeleteUnavailability(popoverData)
                      }}
                    >
                      {this.props.translation.entityEdit.deleteButton}
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({ anchorEl: null })
                        this.editState((draft) => {
                          draft.openForm = true
                          draft.formData = this.getFormDataFromUnavailability(popoverData)
                        })
                      }}
                    >
                      {this.props.translation.entityView.editButton}
                    </Button>
                  </div>
                </React.Fragment>
              )}
              <Divider />
            </div>
          )}
        </Popover>
        <Scheduler
          canEditAppointments={this.props.canEditAppointments}
          canAddAppointments={this.props.canAddAppointments}
          canEditCheckpoint={this.props.canEditCheckpoint}
          onOpenPopover={(data, target) => {
            this.setState({ anchorEl: target, popoverData: data })
          }}
          data={this.state.data}
          checkpoints={this.state.config.checkpoints}
          date={this.state.currentDate}
          onCellDoubleClick={(ev) => {
            this.editState((draft) => {
              draft.openForm = true
              draft.formData = this.getFormDataFromUnavailability(ev.cellData)
            })
          }}
          appointmentHeight={this.state.appointmentHeight}
          onUnavailabilityDelete={this.handleDeleteUnavailability}
          onUnavailabilityEdit={(data) => {
            this.editState((draft) => {
              draft.openForm = true
              draft.formData = this.getFormDataFromUnavailability(data)
            })
          }}
          onAppointmentClick={(appointment) =>
            this.editState((draft) => {
              draft.selectedAppointment = appointment
            })
          }
        />

        {this.renderAppointmentDetails()}

        <Dialog open={this.state.openForm} onClose={this.handleCloseForm}>
          <UnavailabilityForm
            data={this.state.formData}
            onClose={this.handleCloseForm}
            handleSubmit={this.handleCreateUnavailability}
          />
        </Dialog>

        <GlobalStylesDevExtremeScheduler $appointmentHeight={this.state.appointmentHeight} />
        <ZoomContainer>
          <ZoomControl
            onClick={() => {
              if (this.state.appointmentHeight - zoomStep > minHeight) {
                this.setState({ appointmentHeight: this.state.appointmentHeight - zoomStep })
                localStorage.setItem(zoomStorageKey, this.state.appointmentHeight - zoomStep)
              }
            }}
            $disabled={this.state.appointmentHeight - zoomStep <= minHeight}
          >
            -
          </ZoomControl>
          <ZoomValue>{Math.floor((this.state.appointmentHeight / defaultAppointmentHeight) * 100)}%</ZoomValue>
          <ZoomControl
            onClick={() => {
              if (this.state.appointmentHeight + zoomStep < maxHeight) {
                this.setState({ appointmentHeight: this.state.appointmentHeight + zoomStep })
                localStorage.setItem(zoomStorageKey, this.state.appointmentHeight + zoomStep)
              }
            }}
            $disabled={this.state.appointmentHeight + zoomStep >= maxHeight}
          >
            +
          </ZoomControl>
        </ZoomContainer>
      </div>
    )
  }

  renderAppointmentDetails() {
    if (!this.state.selectedAppointment) {
      return
    }

    return (
      <div className={css.panel}>
        <div
          className={css.panelCollapser}
          onClick={() =>
            this.editState((draft) => {
              draft.selectedAppointment = null
            })
          }
        >
          <ChevronRightIcon />
        </div>
        <div className={css.panelContent}>{this.renderAppointmentView(this.state.selectedAppointment)}</div>
      </div>
    )
  }

  renderAppointmentView(data) {
    return (
      <AppointmentScheduling
        canEdit={this.props.canEditAppointments}
        data={data}
        onLoadingAuthorizationClick={(loadingAuthorizationId, startDate) =>
          this.redirectToLoadingAuthorizations({
            loadingAuthorizationId,
            shopId: data.shopId,
            startDate
          })
        }
        onCancelClick={(appointment, cancelReason) => {
          this.handleCancelAppointment(data.token, cancelReason)
          this.editState((draft) => {
            draft.selectedAppointment = null
          })
        }}
        onUpdateClick={(appointment) => {
          const { token } = appointment
          this.pushPath(Nav.appointmentsSchedulingEdit(this.props.match.params.shopId, token))
        }}
        onDeclareArrivedClick={() => {
          this.handleAppointmentStatusChange(data.token, TotalAppointmentStatuses.Arrived)
          this.editState((draft) => {
            draft.selectedAppointment = null
          })
        }}
        onDeclareFinishedClick={() => {
          this.handleAppointmentStatusChange(data.token, TotalAppointmentStatuses.Finished)
          this.editState((draft) => {
            draft.selectedAppointment = null
          })
        }}
        onDeclareAbsentClick={() => {
          this.handleAppointmentStatusChange(data.token, TotalAppointmentStatuses.Absent)
          this.editState((draft) => {
            draft.selectedAppointment = null
          })
        }}
      />
    )
  }

  chartToggle = () => {
    this.editState((draft) => {
      const toggledValue = !this.state.displayChart
      draft.displayChart = toggledValue
      localStorage.setItem('scheduling_chart_display', toggledValue)
    })
  }

  getFormDataFromUnavailability(data) {
    const checkpointId = data.checkpointId || data.groups.checkpointId
    const safeStartTime = +data.startDateSrc || +data.startDate
    const safeEndTime = +data.endDateSrc || +data.endDate

    const offset =
      -60 * 1000 * moment.tz.zone(this.props.timeZoneId).utcOffset(safeStartTime - this.props.timeZoneOffset)

    return {
      id: data.id,
      checkpointId: checkpointId,
      checkpointName: this.state.config.checkpoints.find((checkpoint) => checkpoint.id == checkpointId).name,
      endDate: safeEndTime - offset,
      startDate: safeStartTime - offset,
      endTime: {
        minutes: new Date(safeEndTime - offset).getMinutes(),
        hours: new Date(safeEndTime - offset).getHours()
      },
      startTime: {
        minutes: new Date(safeStartTime - offset).getMinutes(),
        hours: new Date(safeStartTime - offset).getHours()
      },
      comment: data.comment
    }
  }

  handleCloseForm = () =>
    this.editState((draft) => {
      draft.openForm = false
    })

  handleCreateUnavailability = (data) => {
    if (data.id) {
      total
        .updateCheckpointUnavailability(data)
        .then(() => {
          this.props.dispatch(showMessage(this.props.translation.totalAppointments.updateCheckpointSuccess, 'success'))
          this.editState((draft) => {
            const index = draft.data.unavailability.findIndex((x) => x.id == data.id)
            draft.data.unavailability[index] = this.convertUnavailability(data)
            draft.openForm = false
          }, this.fetchSchedulerData)
        })
        .catch(() =>
          this.props.dispatch(showMessage(this.props.translation.totalAppointments.updateCheckpointError, 'error'))
        )
    } else {
      total
        .createCheckpointUnavailability(data)
        .then((resp) => {
          this.editState((draft) => {
            draft.data.unavailability.push(
              this.convertUnavailability({
                id: resp.id,
                ...data
              })
            )
            draft.openForm = false
          }, this.fetchSchedulerData)
          this.props.dispatch(showMessage(this.props.translation.totalAppointments.createCheckpointSuccess, 'success'))
        })
        .catch(() =>
          this.props.dispatch(showMessage(this.props.translation.totalAppointments.createCheckpointError, 'error'))
        )
    }
  }

  handleDeleteUnavailability = (data) => {
    total
      .deleteCheckpointUnavailability(data.id)
      .then(() => {
        this.props.dispatch(showMessage(this.props.translation.totalAppointments.deleteCheckpointSuccess, 'success'))
        this.editState((draft) => {
          const index = draft.data.unavailability.findIndex((x) => x.id == data.id)
          draft.data.unavailability.splice(index, 1)
        }, this.fetchSchedulerData)
      })
      .catch(() =>
        this.props.dispatch(showMessage(this.props.translation.totalAppointments.deleteCheckpointError, 'error'))
      )
  }

  convertUnavailability = (data) => {
    let startDate = new Date(data.startDate)
    let endDate = new Date(data.endDate)

    startDate.setUTCHours(data.startTime.hours)
    startDate.setUTCMinutes(data.startTime.minutes)
    endDate.setUTCHours(data.endTime.hours)
    endDate.setUTCMinutes(data.endTime.minutes)

    return {
      id: data.id,
      checkpointId: data.checkpointId,
      startDate: +startDate,
      endDate: +endDate,
      comment: data.comment
    }
  }

  renderSearchResult() {
    if (!this.state.searchResult) {
      return null
    }

    const { notFound } = this.props.translation.totalAppointments

    if (!this.state.searchResult.length) {
      return (
        <div className={css.searchResult}>
          <Text type="body-2">{notFound}</Text>
        </div>
      )
    }

    const cardStyle = {
      ToBeConfirmed: { background: '#3F80C1', color: '#ffffff' },
      Valid: { background: '#228B22', color: '#ffffff' },
      ToBeCancelled: { background: '#EED202', color: '#ffffff' },
      Arrived: { background: '#D3D3D3', color: 'rgba(0,0,0,0.83)' },
      Absent: { background: '#E62020', color: '#ffffff' },
      Cancelled: { background: '#6D6D6D', color: '#ffffff' },
      Finished: { background: '#228B22', color: '#ffffff' },
      Unknown: { background: '#ffffff', color: 'rgba(0,0,0,0.83)' }
    }

    return (
      <div className={css.searchResult}>
        {this.state.searchResult.map((result, index) => {
          return (
            <div
              key={index}
              style={{
                borderRadius: 4,
                marginRight: 16,
                position: 'relative',
                overflow: 'hidden',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
              }}
            >
              {this.renderAppointmentView(result)}
              <div
                style={{
                  zIndex: -100,
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  opacity: 0.24,
                  position: 'absolute',
                  ...(cardStyle[result.status] || cardStyle.Unknown)
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }

  handleCancelAppointment = (appointmentToken, cancelReason) => {
    const requestBody = {
      token: appointmentToken,
      status: TotalAppointmentStatuses.Cancelled,
      reason: cancelReason
    }

    total.updateAppointmentStatus(requestBody).then(() => this.fetchSchedulerData())
  }

  handleAppointmentStatusChange(appointmentToken, appointmentStatus) {
    if (!appointmentStatus) {
      console.warn('No status to send.')
      return
    }
    const requestBody = {
      token: appointmentToken,
      status: appointmentStatus
    }

    total.updateAppointmentStatus(requestBody).then(() => this.fetchSchedulerData())
  }

  handleSearch = debounce((value) => {
    if (value === '') {
      this.editState((draft) => {
        draft.searchTerm = value
        draft.searchResult = null
      })
      return
    }
    this.editState((draft) => {
      draft.searchTerm = value
    })

    this.search({ term: value, currentDate: this.state.currentDate, placeId: Number(this.getPlaceId()) })
  }, 300)

  handleClearSearch = () => {
    this.editState((draft) => {
      draft.searchTerm = ''
      draft.searchResult = null
    })
  }

  redirectToLoadingAuthorizations = (redirectData) => {
    const { loadingAuthorizationId, shopId, startDate } = redirectData

    window.open(
      '#' +
        Nav.loadingAuthorizations(shopId) +
        `?loadingAuthorizationId=${loadingAuthorizationId}&startDate=${startDate}&endDate=${startDate}`
    )
  }

  formatDate(date) {
    return this.props.dateFormatter(date, { hour: '2-digit', minute: '2-digit', day: 'numeric', month: 'short' })
  }

  pushPath = (path) => this.props.history.push(path)
}

const stateToProps = (state) => ({
  canEditAppointments: state.user.permissions.canEditAppointments,
  canAddAppointments: state.user.permissions.canAddAppointments,
  canEditCheckpoint: state.user.permissions.canEditCheckpoint,
  shops: state.shops.items
})

export default connect(stateToProps)(hideTimeZone()(translate(AppointmentsScheduling)))
