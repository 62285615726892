import React, { Component } from 'react'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import {
  getMessageTemplatesConfig,
  invalidateMessageTemplatesConfig
} from 'store/actions/message-templates-action-creators'
import MessageTemplatesForm from './message-templates-form'
import { messageTemplates, shop as shopAPI } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CenterContainer } from 'dumb/center-container'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import get from 'lodash/get'
import Container from 'dumb/container'
import timeZoneFilter from 'smart/time-zone-filter'

class MessageTemplates extends Component {
  state = {
    language: this.props.config && get(this.props.config, ['languages', 0, 'value']),
    messageType: this.props.config && get(this.props.config, ['messageTypes', 0, 'items', 0, 'value']),
    data: null,
    parents: null,
    loading: true,
    parentMessage: '',
    isFetching: false
  }

  initializeStateFromConfiguration(nextProps) {
    //NOTE: Reset loading state and set default selected language and messageType from loaded configuration
    const language = this.state.language ?? get(nextProps.config, ['languages', 0, 'value'])
    const messageType = get(nextProps.config, ['messageTypes', 0, 'items', 0, 'value'])

    this.setState({ loading: false, language, messageType })

    //NOTE: First template load after setting the language and message type
    this.fetchTemplate(language, messageType)
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.config && nextProps.config) {
      this.initializeStateFromConfiguration(nextProps)
    }

    return true
  }

  componentDidMount() {
    shopAPI.getShop(this.getShopId()).then((resp) => {
      this.props.dispatch(
        getMessageTemplatesConfig({
          lineId: this.getLineId(),
          shopId: this.getShopId(),
          supportedLanguages: resp.supportedLanguages
        })
      )
    })
  }

  componentWillUnmount() {
    this.props.dispatch(invalidateMessageTemplatesConfig())
  }

  componentDidUpdate() {
    const { parents } = this.state

    if (this.crumbsDefined || !parents) {
      return null
    }

    this.crumbsDefined = true

    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    this.props.dispatch(setBreadcrumbs(this.getBreadcrumbs(crumb, parents)))
  }

  getBreadcrumbs(crumb, parents) {
    if (!parents) {
      return null
    }

    const { shop, line } = parents

    if (this.getShopId() && this.getLineId()) {
      return [
        crumb.home(),
        crumb.shops(),
        crumb.shop([shop.id], shop.name),
        crumb.lines([shop.id]),
        crumb.line([shop.id, line.id], line.name),
        crumb.messageTemplates()
      ]
    }

    if (this.getShopId()) {
      return [crumb.home(), crumb.shops(), crumb.shop([shop.id], shop.name), crumb.messageTemplates()]
    }

    return [crumb.home(), crumb.messageTemplates()]
  }

  fetchTemplate(language, messageType, languageChanged) {
    this.setState({ loading: true })

    messageTemplates
      .getMessageTemplate({ language, messageType, lineId: this.getLineId(), shopId: this.getShopId() })
      .then((resp) => {
        if (languageChanged) {
          this.setState({ language })
        }

        this.setState({
          messageType,
          data: resp.data,
          parents: resp.parents,
          parentMessage: resp.parentMessage || '',
          loading: false
        })
      })
  }

  handleMessageTypeChange = (data) => {
    this.fetchTemplate(this.state.language, data)
  }

  handleLanguageChange = (data) => {
    this.fetchTemplate(data, this.state.messageType, true)
  }

  handleSave = (data) => {
    this.setState({ isFetching: true })

    let json = {
      ...data,
      shopId: this.getShopId(),
      lineId: this.getLineId(),
      language: this.state.language,
      messageType: this.state.messageType
    }

    messageTemplates
      .updateMessageTemplate(json)
      .then(() => {
        this.props.dispatch(showMessage(this.props.translation.messageTemplates.success, MessageTypes.Success))
      })
      .catch(() => {
        this.props.dispatch(showMessage(this.props.translation.messageTemplates.error, MessageTypes.Error))
      })
      .finally(() => {
        this.setState({ isFetching: false })
      })
  }

  getShopId = () => this.props.match.params.shopId
  getLineId = () => this.props.match.params.lineId

  render() {
    if (this.state.loading && !this.state.data) {
      return (
        <CenterContainer>
          <CircularProgress />
        </CenterContainer>
      )
    }

    if (!this.props.config || !this.state.data) {
      return null
    }

    return (
      <Container style={{ maxWidth: '1100px' }}>
        <MessageTemplatesForm
          config={this.props.config}
          language={this.state.language}
          messageType={this.state.messageType}
          data={this.state.data}
          parentMessage={this.state.parentMessage}
          onMessageTypeChange={this.handleMessageTypeChange}
          onLanguageChange={this.handleLanguageChange}
          onSave={this.handleSave}
          canSave={this.props.canSave}
          isFetching={this.state.isFetching}
        />
      </Container>
    )
  }
}

const stateToProps = (state) => ({
  config: state.messageTemplates?.data,
  canSave: state.user.userProfile?.permissions?.canEditShop
})

export default connect(stateToProps)(timeZoneFilter(translate(MessageTemplates)))
