import React, { Component } from 'react'
import EnhancedInput from './enhanced-input'
import EnhancedTextarea from './enhanced-textarea'
import TextFieldMessage from './text-field-message'

export default class TextField extends Component {
  static defaultProps = {
    multiline: false
  }

  constructor(props) {
    super(props)

    this.state = {
      errorMessages: null
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.value !== this.props.value ||
      nextProps.disabled !== this.props.disabled ||
      nextProps.attention !== this.props.attention ||
      nextProps.label !== this.props.label
    )
  }

  render() {
    const defaultChildren = []
    const messageChildren = []
    const infoMessagesToShow = []
    const children = this.props.children

    if (children) {
      React.Children.forEach(children, (child) => {
        const childName = child.type.displayName
        if (childName === TextFieldMessage.displayName) {
          messageChildren.push(child)
          if (child.props.type === 'info') infoMessagesToShow.push(child)
        } else defaultChildren.push(child)
      })
    }

    const onChange = (value) => {
      const errorMessagesToShow = []

      messageChildren.forEach((messageChild, index) => {
        var children = React.createElement(TextFieldMessage, {
          ...messageChild.props,
          displayMessage: value ? !messageChild.props.check(value) : false,
          key: index
        })

        switch (children.props.type) {
          case 'error':
            errorMessagesToShow.push(children)
            break
        }
      })

      this.setState({ errorMessages: errorMessagesToShow })

      if (this.props.onChange) this.props.onChange(value)
    }

    return (
      <section style={this.props.style}>
        {this.props.multiline
          ? React.createElement(EnhancedTextarea, { ...this.props })
          : React.createElement(EnhancedInput, {
              ...this.props,
              onChange: (value) => {
                onChange(value)
              }
            })}
        <div style={{ marginTop: '8px' }}>{this.state.errorMessages}</div>
        <div style={{ marginTop: '8px' }}>{infoMessagesToShow}</div>
      </section>
    )
  }
}
