import React from 'react'
import * as Nav from 'app/nav'
import { connect } from 'react-redux'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import translate from 'i18n/translate'
import timeZoneFilter from 'smart/time-zone-filter'
import Container from 'dumb/container'
import Card from 'dumb/card'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { total } from 'provider'
import { FormActionGroup } from 'dumb/form'
import Avatar from '@material-ui/core/Avatar/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import { List } from 'dumb/list'
import CompanyForm from './transport-company-form'
import { produce } from 'immer'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/AddCircleOutline'

class TransportCompanyEdit extends React.Component {
  static contextTypes = {
    router: () => null
  }

  state = {
    entity: null,
    operation: false,
    loading: false
  }

  editState = (fn) => this.setState(produce(fn))

  fetchData() {
    this.editState((draft) => {
      draft.loading = true
    })

    return total
      .getTransportCompany({
        companyId: this.getCompanyId()
      })
      .then((resp) => {
        this.editState((draft) => {
          draft.loading = false
          draft.entity = resp
        })

        return resp
      })
  }

  componentDidMount() {
    this.fetchData().then((entity) => this.setBreadcrumbs(entity))
  }

  setBreadcrumbs(entity) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    const crumbs = [
      crumb.home(),
      crumb.transportCompanies(),
      this.getCompanyId() ? { title: entity.data.name } : crumb.new()
    ]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  shouldNotRenderForm() {
    return this.state.operation || !this.state.entity
  }

  render() {
    if (this.state.loading) return <CircularProgress style={{ marginTop: '100px' }} color="secondary" />

    if (this.shouldNotRenderForm()) {
      return null
    }

    const { data } = this.state.entity

    return (
      <Container>
        <CompanyForm
          data={{ ...data }}
          canDelete={this.props.canDeleteTransportCompanies}
          canEdit={this.props.canEditTransportCompanies}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
        />
        {this.renderUsers()}
      </Container>
    )
  }

  renderUsers() {
    if (!this.getCompanyId()) {
      return
    }

    return (
      <Card>
        <List
          data={this.state.entity.data.users}
          onSelect={this.handleSelectUser}
          dataConverter={(item) => {
            const avatar = (
              <Avatar style={{ backgroundColor: item.isActive ? '#7CB342' : '' }}>
                <PersonIcon />
              </Avatar>
            )

            return {
              id: item.id,
              primaryText: item.name,
              secondaryText: item.email,
              avatar
            }
          }}
        />
        {this.props.canAddTransportCompanyUsers && (
          <FormActionGroup>
            <Button startIcon={<AddIcon />} onClick={this.handleAddUser}>
              {this.props.translation.transportCompanyEdit.addUser}
            </Button>
          </FormActionGroup>
        )}
      </Card>
    )
  }

  handleAddUser = () => {
    this.pushPath(Nav.transportCompanyUserCreate(this.getCompanyId()))
  }

  handleSelectUser = (data) => {
    this.pushPath(Nav.transportCompanyUser(this.getCompanyId(), data.id))
  }

  handleSave = (d) => {
    const { companyCreatedMessage, companyUpdatedMessage, alreadyExistedEmailMessage } =
      this.props.translation.transportCompanyEdit

    this.editState((draft) => {
      draft.operation = true
      draft.loading = true
    })

    const data = d

    if (this.getCompanyId()) {
      total
        .updateTransportCompany(data)
        .then(() => {
          this.editState((draft) => {
            draft.loading = false
          })

          this.pushPath(Nav.transportCompanies())
          this.props.dispatch(showMessage(companyUpdatedMessage, 'success'))
        })
        .catch((error) => {
          this.editState((draft) => {
            draft.loading = false
          })

          if (error.status === 400 && error.message === 'AlreadyExistedEmail') {
            this.pushPath(Nav.transportCompanies())
            this.props.dispatch(showMessage(alreadyExistedEmailMessage, 'error'))
          }
        })
    } else {
      total
        .createTransportCompany(data)
        .then(() => {
          this.editState((draft) => {
            draft.loading = false
          })

          this.pushPath(Nav.transportCompanies())
          this.props.dispatch(showMessage(companyCreatedMessage, 'success'))
        })
        .catch((error) => {
          this.editState((draft) => {
            draft.loading = false
          })

          if (error.status === 400 && error.message === 'AlreadyExistedEmail') {
            this.pushPath(Nav.transportCompanies())
            this.props.dispatch(showMessage(alreadyExistedEmailMessage, 'error'))
          }
        })
    }
  }

  handleDelete = (data) => {
    const { companyDeletedMessage } = this.props.translation.transportCompanyEdit

    this.editState((draft) => {
      draft.operation = true
      draft.loading = true
    })

    total
      .deleteTransportCompany(data)
      .then(() => {
        this.editState((draft) => {
          draft.loading = false
        })

        this.pushPath(Nav.transportCompanies())
        this.props.dispatch(showMessage(companyDeletedMessage, 'success'))
      })
      .catch(() => {
        this.editState((draft) => {
          draft.loading = false
        })
      })
  }

  getCompanyId = () => this.props.match.params.companyId

  pushPath = (path) => this.props.history.push(path)
}

const stateToProps = (state) => ({
  canDeleteTransportCompanies: state.user.permissions.canDeleteTransportCompanies,
  canEditTransportCompanies: state.user.permissions.canEditTransportCompanies,
  canAddTransportCompanyUsers: state.user.permissions.canAddTransportCompanyUsers
})

export default connect(stateToProps)(timeZoneFilter(translate(TransportCompanyEdit)))
