import React, { Component } from 'react'
import { ControlLabel } from 'dumb/control-label'
import css from './dropdown.module.scss'
import Text from 'dumb/text'
import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

export default class Dropdown extends Component {
  static defaultProps = {
    dataConverter: (data) => data,
    dataAccessor: (x, i) => (x.get ? x.get(i) : x[i])
  }

  componentDidMount() {
    if (
      this.props.ensureValue &&
      (this.props.data.size || this.props.data.length)
    ) {
      const first = this.props.dataAccessor(this.props.data, 0)
      const data = this.props.dataConverter(first)
      this.props.onChange(this.props.value ? this.props.value : data.value)
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props != nextProps) {
      if (
        nextProps.ensureValue &&
        (nextProps.data.size || nextProps.data.length)
      ) {
        if (
          !isUndefined(nextProps.value) &&
          !isNull(nextProps.value) &&
          this.props.value === nextProps.value
        )
          return null

        const first = this.props.dataAccessor(nextProps.data, 0)
        const data = nextProps.dataConverter(first)
        nextProps.onChange(nextProps.value ? nextProps.value : data.value)
      }
    }

    return true
  }

  render() {
    return (
      <section>
        <div className={css.title}>
          <ControlLabel>{this.props.label}</ControlLabel>
          {this.props.attention && <div className={css.attention} />}
        </div>
        <div className={css.control}>
          <Select
            displayEmpty={this.props.displayEmpty}
            disabled={this.props.disabled}
            style={{ ...this.props.style, color: '#374649' }}
            value={this.props.value || ''}
            onChange={(event, i) => this.handleChange(event.target.value, i)}
            fullWidth={this.props.fullWidth}
          >
            {this.props.displayEmpty && (
              <MenuItem value="">{this.props.emptyLable || 'None'}</MenuItem>
            )}
            {this.props.data.map((item, i) => {
              const data = item.noDataSelected
                ? item
                : this.props.dataConverter(item, i)

              return (
                <MenuItem key={data.value} value={data.value}>
                  {this.renderText(data.text, data.secondary)}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      </section>
    )
  }

  renderText(primary, secondary) {
    return (
      <div>
        <span style={{ color: '#374649' }}>{primary}</span>
        {Boolean(secondary) && (
          <Text size={2} fade={2} className={css.secondaryText}>
            {secondary}
          </Text>
        )}
      </div>
    )
  }

  handleChange(val, i) {
    this.props.onChange(val, i)
  }
}
