import React from 'react'
import { users } from 'app/nav'
import { UserEdit, UserCreate } from 'dumb/user'
import { connect } from 'react-redux'
import { updateUser, createUser, deleteUser } from 'store/actions/entity-action-creators'
import { getAndStoreUserProfile } from 'store/actions/user-action-creators'
import { getUser } from 'store/actions/entity-action-creators'
import { showMessage } from 'store/actions/main-layout-action-creators'
import BaseEdit from 'smart/base/base-entity-edit'
import translate from 'i18n/translate'
import { extractStoredUrl } from 'utils/history'
import { user } from 'provider'
import { storeUserProfile } from 'store/actions/user-action-creators'

class SmartUserEdit extends BaseEdit {
  identity = 'user'

  behavior = {
    create: {
      redirect: () => users()
    },
    delete: {
      redirect: () => users()
    },
    update: {
      redirect: () => users(),
      onSuccess: () => {
        if (this.isSelf()) this.props.getUserProfile()
      }
    }
  }

  operationMessages = () => this.props.translation.userOperationMessages
  checkViewPermissions = () => this.isSelf() || this.props.canEditUser

  fetchEntity(p) {
    this.props.getEntity(p.userId)
  }

  getBreadcrumbs(crumb, data) {
    const userId = data.id

    return [crumb.home(), crumb.users(), userId ? crumb.editUser() : crumb.newUser()]
  }

  shouldComponentUpdate(nextProps) {
    if (this.props != nextProps) {
      const id = this.props.match.params.userId
      const nextId = nextProps.match.params.userId

      if (id != nextId) this.props.getEntity(nextId)
    }

    return super.shouldComponentUpdate(nextProps)
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    const url = extractStoredUrl()

    if (url) this.pushPath(url)
  }

  isNew() {
    return !this.props.match.params.userId
  }

  isSelf() {
    return this.props.match.params.userId == this.props.userId
  }

  renderMainForm() {
    const { entity } = this.props
    const canEditRights =
      !this.isSelf() &&
      (this.props.canPromoteToAdmin ||
        this.props.canGiveManageUserAccounts ||
        this.props.canGiveAddAndDeleteShops ||
        this.props.canGiveUpdateShops ||
        this.props.canGiveOpenAndCloseCheckpoints ||
        this.props.canGiveUpdateServicePointStartAndEndHours ||
        this.props.canGiveAccessFromMonitor)

    const props = {
      data: { ...(entity.data || {}) },
      shops: entity.associated?.shops?.data,
      languages: entity.associated?.languages,
      myprofile: this.isSelf(),
      canEditShops: !this.isSelf(),
      canEditRights,
      canDelete: this.props.canDeleteUser,
      canPromoteToAdmin: this.props.canPromoteToAdmin,
      canGiveManageUserAccounts: this.props.canGiveManageUserAccounts,
      canGiveAddAndDeleteShops: this.props.canGiveAddAndDeleteShops,
      canGiveUpdateShops: this.props.canGiveUpdateShops,
      canGiveOpenAndCloseCheckpoints: this.props.canGiveOpenAndCloseCheckpoints,
      canGiveAccessFromMonitor: this.props.canGiveAccessFromMonitor,
      canGiveUpdateServicePointStartAndEndHours: this.props.canGiveUpdateServicePointStartAndEndHours,
      canGiveManageAppointments: this.props.canGiveManageAppointments,
      canGiveManageTransportCompanies: this.props.canGiveManageTransportCompanies
    }

    if (this.isNew()) {
      return <UserCreate {...props} onSave={(data) => this.handleSave(data)} />
    }

    return <UserEdit {...props} onSave={(data) => this.handleSave(data)} onDelete={(data) => this.handleDelete(data)} />
  }

  handleSave = (data) => {
    user
      .updateUser(data)
      .then(() => {
        user.storeProfile().then((profile) => {
          this.props.dispatch(storeUserProfile(profile))
          this.props.dispatch(showMessage(this.props.translation.userOperationMessages.updateSuccess, 'success'))
          this.pushPath(extractStoredUrl() || users())
        })
      })
      .catch((err) => {
        if (err == 'Error: IGG')
          this.props.dispatch(showMessage(this.props.translation.userOperationMessages.iggError, 'error'))
        else if (err == 'Error: Email')
          this.props.dispatch(showMessage(this.props.translation.userOperationMessages.emailError, 'error'))
        else this.props.dispatch(showMessage(this.props.translation.userOperationMessages.error, 'error'))
      })
  }

  processCreateData = (data) => data

  handleDelete = (data) => {
    this.props.deleteEntity(data)
  }
}

const stateToProps = (state) => ({
  entity: state.entity,
  userId: state.user.userProfile.id,
  insecurePassword: state.user.userProfile.insecurePassword,
  canPromoteToAdmin: state.user.permissions.canPromoteToAdmin,
  canDeleteUser: state.user.permissions.canDeleteUser,
  canEditUser: state.user.permissions.canEditUser,
  canGiveManageUserAccounts: state.user.permissions.canGiveManageUserAccounts,
  canGiveAddAndDeleteShops: state.user.permissions.canGiveAddAndDeleteShops,
  canGiveUpdateShops: state.user.permissions.canGiveUpdateShops,
  canGiveManageAppointments: state.user.permissions.canGiveManageAppointments,
  canGiveManageTransportCompanies: state.user.permissions.canGiveManageTransportCompanies,
  canGiveOpenAndCloseCheckpoints: state.user.permissions.canGiveOpenAndCloseCheckpoints,
  canGiveAccessFromMonitor: state.user.permissions.canGiveAccessFromMonitor,
  canGiveUpdateServicePointStartAndEndHours: state.user.permissions.canGiveUpdateServicePointStartAndEndHours
})

const actionsToProps = (dispatch) => ({
  getEntity: (userId) => dispatch(getUser(userId)),
  updateEntity: (data) => dispatch(updateUser(data)),
  createEntity: (data) => dispatch(createUser(data)),
  deleteEntity: (data) => dispatch(deleteUser(data)),
  getUserProfile: () => dispatch(getAndStoreUserProfile()),
  dispatch
})

export default connect(stateToProps, actionsToProps)(translate(SmartUserEdit))
