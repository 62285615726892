import React, { Component } from 'react'
import css from './empty-screen.module.scss'

const iconProps = { style: { width: 96, height: 96, verticalAlign: 'middle' }, color: 'rgba(0, 0, 0, .17)' }

export default class EmptyScreen extends Component {
  render() {
    return (
      <div className={css.root}>
        <div className={css.iconWrap}>{React.createElement(this.props.iconClass, iconProps)}</div>
        <div className={css.text}>{this.props.text}</div>
      </div>
    )
  }
}
