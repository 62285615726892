import React, { Component } from 'react'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default class RadioButton extends Component {
  componentDidMount() {
    const { ensureValue, value } = this.props

    if (ensureValue && !value) this.props.onChange(ensureValue)
  }

  render() {
    return (
      <FormControlLabel
        style={this.props.style}
        value={this.props.value}
        control={<Radio />}
        label={this.props.label}
        disabled={this.props.disabled}
      />
    )
  }
}
