import React, { Component } from 'react'
import css from './base-legacy.module.scss'
import { withMods } from 'utils/cssm'
import debounce from 'lodash/debounce'

const mod = withMods(css)

export default class LegacyBase extends Component {
  state = {
    smallScreen: false,
    hideBar: false
  }

  scrollTop = 0

  componentDidMount() {
    if (this.renderBarContent) {
      window.addEventListener('scroll', this.scrollHandler, false)
      window.addEventListener('resize', this.resizeHandler, false)
      this.resizeHandler()
    }
  }

  componentDidUpdate() {
    this.resizeHandler()
  }

  scrollHandler = debounce(() => {
    if (!this.state.smallScreen) {
      return
    }

    const st = window.pageYOffset || document.documentElement.scrollTop

    const delta = st - this.scrollTop

    this.scrollTop = st

    if (delta > 70) {
      this.setState({ hideBar: true })
    }

    if (delta < -70) {
      this.setState({ hideBar: false })
    }
  }, 100)

  resizeHandler = debounce(() => {
    const bar = this.bar

    if (!bar) {
      return
    }

    if (bar.clientWidth < bar.scrollWidth && !this.state.smallScreen) {
      this.breakpoint = bar.scrollWidth
      this.setState({ smallScreen: true })
    }

    if (bar.clientWidth > this.breakpoint && this.state.smallScreen) {
      this.setState({ smallScreen: false })
    }
  }, 250)

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler, false)
    window.removeEventListener('scroll', this.scrollHandler, false)
  }

  render() {
    const bar = this.renderBarContent && this.renderBarContent({ smallScreen: this.state.smallScreen })
    const withBar = Boolean(bar) && !this.state.hideBar

    return (
      <div className={mod.root({ withBar })}>
        {withBar ? this.renderBar(bar) : undefined}
        {this.renderLoading && this.renderLoading()}
        <div className={css.container}>{this.renderContent({ smallScreen: this.state.smallScreen })}</div>
      </div>
    )
  }

  renderBar(bar) {
    return (
      <div className={mod.barsticky({ smallScreen: this.state.smallScreen })} ref={(bar) => (this.bar = bar)}>
        <div className={mod.barContainer()}>{bar}</div>
      </div>
    )
  }
}
