import { useEffect, useState } from 'react'
import Tile, { TileContainer } from 'dumb/tile'
import { connect } from 'react-redux'
import * as Nav from 'app/nav'
import { useTranslation } from 'i18n/translate'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import TodayIcon from '@material-ui/icons/Today'
import Container from '../../dumb/container'
import { shop } from 'provider'
import { useHistory } from 'react-router-dom'

function Reports(props: any) {
  const { tr } = useTranslation()

  const [templates, setTemplates] = useState<any[]>([])

  const history = useHistory()

  useEffect(() => {
    fetchData()

    const crumb = getCrumbs(tr.breadcrumbs)
    props.dispatch(setBreadcrumbs([crumb.home(), crumb.reports()]))
  }, [])

  function fetchData() {
    shop.getExportTemplates().then((resp) => {
      setTemplates(resp)
    })
  }

  return (
    <Container>
      <TileContainer>
        {templates.map((template) => (
          <Tile
            key={template.value}
            title={template.text}
            iconClass={TodayIcon}
            onClick={() =>
              template.useFilters
                ? history.push(Nav.report(template.value))
                : shop.exportDataFile({ template: template.value, line: -1, shopId: -1, startDate: 0, endDate: 0 })
            }
          />
        ))}
      </TileContainer>
    </Container>
  )
}

export default connect()(Reports)
