const today = new Date()
const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())).getTime()

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface StaffManagementState {
  startDate: number
  endDate: number
}

const initialState: StaffManagementState = {
  startDate: todayUTC,
  endDate: todayUTC + 14 * 24 * 60 * 60 * 1000
}

const staffManagementSlice = createSlice({
  name: 'staffManagement',
  initialState,
  reducers: {
    storeStaffManagementFilter: (state, action: PayloadAction<{ startDate?: number; endDate?: number }>) => {
      if (action.payload.startDate !== undefined) {
        state.startDate = action.payload.startDate
      }
      if (action.payload.endDate !== undefined) {
        state.endDate = action.payload.endDate
      }
    }
  }
})

export const { storeStaffManagementFilter } = staffManagementSlice.actions

export default staffManagementSlice.reducer
