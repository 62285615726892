import React from 'react'
import { checkpoints } from 'app/nav'
import CheckpointEdit from 'dumb/checkpoint-edit'
import { connect } from 'react-redux'
import { updateCheckpoint, createCheckpoint, deleteCheckpoint } from 'store/actions/entity-action-creators'
import { getCheckpoint } from 'store/actions/entity-action-creators'
import BaseEdit from 'smart/base/base-entity-edit'
import translate from 'i18n/translate'
import dataProvider from 'smart/isolated-data-provider'
import timeZoneFilter from 'smart/time-zone-filter'

class SmartCheckpointEdit extends BaseEdit {
  identity = 'checkpoint'

  behavior = {
    create: {
      redirect: (p) => checkpoints(p.shopId, p.lineId)
    },
    delete: {
      redirect: (p) => checkpoints(p.shopId, p.lineId)
    },
    update: {
      redirect: (p) => checkpoints(p.shopId, p.lineId)
    }
  }

  operationMessages = () => this.props.translation.checkpointOperationMessages
  checkViewPermissions = () => this.props.canEditCheckpoint

  fetchEntity(p) {
    this.props.getEntity(p.checkpointId, p.shopId, p.lineId)
  }

  getBreadcrumbs(crumb, data, parents) {
    const shop = parents.shop
    const line = parents.line
    const checkpointId = data.id

    return [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shop.id], shop.name),
      crumb.lines([shop.id]),
      crumb.line([shop.id, line.id], line.name),
      crumb.checkpoints([shop.id, line.id]),
      checkpointId ? crumb.editCheckpoint([], data.name) : crumb.newCheckpoint()
    ]
  }

  isNew() {
    return !this.props.match.params.checkpointId
  }

  renderMainForm() {
    return (
      <CheckpointEdit
        canDelete={this.props.canDeleteCheckpoint}
        data={{ ...this.props.entity.data }}
        onSave={(data) => this.handleSave(data)}
        onDelete={(data) => this.handleDelete(data)}
        shopId={this.props.match.params.shopId}
      />
    )
  }

  handleSave(data) {
    if (this.isNew()) {
      this.props.createEntity(this.processCreateData(data))
    } else {
      this.props.updateEntity(data)
    }
  }

  processCreateData(data) {
    data.lineId = this.props.match.params.lineId
    return data
  }

  handleDelete(data) {
    this.props.deleteEntity(data)
  }
}

const stateToProps = (state) => ({
  entity: state.entity,
  canDeleteCheckpoint: state.user.permissions.canDeleteCheckpoint,
  canEditCheckpoint: state.user.permissions.canEditCheckpoint
})

const actionsToProps = (dispatch) => ({
  getEntity: (checkpointId, shopId, lineId) => dispatch(getCheckpoint(checkpointId, shopId, lineId)),
  updateEntity: (data) => dispatch(updateCheckpoint(data)),
  createEntity: (data) => dispatch(createCheckpoint(data)),
  deleteEntity: (data) => dispatch(deleteCheckpoint(data)),
  dispatch
})

export default dataProvider({ lines: (props) => props.match.params.shopId })(
  connect(stateToProps, actionsToProps)(timeZoneFilter(translate(SmartCheckpointEdit)))
)
