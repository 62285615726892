import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import HomeTitles from './home-tiles'
import HomeShops from './home-shops'
import HomeShop from './home-shop'
import translate from 'i18n/translate'
import css from 'smart/base/base-entity.module.scss'

class SmartHome extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.groupShops !== this.props.groupShops || nextProps.singleShop !== this.props.singleShop
  }

  componentDidMount() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    this.props.dispatch(setBreadcrumbs([crumb.home()]))
  }

  render() {
    return (
      <div className={css.root}>
        <div className={css.container}>
          {this.renderShops()}
          {this.renderTiles()}
        </div>
      </div>
    )
  }

  renderShops() {
    if (this.props.singleShop) {
      return <HomeShop />
    } else if (!this.props.groupShops) {
      return <HomeShops />
    }
  }

  renderTiles() {
    if (!this.props.singleShop) {
      return <HomeTitles />
    }
  }
}

const stateToProps = (state) => ({
  groupShops: state.user.groupShops,
  singleShop: state.user.singleShop
})

export default connect(stateToProps)(translate(SmartHome))
