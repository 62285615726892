import React, { Component } from 'react'
import Text from 'dumb/text'
import { Form, FormGroup } from 'dumb/form'
import translate from 'i18n/translate'
import Divider from '@material-ui/core/Divider'

class ShopView extends Component {
  render() {
    const d = this.props.data
    const t = this.props.translation

    return (
      <div>
        <Form>
          <FormGroup>
            <Text type="caption">{t.shopView.name}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {d.name}
            </Text>
          </FormGroup>
          <FormGroup>
            <Text type="caption">{t.shopView.description}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {d.description}
            </Text>
          </FormGroup>
          {d.monitoringId && (
            <FormGroup>
              <Text type="caption">{'GUID'}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.monitoringId}
              </Text>
            </FormGroup>
          )}
        </Form>
        <Divider />
        <Form title={t.shopView.location} expandable>
          {this.hasText(d.address) && (
            <FormGroup>
              <Text type="caption">{t.shopView.address}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.address}
              </Text>
            </FormGroup>
          )}
          {this.hasText(d.city) && (
            <FormGroup>
              <Text type="caption">{t.shopView.city}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.city}
              </Text>
            </FormGroup>
          )}
          {this.hasText(d.zipCode) && (
            <FormGroup>
              <Text type="caption">{t.shopView.zipCode}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.zipCode}
              </Text>
            </FormGroup>
          )}
          <FormGroup>
            <Text type="caption">{t.shopView.gpsPosition}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>{`${d.latitude}, ${d.longitude}`}</Text>
          </FormGroup>
        </Form>
        <Divider />
        <Form title={t.shopView.moreInfo} expandable>
          {this.renderTimeZone(d)}
          {Boolean(d.limitInDays) && (
            <FormGroup>
              <Text type="caption">{t.shopView.limitInDays}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.limitInDays}
              </Text>
            </FormGroup>
          )}
          {Boolean(d.limitInDaysToFollowUp) && (
            <FormGroup>
              <Text type="caption">{t.shopView.limitInDaysToFollowUp}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.limitInDaysToFollowUp}
              </Text>
            </FormGroup>
          )}
          {Boolean(d.limitInDaysToCancel) && (
            <FormGroup>
              <Text type="caption">{t.shopView.limitInDaysToCancel}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.limitInDaysToCancel}
              </Text>
            </FormGroup>
          )}

          {this.hasText(d.expeditionEmail) && (
            <FormGroup>
              <Text type="caption">{t.shopView.expeditionEmail}</Text>
              <Text type="body-1" style={{ color: '#374649' }}>
                {d.expeditionEmail}
              </Text>
            </FormGroup>
          )}
        </Form>
        <Divider />
      </div>
    )
  }

  renderTimeZone(d) {
    const timeZone = this.props.timeZones.find((x) => x.id === d.timeZoneId)

    if (!timeZone) {
      return
    }

    return (
      <FormGroup>
        <Text type="caption">{this.props.translation.shopView.timeZone}</Text>
        <Text type="body-1" style={{ color: '#374649' }}>
          {timeZone.name}
        </Text>
      </FormGroup>
    )
  }

  hasText(value) {
    return Boolean(value && value.length)
  }
}

export default translate(ShopView)
