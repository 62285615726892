import React, { Component } from 'react'
import Dropdown from 'dumb/dropdown'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import translate from 'i18n/translate'

class LoadingArmFilter extends Component {
  handleLoadingArmChange = (id) => {
    this.props.onChange({ loadingArmId: id })
  }

  render() {
    const { loadingArm, all } = this.props.translation.common
    const loadingArmsItems = this.props.loadingArms

    const dropdownLoadingArms = loadingArmsItems.length > 1 ? [{ id: null, name: all }, ...loadingArmsItems] : []

    return (
      <SubBar smallScreen={this.props.smallScreen}>
        {loadingArmsItems.length > 1 && (
          <SubBarControl title={loadingArm}>
            <Dropdown
              style={{ marginRight: 24 }}
              value={this.props.loadingArmId}
              data={dropdownLoadingArms}
              dataConverter={(item) => ({ value: item.id, text: item.name })}
              onChange={this.handleLoadingArmChange}
            />
          </SubBarControl>
        )}
      </SubBar>
    )
  }
}

export default translate(LoadingArmFilter)
