import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import translate from 'i18n/translate'
import { isPositiveNumber } from 'utils/string-test'
import Dropdown from 'dumb/dropdown'
import Toggle from 'dumb/toggle-wrap'
import Divider from '@material-ui/core/Divider'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'
import { InputTranslation } from 'features/input-translation'

class ShopEdit extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'name',
        stateField: 'nameIsValid'
      },
      {
        dataField: 'description',
        stateField: 'descriptionIsValid'
      },
      {
        dataField: 'latitude',
        stateField: 'latitudeIsValid',
        validator: (val) => val > -91 && val < 91
      },
      {
        dataField: 'longitude',
        stateField: 'longitudeIsValid',
        validator: (val) => val > -181 && val < 181
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}

        <EntityActions showButtons={true} buttons={this.renderControls()} updateData={this.state.data.lastUpdate} />
      </Card>
    )
  }

  renderControls() {
    const { createButton, updateButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button id="confirm" onClick={() => this.props.onDelete(this.state.data.id)}>
              {deleteButton}
            </Button>
            <Button id="cancel" onClick={() => this.handleCancelDelete()}>
              {cancelButton}
            </Button>
          </div>
        </div>
      )

    if (!this.state.data.id)
      return (
        <Button disabled={!this.stateIsValid()} id="create" onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    else
      return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()} id="update">
            {updateButton}
          </Button>
          {this.props.canDelete !== false && (
            <Button id="delete" onClick={() => this.handleTryDelete()}>
              {deleteButton}
            </Button>
          )}
        </div>
      )
  }

  handleTryDelete() {
    this.setState({
      confirmDelete: true
    })
  }

  handleCancelDelete() {
    this.setState({
      confirmDelete: false
    })
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderMainForm(d) {
    const t = this.props.translation
    const { chars } = this.props.translation.entityEdit

    return (
      <div>
        <Form>
          <FormGroup>
            <Dropdown
              ensureValue
              label={t.shopEdit.brand}
              value={d.brandId}
              data={this.props.brands}
              dataConverter={(item) => ({ value: item.id, text: item.name })}
              onChange={(val) => this.handlePropChange('brandId', val)}
            />
          </FormGroup>
          <FormGroup>
            <InputTranslation
              inputValue={d.name}
              setInputValue={(val) => this.handlePropChange('name', val)}
              brandId={d.brandId}
              shopId={d.id}
            >
              <TextField
                label={t.shopEdit.name}
                placeholder={t.shopEdit.namePlaceholder}
                autofocus={true}
                value={d.name}
                onChange={(val) => this.handlePropChange('name', val)}
                attention={!this.state.nameIsValid}
                message={`${d.name ? d.name.length : 0}/200 ${chars}`}
                test={(v) => v.length < 201}
              />
            </InputTranslation>
          </FormGroup>
          <FormGroup>
            <InputTranslation
              inputValue={d.description}
              setInputValue={(val) => this.handlePropChange('description', val)}
              brandId={d.brandId}
              shopId={d.id}
            >
              <TextField
                label={t.shopEdit.description}
                placeholder={t.shopEdit.descriptionPlaceholder}
                value={d.description}
                onChange={(val) => this.handlePropChange('description', val)}
                attention={!this.state.descriptionIsValid}
                message={`${d.description ? d.description.length : 0}/200 ${chars}`}
                test={(v) => v.length < 201}
              />
            </InputTranslation>
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.externalCode}
              placeholder={t.shopEdit.externalCode}
              value={d.externalCode}
              onChange={(val) => this.handlePropChange('externalCode', val)}
              message={`${d.externalCode ? d.externalCode.length : 0}/100 ${chars}`}
              test={(v) => v.length < 101}
            />
          </FormGroup>
          <FormGroup>
            <Dropdown
              ensureValue
              label={t.shopEdit.timeZone}
              value={d.timeZoneId}
              data={this.props.timeZones}
              dataConverter={(item) => ({ value: item.id, text: item.name })}
              onChange={(val) => this.handlePropChange('timeZoneId', val)}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.limitInDays}
              placeholder={t.shopEdit.limitInDaysPlaceholder}
              value={d.limitInDays}
              onChange={(val) => this.handlePropChange('limitInDays', val)}
              test={isPositiveNumber}
              hint={t.time.daysHint}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.limitInDaysToFollowUp}
              placeholder={t.shopEdit.limitInDaysToFollowUpPlaceholder}
              value={d.limitInDaysToFollowUp}
              onChange={(val) => this.handlePropChange('limitInDaysToFollowUp', val)}
              test={isPositiveNumber}
              hint={t.time.daysHint}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.limitInDaysToCancel}
              placeholder={t.shopEdit.limitInDaysToCancelPlaceholder}
              value={d.limitInDaysToCancel}
              onChange={(val) => this.handlePropChange('limitInDaysToCancel', val)}
              test={isPositiveNumber}
              hint={t.time.daysHint}
            />
          </FormGroup>
          <FormGroup>
            <Toggle
              toggled={d.exportDataIsAvailable}
              label={t.shopEdit.exportDataIsAvailable}
              onToggle={(ev, val) => this.handlePropChange('exportDataIsAvailable', val)}
            />
          </FormGroup>
        </Form>
        <Divider />
        <Form title={t.shopEdit.location} expandable>
          <FormGroup>
            <TextField
              label={t.shopEdit.address}
              placeholder={t.shopEdit.addressPlaceholder}
              value={d.address}
              onChange={(val) => this.handlePropChange('address', val)}
              message={`${d.address ? d.address.length : 0}/400 ${chars}`}
              test={(v) => v.length < 401}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.city}
              placeholder={t.shopEdit.cityPlaceholder}
              value={d.city}
              onChange={(val) => this.handlePropChange('city', val)}
              message={`${d.city ? d.city.length : 0}/50 ${chars}`}
              test={(v) => v.length < 51}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.zipCode}
              placeholder={t.shopEdit.zipCodePlaceholder}
              value={d.zipCode}
              onChange={(val) => this.handlePropChange('zipCode', val)}
              message={`${d.zipCode ? d.zipCode.length : 0}/20 ${chars}`}
              test={(v) => v.length < 21}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.latitude}
              placeholder={t.shopEdit.latitudePlaceholder}
              value={d.latitude}
              onChange={(val) => this.handlePropChange('latitude', val)}
              attention={!this.state.latitudeIsValid}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.longitude}
              placeholder={t.shopEdit.longitudePlaceholder}
              value={d.longitude}
              onChange={(val) => this.handlePropChange('longitude', val)}
              attention={!this.state.longitudeIsValid}
            />
          </FormGroup>
        </Form>
        <Divider />
        <Form title={t.shopEdit.expedition} expandable>
          <FormGroup>
            <TextField
              label={t.shopEdit.expeditionEmail}
              placeholder={t.shopEdit.expeditionEmailPlaceholder}
              value={d.expeditionEmail}
              onChange={(val) => this.handlePropChange('expeditionEmail', val)}
              message={`${d.expeditionEmail ? d.expeditionEmail.length : 0}/100 ${chars}`}
              test={(v) => v.length < 101}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.shopEdit.expeditionPassword}
              placeholder={t.shopEdit.expeditionPasswordPlaceholder}
              value={d.expeditionPassword}
              onChange={(val) => this.handlePropChange('expeditionPassword', val)}
              type="password"
              message={`${d.expeditionPassword ? d.expeditionPassword.length : 0}/50 ${chars}`}
              test={(v) => v.length < 51}
            />
          </FormGroup>
        </Form>
        <Divider />
      </div>
    )
  }
}

export default translate(ShopEdit)
