import React from 'react'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import { EnhancedInput } from 'dumb/text-field'
import debounce from 'lodash/debounce'
import LegacyBase from 'smart/base/legacy-base'
import { total } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import * as Nav from 'app/nav'
import LoadingAuthorizationItem from 'dumb/loading-authorization/loading-authorization-item'
import { Divider } from '@material-ui/core'
import { DatePicker } from 'dumb/date'
import { produce } from 'immer'
import UploadIcon from '@material-ui/icons/CloudUploadOutlined'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Card from 'dumb/card'
import Uploader from 'utils/file-uploader'
import { setLoadingAuthorizationsFilter } from 'store/actions/memory-filters-state-actions'
import CircularProgress from '@material-ui/core/CircularProgress'

class SmartLoadingAuthorizationsList extends LegacyBase {
  barLines = 1

  constructor(props) {
    super(props)

    const dateParser = function (dateString, defaultValue) {
      return dateString === 'null' ? defaultValue : Number.parseInt(dateString) || defaultValue
    }

    const search = this.props.location.search
    const parsedSearch = new URLSearchParams(search)

    let term = parsedSearch.get('loadingAuthorizationId') || ''
    let startDate = dateParser(parsedSearch.get('startDate') || undefined, Date.now())
    let endDate = dateParser(parsedSearch.get('endDate') || undefined, startDate + 1000 * 60 * 60 * 24 * 7)

    if (!term) {
      const filterState = this.props.filterState

      if (filterState) {
        try {
          startDate = filterState.startDate
          endDate = filterState.endDate
          term = filterState.term
        } catch (e) {
          console.warn('Set Filter FAIL. Storage filter removed.')
          this.props.setAppointmentsFilter(null)
        }
      }
    }

    this.state = {
      list: null,
      loading: false,
      filter: {
        term: term,
        startDate: startDate,
        endDate: endDate
      }
    }
  }

  fetchData = () => {
    this.setState({ loading: true })

    return total.getLoadingAuthorizations(this.state.filter).then((list) => {
      this.setState({ list })
      this.setBreadcrumbs(list)
      this.setState({ loading: false })

      return list
    })
  }

  delayFetchData = debounce(() => this.fetchData(), 300, {
    leading: true
  }).bind(this)

  setBreadcrumbs() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    const crumbs = [crumb.home(), crumb.loadingAuthorizations()]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  componentDidMount() {
    this.fetchData()
  }

  processFileForm = (data) => {
    let form = new FormData(data)
    return form
  }

  handleUpload = (res) => {
    const successLans = res.id.successfulls
      ? `${res.id.successfulls} ${this.props.translation.uploadOperationMessages.lanSuccess}`
      : ``
    const failedLans = res.id.errors
      ? `${res.id.errors} ${this.props.translation.uploadOperationMessages.lanFailed}`
      : ``
    this.props.dispatch(
      showMessage(
        `${this.props.translation.uploadOperationMessages.importFinish} ${successLans} ${failedLans}`,
        'success'
      )
    )
    this.fetchData(this.props)
  }

  handleError = () => {
    this.props.dispatch(showMessage(this.props.translation.uploadOperationMessages.uploadError, 'error'))
  }

  uploader = new Uploader({
    request: total.uploadLoadingAuthorizations,
    processForm: this.processFileForm,
    onSuccess: this.handleUpload,
    onFail: this.handleError,
    accept: '.xls, .xlsx'
  })
  renderLoading() {
    if (this.state.loading && !this.state.list)
      return <CircularProgress color="secondary" style={{ alignItems: 'center' }} />

    return null
  }
  renderContent() {
    if (!this.state.list) {
      return null
    }

    const { classes } = this.props
    const { upload } = this.props.translation.common

    return (
      <div>
        {this.props.canEditShop && (
          <Card>
            <Button className={classes.button} onClick={() => this.uploader.openDialog()}>
              <UploadIcon className={classes.leftIcon} />
              {upload}
            </Button>
          </Card>
        )}
        <Card>
          {this.state.list.data.map((value, index, array) => (
            <React.Fragment key={value.id || index}>
              <LoadingAuthorizationItem
                limitedView={this.props.limitedView}
                style={{ padding: '16px 24px', lineHeight: '2' }}
                data={value}
                detailClick={(appointment) => this.redirectToTotalAppointments(appointment)}
              />
              {index !== array.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Card>
      </div>
    )
  }

  renderBarContent(props) {
    const { startDate, endDate } = this.props.translation.reportsFilter

    this.search = (
      <SubBarControl>
        <EnhancedInput
          value={this.state.filter.term}
          onChange={(value) =>
            this.editState((draft) => {
              draft.filter.term = value
            })
          }
          placeholder={this.props.translation.loadingAuthorizations.search}
        />
      </SubBarControl>
    )

    this.startDate = (
      <SubBarControl title={startDate}>
        <DatePicker
          value={this.state.filter.startDate}
          style={{ marginTop: 0 }}
          onChange={(value) =>
            this.editState((draft) => {
              draft.filter.startDate = value
            })
          }
        />
      </SubBarControl>
    )

    this.endDate = (
      <SubBarControl title={endDate}>
        <DatePicker
          value={this.state.filter.endDate}
          style={{ marginTop: 0 }}
          onChange={(value) =>
            this.editState((draft) => {
              draft.filter.endDate = value
            })
          }
        />
      </SubBarControl>
    )

    return (
      <SubBar smallScreen={props.smallScreen}>
        {this.startDate}
        {this.endDate}
        {this.search}
        <div style={{ width: '20px' }}>
          {this.state.loading && this.state.list && (
            <CircularProgress color="secondary" style={{ width: '24px', height: '24px' }} />
          )}
        </div>
      </SubBar>
    )
  }

  editState = (fn) => {
    this.setState(produce(fn), () => {
      this.delayFetchData()

      this.props.setLoadingAuthorizationsFilter({
        startDate: this.state.filter.startDate,
        endDate: this.state.filter.endDate,
        term: this.state.filter.term
      })
    })
  }

  pushPath = (path) => this.props.history.push(path)

  redirectToTotalAppointments = (appointment) => {
    const reservecode = appointment.code
    const shopId = appointment.shopId
    const startDate = appointment.productAppointments[0]?.appointmentTime
    const endDate = startDate

    window.open(
      '#' + Nav.totalAppointments(shopId) + `?reservation=${reservecode}&startDate=${startDate}&endDate=${endDate}`
    )
  }
}

const styles = (theme) => ({ button: { margin: theme.spacing(1) }, leftIcon: { marginRight: theme.spacing(1) } })

const stateToProps = (state) => ({
  filterState: state.memoryFiltersState.loadingAuthorizationsFilter,
  canEditShop: state.user.permissions.canEditShop,
  limitedView: state.user.permissions.limitedView
})

const actionsToProps = (dispatch) => ({
  setLoadingAuthorizationsFilter: (data) => dispatch(setLoadingAuthorizationsFilter(data)),
  dispatch
})

export default withStyles(styles)(connect(stateToProps, actionsToProps)(translate(SmartLoadingAuthorizationsList)))
