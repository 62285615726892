import React from 'react'
import Base from 'smart/base/base'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { Form, FormGroup } from 'dumb/form'
import { editLineStaffManagement, createLineStaffManagement } from 'app/nav'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import { DatePicker } from 'dumb/date'
import PageLoader from 'dumb/page-loader'
import { getStaffManagement, resetEntityList } from 'store/actions/entity-list-action-creators'
import { storeStaffManagementFilter } from 'store/actions/staff-management-action-creators'
import Card from 'dumb/card'
import Text from 'dumb/text'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { staffManagement } from 'provider'
import { showMessage } from 'store/actions/main-layout-action-creators'
import timeZoneFilter from 'smart/time-zone-filter'
import Uploader from 'utils/file-uploader'
import UploadIcon from '@material-ui/icons/CloudUploadOutlined'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

class StaffManagement extends Base {
  barLines = 1

  processFileForm = (data) => {
    let form = new FormData(data)
    form.append('lineId', this.props.match.params.lineId)

    return form
  }

  handleUpload = () => {
    this.props.dispatch(showMessage(this.props.translation.staffManagement.uploadSuccess, 'success'))
    this.fetchData(this.props)
  }

  handleError = (file, error) => {
    this.props.dispatch(showMessage(this.props.translation.staffManagement.uploadError, 'error'))
  }

  uploader = new Uploader({
    request: staffManagement.uploadStaffManagement,
    processForm: this.processFileForm,
    onSuccess: this.handleUpload,
    onFail: this.handleError,
    accept: '.xls, .xlsx'
  })

  getBreadcrumbs(crumb, parents) {
    if (!parents) return []

    const shop = parents.shop
    const line = parents.line

    return [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shop.id], shop.name),
      crumb.lines([shop.id]),
      crumb.line([shop.id, line.id], line.name),
      crumb.staffManagement([shop.id, line.id])
    ]
  }

  componentDidMount() {
    super.componentDidMount()
  }

  componentDidUpdate() {
    const data = this.props.entityList.items
    const parents = this.props.entityList.parents

    if (data === null || this.crumbsDefined) {
      return null
    }

    this.crumbsDefined = true
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    this.setBreadcrumbs(this.getBreadcrumbs(crumb, parents))
  }

  fetchData(props = this.props) {
    this.props.getEntityList({
      startDate: props.staffManagement.startDate,
      endDate: props.staffManagement.endDate,
      lineId: props.match.params.lineId,
      shopId: props.match.params.shopId
    })
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.props.resetEntityList()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.staffManagement.startDate != nextProps.staffManagement.startDate ||
      this.props.staffManagement.endDate != nextProps.staffManagement.endDate
    )
      this.fetchData(nextProps)

    return true
  }

  renderContent() {
    if (this.props.entityList?.items === null) {
      return <PageLoader />
    }

    const items = [...this.props.entityList.items || []]

    const { startDate, startHour, endHour, addRecord, upload } = this.props.translation.staffManagement

    const { classes } = this.props

    return (
      <div>
        {this.props.canAddRecord && (
          <Card>
            <Form>
              <div style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem' }}>
                <Button onClick={() => this.uploader.openDialog()}>
                  <UploadIcon className={classes.leftIcon} />
                  {upload}
                </Button>
                <Button onClick={this.handleAdd}>
                  <AddIcon className={classes.leftIcon} />
                  {addRecord}
                </Button>
              </div>
            </Form>
          </Card>
        )}
        {items.map((x) => (
          <div style={{ cursor: 'pointer' }} key={x.id} onClick={() => this.handleSelect(x.id)}>
            <Card>
              <Form>
                <FormGroup style={{ display: 'flex' }}>
                  <div style={{ marginRight: 32 }}>
                    <Text type="caption">{startDate}</Text>
                    <Text type="body-1">{this.props.dateFormatter(x.startDate)}</Text>
                  </div>
                  <div style={{ marginRight: 32 }}>
                    <Text type="caption">{startHour}</Text>
                    <Text type="body-1">{this.props.timeFromObject(x.startHour, false, x.startDate)}</Text>
                  </div>
                  <div style={{ marginRight: 32 }}>
                    <Text type="caption">{endHour}</Text>
                    <Text type="body-1">{this.props.timeFromObject(x.endHour, false, x.startDate)}</Text>
                  </div>
                </FormGroup>
                {Boolean(x.comments && x.comments.length) && (
                  <FormGroup>
                    <div>
                      <Text type="body-1">{x.comments}</Text>
                    </div>
                  </FormGroup>
                )}
              </Form>
            </Card>
          </div>
        ))}
      </div>
    )
  }

  handleSelect = (id) => {
    this.pushPath(editLineStaffManagement(this.props.match.params.shopId, this.props.match.params.lineId, id))
  }

  handleAdd = () => {
    this.pushPath(createLineStaffManagement(this.props.match.params.shopId, this.props.match.params.lineId))
  }

  renderBarContent(props) {
    const { startDate, endDate } = this.props.translation.reportsFilter

    return (
      <SubBar smallScreen={props.smallScreen}>
        <SubBarControl title={startDate}>
          <DatePicker
            style={{ marginTop: 0 }}
            onChange={this.handleChangeStartDate}
            value={this.props.staffManagement.startDate}
          />
        </SubBarControl>
        <SubBarControl title={endDate}>
          <DatePicker
            style={{ marginTop: 0 }}
            onChange={this.handleChangeEndDate}
            value={this.props.staffManagement.endDate}
          />
        </SubBarControl>
      </SubBar>
    )
  }

  handleChangeStartDate = (startDate) => this.props.dispatch(storeStaffManagementFilter({ startDate }))
  handleChangeEndDate = (endDate) => this.props.dispatch(storeStaffManagementFilter({ endDate }))
}

const styles = (theme) => ({ button: { margin: theme.spacing(1) }, leftIcon: { marginRight: theme.spacing(1) } })

const stateToProps = (state) => ({
  staffManagement: state.staffManagement,
  entityList: state.entityList,
  canAddRecord: state.user.permissions.canEditShop
})

const actionsToProps = (dispatch) => ({
  getEntityList: (data) => dispatch(getStaffManagement(data)),
  resetEntityList: () => dispatch(resetEntityList()),
  dispatch
})

export default withStyles(styles)(connect(stateToProps, actionsToProps)(timeZoneFilter(translate(StaffManagement))))
