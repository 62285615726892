import { user } from 'provider'

export function login(email: string, password: string) {
  return user.login(email, password)
}

export function loginOpenAuth() {
  window.open(`${window.location.origin}/api/login`, '_self')
}

export function logout() {
  return user.logout()
}
