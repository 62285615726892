import { useEffect } from 'react'
import { editShop } from 'app/nav'
import { ShopView } from 'dumb/shop'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { useTranslation } from 'i18n/translate'
import ShopTiles from './shop-tiles'
import { Button, CircularProgress } from '@material-ui/core'
import { useDataProvider } from 'smart/use-data-provider'
import { useTimeZoneFilter } from 'smart/time-zone-filter'
import Container from 'dumb/container'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions/entity-actions'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { shop as shopAPI, brand } from 'provider'
import { Edit as ModeEdit } from '@material-ui/icons'
import { CenterContainer } from 'dumb/center-container'
import { useHistory, useParams } from 'react-router'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppDispatch, useAppSelector } from 'store'
import { useQuery } from 'react-query'

function SmartShopView() {
  const { timeZones } = useDataProvider()
  const { canEditShop } = useAppSelector(getPermissionsSelector)
  const dispatch = useAppDispatch()
  const { tr, locale } = useTranslation()
  const history = useHistory()
  const { shopId } = useParams<{ shopId: string }>()

  const { data: shop, isLoading } = useQuery(['shop', shopId], ({ signal }) => shopAPI.getShop(shopId, { signal }))
  const { data: brands } = useQuery(['brands'], ({ signal }) => brand.getBrandList({ signal }))

  useTimeZoneFilter(shopId)
  useEffect(setCrumbs, [shop, locale])

  function setCrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)
    const crumbs = [crumb.home(), crumb.shops(), crumb.shop([shopId], shop?.name || tr.breadcrumbs.shop)]

    dispatch(setBreadcrumbs(crumbs))
  }

  function handleEdit() {
    history.push(editShop(shopId))
  }

  if (isLoading) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!shop) {
    return null
  }

  return (
    <Container>
      <Card>
        <ShopView data={shop.data} brands={brands?.data || []} timeZones={timeZones} canUpdateShops={canEditShop} />
        {canEditShop && (
          <EntityActions
            showButtons
            buttons={
              <Button onClick={handleEdit} startIcon={<ModeEdit />}>
                {tr.entityView.editButton}
              </Button>
            }
            updateData={shop.data.lastUpdate}
          />
        )}
      </Card>
      <ShopTiles shop={shop.data} />
    </Container>
  )
}

export default SmartShopView
