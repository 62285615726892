import React, { Component } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import { ControlLabel } from 'dumb/control-label'

export default class RadioButtonGroup extends Component {
  componentDidMount() {
    const { ensureValue, value } = this.props

    if (ensureValue && !value) this.props.onChange(ensureValue)
  }

  render() {
    const style = this.props.vertical ? { marginTop: 12 } : { display: 'flex', marginTop: 12, whiteSpace: 'nowrap' }

    return (
      <div>
        <ControlLabel>{this.props.label}</ControlLabel>
        <RadioGroup
          row={this.props.row}
          onChange={(event) => this.props.onChange(event.target.value)}
          name={this.props.name}
          value={this.props.value}
          style={style}
        >
          {this.props.children}
        </RadioGroup>
      </div>
    )
  }
}
