import React, { Component } from 'react'
import css from './card.module.scss'

export default class Card extends Component {
  render() {
    return (
      <section className={css.root} style={this.props.style}>
        <div className={css.paper} style={this.props.paperStyle}>{this.props.children}</div>
      </section>
    )
  }
}
