import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import { DatePicker } from 'dumb/date'
import translate from 'i18n/translate'
import Dropdown from 'dumb/dropdown/dropdown'
import TimePicker from 'dumb/time/time-picker'
import { ControlLabel } from 'dumb/control-label'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'

class LoadingArmsUnavailabilityEdit extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'loadingArmId',
        stateField: 'loadingArmIdIsValid',
        validator: (val) => Boolean(val) || val === 0
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}

        <EntityActions showButtons={true} buttons={this.renderControls()} updateData={this.state.data.lastUpdate} />
      </Card>
    )
  }

  renderControls() {
    const { createButton, updateButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button id="confirm" onClick={() => this.props.onDelete(this.state.data.id)}>
              {deleteButton}
            </Button>
            <Button id="cancel" onClick={() => this.handleCancelDelete()}>
              {cancelButton}
            </Button>
          </div>
        </div>
      )

    if (!this.state.data.id)
      return (
        <Button disabled={!this.stateIsValid()} id="create" onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    else
      return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()} id="update">
            {updateButton}
          </Button>
          {this.props.canDelete !== false && (
            <Button id="delete" onClick={() => this.handleTryDelete()}>
              {deleteButton}
            </Button>
          )}
        </div>
      )
  }

  handleTryDelete() {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete() {
    this.setState({ confirmDelete: false })
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderMainForm(d) {
    const { comments, beginningOfUnavailability, endOfUnavailability } = this.props.translation.unavailabilityEdit
    const { loadingArm } = this.props.translation.common

    return (
      <Form>
        <FormGroup>
          <Dropdown
            label={loadingArm}
            attention={!this.state.loadingArmIdIsValid}
            value={d.loadingArmId}
            data={this.props.loadingArms}
            dataConverter={(item) => ({ value: item.id, text: item.name })}
            onChange={(val) => this.handlePropChange('loadingArmId', val)}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>{beginningOfUnavailability}</ControlLabel>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <DatePicker
              value={d.startDate}
              onChange={(val) => this.handlePropChange('startDate', +val)}
              disabled={!this.props.canEdit}
            />
            <TimePicker
              style={{ marginLeft: '8px' }}
              value={d.startTime}
              onChange={(val) => this.handlePropChange('startTime', val)}
              disabled={!this.props.canEdit}
              timeZone={this.props.timeZoneId}
              date={d.startDate}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <ControlLabel>{endOfUnavailability}</ControlLabel>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <DatePicker
              value={d.endDate}
              onChange={(val) => this.handlePropChange('endDate', +val)}
              disabled={!this.props.canEdit}
            />
            <TimePicker
              style={{ marginLeft: '8px' }}
              value={d.endTime}
              onChange={(val) => this.handlePropChange('endTime', val)}
              disabled={!this.props.canEdit}
              timeZone={this.props.timeZoneId}
              date={d.endDate}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <TextField
            label={comments}
            placeholder={comments}
            value={d.comment}
            onChange={(val) => this.handlePropChange('comment', val)}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
      </Form>
    )
  }
}

export default translate(LoadingArmsUnavailabilityEdit)
