class ClassesWithMods {
  constructor(classes: string[]) {
    this.classes = classes
    this.modMethods = {}
    this.createMethods()
  }

  classes: any[] = []
  modMethods

  createMethods() {
    Object.keys(this.classes).forEach((className) => {
      if (className.split('_').length === 1) {
        this.modMethods[className] = this.getModMethod(className)
      }
    })
  }

  getModMethod(className) {
    return (mods = {}) => {
      let withMods = Object.keys(mods).reduce((result, mod) => {
        const modClass = ClassesWithMods.stringifyMod(className, mod, mods[mod])
        return result + this.getModClassValue(modClass)
      }, this.classes[className])

      return {
        toString() {
          return withMods
        },

        add(name) {
          if (name) {
            withMods += ' ' + name
          }

          return this
        }
      }
    }
  }

  static stringifyMod(base, mod, modValue) {
    let result = ''

    if (modValue === false || typeof modValue === 'undefined') {
      return result
    }

    result += `${base}_${mod}`

    if (modValue !== true) {
      result += `_${modValue}`
    }

    return result
  }

  getModClassValue(className: string) {
    const classValue = this.classes[className]
    return classValue ? ` ${classValue}` : ''
  }
}

export function withMods(classes) {
  return new ClassesWithMods(classes).modMethods
}
