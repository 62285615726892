import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum MessageTypes {
  Default = 'default',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export type MessageModel = {
  type: MessageTypes
  text: string
  stamp: number
  duration?: number
}

export interface MainLayoutState {
  messages: MessageModel[]
  breadcrumbs: any[]
  hideTimezone: boolean
}

const initialState: MainLayoutState = {
  messages: [],
  breadcrumbs: [],
  hideTimezone: false
}

const mainLayoutSlice = createSlice({
  name: 'mainLayout',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<any[]>) => {
      state.breadcrumbs = action.payload
    },
    showMessage: (state, action: PayloadAction<MessageModel>) => {
      state.messages.push(action.payload)
    },
    removeMessage: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.filter((el) => el.stamp !== action.payload)
    },
    hideTimeZone: (state) => {
      state.hideTimezone = true
    },
    showTimeZone: (state) => {
      state.hideTimezone = false
    }
  }
})

export const { removeMessage, setBreadcrumbs, showMessage, hideTimeZone, showTimeZone } = mainLayoutSlice.actions

export default mainLayoutSlice.reducer
