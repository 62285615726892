import { messageTemplates } from 'provider'
import { Dispatch } from '@reduxjs/toolkit'
import { setListRequest, setListSuccess, invalidateList } from '../reducers/message-templates-reducer'

export function getMessageTemplatesConfig({ lineId, shopId, supportedLanguages }) {
  return async (dispatch: Dispatch) => {
    dispatch(setListRequest())

    const res = await messageTemplates.getMessageTemplatesConfig(lineId, shopId)

    if (supportedLanguages) {
      const supportedLanguagesList = supportedLanguages.split(';')
      res.data.languages = res.data.languages.filter((x) => supportedLanguagesList.includes(x.value))
    }

    dispatch(setListSuccess(res.data))
  }
}

export function invalidateMessageTemplatesConfig() {
  return async (dispatch: Dispatch) => {
    dispatch(invalidateList())
  }
}
