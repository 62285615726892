import React, { Component } from 'react'
import css from './time-picker.module.scss'
import { addOffsetToObject } from 'i18n/date-formatter'
import translate from 'i18n/translate'
import { MuiPickersUtilsProvider, TimePicker as ClickTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import pickerLocale from 'i18n/picker-locale'
import moment from 'moment-timezone'

class TimePicker extends Component {
  handleChange = (date) => {
    if (this.props.timeZone) {
      const offset = moment.tz.zone(this.props.timeZone).utcOffset(this.props.date || new Date().getTime())
      date = new Date(+date + offset * 60 * 1000)
    } else if (this.props.offset) {
      date = new Date(+date - this.props.offset)
    }

    this.props.onChange({ minutes: date.getMinutes(), hours: date.getHours() })
  }

  getValue() {
    const { value } = this.props

    if (!value) {
      return null
    }

    let val = value.toObject ? value.toObject() : value

    if (this.props.timeZone) {
      const offset = moment.tz.zone(this.props.timeZone).utcOffset(this.props.date || new Date().getTime())
      val = addOffsetToObject(val, -offset * 60 * 1000)
    } else if (this.props.offset) {
      val = addOffsetToObject(val, this.props.offset)
    }

    let date = new Date()
    date.setMinutes(val.minutes)
    date.setHours(val.hours)

    return date
  }

  render() {
    const { ok, cancel, clear } = this.props.translation.timePicker

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.props.pickerLocale}>
        <ClickTimePicker
          okLabel={ok}
          cancelLabel={cancel}
          clearLabel={clear}
          margin={this.props.margin}
          id={this.props.id}
          style={this.props.style}
          clearable
          ampm={false}
          label={
            (this.props.label || this.props.attention) && (
              <div style={{ display: 'flex' }}>
                {this.props.label}
                {this.props.attention && <div className={css.attention} />}
              </div>
            )
          }
          value={this.getValue()}
          onChange={this.handleChange}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

export default pickerLocale()(translate(TimePicker))
