import React, { Component } from 'react'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import css from './base-entity.module.scss'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { index } from 'app/nav'
import { withMods } from 'utils/cssm'
import debounce from 'lodash/debounce'

const mod = withMods(css)

export default class Base extends Component {
  state = {
    smallScreen: false,
    hideBar: false
  }

  scrollTop = 0

  componentDidMount() {
    if (this.renderBarContent) {
      window.addEventListener('scroll', this.scrollHandler, false)
      window.addEventListener('resize', this.resizeHandler, false)
      this.resizeHandler()
    }

    if (this.checkViewPermissions() === false) {
      this.pushPath(index())
    }

    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    this.setBreadcrumbs(this.getBreadcrumbs(crumb))

    this.fetchData()
  }

  componentDidUpdate() {
    this.resizeHandler()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler, false)
    window.removeEventListener('scroll', this.scrollHandler, false)
  }

  scrollHandler = debounce(() => {
    if (!this.state.smallScreen) {
      return
    }

    const st = window.pageYOffset || document.documentElement.scrollTop

    const delta = st - this.scrollTop

    this.scrollTop = st

    if (delta > 70) {
      this.setState({ hideBar: true })
    }

    if (delta < -70) {
      this.setState({ hideBar: false })
    }
  }, 100)

  resizeHandler = debounce(() => {
    const bar = this.bar

    if (!bar) {
      return
    }

    if (bar.clientWidth < bar.scrollWidth && !this.state.smallScreen) {
      this.breakpoint = bar.scrollWidth
      this.setState({ smallScreen: true })
    }

    if (bar.clientWidth > this.breakpoint && this.state.smallScreen) {
      this.setState({ smallScreen: false })
    }
  }, 250)

  render() {
    const bar = this.renderBarContent && this.renderBarContent({ smallScreen: this.state.smallScreen })

    const withBar = Boolean(bar) && !this.state.hideBar

    return (
      <div className={mod.root({ withBar })}>
        {withBar ? this.renderBar(bar) : undefined}
        <div className={css.container}>
          {this.state.smallScreen && this.barLines && <div style={{ height: this.barLines * 56 }} />}
          {this.renderContent({
            smallScreen: this.state.smallScreen
          })}
        </div>
      </div>
    )
  }

  renderBar(bar) {
    const { smallScreen } = this.state

    return (
      <div className={mod.bar({ smallScreen })} ref={(bar) => (this.bar = bar)}>
        <div className={mod.barContainer()}>{bar}</div>
      </div>
    )
  }

  checkViewPermissions() {}

  fetchData() {}

  pushPath(path) {
    this.props.history.push(path)
  }

  setBreadcrumbs(crumbs) {
    this.props.dispatch(setBreadcrumbs(crumbs))
  }
}
