import React from 'react'

export default class LocalGasStationUnavailable extends React.PureComponent {
  render() {
    return (
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <path
          d="M19.77,7.23 C20.22,7.68 20.5,8.31 20.5,9 L20.5,18.5 C20.5,19.88 19.38,21 18,21 C16.62,21 15.5,19.88 15.5,18.5 L15.5,13.5 L14,13.5 L14,21 L4,21 L4,5 C4,3.9 4.9,3 6,3 L12,3 C13.1,3 14,3.9 14,5 L14,12 L15,12 C16.1,12 17,12.9 17,14 L17,18.5 C17,19.05 17.45,19.5 18,19.5 C18.55,19.5 19,19.05 19,18.5 L19,11.29 C18.69,11.42 18.36,11.5 18,11.5 C16.62,11.5 15.5,10.38 15.5,9 C15.5,7.93 16.17,7.03 17.11,6.67 L15,4.56 L16.06,3.5 L19.78,7.22 L19.77,7.23 Z M7.58578644,13.1715729 L6.17157288,14.5857864 L7.59740277,16.0116163 L6.17157288,17.4142136 L7.58578644,18.8284271 L9.006356,17.4205696 L10.4142136,18.8284271 L11.8284271,17.4142136 L10.4142136,16 L11.8284271,14.5857864 L10.4142136,13.1715729 L9,14.5857864 L7.58578644,13.1715729 Z M12,10 L12,5 L6,5 L6,10 L12,10 Z M18,10 C18.55,10 19,9.55 19,9 C19,8.45 18.55,8 18,8 C17.45,8 17,8.45 17,9 C17,9.55 17.45,10 18,10 Z"
          id="Shape"
          fillRule="nonzero"
        />
      </svg>
    )
  }
}
