import React, { Component } from 'react'
import { List } from 'dumb/list'
import translate from 'i18n/translate'
import isFunction from 'lodash/isFunction'
import Avatar from '@material-ui/core/Avatar'

class LineList extends Component {
  render() {
    return (
      <List
        data={this.props.data}
        onSelect={(data) => this.props.onSelect(data)}
        dataConverter={(item) => {
          const name = item.name
          const avatar = <Avatar>{name[0].toUpperCase()}</Avatar>
          const { lineState } = this.props.translation.monitoring
          const stateTr = lineState[item.state]

          return {
            id: item.id,
            primaryText: name,
            avatar
          }
        }}
      />
    )
  }
}

export default translate(LineList)
