import React, { Component, createElement } from 'react'
import { connect } from 'react-redux'
import isFunction from 'lodash/isFunction'
import {
  getIsolatedShopList,
  getIsolatedLineList,
  getIsolatedTimeZones,
  getIsolatedCheckpointList
} from 'store/actions/shop-action-creators'
import { getIsolatedBrandList } from 'store/actions/brand-action-creators'
import debounce from 'lodash/debounce'
import CircularProgress from '@material-ui/core/CircularProgress'

function getStateToProps(opts) {
  return (state) => {
    let props = {}

    if (opts.shops || opts.lines || opts.checkpoints) {
      props.shops = state.shops.items
      props.shopsFetching = state.shops.fetching
    }

    if (opts.brands) {
      props.brands = state.brands.items
    }

    if (opts.timeZones) {
      props.timeZones = state.timeZones.items
    }

    return props
  }
}

export default function DataProvider(opts) {
  return (WrappedComponent) => {
    class DataProviderComponent extends Component {
      componentDidMount() {
        this.fetchData()
      }

      componentDidUpdate() {
        this.fetchData()
      }

      fetchData = debounce(() => {
        if (this.waitBrands(opts)) {
          this.props.dispatch(getIsolatedBrandList())
          return null
        }

        if (this.waitTimeZones(opts)) {
          this.props.dispatch(getIsolatedTimeZones())
          return null
        }

        if (this.waitShops(opts)) {
          if (!this.props.shopsFetching) this.props.dispatch(getIsolatedShopList())

          return null
        }

        if (this.waitLines(opts)) {
          this.props.dispatch(getIsolatedLineList(opts.lines(this.props)))
          return null
        }

        if (this.waitCheckpoints(opts)) this.props.dispatch(getIsolatedCheckpointList(opts.checkpoints(this.props)))
      }, 10)

      render() {
        if (
          this.waitTimeZones(opts) ||
          this.waitBrands(opts) ||
          this.waitShops(opts) ||
          this.waitLines(opts) ||
          this.waitCheckpoints(opts)
        )
          return (
            <div
              style={{
                background: '#fafafa',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )

        return createElement(WrappedComponent, this.getWrappedProps(opts))
      }

      waitShops(opts) {
        return !this.props.shops && (opts.shops || opts.lines)
      }

      waitLines(opts) {
        return isFunction(opts.lines) && !this.getLines(opts.lines(this.props))
      }

      waitCheckpoints(opts) {
        return isFunction(opts.checkpoints) && !this.getCheckpoints(opts.checkpoints(this.props))
      }

      waitBrands(opts) {
        return !this.props.brands && opts.brands
      }

      waitTimeZones(opts) {
        return !this.props.timeZones && opts.timeZones
      }

      getWrappedProps(opts) {
        let props = { ...this.props }

        if (opts.shops || opts.lines || opts.checkpoints) {
          props.getShops = this.getShops
          props.getShop = this.getShop
        }

        if (opts.lines) {
          props.getLines = this.getLines
        }

        if (opts.checkpoints) {
          props.getCheckpoints = this.getCheckpoints
          props.getCheckpoint = this.getCheckpoint
        }

        if (opts.timeZones) {
          props.getTimeZones = this.getTimeZones
        }

        if (opts.brands) {
          props.getBrands = this.getBrands
        }

        return props
      }

      getShops = () => this.props.shops
      getShop = (shopId) => this.getShops().find((x) => x.id == shopId)
      getLines = (shopId) => this.getShop(shopId).lines
      getCheckpoints = (shopId) => this.getShop(shopId).checkpoints
      getCheckpoint = (shopId, checkpointId) => this.getCheckpoints(shopId).find((x) => x.id == checkpointId)
      getTimeZones = () => this.props.timeZones
      getBrands = () => this.props.brands
    }

    return connect(getStateToProps(opts))(DataProviderComponent)
  }
}
