import React from 'react'
import { connect } from 'react-redux'
import * as Nav from 'app/nav'
import translate from 'i18n/translate'
import dataProvider from 'smart/isolated-data-provider'
import timeZoneFilter from 'smart/time-zone-filter'
import Container from 'dumb/container'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import GenericProductEdit from 'dumb/generic-product-total/generic-product-edit'
import debounce from 'lodash/debounce'
import { total } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { produce } from 'immer'
import CircularProgress from '@material-ui/core/CircularProgress'

class SmartGenericProductEdit extends React.Component {
  state = {
    operation: false,
    data: null,
    loading: false
  }

  editState = (fn) => this.setState(produce(fn))

  fetchData = debounce(
    (term) => {
      this.editState((draft) => {
        draft.loading = true
      })

      return total.getGenericProduct({ id: this.getGenericProductId() }).then((resp) => {
        this.editState((draft) => {
          draft.data = resp
          draft.loading = false
        })

        return resp
      })
    },
    300,
    { leading: true }
  )

  componentDidMount() {
    this.getGenericProductId()
      ? this.fetchData().then((data) => this.setBreadcrumbsRequest(data))
      : this.setBreadcrumbsStandAlone()
  }

  setBreadcrumbsStandAlone() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const crumbs = [crumb.home(), crumb.genericProducts(), crumb.newGenericProduct()]
    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  setBreadcrumbsRequest(data) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const genericProductId = this.getGenericProductId()
    const genericProductName = data.data.name
    const crumbs = [
      crumb.home(),
      crumb.genericProducts(),
      crumb.editGenericProduct([genericProductId], genericProductName)
    ]
    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  render() {
    if (this.state.loading) return <CircularProgress color="secondary" style={{ marginTop: '100px' }} />

    return (
      <Container>
        <GenericProductEdit
          data={{ ...(this.state.data ? this.state.data.data : { code: '', name: '' }) }}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          canEdit={this.props.canEditShop}
        />
      </Container>
    )
  }

  handleSave = (data) => {
    this.editState((draft) => {
      draft.loading = true
      draft.operation = true
    })

    const msg = this.props.translation.genericProductOperationMessages

    if (this.getGenericProductId()) {
      total
        .updateGenericProduct(data)
        .then(() => {
          this.editState((draft) => {
            draft.loading = false
          })
          this.props.dispatch(showMessage(msg.updateSuccess, 'success'))
          this.pushPath(Nav.genericProducts())
        })
        .catch(() => {
          this.editState((draft) => {
            draft.loading = false
          })
          this.props.dispatch(showMessage(msg.updateError, 'error'))
        })
    } else {
      total
        .createGenericProduct(data)
        .then(() => {
          this.editState((draft) => {
            draft.loading = false
          })
          this.props.dispatch(showMessage(msg.createSuccess, 'success'))
          this.pushPath(Nav.genericProducts())
        })
        .catch(() => {
          this.editState((draft) => {
            draft.loading = false
          })
          this.props.dispatch(showMessage(msg.createError, 'error'))
        })
    }
  }

  handleDelete = (id) => {
    this.editState((draft) => {
      draft.loading = true
      draft.operation = true
    })

    const msg = this.props.translation.genericProductOperationMessages

    total
      .deleteGenericProduct(id)
      .then(() => {
        this.editState((draft) => {
          draft.loading = false
        })
        this.props.dispatch(showMessage(msg.deleteSuccess, 'success'))
        this.pushPath(Nav.genericProducts())
      })
      .catch(() => {
        this.editState((draft) => {
          draft.loading = false
        })
        this.props.dispatch(showMessage(msg.deleteError, 'error'))
      })
  }

  pushPath = (path) => this.props.history.push(path)

  getGenericProductId = () => this.props.match.params.genericProductId
}

const stateToProps = (state) => ({ canEditShop: state.user.permissions.canEditShop })

export default connect(stateToProps)(
  dataProvider({ shops: true, brands: true, timeZones: true })(timeZoneFilter(translate(SmartGenericProductEdit)))
)
