import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import mainLayout from './reducers/main-layout-reducer'
import entityList from './reducers/entity-list-reducer'
import entity from './reducers/entity-reducer'
import user from './reducers/user-reducer'
import shops from './reducers/shops-reducer'
import brands from './reducers/brands-reducer'
import messageTemplates from './reducers/message-templates-reducer'
import staffManagement from './reducers/staff-management-reducer'
import timeZones from './reducers/time-zones-reducer'
import memoryFiltersState from './reducers/memory-filters-state-reducer'

const store = configureStore({
  reducer: {
    mainLayout,
    entityList,
    entity,
    user,
    shops,
    brands,
    messageTemplates,
    staffManagement,
    timeZones,
    memoryFiltersState
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { store }
