import React from 'react'
import LegacyBase from 'smart/base/legacy-base'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { editProductsUnavailability, createProductsUnavailability } from 'app/nav'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import { DatePicker } from 'dumb/date'
import Card from 'dumb/card'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import timeZoneFilter from 'smart/time-zone-filter'
import Uploader from 'utils/file-uploader'
import { total } from 'provider'
import UploadIcon from '@material-ui/icons/CloudUploadOutlined'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { List } from 'dumb/list'
import { shallowDiff } from 'utils/object'
import { setLoadingArmsUnavailabilityFilter } from 'store/actions/memory-filters-state-actions'
import { produce } from 'immer'
import LinearProgress from '@material-ui/core/LinearProgress'
import DropdownSubBarFilter from 'dumb/dropdown-sub-bar-filter'
import CircularProgress from '@material-ui/core/CircularProgress'

class GenericProductsUnavailability extends LegacyBase {
  barLines = 2

  state = {
    list: null,
    loadingGenericProducts: null,
    startDate: Date.now(),
    endDate: Date.now() + 1000 * 60 * 60 * 24 * 30,
    genericProductId: null,
    loading: false
  }

  processFileForm = (data) => {
    let form = new FormData(data)

    return form
  }

  handleUploadSuccess = () => {
    this.setState({ loading: false })
    this.props.dispatch(showMessage(this.props.translation.uploadOperationMessages.uploadSuccess, 'success'))
    this.fetchData(this.props)
  }

  handleError = (file, error) => {
    this.setState({ loading: false })
    this.props.dispatch(showMessage(this.props.translation.uploadOperationMessages.uploadError, 'error'))
  }

  handleUploadStart = () => this.setState({ loading: true })

  uploader = new Uploader({
    request: total.uploadGenericProductUnavailability,
    processForm: this.processFileForm,
    onSuccess: this.handleUploadSuccess,
    onFail: this.handleError,
    onUpload: this.handleUploadStart,
    accept: '.xls, .xlsx'
  })

  setBreadcrumbs(data) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    const crumbs = [crumb.home(), crumb.productsUnavailability()]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  componentDidMount() {
    super.componentDidMount()

    this.trySetFilterFromStorage()

    this.fetchLoadingArmsDataToFilter()

    this.fetchData().then((list) => this.setBreadcrumbs(list))
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate()
    if (shallowDiff(prevState, this.state, ['startDate', 'endDate', 'genericProductId'])) {
      this.fetchData()
    }
  }

  trySetFilterFromStorage = () => {
    const filterState = this.props.filterState
    const filterStateBefore = this.state

    if (filterState) {
      try {
        this.setState({
          startDate: filterState.startDate,
          endDate: filterState.endDate,
          loadingArmId: filterState.genericProductId
        })
      } catch (e) {
        this.setState(filterStateBefore)
        console.warn('Set Filter FAIL. Storage filter remove.')
        this.props.setLoadingArmsUnavailabilityFilter(null)
      }
    }
  }

  fetchLoadingArmsDataToFilter = () => {
    this.setState({ loading: true })

    total.getGenericProducts().then((resp) => {
      const loadingGenericProducts = resp.data
      this.setState({ loadingGenericProducts })
      if (this.state.list) this.setState({ loading: false })
    })
  }

  fetchData() {
    this.setState({ loading: true })
    return total
      .getGenericProductsUnavailabilities({
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        genericProductId: this.state.genericProductId || ''
      })
      .then((resp) => {
        const list = resp
        this.setState({ list })
        this.setState({ loading: false })
        return list
      })
  }

  renderLoading() {
    if (this.state.loading && (!this.state.list || !this.state.loadingGenericProducts))
      return <CircularProgress color="secondary" />
    return null
  }

  renderContent() {
    if (!this.state.list || !this.state.loadingGenericProducts) {
      return null
    }

    const { add, upload } = this.props.translation.common

    const { classes } = this.props

    const { loading } = this.state

    return (
      <div>
        {this.props.canEditShop && (
          <Card>
            {loading && <LinearProgress variant="query" color="secondary" />}
            <div style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem' }}>
              <Button disabled={loading} onClick={() => this.uploader.openDialog()}>
                <UploadIcon className={classes.leftIcon} />
                {upload}
              </Button>
              <Button disabled={loading} onClick={this.handleAdd}>
                <AddIcon className={classes.leftIcon} />
                {add}
              </Button>
            </div>
          </Card>
        )}
        <Card>
          <List
            data={this.state.list.data}
            onSelect={(data) => this.handleSelect(data.id)}
            dataConverter={(item) => {
              const name = item.name
              const code = item.code

              const startDate = this.props.dateFormatter(item.startDate, 'date')
              const endDate = this.props.dateFormatter(item.endDate, 'date')
              const startTime = this.props.timeFromObject(item.startTime, false, item.startDate)
              const endTime = this.props.timeFromObject(item.endTime, false, item.endDate)

              return {
                id: item.id,
                primaryText: (
                  <div>
                    {code} — {name}
                    <div style={{ marginTop: 4 }}>
                      {startDate} {startTime}
                      <span style={{ opacity: 0.54 }}>—</span> {endDate} {endTime}
                    </div>
                  </div>
                ),
                secondaryText: this.props.translation.entityView.lastUpdate(
                  item.lastUpdate.user.firstName,
                  item.lastUpdate.user.secondName,
                  this.props.dateFormatter(item.lastUpdate.date, 'dateTime')
                )
              }
            }}
          />
        </Card>
      </div>
    )
  }

  handleSelect = (id) => {
    this.pushPath(editProductsUnavailability(id))
  }

  handleAdd = () => {
    this.pushPath(createProductsUnavailability())
  }

  renderBarContent(props) {
    if (!this.state.loadingGenericProducts) {
      return
    }

    const { startDate, endDate } = this.props.translation.reportsFilter
    const { genericProduct } = this.props.translation.common

    return (
      <SubBar smallScreen={props.smallScreen}>
        <SubBarControl title={startDate}>
          <DatePicker style={{ marginTop: 0 }} onChange={this.handleChangeStartDate} value={this.state.startDate} />
        </SubBarControl>
        <SubBarControl title={endDate}>
          <DatePicker style={{ marginTop: 0 }} onChange={this.handleChangeEndDate} value={this.state.endDate} />
        </SubBarControl>
        <DropdownSubBarFilter
          title={genericProduct}
          items={this.state.loadingGenericProducts}
          selectedId={this.state.genericProductId}
          onChange={this.handleChangeGenericProduct}
          dataConverter={(item) => ({
            text: item.code ? item.code : item.name,
            value: item.id
          })}
        />
        <div style={{ width: '20px' }}>
          {this.state.loading && this.state.list && this.state.loadingGenericProducts && (
            <CircularProgress color="secondary" style={{ width: '24px', height: '24px', marginLeft: '-24px' }} />
          )}
        </div>
      </SubBar>
    )
  }

  handleFilterState = (propsName, value) =>
    this.setState(
      (state) =>
        produce(state, (draft) => {
          draft[propsName] = value
        }),
      () =>
        this.props.setLoadingArmsUnavailabilityFilter({
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          genericProductId: this.state.genericProductId
        })
    )

  handleChangeStartDate = (startDate) => this.handleFilterState('startDate', startDate) //this.setState({ startDate });
  handleChangeEndDate = (endDate) => this.handleFilterState('endDate', endDate) //this.setState({ endDate });
  handleChangeGenericProduct = (data) => this.handleFilterState('genericProductId', data.id) //this.setState({ loadingArmId: data.loadingArmId });

  pushPath = (path) => this.props.history.push(path)
}

const styles = (theme) => ({
  button: { margin: theme.spacing(1) },
  leftIcon: { marginRight: theme.spacing(1) }
})

const stateToProps = (state) => ({
  filterState: state.memoryFiltersState.loadingArmsUnavailabilityFilter,
  canEditShop: state.user.permissions.canEditShop
})

const actionsToProps = (dispatch) => ({
  setLoadingArmsUnavailabilityFilter: (data) => dispatch(setLoadingArmsUnavailabilityFilter(data)),
  dispatch
})

export default withStyles(styles)(
  connect(stateToProps, actionsToProps)(timeZoneFilter(translate(GenericProductsUnavailability)))
)
