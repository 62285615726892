import React, { Component } from 'react'
import css from './list-item.module.scss'
import { withMods } from 'utils/cssm'
import isArray from 'lodash/isArray'

const mod = withMods(css)

export default class EntityItem extends Component {
  render() {
    return (
      <div
        className={mod.root({
          notActive: this.props.notActive,
          selected: this.props.selected,
          avatarOnTop: this.props.avatarOnTop
        })}
      >
        {this.renderAvatar()}
        <div
          className={css.text}
          onClick={() => {
            this.handleSelect()
          }}
        >
          <div className={css.primary}>{this.props.primaryText}</div>
          {this.renderSecondaryText()}
        </div>
        {this.renderControls()}
        <div className={css.bg} />
      </div>
    )
  }

  renderAvatar() {
    if (!this.props.avatar) return

    return (
      <div
        className={mod.avatar({
          onTop: this.props.avatarOnTop
        })}
      >
        {this.props.avatar}
      </div>
    )
  }

  renderSecondaryText() {
    if (!this.props.secondaryText) return null

    let content = this.props.secondaryText

    if (isArray(content)) {
      content = content.map((item, i) => (
        <div className={css.secondaryTextItem} key={i}>
          {item}
        </div>
      ))
    }
    return <div className={css.secondary}>{content}</div>
  }

  renderControls() {
    const { controls } = this.props

    if (!controls) return

    return (
      <div className={css.controls}>
        {controls.map((control, i) => (
          <div key={i}>{control}</div>
        ))}
      </div>
    )
  }

  handleSelect() {
    const { onSelect, handleData, entity, id } = this.props
    onSelect(handleData ? handleData(entity) : { id })
  }
}
