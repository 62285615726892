import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface MemoryFiltersState {
  pointOfServicesUnavailabilityFilter: any
  loadingArmsUnavailabilityFilter: any
  appointmentsSchedulingFilter: any
  loadingAuthorizationsFilter: any
  appointmentsToCancelFilter: any
  appointmentsFilter: any
}

const initialState: MemoryFiltersState = {
  pointOfServicesUnavailabilityFilter: null,
  loadingArmsUnavailabilityFilter: null,
  appointmentsSchedulingFilter: null,
  loadingAuthorizationsFilter: null,
  appointmentsToCancelFilter: null,
  appointmentsFilter: null
}

const memoryFiltersSlice = createSlice({
  name: 'memoryFilters',
  initialState,
  reducers: {
    setPointOfServicesUnavailabilityFilter: (state, action: PayloadAction<any>) => {
      state.pointOfServicesUnavailabilityFilter = action.payload
    },
    setLoadingArmsUnavailabilityFilter: (state, action: PayloadAction<any>) => {
      state.loadingArmsUnavailabilityFilter = action.payload
    },
    setLoadingAuthorizationsFilter: (state, action: PayloadAction<any>) => {
      state.loadingAuthorizationsFilter = action.payload
    },
    setAppointmentsFilter: (state, action: PayloadAction<any>) => {
      state.appointmentsFilter = action.payload
    }
  }
})

export const {
  setAppointmentsFilter,
  setLoadingArmsUnavailabilityFilter,
  setLoadingAuthorizationsFilter,
  setPointOfServicesUnavailabilityFilter
} = memoryFiltersSlice.actions

export default memoryFiltersSlice.reducer
