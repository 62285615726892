import React, { Component } from 'react'
import { FormActionGroup } from 'dumb/form'
import css from './entity-actions.module.scss'
import Text from 'dumb/text'
import translate from 'i18n/translate'

class EntityActions extends Component {
  renderButtons() {
    if (this.props.showButtons) {
      return this.props.buttons
    }

    return <div style={{ marginLeft: -8 }} />
  }

  render() {
    const { lastUpdate } = this.props.translation.entityView
    const { updateData } = this.props

    return (
      <FormActionGroup>
        {this.renderButtons()}
        {updateData && (
          <div className={css.lastUpdate}>
            <Text type="body-3">
              {lastUpdate(
                updateData.user?.firstName,
                updateData.user?.secondName,
                this.props.dateFormatter(updateData.date, 'dateTime')
              )}
            </Text>
          </div>
        )}
      </FormActionGroup>
    )
  }
}

export default translate(EntityActions)
