import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import Card from 'dumb/card'
import translate from 'i18n/translate'
import Toggle from 'dumb/toggle-wrap'
import { DatePicker } from 'dumb/date'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import SaveIcon from '@material-ui/icons/Check'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'
import Chip from '@material-ui/core/Chip'
import Text from '../text/text'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { Validation } from 'utils/@validation'

class ShopDaysOff extends Validation {
  render() {
    const { save, daysOff, addDay, repeatDay, weekdays, selectNotWorkingWeekdays } = this.props.translation.shopEdit

    const d = this.state.data

    return (
      <React.Fragment>
        <Card>
          <Form title={weekdays}>
            <FormGroup>
              <Text type="caption">{selectNotWorkingWeekdays}</Text>
            </FormGroup>
            <FormGroup>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((x) => (
                  <div style={{ marginRight: 8, marginBottom: 8 }} key={x}>
                    <Chip
                      variant="outlined"
                      color={(d.weekdays || []).find((wd) => wd === x) ? 'secondary' : 'default'}
                      onClick={this.props.canEdit ? () => this.handleWeekDayChange(x) : () => {}}
                      label={this.props.translation.weekdays[x]}
                    />
                  </div>
                ))}
              </div>
            </FormGroup>
          </Form>
          <Form title={daysOff}>
            <FormGroup>
              {d.daysOff.map((day, i) => (
                <div style={{ display: 'flex', alignItems: 'center' }} key={i + day.date}>
                  <DatePicker
                    disabled={!this.props.canEdit}
                    value={day.date}
                    onChange={(val) => this.handleDateChange(i, +val)}
                    style={{ marginTop: 0 }}
                  />
                  <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                    <div>
                      <Toggle
                        disabled={!this.props.canEdit}
                        toggled={day.repeat}
                        label={repeatDay}
                        onToggle={(ev, val) => this.handleRepeatChange(i, val)}
                      />
                    </div>
                  </div>
                  <IconButton disabled={!this.props.canEdit} onClick={() => this.handleRemoveDay(i)}>
                    <RemoveIcon />
                  </IconButton>
                </div>
              ))}
            </FormGroup>
            {this.props.canEdit && (
              <div style={{ display: 'flex', gap: '0.5rem', padding: '0.5rem' }}>
                <Button startIcon={<AddIcon />} onClick={this.handleAddDay}>
                  {addDay}
                </Button>
                <Button startIcon={<SaveIcon />} onClick={this.handleSave}>
                  {save}
                </Button>
              </div>
            )}
          </Form>
        </Card>
      </React.Fragment>
    )
  }

  handleWeekDayChange = (controlData) => {
    let arrayOfWeekDays = [...(this.state.data.weekdays || [])]

    if (arrayOfWeekDays.find((x) => x === controlData)) {
      arrayOfWeekDays = arrayOfWeekDays.filter((x) => x !== controlData)
    } else {
      arrayOfWeekDays = [...arrayOfWeekDays, controlData]
    }

    console.log(arrayOfWeekDays)

    this.setState({ data: { ...this.state.data, weekdays: arrayOfWeekDays } })
  }

  handleSave = () => {
    this.props.onSave(this.state.data)
  }

  handleAddDay = () => {
    this.setState({
      data: { ...this.state.data, daysOff: [...this.state.data.daysOff, { repeat: true, date: Date.now() }] }
    })
  }

  handleRemoveDay = (i) => {
    const daysOff = [...(this.state.data.daysOff || [])]
    daysOff.splice(i, 1)
    this.setState({ data: { ...this.state.data, daysOff } })
  }

  handleRepeatChange = (i, val) => {
    const daysOff = [...(this.state.data.daysOff || [])]
    daysOff[i] = { ...daysOff[i], repeat: val }
    this.setState({ data: { ...this.state.data, daysOff } })
  }

  handleDateChange = (i, val) => {
    const daysOff = [...(this.state.data.daysOff || [])]
    daysOff[i] = { ...daysOff[i], date: val }
    this.setState({ data: { ...this.state.data, daysOff } })
  }
}

export default translate(ShopDaysOff)
