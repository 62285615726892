import styled, { createGlobalStyle } from 'styled-components'

const GlobalStylesDevExtremeScheduler = createGlobalStyle<{ $appointmentHeight?: number }>`
  .dx-scheduler-time-panel-cell {
    height: ${(props) => props.$appointmentHeight || 80}px !important;
  }
  .dx-scheduler-date-table-cell {
    height: ${(props) => props.$appointmentHeight || 80}px !important;
  }
`

const ZoomContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 1rem;
  background: #fff;
  border: solid 1px #cdd1d3;
  display: flex;
  z-index: 999;
`

const ZoomControl = styled.div<{ $disabled: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.$disabled ? 0.32 : 1)};
  font-weight: bolder;
`

const ZoomValue = styled.div`
  height: 24px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`

export { GlobalStylesDevExtremeScheduler, ZoomContainer, ZoomControl, ZoomValue }
