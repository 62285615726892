import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import { msToTime, isTimeZoneSupported } from 'utils/date'
import { TimeFormats } from './translation-context'

const addZero = (n: number) => (n < 10 ? '0' : '') + n

export default (
  locale: string,
  date: number | string | Date | null,
  format: TimeFormats | Intl.DateTimeFormatOptions = 'date',
  timeZone = 'UTC',
  noShift = false
) => {
  const formats = {
    time: {
      hour: 'numeric',
      minute: '2-digit'
    },
    date: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    dateTime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit'
    }
  }

  if (isString(date) && date !== null) {
    date = new Date(date)
  }

  let f = isObject(format) ? (format as any) : formats[format as TimeFormats]

  if (isTimeZoneSupported) {
    f.timeZone = timeZone
  }

  if (noShift) f.timeZone = 'UTC'

  return new Intl.DateTimeFormat(locale, f).format(date as any)
}

export const timeFromObject = (timeObj, offset, noShift = false) => {
  let time = { ...timeObj }

  time.hours = (Number(time.hours) + 24) % 24

  if (offset) {
    const t: any = !noShift ? msToTime(objectToMs(time) + offset) : msToTime(objectToMs(time))
    t.hh = t.hh % 24

    return `${t.hh}:${t.mm}`
  }

  const hours = genericGetFromObject(time, 'hours')
  const minutes = genericGetFromObject(time, 'minutes')

  const hoursAddZero = addZero(hours)
  const minutesAddZero = addZero(minutes)

  return `${hoursAddZero}:${minutesAddZero}`
}

function objectToMs(time) {
  const hours = genericGetFromObject(time, 'hours')
  const minutes = genericGetFromObject(time, 'minutes')

  return 3600000 * hours + 60000 * minutes
}

const genericGetFromObject = (obj, propName) => {
  if (!obj) return 0

  const getValue = obj.get ? obj.get(propName) : obj[propName]

  return getValue
}

export const addOffsetToObject = (time, offset) => {
  const ms = 3600000 * time.hours + 60000 * time.minutes
  let s = ms + offset

  if (s < 0) s += 3600000 * 24

  const t = msToTime(s)

  return {
    hours: t.h,
    minutes: t.m
  }
}
