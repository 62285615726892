import React from 'react'
import JeFileFrCircleIcon from '../icons/fr-circle-icon'
import JeFileDeCircleIcon from '../icons/de-circle-icon'
import JeFileItCircleIcon from '../icons/it-circle-icon'
import JeFileEnCircleIcon from '../icons/en-circle-icon'
import JeFileEsCircleIcon from '../icons/es-circle-icon'

export default class LanguageIcon extends React.Component {
  langIcons = {
    fr: JeFileFrCircleIcon,
    de: JeFileDeCircleIcon,
    it: JeFileItCircleIcon,
    en: JeFileEnCircleIcon,
    es: JeFileEsCircleIcon,
    noSupportedIcon: null
  }

  render() {
    const { language } = this.props

    if (!language) return null

    const Icon = this.langIcons[language] || this.langIcons.noSupportedIcon

    return <Icon width={32} height={32} {...this.props} />
  }
}
