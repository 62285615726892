import React, { Component } from 'react'
import css from './sub-bar.module.scss'
import { withMods } from 'utils/cssm'

const mod = withMods(css)

export default class Bar extends Component {
  render() {
    return (
      <section
        style={this.props.style}
        className={mod.root({
          smallScreen: this.props.smallScreen
        })}
      >
        {this.props.children}
      </section>
    )
  }
}

export class SubBarTitle extends Component {
  render() {
    return (
      <div style={this.props.style} className={css.title}>
        {this.props.children}
      </div>
    )
  }
}

export class SubBarControl extends Component {
  render() {
    return (
      <div style={this.props.style} className={css.control}>
        {Boolean(this.props.title) && (
          <div className={css.title}>{this.props.title}</div>
        )}
        {this.props.children}
      </div>
    )
  }
}
