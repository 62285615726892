import React from 'react'
import { LineView } from 'dumb/line'
import Tile, { TileContainer } from 'dumb/tile'
import { connect } from 'react-redux'
import { checkpoints, editLine, messageTemplates, lineStaffManagement } from 'app/nav'
import { getLine } from 'store/actions/entity-action-creators'
import BaseView from 'smart/base/base-entity-view'
import translate from 'i18n/translate'
import timeZoneFilter from 'smart/time-zone-filter'
import BasketIcon from '@material-ui/icons/ShoppingBasket'
import TemplateIcon from '@material-ui/icons/Message'
import StaffIcon from '@material-ui/icons/People'

class SmartLineView extends BaseView {
  identity = 'line'

  fetchEntity(p) {
    this.props.getEntity(p.lineId, p.shopId)
  }

  getBreadcrumbs(crumb, data, parents) {
    const shopId = parents.shop.id
    const shopName = parents.shop.name
    const lineName = data.name

    return [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shopId], shopName),
      crumb.lines([shopId]),
      crumb.line([], lineName)
    ]
  }

  renderMainForm() {
    return <LineView data={this.props.entity.data} />
  }

  renderAfterForm() {
    const count = this.props.entity.data.checkpointCount

    return (
      <TileContainer>
        {this.props.canViewCheckpoint && (
          <Tile
            title={this.props.translation.lineView.checkpoints(count)}
            iconClass={BasketIcon}
            count={count}
            onClick={this.handleCheckpointsClick}
          />
        )}
        <Tile
          title={this.props.translation.lineView.messageTemplates}
          iconClass={TemplateIcon}
          onClick={this.handleTemplatesClick}
        />
        <Tile
          title={this.props.translation.lineView.staffManagement}
          iconClass={StaffIcon}
          onClick={this.handleStaffManagementClick}
        />
      </TileContainer>
    )
  }

  checkEditPermissions = () => this.props.canEditLine
  checkViewPermissions = () => this.props.canViewLine

  handleEdit() {
    const { shopId, lineId } = this.props.match.params
    this.pushPath(editLine(shopId, lineId))
  }

  handleCheckpointsClick = () => {
    const { shopId, lineId } = this.props.match.params
    this.pushPath(checkpoints(shopId, lineId))
  }

  handleTemplatesClick = () => {
    const { shopId, lineId } = this.props.match.params
    this.pushPath(messageTemplates(shopId, lineId))
  }

  handleStaffManagementClick = () => {
    const { shopId, lineId } = this.props.match.params
    this.pushPath(lineStaffManagement(shopId, lineId))
  }
}

const stateToProps = (state) => ({
  entity: state.entity,
  canEditLine: state.user.permissions.canEditLine,
  canViewLine: state.user.permissions.canViewLine,
  canViewCheckpoint: state.user.permissions.canViewCheckpoint
})

const actionsToProps = (dispatch) => ({ getEntity: (lineId, shopId) => dispatch(getLine(lineId, shopId)), dispatch })

export default connect(stateToProps, actionsToProps)(timeZoneFilter(translate(SmartLineView)))
