import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import translate from 'i18n/translate'
import Toggle from 'dumb/toggle-wrap'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'
import { InputTranslation } from 'features/input-translation'

class CheckpointEdit extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'name',
        stateField: 'nameIsValid'
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}
        <EntityActions showButtons buttons={this.renderControls()} updateData={this.state.data.lastUpdate} />
      </Card>
    )
  }

  renderControls() {
    const { createButton, updateButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete) {
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button id="confirm" onClick={() => this.props.onDelete(this.state.data.id)}>
              {deleteButton}
            </Button>
            <Button id="cancel" onClick={() => this.handleCancelDelete()}>
              {cancelButton}
            </Button>
          </div>
        </div>
      )
    }

    if (!this.state.data.id) {
      return (
        <Button disabled={!this.stateIsValid()} id="create" onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    } else {
      return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()} id="update">
            {updateButton}
          </Button>
          {this.props.canDelete !== false && (
            <Button id="delete" onClick={() => this.handleTryDelete()}>
              {deleteButton}
            </Button>
          )}
        </div>
      )
    }
  }

  handleTryDelete() {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete() {
    this.setState({ confirmDelete: false })
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderMainForm(d) {
    const {
      name,
      namePlaceholder,
      description,
      descriptionPlaceholder,
      loadingOfFluids,
      loadingFluidsFlexitank,
      loadingOfConventionalCharges
    } = this.props.translation.checkpointEdit

    const { chars } = this.props.translation.entityEdit

    const fields = !d.loadingOfConventionalCharges ? (
      <div>
        <FormGroup>
          <Toggle
            toggled={d.loadingOfFluids}
            label={loadingOfFluids}
            onToggle={() => this.handlePropChange('loadingOfFluids', !d.loadingOfFluids)}
          />
        </FormGroup>
        <FormGroup>
          <Toggle
            toggled={d.loadingFluidsFlexitank}
            label={loadingFluidsFlexitank}
            onToggle={() => this.handlePropChange('loadingFluidsFlexitank', !d.loadingFluidsFlexitank)}
          />
        </FormGroup>
      </div>
    ) : (
      ''
    )

    return (
      <div>
        <Form>
          <FormGroup>
            <InputTranslation
              inputValue={d.name}
              setInputValue={(val) => this.handlePropChange('name', val)}
              brandId={d.brandId}
              shopId={this.props.shopId}
            >
              <TextField
                label={name}
                placeholder={namePlaceholder}
                autofocus={true}
                value={d.name}
                onChange={(val) => this.handlePropChange('name', val)}
                attention={!this.state.nameIsValid}
                message={`${d.name ? d.name.length : 0}/20 ${chars}`}
                test={(v) => v.length < 21}
              />
            </InputTranslation>
          </FormGroup>
          <FormGroup>
            <InputTranslation
              inputValue={d.description}
              setInputValue={(val) => this.handlePropChange('description', val)}
              brandId={d.brandId}
              shopId={this.props.shopId}
            >
              <TextField
                label={description}
                placeholder={descriptionPlaceholder}
                value={d.description}
                onChange={(val) => this.handlePropChange('description', val)}
                message={`${d.description ? d.description.length : 0}/100 ${chars}`}
                test={(v) => v.length < 101}
              />
            </InputTranslation>
          </FormGroup>
          <FormGroup>
            <Toggle
              toggled={d.loadingOfConventionalCharges}
              label={loadingOfConventionalCharges}
              onToggle={() => {
                this.handlePropChange('loadingOfFluids', false, () => {
                  this.handlePropChange('loadingFluidsFlexitank', false, () => {
                    this.handlePropChange('loadingOfConventionalCharges', !d.loadingOfConventionalCharges)
                  })
                })
              }}
            />
          </FormGroup>
          {fields}
        </Form>
      </div>
    )
  }
}

export default translate(CheckpointEdit)
