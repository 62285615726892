import { useState, useEffect } from 'react'
import { ShopEdit } from 'dumb/shop'
import * as Nav from 'app/nav'
import { useTranslation } from 'i18n/translate'
import Container from 'dumb/container'
import { shop as shopAPI, brand as brandAPI } from 'provider'
import { setBreadcrumbs, showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { invalidateIsolatedShopList } from 'store/actions/shop-action-creators'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory, useParams } from 'react-router'
import { CenterContainer } from 'dumb/center-container'
import { getTimeZonesSelector } from 'store/selectors/timeZoneSelectors'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppDispatch, useAppSelector } from 'store'
import { useQuery } from 'react-query'
import { useTimeZoneFilter } from 'smart/time-zone-filter'
import { makeUUID } from 'utils/guid'

function SmartShopEdit() {
  const [operation, setOperation] = useState(false)

  const { shopId } = useParams<{ shopId: string }>()
  const history = useHistory()

  useTimeZoneFilter(shopId)

  const dispatch = useAppDispatch()
  const timeZones = useAppSelector(getTimeZonesSelector)
  const { canDeleteShop } = useAppSelector(getPermissionsSelector)

  const { tr, locale } = useTranslation()

  const { data: shop, isFetching } = useQuery(['shop', shopId], ({ signal }) => shopAPI.getShop(shopId, { signal }), {
    enabled: !!shopId
  })
  const { data: brands } = useQuery(['brands'], ({ signal }) => brandAPI.getBrandList({ signal }))

  useEffect(setCrumbs, [shop, locale])

  function setCrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)

    const crumbs = !shopId
      ? [crumb.home(), crumb.shops(), crumb.newShop()]
      : [
          crumb.home(),
          crumb.shops(),
          crumb.shop([shopId], shop?.displayName || shop?.name || tr.breadcrumbs.shop),
          crumb.editShop([shopId])
        ]

    dispatch(setBreadcrumbs(crumbs))
  }

  async function handleDelete(id: string | number) {
    setOperation(true)

    shopAPI
      .deleteShop(id)
      .then(() => {
        dispatch(invalidateIsolatedShopList())
        dispatch(showMessage(tr.shopOperationMessages.deleteSuccess, MessageTypes.Success))
        history.push(Nav.shops())
      })
      .catch(() => {
        dispatch(showMessage(tr.shopOperationMessages.deleteError, MessageTypes.Error))
        setOperation(false)
      })
  }

  async function handleSave(data) {
    setOperation(true)
    const msg = tr.shopOperationMessages

    if (shopId) {
      shopAPI
        .updateShop(data)
        .then(() => {
          dispatch(invalidateIsolatedShopList())
          dispatch(showMessage(msg.updateSuccess, MessageTypes.Success))
          history.push(Nav.shop(shopId))
        })
        .catch(() => {
          dispatch(showMessage(msg.updateError, MessageTypes.Error))
          setOperation(false)
        })
    } else {
      shopAPI
        .createShop(data)
        .then(() => {
          dispatch(invalidateIsolatedShopList())
          dispatch(showMessage(msg.createSuccess, MessageTypes.Success))
          history.push(Nav.shops())
        })
        .catch(() => {
          dispatch(showMessage(msg.createError, MessageTypes.Error))
          setOperation(false)
        })
    }
  }

  if (isFetching || operation) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!shop && !!shopId) {
    return null
  }

  return (
    <Container>
      <ShopEdit
        data={shop ? shop.data : { monitoringId: makeUUID() }}
        brands={brands?.data || []}
        timeZones={timeZones || []}
        canDelete={canDeleteShop}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </Container>
  )
}

export default SmartShopEdit
