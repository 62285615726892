import { checkpoint, staffManagement } from 'provider'
import {
  getEntityListFailure,
  getEntityListRequest,
  resetEntityList,
  getEntityListSuccess
} from '../reducers/entity-list-reducer'

export function createEntityListGetter(reqSource?: any, identity?: string) {
  return function getEntity(...args) {
    return (dispatch) => {
      dispatch(getEntityListRequest())
      reqSource
        .apply(null, args)
        .then((data) => {
          data.identity = identity
          dispatch(getEntityListSuccess(data))
        })
        .catch((error) => dispatch(getEntityListFailure(error)))
    }
  }
}

export { resetEntityList }

export const getCheckpointList = createEntityListGetter(checkpoint.getCheckpointList, 'checkpointList')
export const getStaffManagement = createEntityListGetter(staffManagement.getStaffManagement, 'staffManagement')
