import { createElement, useContext } from 'react'
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'
import { ITranslationContextProps, TranslateContext } from './translation-context'

function pickerLocale() {
  return (WrappedComponent) => {
    return function PickerLocaleComponent(props) {
      const { locale } = useContext(TranslateContext) as ITranslationContextProps

      const localeMap = {
        en: enLocale,
        'en-GB': enLocale,
        fr: frLocale,
        'fr-FR': frLocale,
        default: enLocale
      }

      return createElement(WrappedComponent, {
        ...props,
        pickerLocale: localeMap[locale] || localeMap.default
      })
    }
  }
}

export default pickerLocale
