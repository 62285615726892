import axios from 'axios'

export default {
  getLineList(shopId) {
    return axios.get('getLineList', { params: { shopId } })
  },
  getLine(id = '', shopId) {
    return axios.get('getLine', { params: { id, shopId } }).then((resp) => {
      if (!resp.data.modes) {
        resp.data.modes = ['mobileApp']
      }

      return resp
    })
  },
  createLine(data) {
    return axios.post('createLine', data)
  },
  updateLine(data) {
    return axios.put('updateLine', data)
  },
  deleteLine(id) {
    return axios.delete('deleteLine', { data: id, headers: { 'Content-Type': 'application/json' } })
  }
}
