import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import Divider from '@material-ui/core/Divider'
import Dropdown from 'dumb/dropdown'
import translate from 'i18n/translate'
import AddList from 'dumb/add-list'
import Toggle from 'dumb/toggle-wrap'
import TextFieldMessage from 'dumb/text-field/text-field-message'
import { isValidPassword } from 'utils/string-test'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'
import { isEmail } from 'utils/string-test'

class UserEdit extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'firstName',
        stateField: 'firstNameIsValid'
      },
      {
        dataField: 'secondName',
        stateField: 'secondNameIsValid'
      },
      {
        dataField: 'email',
        stateField: 'emailIsValid',
        validator: (val) => isEmail(val)
      },
      {
        dataField: 'shopIds',
        stateField: 'shopIdsIsValid',
        validator: (ids, state) => {
          return Boolean(ids.length || state.data.isAdmin)
        }
      },
      {
        dataField: 'isAdmin',
        calcState: (value, state) => {
          return {
            shopIdsIsValid: Boolean(state.data.shopIds.length || value)
          }
        }
      },
      {
        dataField: 'igg',
        stateField: 'iggIsValid'
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}
        <EntityActions showButtons={true} buttons={this.renderControls()} />
      </Card>
    )
  }

  renderControls() {
    const { createButton } = this.props.translation.entityEdit

    return (
      <Button disabled={!this.stateIsValid()} id="create" onClick={() => this.handleSave()}>
        {createButton}
      </Button>
    )
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderMainForm(d) {
    const {
      firstName,
      firstNamePlaceholder,
      secondName,
      secondNamePlaceholder,
      email,
      emailPlaceholder,
      confirmPassword,
      password,
      passwordPlaceholder,
      interfaceLanguage,
      shops,
      rights,
      isAdmin,
      isActive,
      canManageUserAccounts,
      canAddAndDeleteShops,
      canUpdateShops,
      ruleSecurePasswordInputMessage,
      allCountries,
      countries,
      manageAppointments,
      limitedView,
      igg,
      manageTransportCompanies
    } = this.props.translation.userEdit

    const { chars } = this.props.translation.entityEdit

    return (
      <div>
        <Form>
          <form autoComplete="off">
            <FormGroup>
              <TextField
                label={firstName}
                placeholder={firstNamePlaceholder}
                autofocus={true}
                value={d.firstName}
                onChange={(val) => this.handlePropChange('firstName', val)}
                attention={!this.state.firstNameIsValid}
                message={`${d.firstName ? d.firstName.length : 0}/100 ${chars}`}
                test={(v) => v.length < 101}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label={secondName}
                placeholder={secondNamePlaceholder}
                value={d.secondName}
                onChange={(val) => this.handlePropChange('secondName', val)}
                attention={!this.state.secondNameIsValid}
                message={`${d.secondName ? d.secondName.length : 0}/100 ${chars}`}
                test={(v) => v.length < 101}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label={igg}
                placeholder={igg}
                value={d.igg}
                attention={!this.state.iggIsValid}
                disabled={this.props.myprofile}
                onChange={(val) => this.handlePropChange('igg', val)}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label={email}
                placeholder={emailPlaceholder}
                value={d.email}
                onChange={(val) => this.handlePropChange('email', val)}
                attention={!this.state.emailIsValid}
                message={`${d.email ? d.email.length : 0}/200 ${chars}`}
                test={(v) => v.length < 201}
              />
            </FormGroup>
            <FormGroup>
              <Dropdown
                value={d.interfaceLocale}
                data={this.props.languages}
                label={interfaceLanguage}
                dataConverter={(item) => ({ value: item.locale, text: item.name })}
                onChange={(val) => this.handlePropChange('interfaceLocale', val)}
              />
            </FormGroup>
          </form>
        </Form>
        <Divider />
        <Form>
          <form autoComplete="off">
            <FormGroup>
              <TextField
                type="password"
                label={password}
                placeholder={passwordPlaceholder}
                value={d.password}
                onChange={(val) => this.handlePropChange('password', val)}
                message={`${d.password ? d.password.length : 0}/16 ${chars}`}
                test={(v) => v.length < 17}
              >
                <TextFieldMessage type="error" check={isValidPassword}>
                  {ruleSecurePasswordInputMessage}
                </TextFieldMessage>
              </TextField>
            </FormGroup>
            <FormGroup>
              <TextField
                type="password"
                label={confirmPassword}
                placeholder={passwordPlaceholder}
                value={d.confirmPassword}
                onChange={(val) => this.handlePropChange('confirmPassword', val)}
                message={`${d.confirmPassword ? d.confirmPassword.length : 0}/100 ${chars}`}
                test={(v) => v.length < 101}
              />
            </FormGroup>
          </form>
        </Form>
        <Divider />
        {this.props.canEditRights && (
          <React.Fragment>
            <Form title={rights} expandable>
              {this.props.canGiveManageUserAccounts && (
                <FormGroup>
                  <Toggle
                    toggled={d.isActive}
                    label={isActive}
                    onToggle={() => this.handlePropChange('isActive', !d.isActive)}
                  />
                </FormGroup>
              )}
              {this.props.canPromoteToAdmin && (
                <FormGroup>
                  <Toggle
                    toggled={d.isAdmin}
                    label={isAdmin}
                    onToggle={async () => {
                      await this.handlePropChangeAsync('isAdmin', !d.isAdmin)
                      await this.handlePropChangeAsync('limitedView', false)
                    }}
                  />
                </FormGroup>
              )}
              {!d.isAdmin && (
                <div>
                  <FormGroup>
                    <Toggle
                      toggled={d.limitedView}
                      label={limitedView}
                      onToggle={async () => {
                        await this.handlePropChangeAsync('limitedView', !d.limitedView)
                        await this.handlePropChangeAsync('canManageUserAccounts', false)
                        await this.handlePropChangeAsync('canAddAndDeleteShops', false)
                        await this.handlePropChangeAsync('canUpdateShops', false)
                        await this.handlePropChangeAsync('canManageAppointments', false)
                        await this.handlePropChangeAsync('canManageTransportCompanies', false)
                      }}
                    />
                  </FormGroup>
                  {this.props.canGiveManageUserAccounts && !d.limitedView && (
                    <FormGroup>
                      <Toggle
                        toggled={d.canManageUserAccounts}
                        label={canManageUserAccounts}
                        onToggle={() => this.handlePropChange('canManageUserAccounts', !d.canManageUserAccounts)}
                      />
                    </FormGroup>
                  )}
                  {this.props.canGiveAddAndDeleteShops && !d.limitedView && (
                    <FormGroup>
                      <Toggle
                        toggled={d.canAddAndDeleteShops}
                        label={canAddAndDeleteShops}
                        onToggle={() => this.handlePropChange('canAddAndDeleteShops', !d.canAddAndDeleteShops)}
                      />
                    </FormGroup>
                  )}
                  {this.props.canGiveUpdateShops && !d.limitedView && (
                    <FormGroup>
                      <Toggle
                        toggled={d.canUpdateShops}
                        label={canUpdateShops}
                        onToggle={() => this.handlePropChange('canUpdateShops', !d.canUpdateShops)}
                      />
                    </FormGroup>
                  )}
                  {this.props.canGiveManageAppointments && !d.limitedView && (
                    <FormGroup>
                      <Toggle
                        toggled={d.canManageAppointments}
                        label={manageAppointments}
                        onToggle={() => this.handlePropChange('canManageAppointments', !d.canManageAppointments)}
                      />
                    </FormGroup>
                  )}

                  {this.props.canGiveManageTransportCompanies && !d.limitedView && (
                    <FormGroup>
                      <Toggle
                        toggled={d.canManageTransportCompanies}
                        label={manageTransportCompanies}
                        onToggle={() =>
                          this.handlePropChange('canManageTransportCompanies', !d.canManageTransportCompanies)
                        }
                      />
                    </FormGroup>
                  )}
                </div>
              )}
            </Form>
            <Divider />
          </React.Fragment>
        )}
        {!d.isAdmin && (
          <React.Fragment>
            <Form title={shops} expandable attention={!this.state.shopIdsIsValid}>
              <FormGroup>
                <Toggle
                  toggled={d.allCountries}
                  label={allCountries}
                  onToggle={() => this.handlePropChange('allCountries', !d.allCountries)}
                />
              </FormGroup>
              {!d.allCountries && (
                <FormGroup>
                  <TextField
                    label={countries}
                    placeholder={countries}
                    value={d.countriesAsString}
                    onChange={(val) => this.handlePropChange('countriesAsString', val)}
                  />
                </FormGroup>
              )}
              <AddList
                selectedIds={d.shopIds || []}
                source={this.props.shops}
                addButtonText={this.props.translation.userEdit.addShopButton}
                onChange={(val) => this.handlePropChange('shopIds', val)}
              />
            </Form>
            <Divider />
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default translate(UserEdit)
