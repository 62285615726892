import { user } from 'provider'
import { Dispatch } from '@reduxjs/toolkit'
import { TimeZoneModel } from 'shared/models'
import { setUserProfile, clearProfile, setTimeZone } from '../reducers/user-reducer'

export function getAndStoreUserProfile() {
  return async (dispatch: Dispatch) => {
    const res = await user.storeProfile()
    dispatch(setUserProfile(res))
  }
}

export function storeUserProfile(data: any) {
  return (dispatch: Dispatch) => {
    dispatch(setUserProfile(data))
  }
}

export function changeTimeZone(value: TimeZoneModel) {
  return (dispatch: Dispatch) => {
    dispatch(setTimeZone(value))
  }
}

export function resetUser() {
  return (dispatch: Dispatch) => {
    dispatch(clearProfile())
  }
}
