import React from 'react'
import { LineEdit } from 'dumb/line'
import { connect } from 'react-redux'
import { lines, line } from 'app/nav'
import { getLine, updateLine, createLine, deleteLine } from 'store/actions/entity-action-creators'
import BaseEdit from 'smart/base/base-entity-edit'
import translate from 'i18n/translate'
import timeZoneFilter from 'smart/time-zone-filter'

class SmartLineEdit extends BaseEdit {
  identity = 'line'

  behavior = {
    create: {
      redirect: (p) => lines(p.shopId)
    },
    delete: {
      redirect: (p) => lines(p.shopId)
    },
    update: {
      redirect: (p) => line(p.shopId, p.lineId)
    }
  }

  operationMessages = () => this.props.translation.lineOperationMessages
  checkViewPermissions = () => this.props.canEditLine

  fetchEntity(p) {
    this.props.getEntity(p.lineId, p.shopId)
  }

  getBreadcrumbs(crumb, data, parents) {
    const shopId = parents.shop.id
    const shopName = parents.shop.name
    const lineId = data.id
    const lineName = data.name

    return [crumb.home(), crumb.shops(), crumb.shop([shopId], shopName), crumb.lines([shopId])].concat(
      lineId ? [crumb.line([shopId, lineId], lineName), crumb.editLine()] : [crumb.newLine()]
    )
  }

  isNew() {
    return !this.props.match.params.lineId
  }

  renderMainForm() {
    return (
      <LineEdit
        canDelete={this.props.canDeleteLine}
        data={{ ...(this.props.entity.data || {}) }}
        onSave={(data) => this.handleSave(data)}
        onDelete={(data) => this.handleDelete(data)}
        shopId={this.props.match.params.shopId}
      />
    )
  }

  handleSave(data) {
    if (this.isNew()) {
      this.props.createEntity(this.processCreateData(data))
    } else {
      this.props.updateEntity(data)
    }
  }

  processCreateData(data) {
    data.shopId = this.props.match.params.shopId
    return data
  }

  handleDelete(data) {
    this.props.deleteEntity(data)
  }
}

const stateToProps = (state) => ({
  entity: state.entity,
  canDeleteLine: state.user.permissions.canDeleteLine,
  canEditLine: state.user.permissions.canEditLine
})

const actionsToProps = (dispatch) => ({
  getEntity: (lineId, shopId) => dispatch(getLine(lineId, shopId)),
  updateEntity: (data) => dispatch(updateLine(data)),
  createEntity: (data) => dispatch(createLine(data)),
  deleteEntity: (data) => dispatch(deleteLine(data)),
  dispatch
})

export default connect(stateToProps, actionsToProps)(timeZoneFilter(translate(SmartLineEdit)))
