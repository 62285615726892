import React from 'react'
import { totalAppointments } from 'provider'
import translate from 'i18n/translate'
import { produce } from 'immer'
import { connect } from 'react-redux'
import { showMessage } from 'store/actions/main-layout-action-creators'
import AppointmentEditForm from './appointment-edit-form'
import PageLoader from '../../dumb/page-loader/page-loader'

class AppointmentEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      config: null,
      isRequesting: false,
      isRequestingSilent: false,
      useFlexiTank: this.props.data.useFlexiTank || false
    }
  }

  editState = (fn, cb) => this.setState(produce(fn), cb)

  startRequest = (cb) => {
    this.editState((draft) => {
      draft.isRequesting = true
    }, cb)
  }

  endRequest = (cb) => {
    this.editState((draft) => {
      draft.isRequesting = false
    }, cb)
  }

  componentDidMount() {
    this.fetchConfig(true)
  }

  fetchConfig(withLoading = false) {
    const { serverError } = this.props.translation.requestMessages

    this.editState(
      (draft) => {
        draft.isRequesting = withLoading
        draft.isRequestingSilent = !withLoading
      },
      () => {
        totalAppointments
          .getAppointmentConfiguration({
            useFlexiTank: this.state.useFlexiTank,
            country: this.props.country,
            loadingAuthorizationNumber: this.props.loadingAuthorizationNumber,
            token: this.props.data.id || '' //TODO
          })
          .then((resp) => {
            this.editState((draft) => {
              draft.config = resp
              draft.isRequesting = false
              draft.isRequestingSilent = false
            })
          })
          .catch(() => {
            this.props.dispatch(showMessage(serverError, 'error'))
            this.editState((draft) => {
              draft.isRequesting = false
              draft.isRequestingSilent = false
            })
          })
      }
    )
  }

  render() {
    if (this.state.isRequesting) {
      return <PageLoader />
    }
    if (!this.state.config) {
      return null
    }

    if (!this.state.config.dates || this.state.config.dates.length === 0) {
      const { noDateAvailable } = this.props.translation.totalAppointmentEdit
      return <div style={{ margin: '16px' }}>{noDateAvailable}</div>
    }

    return (
      <AppointmentEditForm
        data={this.props.data}
        config={this.state.config}
        onSave={this.handleSave}
        canDelete={false}
        canEdit={this.props.canEditAppointments}
        useFlexiTank={this.state.useFlexiTank}
        isRequestingSilent={this.state.isRequestingSilent}
        flexiTankChange={(val) => {
          this.setState({ useFlexiTank: val }, this.fetchConfig)
        }}
      />
    )
  }

  handleSave = (data) => {
    this.startRequest(() => {
      const { updateError, updateSuccess } = this.props.translation.appointmentOperationMessages
      const { createError, createSuccess } = this.props.translation.appointmentOperationMessages

      if (data.id) {
        totalAppointments
          .updateTotalAppointment(data)
          .then(() => {
            this.props.dispatch(showMessage(updateSuccess, 'success'))
            this.props.goBack && this.props.goBack()
          })
          .catch((error) => {
            console.log('Ошибка из Catch')
            console.dir(error)
            this.handleRequestFailedPromise(error, updateError)
          })
          .finally(() => {
            this.endRequest()
          })
      } else {
        totalAppointments
          .createTotalAppointment(data)
          .then(() => {
            this.props.dispatch(showMessage(createSuccess, 'success'))
            this.props.goBack && this.props.goBack()
          })
          .catch((error) => {
            console.log('Ошибка из Catch')
            console.dir(error)
            this.handleRequestFailedPromise(error, createError)
          })
          .finally(() => {
            this.endRequest()
          })
      }
    })
  }

  handleInvalidStateError = (params = [], errorMessage) => {
    const { timeRangeNoAvailable } = this.props.translation.appointmentOperationMessages

    if (params.includes('TimeSlotIsNotFree')) {
      this.editState(() => {
        this.fetchConfig()
        this.props.dispatch(showMessage(timeRangeNoAvailable, 'error'))
      })

      return
    }

    this.props.dispatch(showMessage(errorMessage, 'error'))
  }

  handleRequestFailedPromise(error, errorMessage) {
    if (error.response.then) {
      error.response.then((errorData) => {
        const newError = { ...error, response: errorData }
        this.handleRequestError(newError, errorMessage)
      })
    } else {
      this.handleRequestError(error, errorMessage)
    }
  }

  handleRequestError = (error, errorMessage) => {
    if (error.status === 409) {
      const errorJson = error.response.data
      switch (errorJson.type) {
        case 'InvalidState': {
          this.handleInvalidStateError(errorJson.parameters, errorMessage)
          break
        }
        default: {
          this.props.dispatch(showMessage(errorMessage, 'error'))
        }
      }
    }
  }

  getShopId = () => this.props.match.params.shopId
}

const stateToProps = (state) => ({ canEditAppointments: state.user.permissions.canEditAppointments })

export default connect(stateToProps)(translate(AppointmentEdit))
