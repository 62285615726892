import styled from 'styled-components'

const BarContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  flex: 1;
  max-width: 600px;
`

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex: 1;
`

export { BarContent, BarContainer }
