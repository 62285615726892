import { Provider } from 'react-redux'
import themeSettings from './theme'
import TranslationProvider from 'i18n/translation-provider'
import Routing from '../routing'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { Snack } from 'smart/snack'
import './styles/index.scss'
import { QueryClient, QueryClientProvider } from 'react-query'
import { store } from 'store'
import config from 'devextreme/core/config'
import { licenseKey } from 'app/devextreme-license'

config({ licenseKey })

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, refetchOnReconnect: false } }
})

const theme = createTheme(themeSettings)

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <TranslationProvider>
            <Routing />
            <Snack />
          </TranslationProvider>
        </MuiThemeProvider>
      </Provider>
    </QueryClientProvider>
  )
}

export { App }
