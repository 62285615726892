import axios from 'axios'

export default {
  getMessageTemplatesConfig() {
    return axios.get('getMessageTemplatesConfig')
  },
  getMessageTemplate(data) {
    return axios.get('getMessageTemplate', { params: data })
  },
  updateMessageTemplate(data) {
    return axios.post('updateMessageTemplate', data)
  }
}
