import axios from 'axios'

export default {
  getStaffManagement(data) {
    return axios.get('getStaffManagement', { params: data })
  },
  getStaffManagementRecord(data) {
    return axios.get('getStaffManagementRecord', { params: data })
  },
  uploadStaffManagement(data) {
    return axios.post('uploadStaffManagement', data)
  },
  createStaffManagementRecord(data) {
    return axios.post('createStaffManagementRecord', data)
  },
  updateStaffManagementRecord(data) {
    return axios.put('updateStaffManagementRecord', data)
  },
  deleteStaffManagementRecord(id) {
    return axios.delete('deleteStaffManagementRecord', { data: id, headers: { 'Content-Type': 'application/json' } })
  }
}
