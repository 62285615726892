import config from 'app/config'
import * as fakeProvider from './fake-provider'
import * as apiProvider from './api-provider'

let provider

if (config.enableFakes) {
  provider = fakeProvider
} else {
  provider = apiProvider
}

import * as CheckpointStatus from './checkpoint-status'

export { CheckpointStatus }

export const user = provider.user
export const shop = provider.shop
export const line = provider.line
export const checkpoint = provider.checkpoint
export const file = provider.file
export const brand = provider.brand
export const messageTemplates = provider.messageTemplates
export const staffManagement = provider.staffManagement
export const appointments = provider.appointments
export const translations = provider.translations
export const total = provider.total
export const totalScheduler = provider.totalScheduler
export const totalAppointments = provider.totalAppointments
