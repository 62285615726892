import React, { Component } from 'react'
import { Form, FormGroup, FormActionGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import { RadioButtonGroup, RadioButton } from 'dumb/radio-button'
import translate from 'i18n/translate'
import { produce } from 'immer'
import { isPositiveNumber } from 'utils/string-test'
import Button from '@material-ui/core/Button'
import Autocomplete from 'dumb/autocomplete'

class LoadingAuthorizationSearcher extends Component {
  state = {
    isValid: false,
    useFlexiTankIndex: '2',
    data: {
      country: this.props.countries[0],
      loadingAuthorizationNumber: '',
      useFlexiTank: false
    }
  }

  editState = (fn, cb) => this.setState(produce(fn), cb)

  componentDidMount() {}

  isValid = () => Boolean(this.state.data.loadingAuthorizationNumber)

  changeCountryHandler = (value) => {
    this.editState((draft) => {
      draft.data.country = value
    })
  }

  changeloadingAuthorizationNumberHandler = (value) => {
    this.editState((draft) => {
      draft.data.loadingAuthorizationNumber = value
    })
  }

  useFlexiTankChangeHandler = (value) => {
    this.editState((draft) => {
      draft.useFlexiTankIndex = value
      draft.data.useFlexiTank = value === '1'
    })
  }

  render() {
    const { research, search, yourAuthorizationNumber, customerCountryDelivered, loadingWithFlexitank } =
      this.props.translation.authorizationResearch

    const { yes, no } = this.props.translation.common

    const countriesCodes = this.props.translation.countriesCodes

    const sortedCountyCodesDataByTranslations = [...this.props.countries]?.sort?.()

    return (
      <Form title={research}>
        <FormGroup>
          <TextField
            value={this.state.data.loadingAuthorizationNumber}
            onChange={(e) => this.changeloadingAuthorizationNumberHandler(e)}
            label={yourAuthorizationNumber}
            placeholder={yourAuthorizationNumber}
            message={`${this.state.data.loadingAuthorizationNumber.length}/20 ${this.props.translation.entityEdit.chars}`}
            test={(v) => v.length < 21 && isPositiveNumber(v)}
          />
        </FormGroup>
        <FormGroup>
          <Autocomplete
            title={customerCountryDelivered}
            list={sortedCountyCodesDataByTranslations}
            value={this.state.data.countryCode}
            onChange={(e) => this.changeCountryHandler(e)}
            dataConverter={(item) => {
              return {
                value: item,
                text: `${item} — ${countriesCodes[item.toLowerCase()] || ''}`
              }
            }}
          />
        </FormGroup>

        <FormGroup>
          <RadioButtonGroup
            style={{ flexWrap: 'wrap' }}
            label={loadingWithFlexitank}
            name={'useFlexiTankIndex'}
            onChange={(val) => this.useFlexiTankChangeHandler(val)}
            value={this.state.useFlexiTankIndex}
            ensureValue="2"
            row
          >
            <RadioButton
              value="1"
              label={yes}
              style={{ width: 'auto', marginRight: 24 }}
              iconStyle={{ marginRight: 12 }}
            />
            <RadioButton
              value="2"
              label={no}
              style={{ width: 'auto', marginRight: 24 }}
              iconStyle={{ marginRight: 12 }}
            />
          </RadioButtonGroup>
        </FormGroup>

        <FormActionGroup>
          <Button disabled={!this.isValid()} onClick={() => this.props.onSearch(this.state.data)}>
            {search}
          </Button>
        </FormActionGroup>
      </Form>
    )
  }
}

export default translate(LoadingAuthorizationSearcher)
