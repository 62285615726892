import { useState } from 'react'
import { Form, FormGroup, FormActionGroup } from 'dumb/form'
import { useTranslation } from 'i18n/translate'
import Dropdown from 'dumb/dropdown'
import { DatePicker } from 'dumb/date'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { ExportDataFormProps } from './types'

function ExportDataForm(props: ExportDataFormProps) {
  const { tr } = useTranslation()

  const [line, setLine] = useState(props.lines.length === 1 ? props.lines[0].id : null)
  const [shopId, setShopId] = useState<number | null>(null)
  const [startDate, setStartDate] = useState<number | null>(+new Date())
  const [endDate, setEndDate] = useState<number | null>(+new Date())

  function handleExport() {
    props.onExport({
      line,
      shopId,
      startDate,
      endDate,
      template: props.templateId
    })
  }

  return (
    <Form>
      <FormGroup>
        <Dropdown
          displayEmpty
          emptyLable={tr.exportData.allShops}
          label={tr.exportData.shop}
          value={shopId}
          data={props.shops}
          dataConverter={(item) => ({ value: item.id, text: item.name })}
          onChange={(val) => {
            setShopId(val)
            props.onRequestLines(val)
          }}
        />
      </FormGroup>
      <FormGroup>
        <Dropdown
          displayEmpty
          emptyLable={tr.exportData.allLines}
          label={tr.exportData.line}
          value={line}
          data={props.lines}
          dataConverter={(item) => ({ value: item.id, text: item.name })}
          onChange={(val) => setLine(val)}
        />
      </FormGroup>
      <FormGroup>
        <DatePicker label={tr.exportData.startDate} onChange={(val) => setStartDate(val)} value={startDate} />
      </FormGroup>
      <FormGroup>
        <DatePicker label={tr.exportData.endDate} onChange={(val) => setEndDate(val)} value={endDate} />
      </FormGroup>
      <FormActionGroup>
        <Button startIcon={<DownloadIcon />} onClick={handleExport}>
          {tr.exportData.exportButton}
        </Button>
      </FormActionGroup>
    </Form>
  )
}

export default ExportDataForm
