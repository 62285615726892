let _url: string | null = null

export function storeUrl(url: string) {
  _url = url
}

export function extractStoredUrl() {
  const url = _url
  _url = null
  return url
}

export function clearStoredUrl() {
  _url = null
}
