import { getTimeZonesSelector } from 'store/selectors/timeZoneSelectors'
import { getShopsSelector } from 'store/selectors/shopsSelectors'
import { useAppSelector } from 'store'

function useDataProvider() {
  const timeZones = useAppSelector(getTimeZonesSelector) || []
  const shops = useAppSelector(getShopsSelector) || []

  function getShop(shopId: string | number) {
    return shops?.find((x) => String(x.id) === String(shopId))
  }

  return { shops, timeZones, getShop }
}

export { useDataProvider }
