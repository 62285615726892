import React, { Component } from 'react'
import Dropdown from 'dumb/dropdown'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import translate from 'i18n/translate'

class CheckpointFilter extends Component {
  handleCheckpointChange = (id) => {
    this.props.onChange({ checkpointId: id })
  }

  render() {
    const { checkpoint, all } = this.props.translation.common
    const checkpointsItems = this.props.checkpoints

    const dropdownCheckpoints = checkpointsItems.length > 1 ? [{ id: null, name: all }, checkpointsItems] : []

    return (
      <SubBar smallScreen={this.props.smallScreen}>
        {checkpointsItems.length > 1 && (
          <SubBarControl title={checkpoint}>
            <Dropdown
              style={{ marginRight: 24 }}
              value={this.props.checkpointId}
              data={dropdownCheckpoints}
              dataConverter={(item) => ({ value: item.id, text: item.name })}
              onChange={this.handleCheckpointChange}
            />
          </SubBarControl>
        )}
      </SubBar>
    )
  }
}

export default translate(CheckpointFilter)
