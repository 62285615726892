import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import Divider from '@material-ui/core/Divider'
import { TextField } from 'dumb/text-field'
import Toggle from 'dumb/toggle-wrap'
import translate from 'i18n/translate'
import { isPositiveNumber, isPositiveInteger } from 'utils/string-test'
import TimePicker from 'dumb/time'
import { DatePicker } from 'dumb/date'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import { InputTranslation } from 'features/input-translation'

class LineEdit extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'name',
        stateField: 'nameIsValid'
      },
      {
        dataField: 'shortName',
        stateField: 'shortNameIsValid'
      },
      {
        dataField: 'serviceTime',
        stateField: 'serviceTimeIsValid',
        validator: (val) => val > 9
      },
      {
        dataField: 'timeToReach',
        stateField: 'timeToReachIsValid',
        calcState: (val, state) => {
          const max = state.data.maxTimeToReach

          return {
            timeToReachIsValid: Boolean(val),
            maxTimeToReachIsValid: max > 9 && +max > +val
          }
        }
      },
      {
        dataField: 'maxTimeToReach',
        stateField: 'maxTimeToReachIsValid',
        validator: (val, state) => {
          return val > 9 && +val > +state.data.timeToReach
        }
      },
      {
        dataField: 'openingHour',
        stateField: 'openingHourIsValid',
        ignore: (state) =>
          state.data.nonStopService || (!state.data.allowFutureAppointments && !state.data.manageAppointments),
        calcState: (value) => {
          return {
            openingHourIsValid: Boolean(value)
          }
        }
      },
      {
        dataField: 'closingHour',
        stateField: 'closingHourIsValid',
        ignore: (state) =>
          state.data.nonStopService || (!state.data.allowFutureAppointments && !state.data.manageAppointments),
        validator: (val, state) => {
          const openingHour = state.data.openingHour

          if (!openingHour) {
            return true
          }

          return true
        }
      },
      {
        dataField: 'maxSimultaneous',
        stateField: 'maxSimultaneousIsValid',
        ignore: (state) => !state.data.requestParticipantsNumber
      },
      {
        dataField: 'services',
        stateField: 'servicesIsValid',
        validator: (val) => val.every((service) => service.name.length > 0 && service.duration > 9)
      },
      {
        dataField: 'beforeTheCallTime',
        stateField: 'beforeTheCallTimeIsValid',
        ignore: (state) => !state.data.beforeTheCall
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}
        <EntityActions showButtons={true} buttons={this.renderControls()} updateData={this.state.data.lastUpdate} />
      </Card>
    )
  }

  renderControls() {
    const { createButton, updateButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button id="confirm" onClick={() => this.props.onDelete(this.state.data.id)}>
              {deleteButton}
            </Button>
            <Button id="cancel" onClick={() => this.handleCancelDelete()}>
              {cancelButton}
            </Button>
          </div>
        </div>
      )

    if (!this.state.data.id)
      return (
        <Button disabled={!this.stateIsValid()} id="create" onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    else
      return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()} id="update">
            {updateButton}
          </Button>
          {this.props.canDelete !== false && (
            <Button id="delete" onClick={() => this.handleTryDelete()}>
              {deleteButton}
            </Button>
          )}
        </div>
      )
  }

  handleTryDelete() {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete() {
    this.setState({ confirmDelete: false })
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderMainForm(d) {
    const {
      name,
      namePlaceholder,
      shortName,
      shortNamePlaceholder,
      serviceTime,
      timeToReach,
      maxTimeToReach,
      secondsPlaceholder,
      secondsHint,
      manageAppointments,
      openingHour,
      closingHour,
      maxSimultaneous,
      maxSimultaneousHint,
      maxSimultaneousPlaceholder,
      modes,
      allowFutureAppointments,
      servicesCollection,
      serviceName,
      serviceDuration,
      addService,
      attachIcsFile,
      authoriseAppointmentOnWebPortal,
      blindCopyEmails,
      blindCopyEmailsPlaceholder,
      nearServicePointDistance,
      nearServicePointDistanceHint,
      nearServicePointDistancePlaceholder,
      clientsNotificationsGroup,
      beforeTheCall,
      beforeTheCallTime,
      whenCalled,
      whenServiceStartedByAgent,
      whenServiceEndedByAgent,
      nonStopService,
      parameters,
      appointmentForNextDay,
      openingHours,
      durations
    } = this.props.translation.lineEdit

    const { chars } = this.props.translation.entityEdit

    const { mobileApp, sms } = this.props.translation.lineModes

    return (
      <div>
        <Form title={parameters} expandable expanded>
          <FormGroup>
            <InputTranslation
              inputValue={d.name}
              setInputValue={(val) => this.handlePropChange('name', val)}
              brandId={d.brandId}
              shopId={this.props.shopId}
            >
              <TextField
                label={name}
                placeholder={namePlaceholder}
                autofocus={true}
                value={d.name}
                onChange={(val) => this.handlePropChange('name', val)}
                attention={!this.state.nameIsValid}
                message={`${d.name ? d.name.length : 0}/100 ${chars}`}
                test={(v) => v.length < 101}
              />
            </InputTranslation>
          </FormGroup>
          <FormGroup>
            <TextField
              label={shortName}
              placeholder={shortNamePlaceholder}
              value={d.shortName}
              onChange={(val) => this.handlePropChange('shortName', val)}
              attention={!this.state.shortNameIsValid}
              message={`${d.shortName ? d.shortName.length : 0}/20 ${chars}`}
              test={(v) => v.length < 21}
            />
          </FormGroup>
          <FormGroup>
            <Toggle
              toggled={d.manageAppointments}
              label={manageAppointments}
              onToggle={() => this.handlePropChange('manageAppointments', !d.manageAppointments)}
            />
          </FormGroup>
          {Boolean(d.requestParticipantsNumber) && (
            <FormGroup>
              <TextField
                label={maxSimultaneous}
                placeholder={maxSimultaneousPlaceholder}
                value={d.maxSimultaneous}
                onChange={(val) => this.handlePropChange('maxSimultaneous', val)}
                test={isPositiveInteger}
                hint={maxSimultaneousHint}
                attention={!this.state.maxSimultaneousIsValid}
              />
            </FormGroup>
          )}
          {d.calculateApproachByGPS && (
            <FormGroup>
              <TextField
                label={nearServicePointDistance}
                placeholder={nearServicePointDistancePlaceholder}
                value={d.nearServicePointDistance}
                onChange={(val) => this.handlePropChange('nearServicePointDistance', val)}
                test={isPositiveNumber}
                hint={nearServicePointDistanceHint}
              />
            </FormGroup>
          )}
        </Form>

        <Divider />

        <Form title={appointmentForNextDay} expandable>
          <FormGroup>
            <Toggle
              toggled={d.allowFutureAppointments}
              label={allowFutureAppointments}
              onToggle={() => this.handlePropChange('allowFutureAppointments', !d.allowFutureAppointments)}
            />
          </FormGroup>
          {d.allowFutureAppointments && (
            <FormGroup>
              <Toggle
                toggled={d.attachIcsFile}
                label={attachIcsFile}
                onToggle={() => this.handlePropChange('attachIcsFile', !d.attachIcsFile)}
              />
            </FormGroup>
          )}
          {d.allowFutureAppointments && (
            <FormGroup>
              <Toggle
                toggled={d.authoriseAppointmentOnWebPortal}
                label={authoriseAppointmentOnWebPortal}
                onToggle={() =>
                  this.handlePropChange('authoriseAppointmentOnWebPortal', !d.authoriseAppointmentOnWebPortal)
                }
              />
            </FormGroup>
          )}
          {d.allowFutureAppointments && (
            <FormGroup>
              <TextField
                label={blindCopyEmails}
                placeholder={blindCopyEmailsPlaceholder}
                value={d.blindCopyEmails}
                onChange={(val) => this.handlePropChange('blindCopyEmails', val)}
                multiline
              />
            </FormGroup>
          )}
        </Form>

        <Divider />

        <Form title={servicesCollection} expandable>
          <FormGroup style={{ marginTop: 0 }}>
            {d.services.map((service, i) => (
              <div style={{ display: 'flex', alignItems: 'center' }} key={i}>
                <TextField
                  style={{ marginTop: 18, flex: 1, marginRight: 16 }}
                  placeholder={serviceName}
                  value={service.name}
                  onChange={(val) => this.handleServiceNameChange(i, val)}
                  message={`${service.name ? service.name.length : 0}/40 ${chars}`}
                  test={(v) => v.length < 41}
                  invalid={!service.name}
                />
                <TextField
                  placeholder={serviceDuration}
                  value={service.duration}
                  onChange={(val) => this.handleServiceDurationChange(i, val)}
                  test={isPositiveNumber}
                  hint={secondsHint}
                  invalid={service.duration < 9}
                />
                <IconButton onClick={() => this.handleRemoveService(i)}>
                  <RemoveIcon color="#374649" />
                </IconButton>
              </div>
            ))}
          </FormGroup>
          <FormGroup>
            <div style={{ marginLeft: -8 }}>
              <Button startIcon={<AddIcon />} onClick={this.handleAddService}>
                {addService}
              </Button>
            </div>
          </FormGroup>
        </Form>

        <Divider />

        <Form title={openingHours} expandable>
          <FormGroup>
            <Toggle
              toggled={d.nonStopService}
              label={nonStopService}
              onToggle={() => this.handlePropChange('nonStopService', !d.nonStopService)}
            />
          </FormGroup>
          {!d.nonStopService && (
            <FormGroup>
              <TimePicker
                style={{ width: '100%' }}
                label={openingHour}
                value={d.openingHour}
                onChange={(val) => this.handlePropChange('openingHour', val)}
                attention={!this.state.openingHourIsValid}
                offset={this.props.timeZoneOffset}
              />
            </FormGroup>
          )}
          {!d.nonStopService && (
            <FormGroup>
              <TimePicker
                style={{ width: '100%' }}
                label={closingHour}
                value={d.closingHour}
                onChange={(val) => this.handlePropChange('closingHour', val)}
                attention={!this.state.closingHourIsValid}
                offset={this.props.timeZoneOffset}
              />
            </FormGroup>
          )}
        </Form>

        <Divider />

        <Form title={durations} expandable>
          <FormGroup>
            <TextField
              label={serviceTime}
              placeholder={secondsPlaceholder}
              value={d.serviceTime}
              onChange={(val) => this.handlePropChange('serviceTime', val)}
              attention={!this.state.serviceTimeIsValid}
              test={isPositiveNumber}
              hint={secondsHint}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={timeToReach}
              placeholder={secondsPlaceholder}
              value={d.timeToReach}
              onChange={(val) => this.handlePropChange('timeToReach', val)}
              attention={!this.state.timeToReachIsValid}
              test={isPositiveNumber}
              hint={secondsHint}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={maxTimeToReach}
              placeholder={secondsPlaceholder}
              value={d.maxTimeToReach}
              onChange={(val) => this.handlePropChange('maxTimeToReach', val)}
              attention={!this.state.maxTimeToReachIsValid}
              test={isPositiveNumber}
              hint={secondsHint}
            />
          </FormGroup>
        </Form>

        <Divider />

        <Form title={clientsNotificationsGroup} expandable>
          <FormGroup>
            <Toggle
              toggled={d.beforeTheCall}
              label={beforeTheCall}
              onToggle={() => this.handlePropChange('beforeTheCall', !d.beforeTheCall)}
            />
          </FormGroup>
          {d.beforeTheCall && (
            <FormGroup>
              <TextField
                label={beforeTheCallTime}
                placeholder={'0 min'}
                value={d.beforeTheCallTime}
                onChange={(val) => this.handlePropChange('beforeTheCallTime', val)}
                test={isPositiveInteger}
                hint={() => ' min'}
                attention={!this.state.maxSimultaneousIsValid}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Toggle
              toggled={d.whenCalled === (undefined || null) ? true : d.whenCalled}
              label={whenCalled}
              onToggle={() => this.handlePropChange('whenCalled', !d.whenCalled)}
            />
          </FormGroup>
          <FormGroup>
            <Toggle
              toggled={d.whenServiceStartedByAgent}
              label={whenServiceStartedByAgent}
              onToggle={() => this.handlePropChange('whenServiceStartedByAgent', !d.whenServiceStartedByAgent)}
            />
          </FormGroup>
          <FormGroup>
            <Toggle
              toggled={d.whenServiceEndedByAgent}
              label={whenServiceEndedByAgent}
              onToggle={() => this.handlePropChange('whenServiceEndedByAgent', !d.whenServiceEndedByAgent)}
            />
          </FormGroup>
        </Form>
        <Divider />

        <Form title={modes} expandable>
          <FormGroup>
            <Toggle
              toggled={this.getModeValue('MobileApp')}
              label={mobileApp}
              onToggle={(ev, val) => this.handleModeChange('MobileApp', val)}
            />
          </FormGroup>
          <FormGroup>
            <Toggle
              toggled={this.getModeValue('SMS')}
              label={sms}
              onToggle={(ev, val) => this.handleModeChange('SMS', val)}
            />
          </FormGroup>
        </Form>
        <Divider />
        {this.getModeValue('SMS') && (
          <Form title={'SMS credits'} expandable>
            <FormGroup>
              <DatePicker
                style={{ width: '100%' }}
                label={'Start date for the limit'}
                placeholder={'Date'}
                value={d.creditsLimitStartDate}
                onChange={(val) => this.handlePropChange('creditsLimitStartDate', +val)}
              />
            </FormGroup>
            <FormGroup>
              <DatePicker
                style={{ width: '100%' }}
                label={'End date for the limit'}
                placeholder={'Date'}
                value={d.creditsLimitEndDate}
                onChange={(val) => this.handlePropChange('creditsLimitEndDate', +val)}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                label={'Global limit'}
                placeholder={'Number'}
                value={d.globalCreditsLimit}
                onChange={(val) => this.handlePropChange('globalCreditsLimit', val)}
                test={isPositiveNumber}
              />
            </FormGroup>
          </Form>
        )}
        <Divider />
      </div>
    )
  }

  setServicesData = (data) => {
    let state = { data }

    this.validateDataField(state, 'services', data.services)
    this.setState(state)
  }

  handleAddService = () => {
    this.setServicesData({ ...this.state.data, services: [...this.state.data.services, { name: '', duration: '' }] })
  }

  handleRemoveService = (i) => {
    const services = [...this.state.data.services]
    services.splice(i, 1)
    this.setServicesData({ ...this.state.data, services })
  }

  handleServiceNameChange = (i, val) => {
    const services = [...this.state.data.services]
    services[i].name = val
    this.setServicesData({ ...this.state.data, services })
  }

  handleServiceDurationChange = (i, val) => {
    const services = [...this.state.data.services]
    services[i].duration = val
    this.setServicesData({ ...this.state.data, services })
  }

  getModeValue = (val) => {
    const d = [...(this.state?.data?.modes || [])]
    return d.some((x) => x.toLowerCase() === val.toLowerCase())
  }

  handleModeChange(mode, value) {
    let modes = [...(this.state?.data?.modes || [])]

    const hasMode = modes.some((x) => x.toLowerCase() === mode.toLowerCase())

    if (!hasMode && value) {
      modes.push(mode)
    }

    if (!value) {
      modes = modes.filter((x) => x.toLowerCase() !== mode.toLowerCase())
    }

    this.setState({ data: { ...this.state.data, modes } })
  }

  handleManageAppointments(val) {
    this.setState({ data: { ...this.state.data, manageAppointments: val } })
  }
}

export default translate(LineEdit)
