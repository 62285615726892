import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import translate from 'i18n/translate'
import Divider from '@material-ui/core/Divider'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'

class GenericProductEdit extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'name',
        stateField: 'nameIsValid'
      },
      {
        dataField: 'code',
        stateField: 'codeIsValid'
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}

        <EntityActions showButtons={true} buttons={this.renderControls()} updateData={this.state.data.lastUpdate} />
      </Card>
    )
  }

  renderControls() {
    const { createButton, updateButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button id="confirm" onClick={() => this.props.onDelete(this.state.data.id)}>
              {deleteButton}
            </Button>
            <Button id="cancel" onClick={() => this.handleCancelDelete()}>
              {cancelButton}
            </Button>
          </div>
        </div>
      )

    if (!this.state.data.id)
      return (
        <Button disabled={!this.stateIsValid()} id="create" onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    else
      return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()} id="update">
            {updateButton}
          </Button>
          {this.props.canDelete !== false && (
            <Button id="delete" onClick={() => this.handleTryDelete()}>
              {deleteButton}
            </Button>
          )}
        </div>
      )
  }

  handleTryDelete() {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete() {
    this.setState({ confirmDelete: false })
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderMainForm(d) {
    const t = this.props.translation
    const { chars } = t.entityEdit

    return (
      <div>
        <Form>
          <FormGroup>
            <TextField
              label={t.genericProductEdit.name}
              placeholder={t.genericProductEdit.namePlaceholder}
              autofocus={true}
              value={d.name}
              onChange={(val) => this.handlePropChange('name', val)}
              attention={!this.state.nameIsValid}
              message={`${d.name ? d.name.length : 0}/200 ${chars}`}
              test={(v) => v.length < 201}
              disabled={!this.props.canEdit}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.genericProductEdit.code}
              placeholder={t.genericProductEdit.codePlaceholder}
              autofocus={false}
              value={d.code}
              onChange={(val) => this.handlePropChange('code', val)}
              attention={!this.state.codeIsValid}
              message={`${d.code ? d.code.length : 0}/200 ${chars}`}
              test={(v) => v.length < 201}
              disabled={!this.props.canEdit}
            />
          </FormGroup>
        </Form>
        <Divider />
      </div>
    )
  }
}

export default translate(GenericProductEdit)
