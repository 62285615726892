import { shop, brand, line, checkpoint } from 'provider'
import {
  getEntityRequest,
  getEntitySuccess,
  getEntityFailure,
  createEntitySender,
  createEntityGetter
} from './entity-action-creators'
import { setTimeZones } from '../reducers/time-zones-reducer'
import {
  getCheckpointListSuccess,
  getLineListSuccess,
  getShopListSuccess,
  getShopListRequest,
  invalidateIsolatedShopList,
  setCurrentShop
} from '../reducers/shops-reducer'

export function getShop(shopId) {
  return (dispatch) => {
    dispatch(getEntityRequest())
    Promise.all([shop.getShop(shopId)])
      .then((values) => dispatch(getEntitySuccess({ identity: 'shop', entity: values[0] })))
      .catch((error) => dispatch(getEntityFailure(error)))
  }
}

export function getFirstShop() {
  return (dispatch) => {
    dispatch(getEntityRequest())
    Promise.all([shop.getShopList(), brand.getBrandList(), shop.getTimeZones()])
      .then((values) =>
        dispatch(
          getEntitySuccess({
            identity: 'shop',
            entity: { data: values[0].data[0] },
            associated: { brands: values[1], timeZones: values[2] }
          })
        )
      )
      .catch((error) => dispatch(getEntityFailure(error)))
  }
}

export const getShopDaysOff = createEntityGetter(shop.getShopDaysOff, 'daysOff')

export const updateShopDaysOff = createEntitySender(shop.updateShopDaysOff, { type: 'update' })

export const getIsolatedShopList = function () {
  return (dispatch) => {
    dispatch(getShopListRequest())

    shop.getShopList().then((value) => dispatch(getShopListSuccess(value)))
  }
}

export const getIsolatedLineList = function (shopId) {
  return (dispatch) => {
    line.getLineList(shopId).then((value) => dispatch(getLineListSuccess(value)))
  }
}

export const getIsolatedCheckpointList = function (shopId) {
  return (dispatch) => {
    checkpoint.getCheckpointList(shopId).then((value) => dispatch(getCheckpointListSuccess(value)))
  }
}

export { setCurrentShop, invalidateIsolatedShopList }

let timeZonesFetching = false

export function getIsolatedTimeZones() {
  return async (dispatch) => {
    if (timeZonesFetching) {
      return
    }

    try {
      timeZonesFetching = true

      const res = await shop.getTimeZones()

      dispatch(setTimeZones(res.data))
    } finally {
      timeZonesFetching = false
    }
  }
}
