import axios from 'axios'

export default {
  getSchedulerConfig(data) {
    return axios.get('getSchedulerConfig', { params: data })
  },

  getSchedulerData(data) {
    return axios.get('getSchedulerData', { params: data })
  },

  searchSchedulerAppointments(data) {
    return axios.get('searchSchedulerAppointments', { params: data })
  },

  getFile(id) {
    window.open(`/api/appointmentAttachmentDownload?id=${id}`, '_self')
  }
}
