import * as Nav from 'app/nav'

function createCrumb(res, resName, navName) {
  return function (navParams, title) {
    const nav = Nav[navName || resName]

    return {
      id: resName,
      title: title || res[resName],
      url: nav && nav.apply(null, navParams)
    }
  }
}

export function getCrumbs(res) {
  return {
    new: () => ({ title: res.new }),
    edit: () => ({ title: res.edit }),
    home: createCrumb(res, 'home', 'index'),
    shop: createCrumb(res, 'shop'),
    shops: createCrumb(res, 'shops'),
    newShop: createCrumb(res, 'newShop'),
    editShop: createCrumb(res, 'editShop'),
    lines: createCrumb(res, 'lines'),
    line: createCrumb(res, 'line'),
    newLine: createCrumb(res, 'newLine'),
    editLine: createCrumb(res, 'editLine'),
    checkpoints: createCrumb(res, 'checkpoints'),
    newCheckpoint: createCrumb(res, 'newCheckpoint'),
    editCheckpoint: createCrumb(res, 'editCheckpoint'),
    users: createCrumb(res, 'users'),
    newUser: createCrumb(res, 'newUser'),
    editUser: createCrumb(res, 'editUser'),
    reports: createCrumb(res, 'reports'),
    messageTemplates: createCrumb(res, 'messageTemplates'),
    daysOff: createCrumb(res, 'daysOff'),
    staffManagement: createCrumb(res, 'staffManagement', 'lineStaffManagement'),
    editStaffManagement: createCrumb(res, 'editStaffManagement', 'editLineStaffManagement'),
    newStaffManagement: createCrumb(res, 'newStaffManagement', 'createLineStaffManagement'),
    appointments: createCrumb(res, 'appointments'),
    newAppointment: createCrumb(res, 'newAppointment'),
    editAppointment: createCrumb(res, 'editAppointment'),
    exportData: createCrumb(res, 'exportData'),
    translations: createCrumb(res, 'translations'),
    newTranslation: createCrumb(res, 'newTranslation'),
    editTranslation: createCrumb(res, 'editTranslation'),
    settings: createCrumb(res, 'settings'),
    unavailabilityAndAppointments: createCrumb(res, 'unavailabilityAndAppointments'),
    genericProducts: createCrumb(res, 'genericProducts'),
    newGenericProduct: createCrumb(res, 'newGenericProduct'),
    editGenericProduct: createCrumb(res, 'editGenericProduct'),
    loadingArms: createCrumb(res, 'loadingArms'),
    newLoadingArm: createCrumb(res, 'newLoadingArm'),
    editLoadingArm: createCrumb(res, 'editLoadingArm'),
    transportCompanies: createCrumb(res, 'transportCompanies'),
    loadingAuthorizations: createCrumb(res, 'loadingAuthorizations'),
    totalAppointments: createCrumb(res, 'totalAppointments'),
    checkpointsUnavailability: createCrumb(res, 'checkpointsUnavailability'),
    newCheckpointUnavailability: createCrumb(res, 'newCheckpointUnavailability'),
    editCheckpointUnavailability: createCrumb(res, 'editCheckpointUnavailability'),
    loadingArmsUnavailability: createCrumb(res, 'loadingArmsUnavailability'),
    newLoadingArmUnavailability: createCrumb(res, 'newLoadingArmUnavailability'),
    editLoadingArmUnavailability: createCrumb(res, 'editLoadingArmUnavailability'),
    appointmentsScheduling: createCrumb(res, 'appointmentsScheduling'),
    productsUnavailability: createCrumb(res, 'productsUnavailability'),
    newProductUnavailability: createCrumb(res, 'newProductUnavailability'),
    editProductUnavailability: createCrumb(res, 'editProductUnavailability')
  }
}
