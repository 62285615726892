import { unicodeCategories } from 'utils/regex-helper'

export function isPositiveNumber(val: string) {
  return /^\d+(\.)?(\d+)?$/.test(val)
}

export function isPositiveInteger(val: string) {
  return /^[1-9]\d*$/.test(val)
}

export function isEmail(val = '') {
  const lower = val.toLowerCase()
  return /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(lower)
}

export function isValidPassword(val: string) {
  const symbolsRegex = new RegExp(
    `^(?=.*[${unicodeCategories.Ll}])(?=.*[${unicodeCategories.Lu}])(?=.*\\d)(?=.*[$!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~])[${unicodeCategories.Lu}${unicodeCategories.Ll}\\d$!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~]{8,}$`,
    'g'
  )
  return symbolsRegex.test(val)
}
