import uniqueId from 'lodash/uniqueId'
import fakeBrands from './fake-brands'

export const config = {
  languages: [
    { name: 'English', value: 'en' },
    { name: 'French', value: 'fr' },
    { name: 'Spanish', value: 'es' },

    { name: 'Chinese', value: 'zh' },

    { name: 'Japanese', value: 'ja' },

    { name: 'Italian', value: 'it' },

    { name: 'German', value: 'de' },

    { name: 'Russian', value: 'ru' }
  ]
}

const data = [
  {
    id: uniqueId(),
    brandId: fakeBrands[0].id,
    sourceText: 'Tours de Notre-Dame de Paris',
    translations: [
      {
        destinationLanguage: 'en',
        translationText: 'Towers of Notre-Dame de Paris'
      }
    ]
  }
]

export function getResponse(data, add) {
  return {
    data: add ? Object.assign({}, data, add) : data
  }
}

export default data
