import React, { Component } from 'react'
import { List } from 'dumb/list'
import translate from 'i18n/translate'

class CheckpointList extends Component {
  render() {
    const { lastUpdate } = this.props.translation.entityView

    return (
      <List
        onSelect={(data) => this.props.onSelect(data)}
        data={this.props.data}
        handleData={(item) => ({ id: item.id, lineId: item.lineId })}
        dataConverter={(item) => {
          let name = item.name
          const lineName = item.lineName

          if (this.props.showLine && lineName) {
            name += `, ${lineName}`
          }

          const info =
            !!item?.lastUpdate?.user &&
            lastUpdate(
              item.lastUpdate.user.firstName,
              item.lastUpdate.user.secondName,
              this.props.dateFormatter(item.lastUpdate.date, 'dateTime')
            )

          return { id: item.id, primaryText: name, secondaryText: info }
        }}
      />
    )
  }
}

export default translate(CheckpointList)
