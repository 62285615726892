import { useCallback, useEffect, useRef, useState } from 'react'

type UseIntersectionParams<T> = {
  list: T
  root?: Element
}

function useIntersectionList<T>(params: UseIntersectionParams<T>) {
  const [hiddenCount, setHiddenCount] = useState<number>(0)

  const elementsRef = useRef<Record<number, HTMLElement | null>>({})
  const observerRef = useRef<IntersectionObserver>()

  const onIntersect = useCallback((entries: IntersectionObserverEntry[]) => {
    setHiddenCount((hiddenCount) => {
      const elements = Object.entries(elementsRef.current)
        .filter(([, el]) => Boolean(el) && Boolean(el?.isConnected))
        .map(([index, el]) => ({ index: +index, el }))

      let level = hiddenCount

      entries.forEach((entire) => {
        const el = elements.find((ref) => ref.el === entire.target)

        if (!el) {
          return
        }

        if (entire.intersectionRatio < 1 && el.index + 1 > level) {
          level = el.index + 1
        } else if (entire.intersectionRatio === 1 && el.index < level) {
          level = el.index
        }
      })

      return elements.length === level ? level - 1 : level
    })
  }, [])

  useEffect(() => {
    observerRef.current = new IntersectionObserver(onIntersect, { root: params.root, threshold: 1 })
    return () => observerRef.current?.disconnect()
  }, [])

  const crumbRefFn = useCallback((element: HTMLElement, id: number) => {
    elementsRef.current[id] = element

    if (observerRef.current && elementsRef.current[id]) {
      observerRef.current?.observe(elementsRef.current[id] as HTMLElement)
    }
  }, [])

  return { hiddenCount, crumbRefFn }
}

export { useIntersectionList }
