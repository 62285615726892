import uniqueId from 'lodash/uniqueId'
import random from 'lodash/random'
import fakeShops from './fake-shops'
import fakeCheckpoints from './fake-checkpoints'

const data = {
  checkpoints: fakeCheckpoints.map((x) => {
    return {
      id: x.id,
      name: x.name
    }
  })
}

let date = new Date()
date.setHours(6)
date.setMinutes(0)
date.setSeconds(0)
let ms = +date

const min = 1000 * 60
const hour = min * 60

export const schedulerData = () => ({
  appointments: [
    {
      startDate: ms,
      endDate: ms + random(1, 4) * 60 * 60 * 1000,
      checkpointId: data.checkpoints[0].id,
      loadingAuthorizationId: uniqueId(),
      shopId: fakeShops[0].id,
      driverName: 'Romain',
      driverSurname: 'LAPREE',
      useFlexiTank: true,
      product: {
        name: 'SOLANE HEXAN 45',
        code: '71023',
        qty: 20.0,
        measureUnit: 'TONNES'
      },
      shopName: 'Total Fluides Oudalle',
      servicePointName: 'Piste 01',
      armCode: 'BR20113 - Bac 50',
      clientName: 'Total',
      countryCode: 'FR',
      transportCompanyName: 'XPO Logistics',
      transportCompanyEmail: 'contact@europedev.xpo.com',
      status: 'Valid',
      token: 'hi, i am token1',
      sapCommand: 'some SAP 1',
      rdvCode: 'some RDV 1',
      files: [
        {
          id: 59,
          name: 'file1.doc'
        },
        {
          id: 64,
          name: 'file2.xls'
        }
      ],
      comment: 'This is comment',
      canView: true
    },
    {
      startDate: ms,
      endDate: ms + random(1, 4) * 60 * 60 * 1000,
      checkpointId: data.checkpoints[0].id,
      loadingAuthorizationId: uniqueId(),
      shopId: fakeShops[0].id,
      driverName: 'Romain',
      driverSurname: 'LAPREE',

      product: {
        name: 'SOLANE HEXAN 45',
        code: '71023',
        qty: 20.0,
        measureUnit: 'TONNES'
      },
      shopName: 'Total Fluides Oudalle',
      servicePointName: 'Piste 01',
      armCode: 'BR20113 - Bac 50',
      useFlexiTank: true,

      clientName: 'Total',
      countryCode: 'FR',
      transportCompanyName: 'XPO Logistics',
      transportCompanyEmail: 'contact@europedev.xpo.com',
      status: 'ToBeConfirmed',
      cancelReason: 'some reason',
      token: 'hi, i am token2',
      sapCommand: 'some SAP 2',
      rdvCode: 'some RDV 2',
      canView: true
    },
    {
      startDate: ms,
      endDate: ms + random(1, 4) * 60 * 60 * 1000,
      checkpointId: data.checkpoints[0].id,
      loadingAuthorizationId: uniqueId(),
      shopId: fakeShops[0].id,
      driverName: 'Romain',
      driverSurname: 'LAPREE',
      product: {
        name: 'SOLANE HEXAN 45',
        code: '71023',
        qty: 20.0,
        measureUnit: 'TONNES'
      },
      shopName: 'Total Fluides Oudalle',
      servicePointName: 'Piste 01',
      armCode: 'BR20113 - Bac 50',
      clientName: 'Total',
      countryCode: 'FR',
      transportCompanyName: 'XPO Logistics',
      transportCompanyEmail: 'contact@europedev.xpo.com',
      status: 'Cancelled',
      token: 'hi, i am token3',
      sapCommand: 'some SAP 3',
      rdvCode: 'some RDV 3',
      canView: false
    },
    {
      startDate: ms,
      endDate: ms + 1.5 * 60 * 60 * 1000,
      checkpointId: data.checkpoints[1].id,
      loadingAuthorizationId: uniqueId(),
      shopId: fakeShops[0].id,
      driverName: 'Romain',
      driverSurname: 'LAPREE',
      product: {
        name: 'SOLANE HEXAN 45',
        code: '71023',
        qty: 20.0,
        measureUnit: 'TONNES'
      },
      shopName: 'Total Fluides Oudalle',
      servicePointName: 'Piste 01',
      armCode: 'BR20113 - Bac 50',
      clientName: 'Total',
      countryCode: 'FR',
      transportCompanyName: 'XPO Logistics',
      transportCompanyEmail: 'contact@europedev.xpo.com',
      status: 'ToBeCancelled',
      token: 'hi, i am token4',
      sapCommand: 'some SAP 4',
      rdvCode: 'some RDV 4',
      canView: false
    },
    {
      startDate: ms,
      endDate: ms + 1.5 * 60 * 60 * 1000,
      checkpointId: data.checkpoints[1].id,
      loadingAuthorizationId: uniqueId(),
      shopId: fakeShops[0].id,
      driverName: 'Romain',
      driverSurname: 'LAPREE',
      driverGender: 1,
      product: {
        name: 'SOLANE HEXAN 45',
        code: '71023',
        qty: 20.0,
        measureUnit: 'TONNES'
      },
      shopName: 'Total Fluides Oudalle',
      servicePointName: 'Piste 01',
      armCode: 'BR20113 - Bac 50',
      clientName: 'Total',
      countryCode: 'FR',
      transportCompanyName: 'XPO Logistics',
      transportCompanyEmail: 'contact@europedev.xpo.com',
      status: 'ToBeConfirmed',
      token: 'hi, i am token4.1',
      sapCommand: 'some SAP 5',
      rdvCode: 'some RDV 5',
      canView: false
    },
    {
      startDate: ms + random(2, 3) * 60 * 60 * 1000,
      endDate: ms + random(4, 5) * 60 * 60 * 1000,
      checkpointId: data.checkpoints[2].id,
      loadingAuthorizationId: uniqueId(),
      shopId: fakeShops[0].id,
      driverName: 'Romain',
      driverSurname: 'LAPREE',
      productName: 'Prod name 1',
      genericProductCode: '123',
      clientName: 'Total',
      countryCode: 'FR',
      transportCompanyName: 'XPO Logistics',
      transportCompanyEmail: 'contact@europedev.xpo.com',
      status: 'Arrived',
      token: 'hi, i am token5',
      sapCommand: 'some SAP 6',
      rdvCode: 'some RDV 6',
      canView: false
    },
    {
      startDate: ms + random(2, 3) * 60 * 60 * 1000,
      endDate: ms + random(4, 5) * 60 * 60 * 1000,
      checkpointId: data.checkpoints[3].id,
      status: 'Finished',
      canView: false
    }
  ],
  unavailability: [
    {
      id: uniqueId(),
      startDate: ms + 2.0 * 60 * 60 * 1000,
      endDate: ms + 4.5 * 60 * 60 * 1000,
      checkpointId: data.checkpoints[1].id,
      comment: 'Long long long test comment 1',
      canView: true
    },
    {
      id: uniqueId(),
      startDate: ms - 24 * hour + 3 * hour,
      endDate: ms + 4 * hour,
      checkpointId: data.checkpoints[2].id,
      comment: 'Test comment 3',
      canView: true
    },
    {
      id: uniqueId(),
      startDate: ms + 1 * hour,
      endDate: ms + 1.5 * hour,
      checkpointId: data.checkpoints[2].id,
      comment: 'Test comment 3',
      canView: true
    },
    {
      id: uniqueId(),
      startDate: ms + 2.0 * 60 * 60 * 1000,
      endDate: ms + 4.5 * 60 * 60 * 1000,
      checkpointId: data.checkpoints[1].id,
      comment: 'Test comment 2',
      canView: false
    }
  ]
})

export const searchResult = [schedulerData().appointments[0]]

export function getResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export default data
