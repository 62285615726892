import {
  Dialog,
  IconButton,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  CircularProgress
} from '@material-ui/core'
import { RemoveCircleOutline as RemoveIcon, Public } from '@material-ui/icons'
import { useState } from 'react'
import Dropdown from 'dumb/dropdown'
import css from './line-translation.module.scss'
import { translations } from 'provider'
import { useEffect } from 'react'
import { getPermissionsSelector } from 'store/selectors/permissionSelectors'
import { useAppSelector } from 'store'
import { useDataProvider } from 'smart/use-data-provider'
import { LanguageModel, TextTranslateModel, TranslationModel } from 'shared/models'
import { InputTranslationProps } from './types'
import { useTranslation } from 'i18n/translate'

export function InputTranslation({ brandId, inputValue, setInputValue, children, shopId }: InputTranslationProps) {
  const [isOpenTranslateDialog, setOpenTranslateDialog] = useState(false)
  const [translationsData, setTranslationsData] = useState<TextTranslateModel | null>(null)
  const [languages, setLanguages] = useState<LanguageModel[] | null>(null)
  const [isNewTranslation, setNewTranslation] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const { limitedView } = useAppSelector(getPermissionsSelector)

  const { getShop } = useDataProvider()

  const { tr } = useTranslation()

  function handleChangeOpenTranslateDialog(isOpenValue) {
    return () => setOpenTranslateDialog(isOpenValue)
  }

  function getSupportedLangs(langs?: LanguageModel[]): LanguageModel[] {
    const allLanguageses = langs || languages

    if (shopId && allLanguageses) {
      const res = getShop(shopId)

      if (res) {
        const supportedLanguages = res.supportedLanguages
        const supportedLangs = (supportedLanguages || '').split(';').filter(Boolean)
        const filtredLangs = allLanguageses.filter((lang) => supportedLangs.includes(lang.value))
        const enFound = !!filtredLangs.find((lang) => lang.value === 'en')
        const defaultEn = allLanguageses.find((lang) => lang.value === 'en') || { value: 'en', name: 'En' }

        if (filtredLangs.length > 0) {
          return enFound ? filtredLangs : [defaultEn, ...filtredLangs]
        }
      }
    }

    return allLanguageses || []
  }

  async function getTranslation(languages: LanguageModel[]) {
    if (!brandId) {
      return
    }

    setLoading(true)

    try {
      const res = await translations.getTranslationByBrandAndSourceText({ sourceText: inputValue, brandId })

      if (res?.data?.id && res?.data?.id !== 0) {
        setTranslationsData(res.data)
        setNewTranslation(false)
      } else {
        const translations: TranslationModel[] = []

        if (shopId) {
          const res = getShop(shopId)

          if (res) {
            const supportedLanguages = res.supportedLanguages
            const supportedLangs = (supportedLanguages || '').split(';').filter(Boolean)
            const foundedLangs = languages?.find((lang) => lang.value === supportedLangs[0])

            if (foundedLangs) {
              translations.push({ destinationLanguage: foundedLangs.value, translationText: clearBrackets(inputValue) })
            }
          }
        }

        if (!translations.length || translations[0].destinationLanguage !== 'en') {
          translations.push({ destinationLanguage: 'en', translationText: clearBrackets(inputValue) })
        }

        setTranslationsData({ sourceText: inputValue, brandId, translations })
        setNewTranslation(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function createTranslation() {
    setLoading(true)

    const trs = translationsData?.translations || []

    try {
      await translations
        .createTranslation({ sourceText: `[${clearBrackets(inputValue)}]`, brandId, translations: trs })
        .then(() => setOpenTranslateDialog(false))
    } finally {
      setLoading(false)
    }
  }

  async function updateTranslation() {
    setLoading(true)

    try {
      await translations.updateTranslation(translationsData).then(() => setOpenTranslateDialog(false))
    } finally {
      setLoading(false)
    }
  }

  async function getTranslationConfig(campaignId?: number) {
    setLoading(true)

    try {
      const res = await translations.getTranslationConfig({ campaignId })

      const languages = getSupportedLangs(res.data.languages || [])

      setLanguages(languages)
      getTranslation(languages)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isOpenTranslateDialog) {
      getTranslationConfig()
    } else {
      setTranslationsData(null)
      setLanguages(null)
    }
  }, [isOpenTranslateDialog])

  function handleChangeTranslation(destinationLanguage: string, value, key) {
    const changedTranslations = translationsData?.translations.map((translation) =>
      translation.destinationLanguage === destinationLanguage ? { ...translation, [key]: value } : translation
    )

    if (translationsData) {
      setTranslationsData({ ...translationsData, translations: changedTranslations || [] })
    }
  }

  function addTranslationElement() {
    const destinationLanguage = (languages || []).find((lang) => {
      return !(translationsData?.translations || []).some((tr) => tr.destinationLanguage === lang.value)
    })

    if (translationsData && destinationLanguage) {
      setTranslationsData({
        ...translationsData,
        translations: [
          ...translationsData.translations,
          { destinationLanguage: destinationLanguage.value, translationText: clearBrackets(inputValue) }
        ]
      })
    }
  }

  function removeTranslation(destinationLanguage: string) {
    const filteredTranslation = (translationsData?.translations || []).filter(
      (tr) => tr.destinationLanguage !== destinationLanguage
    )

    if (translationsData) {
      setTranslationsData({ ...translationsData, translations: filteredTranslation })
    }
  }

  function searchEnTranslation() {
    return (
      translationsData?.translations?.filter((translation) => translation.destinationLanguage === 'en')?.length === 1
    )
  }

  function searchEmptyTranslationText() {
    return !!translationsData?.translations?.find((translation) => !translation.translationText)
  }

  function searchRepeatTranslations() {
    const languages = translationsData?.translations?.map((translation) => translation.destinationLanguage)

    return languages?.length === new Set(languages).size
  }

  function clearBrackets(word: string) {
    return !word ? '' : word?.replaceAll('[', '')?.replaceAll(']', '')
  }

  function openTranslate() {
    setOpenTranslateDialog(true)

    if (!(inputValue.includes('[') && inputValue.includes(']'))) {
      setInputValue(`[${inputValue}]`)
    }
  }

  function supportedLangFilter(el: TranslationModel) {
    const availableLangs = languages?.map((lang) => lang.value) || []

    return availableLangs.some((lang) => lang === el.destinationLanguage)
  }

  return (
    <div className={css.translationContainer}>
      <div className={css.translationContent}>{children}</div>
      {!limitedView && (
        <IconButton
          onClick={openTranslate}
          size="small"
          style={{ width: '20px', height: '20px' }}
          disabled={!brandId || !inputValue}
        >
          <Public fontSize="small" />
        </IconButton>
      )}
      <Dialog open={isOpenTranslateDialog} onClose={handleChangeOpenTranslateDialog(false)} fullWidth>
        {!isLoading ? (
          <>
            <DialogTitle>{`${tr.translationEdit.translationsFor} ${clearBrackets(inputValue)}`}</DialogTitle>
            <DialogContent>
              {translationsData?.translations?.filter(supportedLangFilter).map((tr, i) => (
                <div className={css.translationItem} key={i}>
                  {!!languages && (
                    <Dropdown
                      ensureValue
                      value={tr.destinationLanguage}
                      data={languages.filter(
                        (lang) =>
                          lang.value === tr.destinationLanguage ||
                          !translationsData.translations.some((tr) => tr.destinationLanguage === lang.value)
                      )}
                      style={{ flex: '1', width: '100px' }}
                      dataConverter={(item) => ({ value: item.value, text: item.name })}
                      onChange={(val) => handleChangeTranslation(tr.destinationLanguage, val, 'destinationLanguage')}
                    />
                  )}
                  <TextField
                    multiline
                    style={{ flex: '1' }}
                    placeholder={tr.translationText}
                    value={tr.translationText}
                    error={!tr.translationText}
                    onChange={(val) =>
                      handleChangeTranslation(tr.destinationLanguage, val?.target?.value, 'translationText')
                    }
                    maxRows={15}
                  />
                  <IconButton
                    className={css.translationRemoveButton}
                    style={{ margin: 'auto' }}
                    onClick={() => removeTranslation(tr.destinationLanguage)}
                    disabled={tr.destinationLanguage === 'en' && searchEnTranslation()}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleChangeOpenTranslateDialog(false)} size="small">
                {tr.entityEdit.cancelButton}
              </Button>
              <Button
                onClick={addTranslationElement}
                size="small"
                disabled={
                  !(languages || []).find(
                    (lang) => !translationsData?.translations.some((tr) => tr.destinationLanguage === lang.value)
                  )
                }
              >
                {tr.translationEdit.addTranslation}
              </Button>
              <Button
                size="small"
                onClick={isNewTranslation ? createTranslation : updateTranslation}
                disabled={!searchEnTranslation() || searchEmptyTranslationText() || !searchRepeatTranslations()}
              >
                {tr.entityEdit.updateButton}
              </Button>
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '100px', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </div>
  )
}
