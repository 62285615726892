import uniqueId from 'lodash/uniqueId'
import fakeShops from './fake-shops'
import fakeLines from './fake-lines'

const data = [
  {
    id: uniqueId(),
    startDate: Date.now(),
    startHour: {
      minutes: 10,
      hours: 1
    },
    endHour: {
      minutes: 10,
      hours: 1
    },
    numberOfStaff: 3,
    comments: 'Arnaud, Pierre, Marine'
  },
  {
    id: uniqueId(),
    startDate: Date.now(),
    startHour: {
      minutes: 10,
      hours: 1
    },
    endHour: {
      minutes: 10,
      hours: 1
    },
    numberOfStaff: 2,
    comments: 'Arnaud, Pierre'
  }
]

export function getResponse(data, add) {
  return {
    data: add ? Object.assign({}, data, add) : data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      },
      line: {
        id: fakeLines[0].id,
        name: fakeLines[0].name
      }
    }
  }
}

export default data
