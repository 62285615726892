import React from 'react'
import { connect } from 'react-redux'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import translate from 'i18n/translate'
import dataProvider from 'smart/isolated-data-provider'
import timeZoneFilter from 'smart/time-zone-filter'
import Tile, { TileContainer } from 'dumb/tile'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import * as Nav from 'app/nav'
import Container from 'dumb/container'
import CircularProgress from '@material-ui/core/CircularProgress'
import { totalAppointments } from 'provider'
import OffIcon from '@material-ui/icons/HighlightOff'
import StorageIcon from '@material-ui/icons/Storage'

class SmartUnavailabilityAndAppointments extends React.Component {
  static contextTypes = {
    router: () => null
  }

  state = {
    count: null,
    loading: false
  }

  fetchData() {
    totalAppointments
      .getAppointmentCounters(this.props.match.params.shopId)
      .then((res) => {
        this.setState({ count: res.toCancel, loading: true })
      })
      .catch(() => {
        this.setState({ loading: true })
      })
  }

  componentDidMount() {
    this.setBreadcrumbs()
    this.fetchData()
  }

  setBreadcrumbs() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const shop = this.props.shops.find((shop) => shop.id == this.getShopId())
    const shopName = shop.name

    const crumbs = [
      crumb.home(),
      crumb.shops(),
      crumb.shop([this.getShopId()], shopName),
      crumb.unavailabilityAndAppointments()
    ]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  render() {
    if (!this.state.loading)
      return (
        <div
          style={{
            background: '#fafafa',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )

    const { pointOfServicesUnavailability, loadingArmsUnavailability, appointments, appointmentsToCancel } =
      this.props.translation.unavailabilityAndAppointments

    return (
      <Container style={{ maxWidth: '625px', width: '100%' }}>
        <TileContainer>
          <Tile
            title={pointOfServicesUnavailability}
            iconClass={OffIcon}
            onClick={() => this.pushPath(Nav.checkpointsUnavailability(this.getShopId()))}
          />
          <Tile
            title={loadingArmsUnavailability}
            iconClass={OffIcon}
            onClick={() => this.pushPath(Nav.loadingArmsUnavailability(this.getShopId()))}
          />
          {this.props.canViewAppointments && (
            <Tile
              title={appointments}
              iconClass={StorageIcon}
              onClick={() => this.pushPath(Nav.totalAppointments(this.getShopId()))}
            />
          )}
          {this.props.canViewAppointments && (
            <Tile
              title={appointmentsToCancel}
              iconClass={StorageIcon}
              onClick={() => this.pushPath(Nav.totalAppointmentsToCancel(this.getShopId()))}
              count={this.state.count}
            />
          )}
        </TileContainer>
      </Container>
    )
  }

  getShopId = () => this.props.match.params.shopId

  pushPath(path) {
    this.props.history.push(path)
  }
}

const stateToProps = (state) => ({ canViewAppointments: state.user.permissions.canViewAppointments })

export default dataProvider({ shops: true })(
  timeZoneFilter(connect(stateToProps)(translate(SmartUnavailabilityAndAppointments)))
)
