import React, { Component } from 'react'
import ListItem from './list-item'
import css from './list.module.scss'

export default class List extends Component {
  render() {
    return <div className={css.root}>{this.renderEntities()}</div>
  }

  renderEntities() {
    return this.props.data.map((entity, i) => {
      const d = (this.props.dataConverter || this.dataConverter)(entity, i)

      return (
        <ListItem
          key={d.id}
          id={d.id}
          index={i}
          primaryText={d.primaryText}
          secondaryText={d.secondaryText}
          onSelect={(data) => this.handleSelect(data, i)}
          avatar={d.avatar}
          avatarOnTop={d.avatarOnTop}
          controls={d.controls}
          entity={entity}
          handleData={this.props.handleData}
          notActive={d.notActive}
          selected={d.id == this.props.selectedId}
        />
      )
    })
  }

  dataConverter(entity) {
    return { id: entity.id, primaryText: entity.primaryText, secondaryText: entity.secondaryText }
  }

  handleSelect(data, i) {
    this.props.onSelect(data, i)
  }
}
