import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import translate from 'i18n/translate'
import TotalLoadingAuthorization from './loading-authorization'
import AppointmentEdit from './appointment-edit'
import Card from 'dumb/card/card'
import { totalAppointments } from 'provider'
import * as Nav from 'app/nav'
import { produce } from 'immer'
import Container from 'dumb/container'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import LoadingAuthorizationSearcher from './loading-authorization-search/loading-authorization-search-form'
import CircularProgress from '@material-ui/core/CircularProgress'
import LoadingAuthorizationSearchResult from './loading-authorization-search/loading-authorization-search-result'
import TotalAppointmentStatuses from 'provider/total-appointment-statuses'
import dataProvider from 'smart/isolated-data-provider'
import hideTimeZone from '../hide-zone'

const Steps = { SEARCH: 'SEARCH', FORM: 'FORM' }

class SmartRdv extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: this.isEditMode() ? null : Steps.SEARCH,
      loadingAuthorization: null,
      trySearch: false,
      fetching: false,
      countries: null,
      useFlexiTank: false
    }
  }

  setBreadcrumbs() {
    const tr = this.props.translation.breadcrumbs
    const crumb = getCrumbs(tr)
    let crumbs = []

    if (this.getShopId()) {
      const shopId = this.getShopId()

      crumbs = [
        crumb.home(),
        crumb.shops(),
        crumb.shop([shopId], this.props.getShop(shopId).name),
        crumb.unavailabilityAndAppointments([shopId]),
        crumb.totalAppointments([shopId]),
        {
          title: this.isEditMode() ? tr.edit : tr.new
        }
      ]
    } else {
      crumbs = [
        crumb.home(),
        crumb.appointmentsScheduling(),
        {
          title: this.isEditMode() ? tr.edit : tr.new
        }
      ]
    }

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  componentDidMount() {
    // Проверка на право взаимодействия со встречими
    if (!this.props.canEditAppointments) {
      this.props.dispatch(showMessage(this.props.translation.accessMessages.notAuthorised, 'error'))
      this.goBack()
      return null
    }

    this.setState({
      fetching: true
    })

    if (this.isEditMode()) {
      totalAppointments
        .searchLoadingAuthorization({
          shopId: this.getShopId(),
          appointmentToken: this.getAppointmentToken()
        })
        .then((resp) => {
          this.setBreadcrumbs(resp)
          this.setLoadingAuthorizationAndGoToForm(resp.data)
        })
    } else {
      this.editState((draft) => {
        draft.countries = [
          'FR',
          'GB',
          'DE',
          'ID',
          'TR',
          'US',
          'CN',
          'MY',
          'PL',
          'BE',
          'TW',
          'IN',
          'AE',
          'IT',
          'ES',
          'LC',
          'LB',
          'CH',
          'SG',
          'BO',
          'RU',
          'NL',
          'TN',
          'CZ',
          'BB',
          'AR',
          'HU',
          'RE',
          'RO',
          'AU',
          'BR',
          'GR',
          'SK',
          'SX',
          'MF',
          'AT',
          'NA',
          'NC',
          'NE',
          'NF',
          'NG',
          'NI',
          'NO',
          'NP',
          'NR',
          'NU',
          'NZ',
          'OM',
          'PA',
          'PE',
          'PF',
          'PG',
          'PH',
          'PK',
          'PM',
          'PN',
          'PR',
          'PS',
          'PT',
          'MZ',
          'PW',
          'MX',
          'MV',
          'LR',
          'LS',
          'LT',
          'LU',
          'LV',
          'LY',
          'MA',
          'MC',
          'MD',
          'ME',
          'MG',
          'MH',
          'MK',
          'ML',
          'MM',
          'MN',
          'MO',
          'MP',
          'MQ',
          'MR',
          'MS',
          'MT',
          'MU',
          'MW',
          'PY',
          'QA',
          'RS',
          'TO',
          'TT',
          'TV',
          'TZ',
          'UA',
          'UG',
          'UM',
          'UY',
          'UZ',
          'VA',
          'VC',
          'VE',
          'VG',
          'VI',
          'VN',
          'VU',
          'WF',
          'WS',
          'XX',
          'YE',
          'YT',
          'ZA',
          'ZM',
          'TM',
          'TL',
          'TK',
          'TJ',
          'RW',
          'SA',
          'SB',
          'SC',
          'SD',
          'SE',
          'SH',
          'SI',
          'SJ',
          'SL',
          'SM',
          'LK',
          'SN',
          'SR',
          'SS',
          'ST',
          'SV',
          'SY',
          'SZ',
          'TC',
          'TD',
          'TF',
          'TG',
          'TH',
          'SO',
          'LI',
          'BZ',
          'CA',
          'CC',
          'CD',
          'CF',
          'CG',
          'CI',
          'CK',
          'CL',
          'CM',
          'CO',
          'CR',
          'CU',
          'CV',
          'CW',
          'CX',
          'CY',
          'DJ',
          'DK',
          'DM',
          'DO',
          'DZ',
          'EC',
          'BY',
          'BW',
          'BV',
          'BT',
          'AD',
          'AF',
          'AG',
          'AI',
          'AL',
          'AM',
          'AO',
          'AQ',
          'AS',
          'AW',
          'AX',
          'EE',
          'AZ',
          'BD',
          'BF',
          'BG',
          'BH',
          'BI',
          'BJ',
          'BL',
          'BM',
          'BN',
          'BQ',
          'BS',
          'BA',
          'ZW',
          'EG',
          'ER',
          'IE',
          'IL',
          'IM',
          'IO',
          'IQ',
          'IR',
          'IS',
          'JE',
          'JM',
          'JO',
          'JP',
          'KE',
          'KG',
          'KH',
          'KI',
          'KM',
          'KN',
          'KP',
          'KR',
          'KW',
          'KY',
          'KZ',
          'LA',
          'HT',
          'HR',
          'HN',
          'HM',
          'ET',
          'FI',
          'FJ',
          'FK',
          'FM',
          'FO',
          'GA',
          'GD',
          'GE',
          'GF',
          'GG',
          'EH',
          'GH',
          'GL',
          'GM',
          'GN',
          'GP',
          'GQ',
          'GS',
          'GT',
          'GU',
          'GW',
          'GY',
          'HK',
          'GI',
          'ZZ'
        ]
        draft.fetching = false
      })

      this.setBreadcrumbs()
    }
  }

  handleSearch = (data) => {
    this.setState({
      fetching: true,
      trySearch: true,
      useFlexiTank: data.useFlexiTank
    })

    totalAppointments
      .searchLoadingAuthorization({
        shopId: this.getShopId(),
        loadingAuthorizationNumber: data.loadingAuthorizationNumber,
        country: data.country,
        useFlexiTank: data.useFlexiTank
      })
      .then((resp) => {
        this.setState({
          loadingAuthorization: resp.data,
          fetching: false
        })
      })
      .catch((error) => {
        this.setState({
          loadingAuthorization: null,
          fetching: false
        })
        this.searchRequestErrorHandler(error)
      })
  }

  searchRequestErrorHandler(error) {
    const { status } = error
    switch (status) {
      case 400: {
        break
      }
      case 404: {
        break
      }
      default: {
        this.props.dispatch(showMessage(this.props.translation.requestMessages.serverError, 'error'))
      }
    }
  }

  handleSave = (data) => {
    const method = data.id ? totalAppointments.updateTotalAppointment : totalAppointments.createTotalAppointment
    method(data).then(this.goBack)
  }

  goBack = () => {
    this.props.history.push(this.getShopId() ? Nav.totalAppointments(this.getShopId()) : Nav.appointmentsScheduling())
  }

  editState = (fn, cb) => this.setState(produce(fn), cb)

  getDefaultAppointmentFromLoadingAuthorization(data) {
    return {
      loadingAuthorizationNumber: data.loadingAuthorizationNumber,
      country: data.country,
      useFlexiTank: this.state.useFlexiTank
    }
  }

  setLoadingAuthorizationAndGoToForm = (data) => {
    if (!data.appointment || TotalAppointmentStatuses[data.appointment.status] === TotalAppointmentStatuses.Cancelled) {
      data.appointment = this.getDefaultAppointmentFromLoadingAuthorization(data)
    }

    this.setState({
      loadingAuthorization: data,
      currentStep: Steps.FORM,
      fetching: false
    })
  }

  renderSearch() {
    if (this.state.currentStep === Steps.SEARCH && this.state.countries) {
      return (
        <Card>
          <LoadingAuthorizationSearcher countries={this.state.countries} onSearch={this.handleSearch} />
        </Card>
      )
    }
  }

  renderSearchResult() {
    if (this.state.currentStep === Steps.SEARCH && !this.state.fetching && this.state.trySearch) {
      return (
        <Card>
          {this.state.loadingAuthorization ? (
            <LoadingAuthorizationSearchResult
              data={this.state.loadingAuthorization}
              onUpdate={this.setLoadingAuthorizationAndGoToForm}
            />
          ) : (
            <div style={{ margin: '16px' }}>{this.props.translation.authorizationResearch.authorizationNotFound}</div>
          )}
        </Card>
      )
    }
  }

  renderEditForm() {
    if (this.state.currentStep === Steps.FORM) {
      const { appointment, country, loadingAuthorizationNumber } = this.state.loadingAuthorization

      return (
        <AppointmentEdit
          country={country}
          loadingAuthorizationNumber={loadingAuthorizationNumber}
          data={appointment}
          goBack={this.goBack}
        />
      )
    }
  }

  renderLoadingAuthorization() {
    if (this.state.loadingAuthorization && this.state.currentStep === Steps.FORM) {
      return (
        <Card>
          <TotalLoadingAuthorization data={this.state.loadingAuthorization} />
        </Card>
      )
    }
  }

  renderProgress() {
    if (this.state.fetching) {
      return (
        <div style={{ textAlign: 'center', padding: 16 }}>
          <CircularProgress color="secondary" />
        </div>
      )
    }
  }

  render() {
    if (!this.props.canEditAppointments) return null
    return (
      <Container>
        {this.renderSearch()}
        {this.renderProgress()}
        {this.renderSearchResult()}
        {this.renderLoadingAuthorization()}
        {this.renderEditForm()}
      </Container>
    )
  }

  isEditMode = () => Boolean(this.getAppointmentToken()) && this.getAppointmentToken() !== 0
  getAppointmentToken = () => this.props.match.params.appointmentToken

  getShopId = () => this.props.match.params.shopId
}

const stateToProps = (state) => ({ canEditAppointments: state.user.permissions.canEditAppointments })

export default dataProvider({ shops: true })(connect(stateToProps)(translate(hideTimeZone()(SmartRdv))))
