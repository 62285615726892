import uniqueId from 'lodash/uniqueId'
import fakeLines from './fake-lines'
import fakeShops from './fake-shops'
import fakeTransportCompanies from './fake-transport-companies'

const data = [
  {
    updateTime: new Date(2020, 5, 11).getTime(),
    updateUser: 'Andrew Robinson',
    id: uniqueId(),
    lineId: fakeLines[0].id,
    shopId: fakeShops[0].id,
    date: new Date(1970, 0, 11).getTime(),
    time: {
      id: uniqueId(),
      text: '10:30 - 10:35'
    },
    name: 'John Neville',
    email: 'john@gmail.com',
    phoneNumber: '+7 911 623 4971',
    service: 'Service name',
    canUpdate: false,
    staffComments: 'Some Staff Comments',
    products: [
      {
        product: {
          name: 'SOLANE HEXAN 45',
          code: '71023',
          qty: 20.0,
          measureUnit: 'TONNES'
        },
        appointmentTime: Date.now(),
        shopName: 'Total Fluides Oudalle',
        servicePointName: 'Piste 02',
        armCode: 'BR20111 - Bac 53'
      },
      {
        product: {
          name: 'SOLANE HEXAN 45',
          code: '71023',
          qty: 20.0,
          measureUnit: 'TONNES'
        },
        appointmentTime: Date.now(),
        shopName: 'Total Fluides Oudalle',
        servicePointName: 'Piste 01',
        armCode: 'BR20113 - Bac 50'
      }
    ],
    clientName: 'Total',
    countryCode: 'FR',
    loadingAuthorizationId: 111,
    driverGender: 'Male', //0,
    driverPhoneNumber: '+33 6 55 44 33 22',
    transportCompanyName: 'XPO Logistics',
    transportCompanyEmail: 'contact@europedev.xpo.com',
    status: 'Valid', //Valid|ToBeCancelled|Cancelled|Arrived|Absent|ToBeConfirmed|Finished
    token: 'qwerty',
    files: [
      {
        id: uniqueId(),
        name: 'tam.png'
      },
      {
        id: uniqueId(),
        name: 'sam.png'
      },
      {
        id: uniqueId(),
        name: 'som.png'
      }
    ],
    createdBy: {
      user: 'User 1',
      date: Date.now()
    },
    updatedBy: {
      user: 'User 2',
      date: Date.now()
    },
    useFlexiTank: true,
    productAppointment: {
      appointmentTime: 't1',
      checkpointId: 11,
      code: 'someCode'
    },
    driverFirstName: 'DFName',
    driverLastName: 'DLName',
    staffComments: 'SComments',
    transportCompany: fakeTransportCompanies[0]
  },
  {
    updateTime: new Date(1970, 0, 11).getTime(),
    updateUser: 'Andrew Robinson',
    id: uniqueId(),
    lineId: fakeLines[0].id,
    shopId: fakeShops[0].id,
    date: Date.now(),
    time: {
      id: uniqueId(),
      text: '10:35 - 10:40'
    },
    name: 'Samuel',
    email: 'sam@gmail.com',
    phoneNumber: '+7 911 623 4971',
    service: 'Service name',
    comments: 'Comments',
    canUpdate: false,
    staffComments: 'Some Staff Comments',
    products: [
      {
        product: {
          name: 'SOLANE HEXAN 45',
          code: '71023',
          qty: 20.0,
          measureUnit: 'TONNES'
        },
        appointmentTime: Date.now(),
        shopName: 'Total Fluides Oudalle',
        servicePointName: 'Piste 02',
        armCode: 'BR20111 - Bac 53'
      }
    ],
    clientName: 'Total',
    countryCode: 'DE',
    loadingAuthorizationId: 1111,
    driverName: 'Romain',
    driverSurname: 'LAPREE',
    driverGender: 'Female', // 1,
    driverPhoneNumber: '+33 6 55 44 33 22',
    transportCompanyName: 'XPO Logistics',
    transportCompanyEmail: 'contact@europedev.xpo.com',
    status: 'Cancelled', //Valid|ToBeCancelled|Cancelled|Arrived|Absent|Finished
    cancelReason: 'Some cancel reason',
    token: 'uiop',
    createdBy: {
      user: 'User 1',
      date: Date.now()
    },
    updatedBy: {
      user: 'User 2',
      date: Date.now()
    },
    useFlexiTank: true
  }
]

export function getResponse(data) {
  return {
    data: data,
    prevDate: Date.now(),
    nextDate: Date.now(),
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export default data
