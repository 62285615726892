import React from 'react'
import { lineStaffManagement } from 'app/nav'
import { connect } from 'react-redux'
import {
  getStaffManagementRecord,
  updateStaffManagementRecord,
  createStaffManagementRecord,
  deleteStaffManagementRecord
} from 'store/actions/entity-action-creators'
import BaseEdit from 'smart/base/base-entity-edit'
import translate from 'i18n/translate'
import StaffManagementEdit from 'dumb/staff-management-edit'
import timeZoneFilter from 'smart/time-zone-filter'

class SmartCheckpointEdit extends BaseEdit {
  state = {
    data: null
  }

  identity = 'staffManagement'

  behavior = {
    create: {
      redirect: (p) => lineStaffManagement(p.shopId, p.lineId)
    },
    delete: {
      redirect: (p) => lineStaffManagement(p.shopId, p.lineId)
    },
    update: {
      redirect: (p) => lineStaffManagement(p.shopId, p.lineId)
    }
  }

  operationMessages = () => this.props.translation.checkpointOperationMessages
  checkViewPermissions = () => true

  fetchEntity(p) {
    this.props.getEntity({ managementId: p.managementId, shopId: p.shopId, lineId: p.lineId })
  }

  getBreadcrumbs(crumb, data, parents) {
    const shop = parents.shop
    const line = parents.line
    const id = data.id

    return [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shop.id], shop.name),
      crumb.lines([shop.id]),
      crumb.line([shop.id, line.id], line.name),
      crumb.staffManagement([shop.id, line.id]),
      id ? crumb.editStaffManagement() : crumb.newStaffManagement()
    ]
  }

  isNew() {
    return !this.props.match.params.managementId
  }

  renderMainForm() {
    return (
      <StaffManagementEdit
        canDelete={true}
        data={{ ...(this.state.data || (this.props.entity && this.props.entity.data)) }}
        onSave={this.handleSave}
        onDelete={this.handleDelete}
      />
    )
  }

  handleSave = (data) => {
    this.setState({ data })
    if (this.isNew()) {
      this.props.createEntity(this.processCreateData(data))
    } else {
      this.props.updateEntity(data)
    }
  }

  processCreateData(data) {
    data.lineId = this.props.match.params.lineId
    return data
  }

  handleDelete = (data) => {
    this.props.deleteEntity(data)
  }
}

const stateToProps = (state) => ({ entity: state.entity })

const actionsToProps = (dispatch) => ({
  getEntity: (data) => dispatch(getStaffManagementRecord(data)),
  updateEntity: (data) => dispatch(updateStaffManagementRecord(data)),
  createEntity: (data) => dispatch(createStaffManagementRecord(data)),
  deleteEntity: (data) => dispatch(deleteStaffManagementRecord(data)),
  dispatch
})

export default connect(stateToProps, actionsToProps)(timeZoneFilter(translate(SmartCheckpointEdit)))
