import React, { Component } from 'react'
import css from './link.module.scss'

export default class Link extends Component {
  render() {
    return (
      <a
        style={this.props.style}
        href={this.props.href}
        target={this.props.target}
        className={css.root}
        onClick={this.handleClick}
      >
        {this.props.children}
      </a>
    )
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }
}
