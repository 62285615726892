import React from 'react'
import { LineList } from 'dumb/line'
import { line, createLine, index } from 'app/nav'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import timeZoneFilter from 'smart/time-zone-filter'
import dataProvider from 'smart/isolated-data-provider'
import Card from 'dumb/card'
import { line as lineAPI } from 'provider'
import { produce } from 'immer'
import LegacyBase from '../base/legacy-base'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

class SmartLineList extends LegacyBase {
  getShopId = () => this.props.match.params.shopId

  pushPath = (path) => this.props.history.push(path)

  editState = (fn, callback) => this.setState(produce(fn), callback)

  state = {
    lines: [],
    parents: null,
    loading: false
  }

  componentDidMount() {
    this.props.dispatch(setBreadcrumbs(this.getBreadcrumbs()))

    if (this.checkViewPermissions() === false) this.pushPath(index())
    else this.fetchData()
  }

  fetchData = () => {
    this.editState((draft) => {
      draft.loading = true
    })

    lineAPI
      .getLineList(this.getShopId())
      .then((resp) => {
        this.editState((draft) => {
          draft.lines = resp.data
          draft.parents = resp.parents
          draft.loading = false
        })
      })
      .catch(() => {
        this.editState((draft) => {
          draft.loading = false
        })
        this.props.dispatch(showMessage(this.props.translation.errors.serverError, 'error'))
      })
  }

  renderContent() {
    return (
      <>
        {!this.state.loading && <Card>{this.renderActions()}</Card>}
        {this.state.lines && (
          <Card>
            <LineList data={this.state.lines} onSelect={this.handleSelect} />
          </Card>
        )}
        {this.state.loading && <CircularProgress style={{ position: 'absolute', left: '50%', marginLeft: '-20px' }} />}
      </>
    )
  }

  renderActions() {
    if (this.props.canUpdateShops === false) {
      return null
    }

    return (
      <Button style={{ margin: '0.5rem' }} startIcon={<AddIcon />} onClick={this.handleAdd}>
        {this.props.translation.lineList.addButton}
      </Button>
    )
  }

  getBreadcrumbs() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const shopId = this.getShopId()
    const shopName = this.props.getShop(shopId).name

    return [crumb.home(), crumb.shops(), crumb.shop([shopId], shopName), crumb.lines([shopId])]
  }

  checkViewPermissions = () => true

  handleAdd = () => {
    this.pushPath(createLine(this.getShopId()))
  }

  handleSelect = (data) => {
    this.pushPath(line(this.getShopId(), data.id))
  }
}

const stateToProps = (state) => ({ canUpdateShops: state.user.permissions.canUpdateShops })

export default dataProvider({ shops: true })(connect(stateToProps)(timeZoneFilter(translate(SmartLineList))))
