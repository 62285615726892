import uniqueId from 'lodash/uniqueId'
import findIndex from 'lodash/findIndex'
import fakeShops from './fake-shops'

const data = [
  {
    id: uniqueId(),
    loadingAuthorizationNumber: '1522338934',
    startValidityDate: Date.now(),
    endValidityDate: Date.now() + 1000 * 60 * 60 * 24,
    startDateIsValid: true,
    endDateIsValid: true,
    clientName: 'Total',
    countryCode: '33',
    country: 'FR',
    products: [
      {
        code: '73004',
        name: 'SPIRDANE D 60',
        qty: 24.0,
        measureUnit: 'TONNES'
      },
      {
        code: '73031',
        name: 'XYLENE.',
        qty: 22.0,
        measureUnit: 'TONNES'
      },
      {
        code: '73024',
        name: 'SOLANE HEPTANE',
        qty: 25.0,
        measureUnit: 'TONNES'
      },
      {
        code: '73430',
        name: 'KERDANE',
        qty: 20.0,
        measureUnit: 'TONNES'
      }
    ],
    appointment: {
      id: 'c7fa02798c1c4b74ae3a64a90dc9530e',
      shopId: 3,
      code: 13331,
      date: 1566432000000,
      productAppointments: [
        {
          product: {
            name: 'SOLANE HEXAN 45',
            code: '71023',
            qty: 20.0,
            measureUnit: 'TONNES'
          },
          appointmentTime: 1566478800000,
          shopName: 'Total Fluides Oudalle',
          servicePointName: 'Piste 01',
          armCode: 'BR20102 - Bac 55'
        },
        {
          product: {
            name: 'SOLANE HEXAN 42',
            code: '71020',
            qty: 24.0,
            measureUnit: 'TONNES'
          },
          appointmentTime: 1566478800000,
          shopName: 'Total Fluides Oudalle',
          servicePointName: 'Piste 02',
          armCode: 'BR20111 - Bac 53'
        }
      ],
      status: 'Finished',
      placeUtcOffset: 7200000, //new prop
      driverName: 'test',
      driverSurname: 'test',
      transportCompanyName: 'TestDSI',
      staffComments: 'test',
      useFlexiTank: false
    }
  },
  {
    id: uniqueId(),
    loadingAuthorizationNumber: '2012931912',
    startValidityDate: Date.now(),
    endValidityDate: Date.now() + 1000 * 60 * 60 * 24,
    startDateIsValid: true,
    endDateIsValid: true,
    clientName: 'Total',
    countryCode: '33',
    country: 'FR',
    products: [
      {
        code: '73004',
        name: 'SPIRDANE D 60',
        qty: 24.0,
        measureUnit: 'TONNES'
      }
    ],
    appointment: {
      id: 'c7fa02798c1c4b74ae3a64a90dc9530e',
      date: 1566432000000,
      code: 13331,
      shopId: 3,
      productAppointments: [
        {
          product: {
            name: 'SOLANE HEXAN 45',
            code: '71023'
          },
          appointmentTime: 1566478800000,
          shopName: 'Total Fluides Oudalle',
          servicePointName: 'Piste 01',
          armCode: 'BR20102 - Bac 55'
        }
      ],
      status: 'Finished',
      placeUtcOffset: 7200000, //new prop
      driverName: 'test',
      driverSurname: 'test',
      transportCompanyName: 'TestDSI',
      staffComments: 'test',
      useFlexiTank: false
    }
  },
  {
    id: uniqueId(),
    loadingAuthorizationNumber: '1339',
    startValidityDate: Date.now(),
    endValidityDate: Date.now() + 1000 * 60 * 60 * 24,
    clientName: 'Total',
    countryCode: '33',
    country: 'FR'
  }
]

export function getResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export function update(entity) {
  const index = findIndex(data, { id: entity.id })
  data[index] = entity
}

export default data
