import uniqueId from 'lodash/uniqueId'
import fakeUsers from 'provider/fake-data/fake-users'
import fakeCheckpoints from 'provider/fake-data/fake-checkpoints'
import fakeShops from './fake-shops'

const data = [
  {
    id: uniqueId(),
    checkpointId: fakeCheckpoints[0].id,
    startDate: Date.now(),
    startTime: {
      minutes: '01',
      hours: '00'
    },
    endDate: Date.now(),
    endTime: {
      minutes: '01',
      hours: '00'
    },
    lastUpdate: {
      user: fakeUsers[0],
      date: Date.now()
    }
  },
  {
    id: uniqueId(),
    checkpointId: fakeCheckpoints[1].id,
    startDate: Date.now(),
    startTime: {
      minutes: '01',
      hours: '00'
    },
    endDate: Date.now(),
    endTime: {
      minutes: '01',
      hours: '00'
    },
    lastUpdate: {
      user: fakeUsers[0],
      date: Date.now()
    }
  },
  {
    id: uniqueId(),
    checkpointId: fakeCheckpoints[2].id,
    startDate: Date.now(),
    startTime: {
      minutes: '01',
      hours: '00'
    },
    endDate: Date.now(),
    endTime: {
      minutes: '01',
      hours: '00'
    },
    lastUpdate: {
      user: fakeUsers[0],
      date: Date.now()
    }
  }
]

export function getResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export default data
