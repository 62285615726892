import { Tooltip } from '@material-ui/core'
import styled from 'styled-components'

const HelpContainer = styled.div`
  display: flex;
  margin-bottom: -50px;
  padding: 0.5rem;
  width: -webkit-fill-available;
  justify-content: flex-end;
`

const PreMessage = styled.pre`
  white-space: pre-wrap;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 18px;
`

const MessageTemplateFormRoot = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
`

const TooltipStyled = styled(Tooltip)`
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  opacity: 6%;
`

export { HelpContainer, PreMessage, MessageTemplateFormRoot, TooltipStyled }
