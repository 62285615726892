export function index() {
  return '/'
}

export function login() {
  return '/login'
}

export function loginClassic() {
  return '/login-classic'
}

export function shops() {
  return '/shops'
}

export function shop(shopId: string) {
  return `${shops()}/${shopId}`
}

export function createShop() {
  return `${shops()}/create`
}

export function editShop(shopId: string) {
  return `${shop(shopId)}/edit`
}

export function shopDaysOff(shopId: string) {
  return `${shop(shopId)}/daysOff`
}

export function lines(shopId: string) {
  return `${shop(shopId)}/lines`
}

export function line(shopId: string, lineId: string) {
  return `${lines(shopId)}/${lineId}`
}

export function createLine(shopId: string) {
  return `${lines(shopId)}/create`
}

export function editLine(shopId: string, lineId: string) {
  return `${line(shopId, lineId)}/edit`
}

export function createCheckpoint(shopId: string, lineId: string) {
  return `${checkpoints(shopId, lineId)}/create`
}

export function editCheckpoint(shopId: string, lineId: string, checkpointId: string) {
  return `${checkpoints(shopId, lineId)}/${checkpointId}/edit`
}

export function checkpointMonitoring(shopId: string, lineId: string, checkpointId: string) {
  return `${checkpoints(shopId, lineId)}/${checkpointId}/monitoring`
}

export function checkpoints(shopId: string, lineId?: string) {
  return lineId ? `${lines(shopId)}/${lineId}/checkpoints` : `${shop(shopId)}/checkpoints`
}

export function createLineStaffManagement(shopId: string, lineId: string) {
  return `${lineStaffManagement(shopId, lineId)}/create`
}

export function editLineStaffManagement(shopId: string, lineId: string, managementId: string) {
  return `${lineStaffManagement(shopId, lineId)}/${managementId}`
}

export function lineStaffManagement(shopId: string, lineId: string) {
  return `${lines(shopId)}/${lineId}/staffManagement`
}

export function users() {
  return '/users'
}

export function createUser() {
  return `${users()}/create`
}

export function editUser(userId: string) {
  return `${users()}/${userId}/edit`
}

export function messageTemplates(shopId?: string, lineId?: string) {
  if (shopId && lineId) {
    return `${lines(shopId)}/${lineId}/templates`
  }

  if (shopId) {
    return `${shop(shopId)}/templates`
  }

  return `/templates`
}

export function translations() {
  return '/translations'
}

export function createTranslation() {
  return '/translations/create'
}

export function editTranslation(translationId: string) {
  return `/translations/${translationId}`
}

export function reports() {
  return '/reports'
}

export function report(reportId: string) {
  return `${reports()}/${reportId}`
}

export function unavailabilityAndAppointments(shopId: string) {
  return `${shop(shopId)}/unavailabilityAndAppointments`
}

export function checkpointsUnavailability(shopId: string) {
  return `${shop(shopId)}/checkpointsUnavailability`
}

export function createCheckpointUnavailability(shopId: string, checkpointId?: string) {
  return checkpointId
    ? `${shop(shopId)}/checkpointsUnavailability/create/${checkpointId}`
    : `${shop(shopId)}/checkpointsUnavailability/create`
}

export function editCheckpointUnavailability(shopId: string, checkpointId: string) {
  return `${shop(shopId)}/checkpointsUnavailability/${checkpointId}`
}

export function loadingArmsUnavailability(shopId: string) {
  return `${shop(shopId)}/loadingArmsUnavailability`
}

export function createLoadingArmUnavailability(shopId: string) {
  return `${shop(shopId)}/loadingArmsUnavailability/create`
}

export function editLoadingArmUnavailability(shopId: string, checkpointId: string) {
  return `${shop(shopId)}/loadingArmsUnavailability/${checkpointId}`
}

export function genericProducts() {
  return `/genericProducts`
}

export function genericProductEdit(productId: string) {
  return `${genericProducts()}/${productId}/edit`
}

export function genericProductCreate() {
  return `/create`
}

export function loadingArms(shopId: string) {
  return `${shop(shopId)}/loadingArms`
}

export function loadingArmEdit(shopId: string, productId: string) {
  return `${loadingArms(shopId)}/${productId}/edit`
}

export function loadingArmCreate(shopId: string) {
  return `${loadingArms(shopId)}/create`
}

export function transportCompanies() {
  return `/transportCompanies`
}

export function transportCompanyEdit(companyId: string) {
  return `${transportCompanies()}/${companyId}/edit`
}

export function transportCompanyCreate() {
  return `${transportCompanies()}/create`
}

export function transportCompanyUser(companyId: string, userId: string) {
  return `${transportCompanyEdit(companyId)}/user/${userId}`
}

export function transportCompanyUserCreate(companyId: string) {
  return `${transportCompanyEdit(companyId)}/user/create`
}

export function loadingAuthorizations() {
  return `/loadingAuthorizations`
}

export function totalAppointments(shopId: string) {
  return `${unavailabilityAndAppointments(shopId)}/totalAppointments`
}

export function totalAppointmentsToCancel(shopId: string) {
  return `${unavailabilityAndAppointments(shopId)}/totalAppointmentsToCancel`
}

export function totalAppointmentEdit(shopId: string, appointmentToken: string) {
  return `${totalAppointments(shopId)}/${appointmentToken}`
}

export function totalAppointmentCreate(shopId: string) {
  return `${totalAppointments(shopId)}/create`
}

export function appointmentsScheduling(shopId: string) {
  return `${shop(shopId)}/appointmentsScheduling`
}

export function appointmentsSchedulingCreate(shopId: string) {
  return `${appointmentsScheduling(shopId)}/create`
}

export function appointmentsSchedulingEdit(shopId: string, appointmentToken: string) {
  return `${appointmentsScheduling(shopId)}/${appointmentToken}`
}

export function productsUnavailability() {
  return `/productsUnavailability`
}

export function editProductsUnavailability(productId: string) {
  return `${productsUnavailability()}/${productId}`
}

export function createProductsUnavailability() {
  return `${productsUnavailability()}/create`
}
