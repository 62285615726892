import React, { Component } from 'react'
import { List } from 'dumb/list'

export default class ShopList extends Component {
  render() {
    return (
      <List
        data={this.props.data}
        onSelect={(data) => this.props.onSelect(data)}
        dataConverter={(item) => ({ id: item.id, primaryText: item.name, secondaryText: item.description })}
      />
    )
  }
}
