import { Translations } from './translation-types'

function pluralization(n: number, forms: [string, string]) {
  const formIndex = Math.abs(n) > 1 ? 1 : 0
  return forms[formIndex]
}

const tr: Translations = {
  common: {
    title: 'Total: Administration',
    shop: 'Site',
    brand: 'Marque',
    line: "File d'attente",
    checkpoint: 'Piste',
    genericProduct: 'Produit générique',
    loadingArm: 'Bras',
    gender: 'Civilité',
    genderValue(gender) {
      return gender === 1 ? 'Madame' : 'Monsieur'
    },
    booleanValue(value) {
      return value ? 'Oui' : 'Non'
    },
    female: 'Madame',
    male: 'Monsieur',
    customer: 'Client',
    personsCount(count) {
      return `${count} ${pluralization(count, ['personne', 'personnes'])}`
    },
    search: 'Recherche',
    upload: 'Télétransmettre',
    add: 'Ajouter',
    all: 'Toutes',
    yes: 'Oui',
    no: 'Non',
    none: 'Aucune',
    unavailable: 'Indisponible',
    unavailability: 'Indisponibilités',
    taken: 'Occupé',
    date: 'Date',
    time: 'Heure'
  },
  weekdays: {
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche'
  },
  requestMessages: {
    serverError: "Erreur d'interrogation du serveur",
    serverSuccess: 'Succès'
  },
  login: {
    email: 'Courriel',
    password: 'Mot de passe',
    logIn: 'Connexion',
    forgot: 'Mot de passe oublié ?',
    noEmail: 'Server error',
    updatePassword: 'Le nouveau mot passe vous a été envoyé par courriel',
    authError: "Erreur d'authentification",
    emailError: (email = '') =>
      `Aucun compte utilisateur avec ce courriel ${email} n'a été trouvé. Merci de contacter un administrateur`,
    conditional: `En cliquant sur "Me connecter", j'accepte les Conditions Générales d'Utilisation du site Book'n Load. Pour les consulter, cliquez`,
    here: 'ici',
    link: '/docs/CGU_Booknload_BO_FR.pdf'
  },
  addList: {
    selectItem: 'Sélectionnez un élément'
  },
  entityView: {
    editButton: 'Modifier',
    duplicateButton: 'Dupliquer',
    lastUpdate(firstName, secondName, date) {
      return `Dernière mise à jour par ${firstName} ${secondName} le ${date}`
    }
  },
  entityEdit: {
    createButton: 'Créer',
    updateButton: 'Confirmer',
    deleteButton: 'Supprimer',
    cancelButton: 'Annuler',
    confirmDelete: 'Etes-vous certain de vouloir supprimer ?',
    chars: 'caractères',
    advancedGroup: 'Advancé',
    yes: 'Oui',
    no: 'Non'
  },
  entityList: {
    loadMore: 'Suivant'
  },
  breadcrumbs: {
    home: 'Accueil',
    shop: 'Site',
    shops: 'Sites',
    newShop: 'Nouveau site',
    editShop: 'Modifier',
    lines: "Files d'attente",
    line: "File d'attente",
    newLine: "Nouvelle file d'attente",
    editLine: 'Modifier',
    checkpoints: 'Pistes',
    newCheckpoint: 'Nouvelle piste',
    editCheckpoint: 'Modifier la piste',
    users: 'Utilisateurs',
    newUser: 'Nouvel utilisateur',
    editUser: "Modifier l'utilisateur",
    reports: 'Rapports',
    messageTemplates: 'Modèles de messages',
    daysOff: 'Jours de fermeture',
    staffManagement: 'Disponibilités',
    editStaffManagement: 'Modifier',
    newStaffManagement: 'Nouveau',
    appointments: 'Rendez-vous',
    newAppointment: 'Nouveau',
    editAppointment: 'Modifier',
    counter: 'Capteur',
    newCounter: 'Nouveau Capteur',
    editCounter: 'Modifier',
    translations: 'Traductions',
    newTranslation: 'Nouveau',
    editTranslation: 'Modifier',
    settings: 'Paramètres',

    unavailabilityAndAppointments: 'Indisponibilités et rdv',

    genericProducts: 'Produits génériques',
    newGenericProduct: 'Nouveau produit générique',
    editGenericProduct: 'Modifier le produit générique',

    loadingArms: 'Bras',
    newLoadingArm: 'Nouveau bras',
    editLoadingArm: 'Modifier le bras',

    transportCompanies: 'Transporteurs',
    newTransportCompanyUser: 'Nouvel utilisateur',

    checkpointsUnavailability: 'Indisponibilités des pistes',
    newCheckpointUnavailability: 'Nouveau',
    editCheckpointUnavailability: 'Modifier',

    loadingArmsUnavailability: 'Indisponibilités des bras',
    newLoadingArmUnavailability: 'Nouveau',
    editLoadingArmUnavailability: 'Modifier',

    loadingAuthorizations: 'Autorisations de chargement',
    totalAppointments: 'Rendez-vous',

    appointmentsScheduling: 'Planning des rendez-vous',

    productsUnavailability: 'Indisponibilités des produits génériques',
    editProductUnavailability: 'Modifier',
    newProductUnavailability: 'Nouveau',

    new: 'Nouveau',
    edit: 'Modifier'
  },
  home: {
    reports: 'Rapports',
    shops(count) {
      return count === 1 ? 'Site' : 'Sites'
    },
    users: 'Utilisateurs',
    messageTemplates: 'Modèles de messages',
    translations: 'Traductions',
    genericProducts: 'Produits génériques',
    transportCompanies: 'Transporteurs',
    loadingAuthorizations: 'Autorisations de chargement',
    appointmentsScheduling: 'Planning des rendez-vous',
    productsUnavailabilities: 'Indisponibilités des produits génériques'
  },
  shopList: {
    addButton: 'Nouveau site'
  },
  shopView: {
    lines(count) {
      return count === 1 ? "File d'attente" : "Files d'attente"
    },
    checkpoints(count) {
      return count === 1 ? 'Piste' : 'Pistes'
    },
    name: 'Nom',
    description: 'Description',
    position: 'Position',
    resetStatistics: 'Réinitialiser les statistiques',
    daysOff: 'Jours de fermeture',

    gpsPosition: 'Position GPS',

    address: 'Adresse',
    city: 'Ville',
    zipCode: 'Code postal',
    location: 'Coordonnées',
    moreInfo: 'Détails',
    timeZone: 'Fuseau horaire',
    expeditionEmail: 'Courriel expéditeur',
    messageTemplates: 'Modèles de messages',
    loadingArms: 'Bras',
    unavailabilityAndAppointments: 'Indisponibilités et rdv',
    limitInDays: "Nombre de jours d'ouverture du planning",
    limitInDaysToFollowUp: "Nombre de jours avant courriel de relance d'un rendez-vous à confirmer",
    limitInDaysToCancel: "Nombre de jours avant annulation d'un rendez-vous non confirmé"
  },
  unavailabilityAndAppointments: {
    pointOfServicesUnavailability: 'Indisponibilités des pistes',
    loadingArmsUnavailability: 'Indisponibilités des bras',
    appointments: 'Rendez-vous',
    appointmentsToCancel: 'Rendez-vous à annuler'
  },
  genericProductEdit: {
    code: 'Code',
    name: 'Nom'
  },
  genericProductOperationMessages: {
    getError: 'Le produit générique renseigné est invalide',
    createSuccess: 'Le produit générique a été créé avec succès',
    createError: 'Impossible de créer le produit générique',
    deleteSuccess: 'Le produit générique a été supprimé avec succès',
    deleteError: 'Impossible de supprimer le produit générique',
    updateSuccess: 'Le produit générique a été modifié avec succès',
    updateError: 'Impossible de modifier le produit générique'
  },
  genericProductList: {
    add: 'Ajouter',
    search: 'Recherche'
  },
  loadingArmsList: {
    add: 'Ajouter',
    search: 'Recherche',
    genericProducts: 'Produits génériques : ',
    servicePoints: 'Pistes : '
  },
  loadingArmOperationMessages: {
    getError: 'Le bras renseigné est invalide',
    createSuccess: 'Le bras a été créé avec succès',
    createError: 'Impossible de créer Le bras',
    deleteSuccess: 'Le bras a été supprimé avec succès',
    deleteError: 'Impossible de supprimer Le bras',
    updateSuccess: 'Le bras a été modifié avec succès',
    updateError: 'Impossible de modifier Le bras'
  },
  uploadOperationMessages: {
    uploadSuccess: 'La mise à jour a été effectuée avec succès',
    uploadError: "Une erreur à été constatée lors de l'import",
    importFinish: "L'import est terminé.",
    lanSuccess: 'lignes importées.',
    lanFailed: 'lignes en erreur.'
  },
  loadingArmEdit: {
    code: 'Code',
    name: 'Nom',
    genericProducts: 'Produits génériques',
    servicePoints: 'Pistes',
    addGenericProduct: 'Ajouter',
    addServicePoint: 'Ajouter',
    priority: 'Priorité de suggestion de bras tout site confondu (valeur plus grande signifie la priorité plus forte)'
  },
  transportCompanyEdit: {
    name: 'Nom',
    code: 'Code SAP',
    isActive: 'Est actif',
    addUser: 'Nouvel utilisateur',
    email: 'Courriel',
    language: 'Langue',
    companyCreatedMessage: 'Le transporteur a été créé',
    companyDeletedMessage: 'Le transporteur a été supprimé',
    companyUpdatedMessage: 'Le transporteur a été mis à jour',
    alreadyExistedEmailMessage: 'Une autre société de transport a déjà été enregistrée avec ce courriel'
  },
  transportCompanyUserEdit: {
    name: 'Nom',
    email: 'Courriel',
    isActive: 'Est actif',
    resetPassword: 'Réinitialiser le mot de passe',
    lastConnection: 'Dernière connexion',
    language: 'Langue',
    userCreatedMessage: "L'utilisateur a bien été créé",
    userCreatedErrorMessage: "Erreur lors de la création de l'utilisateur",
    userDeletedMessage: "L'utilisateur a bien été supprimé",
    userUpdateMessage: "L'utilisateur a été mis à jour",
    userUpdateErrorMessage: "Erreur lors de la mise à jour de l'utilisateur",
    userPasswordResetMessage: "Le mot de passe de l'utilisateur a été réinitialisé"
  },
  loadingAuthorizations: {
    lanAndClient: (lan, client, countryCode) => `${lan} pour ${client}, ${countryCode}`,
    products: 'Produits :',
    singleProducts: 'Produit :',
    product: (qty, measureUnit, name, code) => `${qty} ${measureUnit} de ${name} / ${code}`,
    appointmentTr: 'RDV',
    appOn: 'du',
    appIn: 'à',
    appA: 'à',
    appAt: 'à',
    validityPeriod(startValidityDate, endValidityDate) {
      return `Valide entre ${startValidityDate} et ${endValidityDate}`
    },
    relatedCommercialProducts(commercialProducts) {
      const separatedCP = commercialProducts.join(', ')
      return `Produits commerciaux concernés : ${separatedCP}`
    },
    deliveredCustomer(clientName, countryCode) {
      return `Client livré : ${clientName}, ${countryCode}`
    },
    appointmentTaken: 'RDV pris pour le',
    appointmentTakenRange(appointmentDate, appointmentHour) {
      return `${appointmentDate}, à ${appointmentHour}`
    },
    soloProductInfo(product, appointmentDate) {
      return `- à ${appointmentDate} pour le produit générique ${product}`
    },
    noAppointmentYet: 'Sans rendez-vous',
    search: 'Recherche',
    lan: 'LAN :',
    genericProd: (count) => (count > 1 ? `Produits génériques :` : `Produit générique :`),
    productsConcerned: (count) => (count > 1 ? `Produits commerciaux :` : `Produit commercial :`)
  },
  totalAppointments: {
    flexiTank: 'Est flexitank',
    orderQuantity: 'Quantité commandée',
    appIn: 'à',
    appA: 'à',
    appAt: 'à',
    forCustomer: 'pour',
    ofDelivery: 'de',
    define: 'Définir',
    deliveredCustomer: 'Client livré',
    relatedGenericProducts: (count) => (count < 2 ? 'Produit' : 'Produits'),
    appointmentTaken(appointmentNumber) {
      return `№ ${appointmentNumber}, le `
    },
    appointmentTakenTime(appointmentDate, appointmentHour, shopName, service, arm, single) {
      const translation = [
        `Le ${appointmentDate}`,
        single && ` à ${appointmentHour}`,
        single && ` à ${shopName}`,
        single && ` \n ${service}`,
        single && arm && ` - ${arm}`
      ].filter(Boolean) as string[]

      return translation
    },
    soloProductInfo(product, appointmentDate) {
      return `- à ${appointmentDate} pour le produit ${product}`
    },
    loadingAuthorisationNumber: 'Autorisation de chargement',
    transportCompany: 'Transporteur',
    driver: 'Conducteur',
    state: 'Statut',
    reason: 'Raison',
    internalComment: 'Commentaire interne',
    declareFinished: 'Terminé',
    declareArrived: 'Honoré',
    declareAbsent: 'Absent',
    update: 'Modifier',
    cancel: 'Annuler',
    search: 'Recherche',
    cancelAppointmentMessage: "Confirmez-vous l'annulation du rendez-vous ?",
    notFound: 'Aucun rendez-vous trouvé',
    rdvCode: 'Code RDV',
    sapCommand: 'Commande SAP',
    createAppointment: 'Créer un rendez-vous',
    createdBy(userName, date) {
      return `Créé par ${userName} le ${date}`
    },
    updatedBy(userName, date) {
      return `Mis à jour par ${userName} le ${date}`
    },
    dateBy(userName, date) {
      return `le ${date} par ${userName}`
    },
    attachmentsOptional: 'Pièces attachées',
    updateCheckpointSuccess: `L'indisponibilité de la piste a été mise à jour avec succès`,
    updateCheckpointError: `La mise à jour de l'indisponibilité de la piste a échoué`,
    createCheckpointSuccess: `L'indisponibilité de la piste a été créée avec succès`,
    createCheckpointError: `La création de l'indisponibilité de la piste a échoué`,
    deleteCheckpointSuccess: `L'indisponibilité de la piste a été supprimée`,
    deleteCheckpointError: `La suppression de l'indisponibilité de la piste a échoué`
  },
  totalAppointmentStatuses: {
    valid: 'Confirmé',
    toBeCancelled: 'A annuler',
    cancelled: 'Annulé',
    arrived: 'Honoré',
    absent: 'Absent',
    toBeConfirmed: 'En attente de confirmation',
    finished: 'Terminé',
    unknown: 'Inconnu'
  },
  unavailabilityEdit: {
    comments: 'Commentaire',
    beginningOfUnavailability: "Début d'indisponibilité",
    endOfUnavailability: "Fin d'indisponibilité"
  },
  totalPlanning: {
    unavailabilityOfServicePoint: 'Indisponibilité de la piste'
  },
  shopEdit: {
    name: 'Nom',
    namePlaceholder: 'Nom du site',
    description: 'Description',
    descriptionPlaceholder: 'Description du site, adresse, téléphone',
    latitude: 'Latitude',
    latitudePlaceholder: 'Latitude du site',
    longitude: 'Longitude',
    longitudePlaceholder: 'Longitude du site',
    brand: 'Marque',
    timeZone: 'Fuseau horaire',
    daysOff: 'Jours de fermeture',
    addDay: 'Ajouter un jour de fermeture',
    add: 'Ajouter',
    repeatDay: 'Se répète chaque année',
    period: 'Période',
    save: 'Sauvegarder',
    address: 'Adresse',
    addressPlaceholder: 'Adresse',
    city: 'Ville',
    cityPlaceholder: 'Ville',
    zipCode: 'Code postal',
    zipCodePlaceholder: 'Code postal',
    expeditionEmail: 'Courriel expéditeur',
    expeditionEmailPlaceholder: 'Courriel',
    expeditionPassword: 'Mot de passe courriel expéditeur',
    expeditionPasswordPlaceholder: 'Mot de passe',
    expedition: 'Envoi des courriels',
    location: 'Coordonnées',
    exportDataIsAvailable: "Un modèle d'export de données est disponible",
    limitInDays: "Nombre de jours d'ouverture du planning",
    limitInDaysToFollowUp: "Nombre de jours avant courriel de relance d'un rendez-vous à confirmer",
    limitInDaysToCancel: "Nombre de jours avant annulation d'un rendez-vous non confirmé",
    limitInDaysPlaceholder: 'Jours',
    limitInDaysToFollowUpPlaceholder: 'Jours',
    limitInDaysToCancelPlaceholder: 'Jours',
    weekdays: 'Jours de la semaine',
    selectNotWorkingWeekdays: 'Sélectionnez les jours de la semaine chômés',
    externalCode: 'Code externe'
  },
  shopOperationMessages: {
    getError: 'Le site renseigné est invalide',
    createSuccess: 'Le site a été créé avec succès',
    createError: 'Impossible de créer le site',
    deleteSuccess: 'Le site a été supprimé avec succès',
    deleteError: 'Impossible de supprimer le site',
    updateSuccess: 'Le site a été modifié avec succès',
    updateError: 'Impossible de modifier le site',
    resetStatisticsSuccess: 'Les statistiques ont été remises à zéro avec succès',
    resetStatisticsError: 'Impossible de remettre à zéro les statistiques',
    updateCheckpointSuccess: `L'indisponibilité de la piste a été mise à jour avec succès`,
    updateCheckpointError: `La mise à jour de l'indisponibilité de la piste a échoué`,
    createCheckpointSuccess: `L'indisponibilité de la piste a été créée avec succès`,
    createCheckpointError: `La création de l'indisponibilité de la piste a échoué`,
    deleteCheckpointSuccess: `L'indisponibilité de la piste a été supprimée`,
    deleteCheckpointError: `La suppression de l'indisponibilité de la piste a échoué`,
    updateLoadingArmSuccess: `L'indisponibilité du bras a été mise à jour avec succès`,
    updateLoadingArmError: `La mise à jour de l'indisponibilité du bras a échoué`,
    createLoadingArmSuccess: `L'indisponibilité du bras a été créée avec succès`,
    createLoadingArmError: `La création de l'indisponibilité du bras a échoué`,
    deleteLoadingArmSuccess: `L'indisponibilité du bras a été supprimée`,
    deleteLoadingArmError: `La suppression de l'indisponibilité du bras a échoué`
  },
  lineList: {
    addButton: "Nouvelle file d'attente"
  },
  lineView: {
    name: 'Nom',
    category: 'Catégorie',
    serviceTime: 'Temps moyen de prise en charge',
    timeToReach: 'Délai moyen pour rejoindre la piste',
    maxTimeToReach: 'Délai maximum pour rejoindre la piste',
    time(minutes) {
      return `${minutes} ${pluralization(minutes, ['minute', 'minutes'])}`
    },
    seconds(seconds) {
      return `${seconds} ${pluralization(seconds, ['seconde', 'secondes'])}`
    },
    checkpoints(count) {
      return count === 1 ? 'Piste' : 'Pistes'
    },
    openingHour: 'Heure de démarrage',
    closingHour: 'Heure de clôture',
    manageAppointments: 'Gérer des rendez-vous',
    manageAppointmentsValue(value) {
      return value ? 'Oui' : 'Non'
    },
    maxSimultaneous: 'Nombre maximum de participants acceptés simultanément',
    maxSimultaneousValue(count) {
      return `${count} ${pluralization(count, ['personne', 'personnes'])}`
    },
    modes: 'Modes',
    shortName: 'Nom court',
    messageTemplates: 'Modèles de messages',
    monitoring: 'Surveillance des réservations',
    smsCredits: 'Crédits SMS',
    staffManagement: 'Disponibilités',
    allowFutureAppointments: 'Prise de rendez-vous pour un autre jour',
    attachIcsFile: 'Joindre un fichier ics au courriel de confirmation',
    authoriseAppointmentOnWebPortal: 'Autoriser la prise de rendez-vous le jour même sur le portail web',
    blindCopyEmails: 'Courriels à mettre en copie cachée pour toutes les confirmations des rendez-vous',
    nonStopService: 'Service sans arrêt',
    nonStopServiceValue(value) {
      return value ? 'Oui' : 'Non'
    },
    nearServicePointDistance: 'Distance de proximité de la piste pour le GPS',
    nearServicePointDistanceValue(value) {
      return pluralization(value, ['mètre', 'mètres'])
    },
    parameters: 'Paramètres',
    appointmentForNextDay: 'Prise de rendez-vous pour un autre jour',
    openingHours: 'Horaires',
    durations: 'Temps',
    servicesCollection: 'Services proposés'
  },
  lineEdit: {
    name: 'Nom',
    namePlaceholder: "Nom de la file d'attente",
    category: 'Catégorie',
    serviceTime: 'Temps moyen de prise en charge',
    timeToReach: 'Délai moyen pour rejoindre la piste',
    maxTimeToReach: 'Délai maximum pour rejoindre la piste',
    timePlaceholder: 'minutes',
    secondsPlaceholder: 'secondes',
    timeHint(minutes) {
      return pluralization(minutes, ['minute', 'minutes'])
    },
    secondsHint(second) {
      return pluralization(second, ['seconde', 'secondes'])
    },
    openingHour: 'Heure de démarrage (changement appliqué à partir de demain)',
    closingHour: 'Heure de clôture (changement appliqué à partir de demain)',
    manageAppointments: 'Gérer des rendez-vous',
    maxSimultaneous: 'Nombre maximum de participants acceptés simultanément',
    maxSimultaneousHint(count) {
      return pluralization(count, ['personne', 'personnes'])
    },
    maxSimultaneousPlaceholder: 'Personnes',
    modes: 'Modes',
    shortName: 'Nom court',
    shortNamePlaceholder: 'Nom court',
    allowFutureAppointments: 'Prise de rendez-vous pour un autre jour',
    servicesCollection: 'Services proposés',
    serviceName: 'Nom de service',
    serviceDuration: 'Durée de service',
    addService: 'Ajouter un service',
    attachIcsFile: 'Joindre un fichier ics au courriel de confirmation',
    authoriseAppointmentOnWebPortal: 'Autoriser la prise de rendez-vous le jour même sur le portail web',
    blindCopyEmails: 'Courriels à mettre en copie cachée pour toutes les confirmations des rendez-vous',
    blindCopyEmailsPlaceholder: 'Courriels',
    nearServicePointDistance: 'Distance de proximité de la piste pour le GPS',
    nearServicePointDistancePlaceholder: 'Mètres',
    nearServicePointDistanceHint(value) {
      return pluralization(value, ['mètre', 'mètres'])
    },
    clientsNotificationsGroup: 'Notifications du client',
    beforeTheCall: 'Avant appel',
    beforeTheCallTime: 'Avant',
    whenCalled: "Lors de l'appel",
    whenServiceStartedByAgent: "Lors de démarrage du service par l'agent",
    whenServiceEndedByAgent: "Lors de fin du service par l'agent",
    nonStopService: 'Service sans arrêt',
    parameters: 'Paramètres',
    appointmentForNextDay: 'Prise de rendez-vous pour un autre jour',
    openingHours: 'Horaires',
    durations: 'Temps'
  },
  lineSignUpModes: {
    never: 'Jamais',
    wifi: 'Si connecté par WIFI',
    always: 'Toujours'
  },
  lineModes: {
    mobileApp: 'Smartphone',
    sms: 'SMS'
  },
  lineOperationMessages: {
    getError: "La file d'attente renseignée est invalide",
    createSuccess: "La file d'attente a été créée avec succès",
    createError: "Impossible de créer la file d'attente",
    deleteSuccess: "La file d'attente a été supprimée avec succès",
    deleteError: "Impossible de supprimer la file d'attente",
    updateSuccess: "La file d'attente a été modifiée avec succès",
    updateError: "Impossible de modifier la file d'attente"
  },
  checkpointStatus: {
    finished(time, firstName, secondName) {
      return `Fermé à ${time} par ${firstName} ${secondName}`
    },
    finishing(time, firstName, secondName) {
      return `En train de fermer depuis ${time} par ${firstName} ${secondName}`
    },
    started(time, firstName, secondName) {
      return `Démarré à ${time} par ${firstName} ${secondName}`
    },
    starting(time, firstName, secondName) {
      return `En train de démarrer depuis ${time} par ${firstName} ${secondName}`
    },
    paused(time, firstName, secondName) {
      return `Mise en pause à ${time} par ${firstName} ${secondName}`
    }
  },
  checkpointList: {
    addButton: 'Nouvelle piste',
    monitoring: 'Surveillance',
    empty: 'Aucun piste'
  },
  checkpointEdit: {
    name: 'Nom',
    namePlaceholder: 'Nom de la piste',
    description: 'Description',
    descriptionPlaceholder: "Description du positionnement de la piste (affichée à l'ecran)",

    loadingOfFluids: 'Chargement des produits en vrac',
    loadingFluidsFlexitank: 'Chargement des produits en vrac par flexitank',
    loadingOfConventionalCharges: 'Chargement des produits conditionnés'
  },
  checkpointOperationMessages: {
    getError: 'La piste renseignée est invalide',
    createSuccess: 'La piste a été créé avec succès',
    createError: 'Impossible de créer la piste',
    deleteSuccess: 'La piste a été supprimée avec succès',
    deleteError: 'Impossible de supprimer la piste',
    updateSuccess: 'La piste a été modifiée avec succès',
    updateError: 'Impossible de modifier la piste'
  },
  checkpointMonitoring: {
    applyButton: 'Appliquer',
    closingTime: 'Heure de clôture',
    startTime: 'Heure de démarrage',
    time(minutes) {
      return `${minutes} ${pluralization(minutes, ['minute', 'minutes'])}`
    },
    apply: 'Appliquer',
    updateCheckpointHostSuccessMessage: 'Paramètres de la piste appliqués avec succès',
    updateCheckpointHostFailureMessage: "Erreur lors de l'application des paramètres de la piste",
    availableAnyHour: 'Toujours disponible quand ouverte',
    normalLineHoursAppliedMorning: "Les horaires normaux de la file d'attente seront appliqués le matin"
  },
  userList: {
    addButton: 'Nouvel utilisateur',
    withInactive: 'Avec les inactifs',
    search: 'Rechercher'
  },
  userEdit: {
    firstName: 'Prénom',
    firstNamePlaceholder: "Prénom de l'utilisateur",
    secondName: 'Nom',
    secondNamePlaceholder: "Nom de l'utilisateur",
    email: 'Courriel',
    emailPlaceholder: "Courriel de l'utilisateur",
    currentPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmation du mot de passe',
    password: 'Mot de passe',
    passwordPlaceholder: 'Mot de passe',
    interfaceLanguage: 'Langue',
    addShopButton: 'Ajouter le site',
    shops: 'Sites accessibles',
    changePassword: 'Modifier le mot de passe',
    rights: 'Droits et paramètres',
    isAdmin: 'Administrateur',
    isActive: 'Est actif',
    canManageUserAccounts: 'Gérer les comptes des utilisateurs',
    canAddAndDeleteShops: 'Créer et supprimer des sites',
    canUpdateShops: 'Mettre à jour le site',
    manageAppointments: 'Gérer les rendez-vous',
    manageTransportCompanies: 'Gérer les transporteurs',
    canOpenAndCloseCheckpoints: 'Ouvrir et fermer les points de service',
    ruleSecurePasswordInputMessage:
      "Le mot de passe doit être d'une longueur de 8 caractères minimum et contenir au moins un caractère minuscule, un majuscule, un chiffre et un caractère spécial",
    canUpdateServicePointStartAndEndHours: "Mettre à jour l'heure de début et de fin de service",
    countries: 'Pays (codes ISO à deux caractères séparés par points virgules)',
    allCountries: 'Tous les pays',
    igg: 'IGG',
    limitedView: 'Limité à la visualisation de plannings, autorisations de chargement, export de données'
  },
  userOperationMessages: {
    getError: "L'utilisateur renseigné est invalide",
    createSuccess: "L'utilisateur a été créé avec succès",
    createError: "Impossible de créer l'utilisateur",
    deleteSuccess: "L'utilisateur a été supprimé avec succès",
    deleteError: "Impossible de supprimer l'utilisateur",
    updateSuccess: "L'utilisateur a été modifié avec succès",
    updateError: "Impossible de modifier l'utilisateur",
    passwordNotSecure: 'Saisissez votre nouveau mot de passe',
    iggError: 'La valeur IGG est déjà utilisée',
    emailError: 'Ce courriel est déjà utilisé',
    error: 'Server error'
  },
  userInAppBar: {
    exitButton: 'Quitter',
    settingsButton: 'Réglages'
  },
  colorLabel: {
    emptyPlaceholder: 'Non'
  },
  fileUploader: {
    uploadButton: 'Télétransmettre'
  },
  reportsFilter: {
    startDate: 'Date de début',
    endDate: 'Date de fin',
    upperDate: 'Date limite',
    date: 'Date'
  },
  customer: {
    customer: 'Utilisateur',
    applicationUsage: "Utilisation de l'application",
    gender: 'Civilité',
    email: 'Courriel',
    phone: 'Téléphone',
    device: 'Caractéristiques du smartphone',
    application: 'Application',
    age: 'Age',
    applicationUsageValue(count, firstUse, lastUse) {
      return `${count} fois entre ${firstUse} et ${lastUse}`
    },
    genderValue(gender) {
      return gender === 1 ? 'Madame' : 'Monsieur'
    },
    customerValue(id, name) {
      return `Utilisateur ${id}${name ? ', ' + name : ''}`
    }
  },
  time: {
    sec(val) {
      return `${val} sec`
    },
    min(val) {
      return `${val} min`
    },
    seconds(val) {
      return `${val} ${pluralization(val, ['seconde', 'secondes'])}`
    },
    minutes(val) {
      return `${val} ${pluralization(val, ['minute', 'minutes'])}`
    },
    days(val) {
      return `${val} ${pluralization(val, ['jour', 'jours'])}`
    },
    daysHint(val) {
      return pluralization(val, ['jour', 'jours'])
    },
    minutesHint(minutes) {
      return pluralization(minutes, ['minute', 'minutes'])
    },
    minutesPlaceholder: 'minutes'
  },
  conj: {
    and: 'et'
  },
  monitoring: {
    autoRefresh: 'Rafraichissement automatique',
    notActive: 'La surveillance démarrera\navec la connexion du premier utilisateur',
    lineName(name) {
      return `File d'attente : ${name}`
    },
    linePeopleCount(count) {
      return `${count} ${pluralization(count, ['personne', 'personnes'])} dans la file d'attente`
    },
    lineServedPeopleCount(count) {
      return `${count}  ${pluralization(count, ['personne', 'personnes'])} servies aujourd'hui`
    },
    lineAvApproachTime: "Temps moyen d'approche",
    lineAvLargeBagService: 'Temps moyen de service du grand panier',
    lineAvSmallBagService: 'Temps moyen de service du petit panier',
    lineLowerAlertTime: 'Alerte basse',
    lineUpperAlertTime: 'Alerte haute',
    lineOverchargedAlertTime: 'Alerte surcharge',
    lineWaitingTime: "Temps d'attente",
    timeslotState: {
      served: 'Servi',
      serving: 'En cours',
      late: 'En retard',
      waiting: 'En attente'
    },
    lineState: {
      late(time) {
        return time === 1 ? "en retard d'une minute" : `en retard de ${time} minutes`
      },
      onTime() {
        return "à l'heure"
      },
      inAdvance(time) {
        return time === 1 ? "en avance d'une minute" : `en avance de ${time} minutes`
      }
    },
    lineReservationCapacity(capacity, total) {
      return `Libres ${total - capacity}, réservées ${capacity} sur ${total}`
    },
    positionId(id) {
      return `ID ${id}`
    },
    positionStatusJoined(time) {
      return `Joint à ${time}`
    },
    positionStatusCalled(time, checkpoint) {
      return `Appelé à ${checkpoint} à ${time}`
    },
    positionStatusMoving(time, checkpoint) {
      return `Se déplace à ${checkpoint} à partir de ${time}`
    },
    positionStatusNearCheckpoint(time, checkpoint) {
      return `Arrivé à ${checkpoint} à ${time}`
    },
    positionStatusInService(time, checkpoint) {
      return `En service à ${checkpoint} à partir de ${time}`
    },
    positionSmallBasket(size) {
      return `Jusqu'à ${size} articless`
    },
    positionLargeBasket(size) {
      return `Plus de ${size} articles`
    },
    positionPeopleAhead(count) {
      return `${count} ${pluralization(count, ['personne', 'personnes'])} devant`
    },
    positionExpectedServiceTime: 'Temps de service estimé',
    positionServiceTime: 'Temps de service',
    positionWaitingServiceTime: 'En attente de démarrer le service',
    positionWaitingToCallTime: "En attente d'appel",
    positionJoinedTime: 'Joint',
    positionCalledTime: 'Appelé',
    positionApproachedTime: 'Approché',
    positionLastRequest: 'Dernière info reçue',
    positionAsapWaitingMode: 'ASAP',
    positionAppointmentWaitingMode(from, to) {
      return `Pris rendez-vous entre ${from} et ${to}`
    },
    noPositions: "La file d'attente est vide",
    checkpointStatusStarted(dateTime) {
      return `Ouverte le ${dateTime}`
    },
    checkpointStatusFinished(dateTime) {
      return `Fermée à ${dateTime}`
    },
    checkpointStatusPaused(dateTime) {
      return `Mis en pause à ${dateTime}`
    },
    checkpointFinishing: 'En train de fermer',
    checkpointStarting: 'En train de démarrer',
    checkpointWaitingTime: "Temps d'attente",
    checkpointServedPeopleCount() {
      return 'Personnes servies'
    },
    checkpointPeopleMovingCount() {
      return 'En approche'
    },
    checkpointPeopleServingCount() {
      return 'En service'
    },
    people(count) {
      return `${count} ${pluralization(count, ['personne', 'personnes'])}`
    },
    peopleWithMobileApp: 'Smartphones',
    peopleWithPaperTicket: 'Tickets',
    peopleWithSms: 'SMS',
    peopleWithNfcCard: 'NFC',
    peopleTotal: 'Total',
    reservation: 'Réservation',
    reservationGroups: 'RDV',
    moreInfo: 'Détails',
    offlineTime(time) {
      return `Depuis ${time}`
    },
    reservationCapacity(capacity, total) {
      return `Réservé ${capacity} sur ${total}`
    }
  },
  messageTemplates: {
    description: 'Description',
    language: 'Langue',
    messageType: 'Modèle de message',
    pluralization: 'Pluriel et singulier',
    text: 'Texte',
    textPlaceholder: 'Texte',
    save: 'Confirmer',
    keywords: 'Champs de fusion',
    success: 'Message mis à jour avec succès',
    error: 'Erreur lors de la mise à jour du message',
    help: 'Aide',
    applyGlobal: 'Appliquer au message modèle',
    parentMessage: 'Message modèle parent '
  },
  staffManagementEdit: {
    startDate: 'Date du changement de disponibilité',
    startHour: 'Début',
    endHour: 'Fin',
    comments: 'Commentaire',
    commentsPlaceholder: 'commentaire'
  },
  staffManagement: {
    startDate: 'Date du changement de disponibilité',
    startHour: 'Début',
    endHour: 'Fin',
    comments: 'Commentaire',
    addRecord: 'Ajouter',
    uploadSuccess: 'Disponibilité importée avec succès',
    uploadError: "Erreur d'importation de disponibilité",
    upload: 'Télétransmettre les disponibilités'
  },
  staffManagementOperationMessages: {
    getError: 'Impossible de lire',
    createSuccess: 'Créé avec succès',
    createError: 'Echec de création',
    deleteSuccess: 'Suppression réussie',
    deleteError: 'Echec de suppression',
    updateSuccess: 'Mise à jour réussie',
    updateError: 'Echec de mise à jour'
  },
  appointment: {
    line: "File d'attente",
    date: 'Date',
    hour: 'Plage horaire',
    name: 'Nom',
    firstName: 'Prénom',
    lastName: 'Nom',
    details: 'Détails',
    email: 'Courriel',
    phone: 'Téléphone',
    service: 'Service',
    comments: 'Commentaire',
    staffComments: 'Commentaire interne',
    shop: 'Site',
    datePlaceholder: 'Date',
    startHour: 'Début',
    endHour: 'Fin',
    namePlaceholder: 'Nom',
    emailPlaceholder: 'Courriel',
    phonePlaceholder: 'Téléphone',
    servicePlaceholder: 'Service',
    commentsPlaceholder: 'Commentaire',
    staffCommentsPlaceholder: 'Commentaire interne',
    edit: 'Modifier',
    next: 'Suivant',
    create: 'Créer',
    lineAndService: "File d'attente et service",
    dateAndTime: 'Plage horaire',
    customer: 'Utilisateur',
    confirmation: 'Confirmation',
    newCustomer: 'Nouveau client'
  },
  appointments: {
    prevDay: 'Jour précédent',
    nextDay: 'Le prochain jour',
    add: 'Ajouter un rendez-vous'
  },
  appointmentOperationMessages: {
    getError: 'Impossible de lire le rendez-vous',
    createSuccess: 'Rendez-vous créé avec succès',
    createError: 'Echec de création du rendez-vous',
    deleteSuccess: 'Le rendez-vous est supprimé',
    deleteError: 'Echec de suppression du rendez-vous',
    updateSuccess: 'Le rendez-vous est mise à jour',
    updateError: 'Echec de la mise à jour du rendez-vous',
    timeRangeNoAvailable:
      "La plage horaire demandée n'est plus disponible. Une autre personne vient de faire la même réservation ou la disponibilité du site a changé. Veuillez en sélectionner une autre plage horaire"
  },
  groupsReservations: {
    uploadInfo(firstName, secondName, dateTime) {
      return `Importé le ${dateTime} par ${firstName} ${secondName}`
    },
    uploadSuccess: 'Réservations importées avec succès',
    uploadDuplicateError: "Impossible d'importer le fichier à cause des codes de réservation dupliqués.",
    uploadError: 'Erreur lors de la télétransmission du fichier',
    forceUpdateGroupsReservations: 'Synchroniser la journée en cours',
    forceUpdateGroupsReservationsSuccessMessage: 'La synchronisation a été réalisée avec succès',
    forceUpdateGroupsReservationsFailureMessage: 'Erreur lors de la synchronisation'
  },
  exportData: {
    line: "File d'attente",
    shop: 'Site',
    allShops: 'Toutes',
    allLines: 'Toutes',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    exportButton: 'Exporter'
  },
  counterOperationMessages: {
    getError: 'Le capteur renseigné est invalide',
    createSuccess: 'Le capteur a été créé avec succès',
    createError: 'Impossible de créer le capteur',
    deleteSuccess: 'Le capteur a été supprimé avec succès',
    deleteError: 'Impossible de supprimer le capteur',
    updateSuccess: 'Le capteur a été modifié avec succès',
    updateError: 'Impossible de modifier le capteur'
  },
  translationList: {
    addButton: 'Nouvelle traduction'
  },
  translationEdit: {
    sourceText: 'Libellé source',
    translationText: 'Traduction',
    brand: 'Marque',
    language: 'Langue',
    addTranslation: 'Ajouter une traduction',
    deleteTitle: 'Supprimer cette traduction',
    translationsFor: 'Traductions pour'
  },
  translationOperationMessages: {
    getError: 'La traduction renseignée est invalide',
    createSuccess: 'La traduction a été créée avec succès',
    createError: 'Impossible de créer la traduction',
    deleteSuccess: 'La traduction a été supprimée avec succès',
    deleteError: 'Impossible de supprimer la traduction',
    updateSuccess: 'La traduction a été modifiée avec succès',
    updateError: 'Impossible de modifier la traduction'
  },
  loadingAuthorisationsCountriesEdit: {
    loadingAuthorisationsCountries: 'Pays des autorisations de chargement',
    countries: 'Pays (codes ISO à deux caractères séparés par points virgules)',
    save: 'Confirmer'
  },
  authorizationResearch: {
    research: 'Recherche',
    search: 'Rechercher',
    yourAuthorizationNumber: "Votre numéro d'autorisation de chargement",
    customerCountryDelivered: 'Pays de client livré',
    loadingWithFlexitank: 'Chargement avec flexitank',
    update: 'Mettre à jour',
    create: 'Créer',
    dateAndHour: 'Date et heure',
    attachedDocuments: 'Pièces attachées',
    securityInformation: 'Informations de sûreté',
    rdvShortInfo(startDate, startDateHour) {
      return `RDV déjà pris le ${startDate}, le ${startDateHour}`
    },
    authorizationFound: 'Autorisation trouvée',
    authorizationNotFound: "L'autorisation de chargement n'a pas été trouvée",
    statuses: {
      toBeConfirmed: 'en attente de confirmation',
      absent: 'absent',
      arrived: 'honoré',
      cancelled: 'annulé',
      toBeCancelled: 'à annuler',
      valid: 'confirmé',
      finished: 'terminé'
    },
    errorParse: "Erreur lors de l'analyse des produits"
  },
  totalAuthorization: {
    authorizationNumber: "Votre numéro d'autorisation de chargement",
    validityPeriod: 'Période de validité',
    productsConcerned: (num) => (num < 2 ? 'Produit commercial' : 'Produits commerciaux'),
    genericProducts: (num) => (num < 2 ? 'Produit' : 'Produits'),
    customerDelivered: 'Client livré',
    period(startDate, endDate) {
      return `Entre ${startDate} et ${endDate}`
    },
    timeWithDate(date, hour) {
      return `le ${date} à ${hour}`
    },
    startDateInvalidText:
      'Désolé, votre autorisation de chargement dépasse la date limite de prise de rendez-vous. Veillez retenter quelques jours plus tard.',
    endDateInvalidText:
      'Attention, certaines dates de la période de validité de votre autorisation de chargement ne sont pas encore disponibles. Par conséquent, la proposition des dates disponibles sera limitée.',
    loadingAutorisationExpired: 'Votre autorisation de chargement est expirée.'
  },
  totalAppointmentEdit: {
    date: 'Date',
    time: 'Heure',
    comment: 'Commentaires',
    transportCompany: 'Nom du transporteur',
    driverFirstName: 'Prénom du conducteur',
    driverLastName: 'Nom du conducteur',
    place: 'Site',
    checkpoint: 'Piste',
    state: 'Statut',
    noDateAvailable: "Aucune date n'est disponible. Merci de contacter votre donneur d'ordre",
    duing(hours) {
      return `durant ${hours} ${pluralization(hours, ['heure', 'heures'])}`
    }
  },
  countriesCodes: {
    ad: 'Andorre',
    ae: 'Emirats arabes unis',
    af: 'Afghanistan',
    ag: 'Antigua et Barbuda',
    ai: 'Anguilla',
    al: 'Albanie',
    an: 'Netherlands Antilles',
    am: 'Arménie',
    ao: 'Angola',
    aq: 'Antarctique',
    ar: 'Argentine',
    as: 'Samoa américaines',
    at: 'Autriche',
    au: 'Australie',
    aw: 'Aruba',
    ax: 'Åland, Îles',
    az: 'Azerbaïdjan',
    ba: 'Bosnie-Herzégovine',
    bb: 'Barbade',
    bd: 'Bangladesh',
    be: 'Belgique',
    bf: 'Burkina Faso',
    bg: 'Bulgarie',
    bh: 'Bahrein',
    bi: 'Burundi',
    bj: 'Bénin',
    bl: 'Saint-Barthélemy',
    bm: 'Bermudes',
    bn: 'Brunéi Darussalam',
    bo: 'Bolivie (État plurinational de)',
    bq: 'Bonaire, Saint-Eustache et Saba',
    br: 'Brésil',
    bs: 'Bahamas',
    bt: 'Bhoutan',
    bv: 'Bouvet, Ile',
    bw: 'Botswana',
    by: 'Bélarus',
    bz: 'Bélize',
    ca: 'Canada',
    cc: 'Cocos/Keeling (Îles)',
    cd: 'Congo, République démocratique du',
    cf: 'République centrafricaine',
    cg: 'Congo',
    ch: 'Suisse',
    ci: "Côte d'Ivoire",
    ck: 'Cook, Iles',
    cl: 'Chili',
    cm: 'Cameroun',
    cn: 'Chine',
    co: 'Colombie',
    cr: 'Costa Rica',
    cu: 'Cuba',
    cv: 'Cabo Verde',
    cw: 'Curaçao',
    cx: 'Christmas, île',
    cy: 'Chypre',
    cz: 'Tchèque, République',
    de: 'Allemagne',
    dj: 'Djibouti',
    dk: 'Danemark',
    dm: 'Dominique',
    do: 'Dominicaine, République',
    dz: 'Algérie',
    ec: 'Equateur',
    ee: 'Estonie',
    eg: 'Egypte',
    eh: 'Sahara occidental',
    er: 'Erythrée',
    es: 'Espagne',
    et: 'Ethiopie',
    fi: 'Finlande',
    fj: 'Fidji',
    fk: 'Falkland/Malouines (Îles)',
    fm: 'Micronésie, Etats Fédérés de',
    fo: 'Féroé, îles',
    fr: 'France',
    ga: 'Gabon',
    gb: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
    gd: 'Grenade',
    ge: 'Géorgie',
    gf: 'Guyane française',
    gg: 'Guernesey',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gl: 'Groenland',
    gm: 'Gambie',
    gn: 'Guinée',
    gp: 'Guadeloupe',
    gq: 'Guinée équatoriale',
    gr: 'Grèce',
    gs: 'Géorgie du sud et les îles Sandwich du sud',
    gt: 'Guatemala',
    gu: 'Guam',
    gw: 'Guinée-Bissau',
    gy: 'Guyana',
    hk: 'Hong Kong',
    hm: 'Heard, Ile et MacDonald, îles',
    hn: 'Honduras',
    hr: 'Croatie',
    ht: 'Haïti',
    hu: 'Hongrie',
    id: 'Indonésie',
    ie: 'Irlande',
    il: 'Israël',
    im: 'Île de Man',
    in: 'Inde',
    io: "Indien (Territoire britannique de l'océan)",
    iq: 'Iraq',
    ir: "Iran, République islamique d'",
    is: 'Islande',
    it: 'Italie',
    je: 'Jersey',
    jm: 'Jamaïque',
    jo: 'Jordanie',
    jp: 'Japon',
    ke: 'Kenya',
    kg: 'Kirghizistan',
    kh: 'Cambodge',
    ki: 'Kiribati',
    km: 'Comores',
    kn: 'Saint-Kitts-et-Nevis',
    kp: 'Corée, République populaire démocratique de',
    kr: 'Corée, République de',
    kw: 'Koweït',
    ky: 'Caïmans, Iles',
    kz: 'Kazakhstan',
    la: 'Laos',
    lb: 'Liban',
    lc: 'Sainte-Lucie',
    li: 'Liechtenstein',
    lk: 'Sri Lanka',
    lr: 'Libéria',
    ls: 'Lesotho',
    lt: 'Lituanie',
    lu: 'Luxembourg',
    lv: 'Lettonie',
    ly: 'Libye',
    ma: 'Maroc',
    mc: 'Monaco',
    md: 'Moldova, République de',
    me: 'Monténégro',
    mf: 'Saint-Martin (partie française)',
    mg: 'Madagascar',
    mh: 'Marshall, Iles',
    mk: 'Macédoine du Nord',
    ml: 'Mali',
    mm: 'Myanmar',
    mn: 'Mongolie',
    mo: 'Macao',
    mp: 'Mariannes du nord, Iles',
    mq: 'Martinique',
    mr: 'Mauritanie',
    ms: 'Montserrat',
    mt: 'Malte',
    mu: 'Maurice',
    mv: 'Maldives',
    mw: 'Malawi',
    mx: 'Mexique',
    my: 'Malaisie',
    mz: 'Mozambique',
    na: 'Namibie',
    nc: 'Nouvelle-Calédonie',
    ne: 'Niger',
    nf: 'Norfolk, Ile',
    ng: 'Nigéria',
    ni: 'Nicaragua',
    nl: 'Pays-Bas',
    no: 'Norvège',
    np: 'Népal',
    nr: 'Nauru',
    nu: 'Niue',
    nz: 'Nouvelle-Zélande',
    om: 'Oman',
    pa: 'Panama',
    pe: 'Pérou',
    pf: 'Polynésie française',
    pg: 'Papouasie-Nouvelle-Guinée',
    ph: 'Philippines',
    pk: 'Pakistan',
    pl: 'Pologne',
    pm: 'Saint-Pierre-et-Miquelon',
    pn: 'Pitcairn',
    pr: 'Porto Rico',
    ps: 'Palestine, Etat de',
    pt: 'Portugal',
    pw: 'Palaos',
    py: 'Paraguay',
    qa: 'Qatar',
    re: 'Réunion',
    ro: 'Roumanie',
    rs: 'Serbie',
    ru: 'Russie, Fédération de',
    rw: 'Rwanda',
    sa: 'Arabie Saoudite',
    sb: 'Salomon, Iles',
    sc: 'Seychelles',
    sd: 'Soudan',
    se: 'Suède',
    sg: 'Singapour',
    sh: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    si: 'Slovénie',
    sj: 'Svalbard et île Jan Mayen',
    sk: 'Slovaquie',
    sl: 'Sierra Leone',
    sm: 'Saint-Marin',
    sn: 'Sénégal',
    so: 'Somalie',
    sr: 'Suriname',
    ss: 'Soudan du Sud',
    st: 'Sao Tomé-et-Principe',
    sv: 'El Salvador',
    sx: 'Saint-Martin (partie néerlandaise)',
    sy: 'République arabe syrienne',
    sz: 'Swaziland',
    tc: 'Turks-et-Caïcos (Îles)',
    td: 'Tchad',
    tf: 'Terres australes françaises',
    tg: 'Togo',
    th: 'Thaïlande',
    tj: 'Tadjikistan',
    tk: 'Tokelau',
    tl: 'Timor-Leste',
    tm: 'Turkménistan',
    tn: 'Tunisie',
    to: 'Tonga',
    tr: 'Turquie',
    tt: 'Trinité-et-Tobago',
    tv: 'Tuvalu',
    tw: 'Taïwan, Province de Chine',
    tz: 'Tanzanie, République unie de',
    ua: 'Ukraine',
    ug: 'Ouganda',
    uk: 'Royaume-Uni',
    um: 'Îles mineures éloignées des Etats-Unis',
    us: "Etats-Unis d'Amérique",
    uy: 'Uruguay',
    uz: 'Ouzbékistan',
    va: 'Saint-Siège',
    vc: 'Saint-Vincent-et-les-Grenadines',
    ve: 'Venezuela (République bolivarienne du)',
    vg: 'Îles vierges britanniques',
    vi: 'Îles vierges des Etats-Unis',
    vn: 'Viet Nam',
    vu: 'Vanuatu',
    wf: 'Wallis et Futuna',
    ws: 'Samoa',
    xx: 'Pays inconnu',
    ye: 'Yémen',
    yt: 'Mayotte',
    za: 'Afrique du Sud',
    zm: 'Zambie',
    zw: 'Zimbabwe',
    zz: 'Pays multiples'
  },
  errors: {
    serverError: 'Server error'
  },
  accessMessages: {
    notAuthorised: 'Accès non autorisé'
  },
  datePicker: {
    ok: 'Ok',
    cancel: 'Annuler'
  },
  timePicker: {
    ok: 'Ok',
    cancel: 'Annuler',
    clear: 'Vider'
  }
}

export default tr
