import React from 'react'
import { connect } from 'react-redux'
import * as Nav from 'app/nav'
import translate from 'i18n/translate'
import timeZoneFilter from 'smart/time-zone-filter'
import Container from 'dumb/container'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { total } from 'provider'
import EditForm from './edit-form'
import { produce } from 'immer'
import CircularProgress from '@material-ui/core/CircularProgress'

class SmartGenericProductUnavailabilityEdit extends React.Component {
  static contextTypes = {
    router: () => null
  }

  state = {
    genericProducts: null,
    entity: null,
    operation: false,
    loading: false
  }

  editState = (fn, cb = () => {}) => this.setState(produce(fn), cb)

  componentDidMount() {
    this.editState((draft) => {
      draft.loading = true
    })

    Promise.all([
      total.getGenericProducts(),
      total.getGenericProductUnavailability({ recordId: this.getRecordId() || '' })
    ]).then((resp) => {
      const genericProducts = resp[0].data
      const entity = resp[1]

      this.editState(
        (draft) => {
          draft.genericProducts = genericProducts
          draft.loading = false
          draft.entity = entity
        },
        () => this.setBreadcrumbs(entity)
      )
    })
  }

  setBreadcrumbs() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const recordId = this.getRecordId()

    let crumbs = [
      crumb.home(),
      crumb.productsUnavailability(),
      recordId ? crumb.editProductUnavailability() : crumb.newProductUnavailability()
    ]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  shouldNotRenderForm() {
    return this.state.operation || !this.state.entity || !this.state.genericProducts
  }

  render() {
    if (this.state.loading) {
      return <CircularProgress style={{ marginTop: '100px' }} color="secondary" />
    }

    if (this.shouldNotRenderForm()) {
      return null
    }

    return (
      <Container>
        <EditForm
          data={{ ...this.state.entity.data }}
          genericProducts={this.state.genericProducts}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          canDelete={true}
          canEdit={this.props.canEditShop}
        />
      </Container>
    )
  }

  handleSave = (data) => {
    this.editState((draft) => {
      draft.operation = true
      draft.loading = true
    })

    const { serverError, serverSuccess } = this.props.translation.requestMessages

    if (this.getRecordId()) {
      total
        .updateGenericProductUnavailability(data)
        .then(() => {
          this.props.dispatch(showMessage(serverSuccess, 'success'))
          this.pushPath(Nav.productsUnavailability())
          this.editState((draft) => {
            draft.loading = false
          })
        })
        .catch(() => {
          this.props.dispatch(showMessage(serverError, 'error'))
          this.editState((draft) => {
            draft.loading = false
          })
        })
    } else {
      total
        .createGenericProductUnavailability(data)
        .then(() => {
          this.props.dispatch(showMessage(serverSuccess, 'success'))
          this.pushPath(Nav.productsUnavailability())
          this.editState((draft) => {
            draft.loading = false
          })
        })
        .catch(() => {
          this.props.dispatch(showMessage(serverError, 'error'))
          this.editState((draft) => {
            draft.loading = false
          })
        })
    }
  }

  handleDelete = (data) => {
    this.editState((draft) => {
      draft.operation = true
      draft.loading = true
    })

    const { serverError, serverSuccess } = this.props.translation.requestMessages

    total
      .deleteGenericProductUnavailability(data)
      .then(() => {
        this.props.dispatch(showMessage(serverSuccess, 'success'))
        this.pushPath(Nav.productsUnavailability())
        this.editState((draft) => {
          draft.loading = false
        })
      })
      .catch(() => {
        this.props.dispatch(showMessage(serverError, 'error'))
        this.editState((draft) => {
          draft.loading = false
        })
      })
  }

  pushPath(path) {
    this.props.history.push(path)
  }

  getRecordId() {
    return this.props.match.params.recordId
  }
}

const stateToProps = (state) => ({ canEditShop: state.user.permissions.canEditShop })

export default connect(stateToProps)(timeZoneFilter(translate(SmartGenericProductUnavailabilityEdit)))
