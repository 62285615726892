import React from 'react'
import { ShopList } from 'dumb/shop'
import translate from 'i18n/translate'
import { connect } from 'react-redux'
import Card from 'dumb/card'
import { FormActionGroup } from 'dumb/form'
import { createShop, shop } from 'app/nav'
import dataProvider from 'smart/isolated-data-provider'
import { withRouter } from 'react-router-dom'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'

class HomeShops extends React.Component {
  static contextTypes = {
    router: () => null
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.shops !== this.props.shops
  }

  render() {
    return (
      <Card>
        <ShopList data={this.props.getShops()} onSelect={(data) => this.pushPath(shop(data.id))} />
        {this.renderActions()}
      </Card>
    )
  }

  renderActions() {
    if (!this.props.canAddShop) return null

    return (
      <FormActionGroup>
        <Button startIcon={<AddIcon />} onClick={() => this.pushPath(createShop())}>
          {this.props.translation.shopList.addButton}
        </Button>
      </FormActionGroup>
    )
  }

  pushPath = (path) => this.props.history.push(path)
}

const stateToProps = (state) => ({ canAddShop: state.user.permissions.canAddShop })

export default dataProvider({ shops: true })(connect(stateToProps)(translate(withRouter(HomeShops))))
