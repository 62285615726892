import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type MessageTemplateState = {
  fetching: boolean
  data: any | null
}

const initialState: MessageTemplateState = {
  fetching: false,
  data: null
}

const messageTemplateSlice = createSlice({
  name: 'messageTemplates',
  initialState,
  reducers: {
    setListRequest: (state) => {
      state.fetching = true
    },
    setListSuccess: (state, action: PayloadAction<any>) => {
      state.fetching = false
      state.data = action.payload
    },
    invalidateList: (state) => {
      state.fetching = false
      state.data = null
    }
  }
})

export const { setListRequest, setListSuccess, invalidateList } = messageTemplateSlice.actions
export default messageTemplateSlice.reducer
