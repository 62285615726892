import { useEffect, useRef, useState } from 'react'
import { Form, FormGroup } from 'dumb/form'
import Card from 'dumb/card'
import Dropdown from 'dumb/dropdown'
import Text from 'dumb/text'
import {
  Button,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
  Typography,
  Box,
  TextField
} from '@material-ui/core'
import { Code as CodeIcon, HelpOutline as HelpIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { MessageTemplateFormRoot, PreMessage, TooltipStyled } from './styled'
import { MessageTemplatesFormProps } from './types'
import { useTranslation } from 'i18n/translate'

function MessageTemplatesForm(props: MessageTemplatesFormProps) {
  const { tr } = useTranslation()

  const [data, setData] = useState(props.data)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [parentMessageCard, setParentMessageCard] = useState(!props.data.text && !!props.parentMessage)
  const [expanded, setExpanded] = useState<number | false>(false)
  const [itemMessageTemplateSelected, setItemMessageTemplateSelected] = useState<string | null>(null)

  const select = useRef<{ start?: number; end?: number }>({})

  useEffect(() => {
    setData(props.data)
    setParentMessageCard(!props.data.text && !!props.parentMessage)
  }, [props.data])

  useEffect(() => {
    setItemMessageTemplateSelected(props.messageType || null)

    const selectedMessageTypeGroup = props.config.messageTypes.find((mt) =>
      mt.items.find((it) => it.value === props.messageType)
    )

    setExpanded(selectedMessageTypeGroup ? props.config.messageTypes.indexOf(selectedMessageTypeGroup) : false)
  }, [])

  function handleAccordionItemClick(value: string) {
    return () => {
      const newData = { ...data }
      newData.messageType = value
      setData(newData)
      setItemMessageTemplateSelected(value)
      props.onMessageTypeChange(value)
    }
  }

  function handleTextChange(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const newData = { ...data }
    newData.text = ev.target.value
    setData(newData)
  }

  function handleTextSelect(ev: any) {
    select.current = { start: ev.target.selectionStart, end: ev.target.selectionEnd }
  }

  function handleMenuItemClick(keyword: string) {
    return () => {
      let text = data.text || ''
      const selectValue = select.current
      const proKeyword = `{{${keyword}}}`

      if (text.length === 0) {
        text = `${proKeyword} `

        if (!select.current) {
          select.current = {}
        }

        select.current.start = text.length
        select.current.end = text.length
      } else {
        if (select && (selectValue.start || selectValue.start === 0)) {
          const beforeInsert = text.substring(0, selectValue.start)
          const afterInsert = text.substring(selectValue.end || 0, text.length)
          text = `${beforeInsert}${proKeyword} ${afterInsert}`
          select.current.start = `${beforeInsert}${proKeyword} `.length
          select.current.end = `${beforeInsert}${proKeyword} `.length
        } else {
          text = `${text}${proKeyword} `
          select.current.start = text.length
          select.current.end = text.length
        }
      }

      const newData = { ...data }
      newData.text = text
      setData(newData)
    }
  }

  function handleGlobalTemplateApply() {
    const newData = { ...data }
    newData.text = props.parentMessage?.text || ''
    setData(newData)
    setParentMessageCard(false)
  }

  return (
    <MessageTemplateFormRoot>
      <Card style={{ flex: 1, margin: 0 }}>
        <Form>
          <FormGroup>
            <Dropdown
              value={props.language}
              data={props.config.languages}
              label={tr.messageTemplates.language}
              dataConverter={(item) => ({ value: item.value, text: item.name })}
              onChange={props.onLanguageChange}
            />
          </FormGroup>
          <FormGroup>
            <Text type="caption">{tr.messageTemplates.messageType}</Text>
            {props.config.messageTypes.map(({ name, items, description }, key) => (
              <Accordion
                key={key}
                expanded={expanded === key}
                onChange={(_, isExpanded: boolean) => setExpanded(isExpanded ? key : false)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ height: '4rem' }}>
                  <Typography style={{ fontSize: '13px', fontWeight: '100' }}>{name}</Typography>
                  {description && (
                    <TooltipStyled
                      arrow
                      title={description}
                      leaveTouchDelay={3000}
                      enterTouchDelay={0}
                      onTouchStart={(event) => event.persist()}
                      onTouchStartCapture={(event) => event.persist()}
                    >
                      <HelpIcon fontSize="small" style={{ opacity: 0.56, marginLeft: '0.5rem' }} />
                    </TooltipStyled>
                  )}
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column', padding: '0' }}>
                  <List>
                    {items.map(({ value, name, description }, key) => (
                      <MenuItem
                        key={key}
                        selected={itemMessageTemplateSelected === value}
                        onClick={handleAccordionItemClick(value)}
                      >
                        <ListItemText primary={name} style={{ whiteSpace: 'normal' }} />
                        {description && (
                          <TooltipStyled
                            arrow
                            title={description}
                            leaveTouchDelay={3000}
                            enterTouchDelay={0}
                            onTouchStart={(event) => event.persist()}
                            onTouchStartCapture={(event) => event.persist()}
                          >
                            <HelpIcon fontSize="small" style={{ opacity: 0.56, marginLeft: '0.5rem' }} />
                          </TooltipStyled>
                        )}
                      </MenuItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </FormGroup>
        </Form>
      </Card>
      <Box minWidth="340px" flex={1}>
        <Card>
          <Form>
            <FormGroup style={{ display: 'flex', alignItems: 'flex-end', position: 'relative' }}>
              <TextField
                style={{ flex: 1 }}
                label={tr.messageTemplates.text}
                placeholder={tr.messageTemplates.textPlaceholder}
                value={data.text || ''}
                onChange={handleTextChange}
                onSelect={handleTextSelect}
                maxRows={15}
                fullWidth
                InputLabelProps={{ shrink: true }}
                multiline
                disabled={props.isFetching}
              />
              <Box position="sticky" bottom={0}>
                <Tooltip title={tr.messageTemplates.keywords} placement="top">
                  <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                    <CodeIcon />
                  </IconButton>
                </Tooltip>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} elevation={2}>
                  {Object.keys(props.config.keywords).map((keyword: any) => (
                    <MenuItem
                      key={keyword.key || keyword}
                      value={keyword.key || keyword}
                      style={{ lineHeight: '32px', minHeight: 32 }}
                      onClick={handleMenuItemClick(keyword.key || keyword)}
                    >
                      <Box flex={1}>{keyword.key || keyword}</Box>
                      {props.config.keywords[keyword] && (
                        <TooltipStyled
                          arrow
                          title={props.config.keywords[keyword]}
                          leaveTouchDelay={3000}
                          enterTouchDelay={0}
                        >
                          <HelpIcon fontSize="small" style={{ opacity: 0.56, marginLeft: '0.5rem' }} />
                        </TooltipStyled>
                      )}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </FormGroup>
          </Form>
        </Card>
        {props.canSave && (
          <Box style={{ gap: '0.5rem' }} display="flex" marginBottom="1rem">
            <Button size="small" color="primary" onClick={() => props.onSave(data)} disabled={props.isFetching}>
              {tr.messageTemplates.save}
            </Button>
            {props.parentMessage && (
              <Button
                size="small"
                onClick={() => {
                  setParentMessageCard((curr) => !curr)
                }}
                disabled={props.isFetching}
              >
                {tr.messageTemplates.parentMessage}
              </Button>
            )}
          </Box>
        )}
        {parentMessageCard && props.parentMessage && props.canSave && (
          <Card>
            <FormGroup>
              <Box fontSize="12px" style={{ opacity: '0.56' }}>
                {tr.messageTemplates.parentMessage}
              </Box>
              <PreMessage>{props.parentMessage.text}</PreMessage>
            </FormGroup>
            <Box padding="0 1rem 1rem">
              <Button size="small" color="primary" onClick={handleGlobalTemplateApply} disabled={props.isFetching}>
                {tr.messageTemplates.applyGlobal}
              </Button>
            </Box>
          </Card>
        )}
        {props.data.description && (
          <Card>
            <FormGroup>
              <Typography variant="subtitle2">{tr.messageTemplates.description}</Typography>
            </FormGroup>
            <FormGroup>
              <Typography variant="body2">{props.data.description}</Typography>
            </FormGroup>
          </Card>
        )}
      </Box>
    </MessageTemplateFormRoot>
  )
}

export default MessageTemplatesForm
