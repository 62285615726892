import React from 'react'
import { ControlLabel } from 'dumb/control-label'
import LanguageIcon from '../language-icon'

export default class Selectlanguage extends React.Component {
  componentDidMount() {
    const { language, languages } = this.props

    if (!language && languages) this.handleSelectLang(languages[0])
  }

  render() {
    const { label, language, languages, attention } = this.props

    if (!languages) return null

    return (
      <React.Fragment>
        {label && (
          <div style={{ display: 'flex' }}>
            <ControlLabel style={{ marginBottom: 12 }}>{label}</ControlLabel>
            {attention && (
              <div
                style={{
                  background: '#ef5350',
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  marginTop: '4px',
                  marginLeft: '4px'
                }}
              />
            )}
          </div>
        )}
        <div style={{ display: 'flex' }}>
          {languages.map((lang) => {
            const style =
              language !== lang
                ? { filter: 'grayscale(70%)', opacity: 0.3 }
                : {}
            return (
              <div
                style={{
                  marginRight: 8,
                  cursor: !this.props.disabled ? 'pointer' : 'default',
                  ...style
                }}
                key={lang}
                onClick={
                  !this.props.disabled
                    ? () => this.handleSelectLang(lang)
                    : () => {}
                }
              >
                {
                  <LanguageIcon
                    key={lang}
                    language={lang}
                    style={style}
                    width={32}
                    height={32}
                  />
                }
              </div>
            )
          })}
        </div>
      </React.Fragment>
    )
  }

  handleSelectLang = (lang) => {
    this.props.onChange && this.props.onChange(lang)
  }
}
