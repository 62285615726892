import React, { Component } from 'react'
import CheckpointList from 'dumb/checkpoint-list'
import { editCheckpoint, createCheckpoint, checkpointMonitoring } from 'app/nav'
import { connect } from 'react-redux'
import { getCheckpointList } from 'store/actions/entity-list-action-creators'
import css from './checkpoint-list.module.scss'
import translate from 'i18n/translate'
import EmptyScreen from 'dumb/empty-screen'
import dataProvider from 'smart/isolated-data-provider'
import timeZoneFilter from 'smart/time-zone-filter'
import BasketIcon from '@material-ui/icons/ShoppingBasket'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormActionGroup } from 'dumb/form'
import Card from 'dumb/card'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/AddCircleOutline'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { resetEntityList } from 'store/actions/entity-list-action-creators'

class SmartCheckpointList extends Component {
  fetchList(p) {
    this.props.getEntityList(p.shopId, p.lineId)
  }

  componentDidMount() {
    if (this.props.canViewCheckpoint === false) {
      this.props.history.push(index())

      return null
    }

    this.fetchList(this.props.match.params)
  }

  componentDidUpdate() {
    const data = this.props.list.items
    const parents = this.props.list.parents

    if (data === null || this.crumbsDefined) {
      return null
    }

    this.crumbsDefined = true
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    this.setBreadcrumbs(this.getBreadcrumbs(crumb, parents))
  }

  componentWillUnmount() {
    this.props.dispatch(resetEntityList())
  }

  shouldNotRender() {
    const { list } = this.props
    const eqIdentity = !this.identity || !list.identity || this.identity === list.identity

    return list.items === null || !eqIdentity
  }

  getBreadcrumbs(crumb, parents) {
    const shop = parents.shop
    const line = parents.line

    let crumbs = [crumb.home(), crumb.shops(), crumb.shop([shop.id], shop.name)]

    if (line) {
      crumbs = crumbs.concat([crumb.lines([shop.id]), crumb.line([shop.id, line.id], line.name)])
    }

    crumbs.push(crumb.checkpoints())

    return crumbs
  }

  sortByName = (a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1

    return 0
  }

  sortByLineId = (a, b) => {
    if (a.lineId < b.lineId) return -1
    if (a.lineId > b.lineId) return 1

    return 0
  }

  checkAddPermissions = () => {
    return !!this.props.canAddCheckpoint && !!this.props.match.params.lineId
  }

  handleAdd() {
    this.props.history.push(createCheckpoint(this.props.match.params.shopId, this.props.match.params.lineId))
  }

  setBreadcrumbs(crumbs) {
    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  handleSelect = (data) => {
    const { shopId } = this.props.match.params

    if (!this.props.canEditCheckpoint) {
      this.props.history.push(checkpointMonitoring(shopId, data.lineId, data.id))
    } else {
      this.props.history.push(editCheckpoint(shopId, data.lineId, data.id))
    }
  }

  render() {
    if (this.shouldNotRender())
      return (
        <div
          style={{
            background: '#fafafa',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )

    const items = this.props.list.items

    return (
      <div className={css.root}>
        <div className={css.container}>
          {this.checkAddPermissions() !== false && (
            <Card>
              <FormActionGroup style={{ padding: '0.5rem' }}>
                <Button startIcon={<Add />} onClick={() => this.handleAdd()}>
                  {this.props.translation.checkpointList.addButton}
                </Button>
              </FormActionGroup>
            </Card>
          )}
          {items && items.length > 0 && (
            <Card>
              <CheckpointList
                data={[...(this.props.list.items || [])].sort(this.sortByLineId).sort(this.sortByName)}
                onSelect={this.handleSelect}
                showLine={!this.props.match.params.lineId}
              />
            </Card>
          )}
          {items && items.length === 0 && (
            <EmptyScreen text={this.props.translation.checkpointList.empty} iconClass={BasketIcon} />
          )}
        </div>
      </div>
    )
  }
}

const stateToProps = (state) => ({
  list: state.entityList,
  canAddCheckpoint: state.user.permissions.canAddCheckpoint,
  canEditCheckpoint: state.user.permissions.canEditCheckpoint,
  canViewCheckpoint: state.user.permissions.canViewCheckpoint,
  canOpenAndCloseCheckpoints: state.user.permissions.canOpenAndCloseCheckpoints
})

const actionsToProps = (dispatch) => ({
  getEntityList: (shopId, lineId) => dispatch(getCheckpointList(shopId, lineId)),
  dispatch
})

export default dataProvider({ lines: (props) => props.match.params.shopId })(
  connect(stateToProps, actionsToProps)(timeZoneFilter(translate(SmartCheckpointList)))
)
