import React from 'react'
import { UserList } from 'dumb/user'
import { editUser, createUser } from 'app/nav'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { SubBar } from 'dumb/sub-bar'
import { EnhancedInput } from 'dumb/text-field'
import Toggle from 'dumb/toggle-wrap'
import debounce from 'lodash/debounce'
import { user } from 'provider'
import { produce } from 'immer'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import Card from 'dumb/card'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import css from './user-list.module.scss'

const hideInactiveStorage = 'userList_hideInactive'

class SmartUserList extends React.Component {
  state = {
    hideInactive: localStorage.getItem(hideInactiveStorage) === 'true',
    term: '',
    list: null
  }

  editState = (fn, callback) => this.setState(produce(fn), callback)

  componentDidUpdate(_, prevState) {
    this.handleSearchChange(prevState)
  }

  handleSearchChange = debounce((prevState) => {
    const filterChanged = this.state.term !== prevState.term

    if (filterChanged) {
      this.fetchList(this.state)
    }
  }, 600)

  fetchList({ term, hideInactive }) {
    user.getUserList({ term, hideInactive }).then((res) => {
      this.setState({ list: res.data })
    })
  }

  componentDidMount() {
    this.setBreadcrumbs()
    this.fetchList(this.state)
  }

  setBreadcrumbs() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    this.props.dispatch(setBreadcrumbs([crumb.home(), crumb.users()]))
  }

  handleSearch = (term) => {
    this.setState({ term })
  }

  handleInactive = (hideInactive) => {
    this.setState({ hideInactive })
    this.fetchList({ ...this.state, hideInactive })
  }

  handleSelect(data) {
    if (this.props.canManageUserAccounts) {
      this.props.history.push(editUser(data.id))
    }
  }

  renderBarContent() {
    return (
      <SubBar className={css.subBar}>
        <div className={css.searchInput}>
          <EnhancedInput
            value={this.state.term}
            onChange={this.handleSearch}
            placeholder={this.props.translation.userList.search}
          />
        </div>
        <div className={css.searchToggle}>
          <Toggle
            toggled={!this.state.hideInactive}
            label={this.props.translation.userList.withInactive}
            onToggle={(_, val) => {
              this.handleInactive(!val)
              localStorage.setItem(hideInactiveStorage, !val)
            }}
          />
        </div>
      </SubBar>
    )
  }

  render() {
    return (
      <div style={{ marginTop: '4rem', width: '100%' }}>
        <div className={css.bar}>
          <div style={{ width: '100%', maxWidth: '600px' }}>{this.renderBarContent()}</div>
        </div>
        <div className={css.container} style={{ marginTop: '1rem' }}>
          <div style={{ width: '100%', maxWidth: '600px' }}>
            {this.props.canManageUserAccounts && (
              <Card>
                <Button style={{ margin: '0.5rem' }} onClick={() => this.props.history.push(createUser())}>
                  <AddIcon style={{ marginRight: '0.5rem' }} />
                  {this.props.translation.userList.addButton}
                </Button>
              </Card>
            )}
            {this.state.list && (
              <Card className={css.container} style={{ marginTop: '1rem' }}>
                <UserList data={this.state.list} onSelect={(data) => this.handleSelect(data)} />
              </Card>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const stateToProps = (state) => ({ canManageUserAccounts: state.user.permissions.canEditUser })

export default connect(stateToProps)(translate(SmartUserList))
