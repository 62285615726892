import React from 'react'
import translate from 'i18n/translate'
import { Form, FormGroup } from 'dumb/form'
import Text from 'dumb/text'

function TotalLoadingAuthorization(props) {
  const { authorizationNumber, validityPeriod, genericProducts, customerDelivered, period, timeWithDate } =
    props.translation.totalAuthorization

  const d = props.data

  if (!d) {
    return null
  }

  const authorizationNumberData = d.loadingAuthorizationNumber

  const validityEndDateData = d.endValidityDate
  const validityStartDateData = d.startValidityDate

  const countryData = d.country
  const clientNameData = d.clientName

  const appointment = d.appointment

  const formatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  const formatHourOptions = { hour: 'numeric', minute: 'numeric', hour12: false }

  return (
    <Form>
      <FormGroup>
        <Text type="caption">{authorizationNumber}</Text>
        <Text type="body-1">{authorizationNumberData}</Text>
      </FormGroup>

      <FormGroup>
        <Text type="caption">{validityPeriod}</Text>
        <Text type="body-1">
          {period(
            props.dateFormatter(validityStartDateData, formatOptions),
            props.dateFormatter(validityEndDateData, formatOptions)
          )}
        </Text>
      </FormGroup>

      <FormGroup>
        <Text type="caption">{genericProducts(d.products.length)}</Text>
        <Text type="body-1">{d.products.map((gp) => `${gp.code} — ${gp.name}`).join(', ')}</Text>
      </FormGroup>

      <FormGroup>
        <Text type="caption">{customerDelivered}</Text>
        <Text type="body-1">{`${clientNameData}, (${countryData})`}</Text>
      </FormGroup>

      {appointment && appointment.id && appointment.time && (
        <FormGroup>
          <Text type="caption">{props.translation.common.appointment}</Text>
          <Text type="body-1">{`${timeWithDate(
            props.dateFormatter(appointment.time.from, formatOptions),
            props.dateFormatter(appointment.time.from, formatHourOptions)
          )}`}</Text>
        </FormGroup>
      )}
    </Form>
  )
}

export default translate(TotalLoadingAuthorization)
