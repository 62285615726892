import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import translate from 'i18n/translate'
import Toggle from 'dumb/toggle-wrap/toggle'
import Selectlanguage from 'dumb/language-select'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'
import { isEmail } from 'utils/string-test'

class TransportCompanyForm extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'name',
        stateField: 'nameIsValid'
      },
      {
        dataField: 'email',
        stateField: 'emailIsValid',
        validator: (val) => isEmail(val)
      },
      {
        dataField: 'language',
        stateField: 'languageIsValid'
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}
        {this.props.canEdit && this.renderActions()}
      </Card>
    )
  }

  renderActions() {
    return (
      <EntityActions
        showButtons={true}
        buttons={this.renderControls()}
        updateData={this.state.data ? this.state.data.lastUpdate : ''}
      />
    )
  }

  isNew = () => (this.state.data ? !this.state.data.id : true)

  handleTryDelete() {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete() {
    this.setState({ confirmDelete: false })
  }

  handleDelete() {
    this.props.onDelete(this.state.data.id)
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderControls() {
    const { createButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button onClick={() => this.handleDelete()}>{deleteButton}</Button>
            <Button onClick={() => this.handleCancelDelete()}>{cancelButton}</Button>
          </div>
        </div>
      )

    if (this.isNew()) {
      return (
        <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    } else return this.renderEditControls()
  }

  renderEditControls() {
    const { updateButton, deleteButton } = this.props.translation.entityEdit

    return (
      <div style={{ gap: '0.5rem', display: 'flex' }}>
        <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()}>
          {updateButton}
        </Button>
        {this.props.canDelete !== false && <Button onClick={() => this.handleTryDelete()}>{deleteButton}</Button>}
      </div>
    )
  }

  renderMainForm(d) {
    const { name, code, isActive, language, email } = this.props.translation.transportCompanyEdit

    const { chars } = this.props.translation.entityEdit

    return (
      <Form>
        <FormGroup>
          <TextField
            label={name}
            placeholder={name}
            autofocus={true}
            value={d.name}
            onChange={(val) => this.handlePropChange('name', val)}
            attention={!this.state.nameIsValid}
            message={`${d.name ? d.name.length : 0}/100 ${chars}`}
            test={(v) => v.length < 101}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label={code}
            placeholder={code}
            value={d.code}
            onChange={(val) => this.handlePropChange('code', val)}
            message={`${d.code ? d.code.length : 0}/100 ${chars}`}
            test={(v) => v.length < 101}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label={email}
            placeholder={email}
            value={d.email}
            onChange={(val) => this.handlePropChange('email', val)}
            attention={!this.state.emailIsValid}
            message={`${d.email ? d.email.length : 0}/100 ${chars}`}
            test={(v) => v.length < 101}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
        <FormGroup>
          <Selectlanguage
            label={language}
            language={d.language}
            languages={['en', 'fr', 'de', 'es']}
            attention={!this.state.languageIsValid}
            onChange={(val) => this.handlePropChange('language', val)}
            disabled={!this.props.canEdit}
          />
        </FormGroup>

        <FormGroup>
          <Toggle
            disabled={!this.props.canEdit}
            toggled={d.isActive}
            label={isActive}
            onToggle={() => this.handlePropChange('isActive', !d.isActive)}
          />
        </FormGroup>
      </Form>
    )
  }
}

export default translate(TransportCompanyForm)
