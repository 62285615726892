import React from 'react'
import LoginForm from 'dumb/login-form-classic'
import { login } from 'provider/auth'
import css from './login.module.scss'
import * as Nav from 'app/nav'
import { extractStoredUrl } from 'utils/history'
import { invalidateIsolatedShopList } from 'store/actions/shop-action-creators'
import { connect } from 'react-redux'
import { showMessage } from 'store/actions/main-layout-action-creators'
import translate from 'i18n/translate'
import { user } from 'provider'
import { storeUserProfile } from 'store/actions/user-action-creators'

class Login extends React.Component {
  state = { request: false }

  componentDidMount() {
    this.props.dispatch(invalidateIsolatedShopList())
  }

  render() {
    return (
      <section className={css.root}>
        <LoginForm onSubmit={this.handleSubmit} disabled={this.state.request} />
      </section>
    )
  }

  handleSubmit = (name, password) => {
    this.setState({ request: true })

    login(name, password)
      .then(() => {
        user
          .storeProfile()
          .then((profile) => {
            this.props.dispatch(storeUserProfile(profile))
            this.props.history.push(extractStoredUrl() || Nav.index())
          })
          .catch(() => {
            this.setState({ request: false })
          })
      })
      .catch(() => {
        this.props.dispatch(showMessage(this.props.translation.login.authError, 'error'))
        this.setState({ request: false })
      })
  }
}

export default connect()(translate(Login))
