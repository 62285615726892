import axios from 'axios'

export default {
  info: undefined as any,
  login(email, password) {
    return axios.post('admin/login', { email, password })
  },
  logout() {
    return axios.post('logout')
  },
  storeProfile() {
    return axios.get('getCurrentUserProfile').then((data) => {
      this.info = data
      return data
    })
  },
  getStoredProfile() {
    return this.info
  },
  getUserList(data) {
    return axios.get('getUserList', { params: data })
  },
  getUser(id = '') {
    return axios.get('getUser', { params: { id } }).then((resp) => {
      if (!resp.data.notifications) {
        resp.data.notifications = []
      }

      return resp
    })
  },
  createUser(data) {
    return axios.post('createUser', data)
  },
  updateUser(data) {
    return axios.put('updateUser', data)
  },
  deleteUser(id) {
    return axios.delete('deleteUser', { data: id, headers: { 'Content-Type': 'application/json' } })
  }
}
