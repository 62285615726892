import { useEffect, createElement } from 'react'
import { setCurrentShop } from 'store/actions/shop-action-creators'
import { useAppDispatch } from 'store'

function TimeZoneFilter(WrappedComponent: any) {
  return function TimeZoneFilterComponent(props: any) {
    useTimeZoneFilter(props.match.params.shopId)

    return createElement(WrappedComponent, props)
  }
}

export function useTimeZoneFilter(shopId?: string | number) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (shopId) {
      dispatch(setCurrentShop(shopId))
    }

    return () => {
      dispatch(setCurrentShop(null))
    }
  }, [])

  return
}

export default TimeZoneFilter
