import { CSSProperties, ReactNode } from 'react'
import { TitleStyled } from './styled'

type Props = {
  children?: ReactNode
  collapsed?: boolean
  style?: CSSProperties
}

function Title(props: Props) {
  const { children, collapsed, style } = props

  if (collapsed) {
    return null
  }

  return <TitleStyled style={style}>{children}</TitleStyled>
}

export { Title }
