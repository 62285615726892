import { getUrl } from './helpers'
import axios from 'axios'

export default {
  getShopList() {
    return axios.get('getShopList')
  },
  getShop(id = '') {
    return axios.get('getShop', { params: { id } })
  },
  createShop(data) {
    return axios.post('createShop', data)
  },
  updateShop(data) {
    return axios.put('updateShop', data)
  },
  deleteShop(id) {
    return axios.delete('deleteShop', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  resetShopStatistics(id) {
    return axios.post('resetShopStatistics', id)
  },
  getTimeZones() {
    return axios.get('getTimeZones')
  },
  getShopDaysOff(data) {
    return axios.get('getShopDaysOff', { params: data })
  },
  updateShopDaysOff(data) {
    return axios.put('updateShopDaysOff', data)
  },
  exportDataFile(data) {
    location.href = getUrl('reports/exportDataFile', {
      template: data.template,
      line: data.line || '',
      shopId: data.shopId || '',
      startDate: data.startDate,
      endDate: data.endDate
    })
  },
  getExportTemplates(data) {
    return axios.get('getExportTemplates', { params: data })
  }
}
