import React, { Component } from 'react'
import Card from 'dumb/card'
import css from './tile.module.scss'

export default class Tile extends Component {
  render() {
    return (
      <div className={css.root} onClick={() => this.props.onClick()}>
        <Card>
          <div className={css.content}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 64 }}>
                {React.createElement(this.props.iconClass, {
                  style: {
                    width: 64,
                    height: 64,
                    fill: 'inherit',
                    transition: 'none'
                  }
                })}
              </div>
              <div className={css.titleAndCount}>
                <div className={css.countWrap}>{this.renderBadge()}</div>
                <div className={css.text}>{this.props.title}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  renderBadge() {
    const { count } = this.props

    if (!count || count === '0') return

    return <div className={css.count}>{count}</div>
  }
}
