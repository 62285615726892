import { List } from 'dumb/list'
import { TextTranslateModel } from 'shared/models'

type Props = {
  data: TextTranslateModel[]
  onSelect: (data: TextTranslateModel) => void
  brands: any
}

function TranslationList(props: Props) {
  return (
    <List
      data={props.data}
      onSelect={(data) => props.onSelect(data)}
      dataConverter={(item) => ({
        id: item.id || '',
        primaryText: item.sourceText,
        secondaryText: props.brands?.find((x) => x.id == item.brandId)?.name
      })}
    />
  )
}

export { TranslationList }
