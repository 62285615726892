import axios from 'axios'

export default {
  getCheckpointList(shopId, lineId = '') {
    return axios.get('getCheckpointList', { params: { shopId, lineId } })
  },
  getCheckpoint(id = '', shopId, lineId) {
    return axios.get('getCheckpoint', { params: { id, lineId, shopId } })
  },
  createCheckpoint(data) {
    return axios.post('createCheckpoint', data)
  },
  updateCheckpoint(data) {
    return axios.put('updateCheckpoint', data)
  },
  deleteCheckpoint(id) {
    return axios.delete('deleteCheckpoint', { data: id, headers: { 'Content-Type': 'application/json' } })
  },
  getCheckpointMonitoring(id) {
    return axios.get('getCheckpointMonitoring', { params: { id } })
  },
  updateCheckpointHost(data) {
    return axios.put('updateCheckpointHost', data)
  }
}
