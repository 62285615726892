import React from 'react'
import { connect } from 'react-redux'
import { removeMessage } from 'store/actions/main-layout-action-creators'
import Snackbar from 'dumb/snackbar'

class SnackComponent extends React.Component {
  removeMessage = (stamp) => {
    this.props.dispatch(removeMessage(stamp))
  }

  render() {
    const message = this.props.mainLayout.messages?.[0]
    const text = message && message.text
    const stamp = message && message.stamp
    const type = message && message.type

    return <Snackbar open={Boolean(text)} type={type} message={text} closeSnack={() => this.removeMessage(stamp)} />
  }
}

export const Snack = connect((state) => ({ mainLayout: state.mainLayout }))(SnackComponent)
