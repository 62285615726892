import { Component } from 'react'
import find from 'lodash/find'

export default class Validation extends Component {
  constructor(props) {
    super(props)
    this.validate = this.validateConfig()
    this.state = this.calcState(props.data)
  }

  validateConfig() {
    return []
  }

  calcState(data) {
    let state = { data }
    this.validate.forEach((v) => {
      let dt = data.get ? data.get(v.dataField) : data[v.dataField]
      this.calcValidateState(state, v, dt)
    })

    return state
  }

  calcValidateState(state, validate, value) {
    if (validate.calcState) {
      Object.assign(state, validate.calcState(value, state))
    } else {
      const validator = validate.validator || this.defaultValidator
      state[validate.stateField] = validator(value, state)
    }

    return state
  }

  defaultValidator(data) {
    return Boolean(data)
  }

  stateIsValid() {
    return this.validate
      .filter((v) => (v.ignore ? !v.ignore(this.state) : true))
      .every((v) => (v.stateField ? this.state[v.stateField] : true))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data && !this.props.preserveData) {
        this.setState(this.calcState(this.props.data))
      }
    }
  }

  handlePropChange(propName, value, cb = () => {}) {
    let state = this.state

    if (this.state.data.set) {
      state = { data: this.state.data.set(propName, value) }
    } else {
      state.data[propName] = value
    }

    this.validateDataField(state, propName, value)
    this.setState(state, cb)
  }

  handlePropChangeAsync = async (propName, value) => {
    let state = this.state

    if (this.state.data.set) {
      state = { data: this.state.data.set(propName, value) }
    } else {
      state.data[propName] = value
    }

    this.validateDataField(state, propName, value)

    return await new Promise((resolve) => {
      this.setState(state, () => {
        resolve()
      })
    })
  }

  validateDataField(state, propName, value) {
    const validate = find(this.validate, { dataField: propName })

    if (validate) this.calcValidateState(state, validate, value)
  }
}
