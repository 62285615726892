import enGB from './enGB'
import fr from './fr'

const resources = (locale) => {
  switch (locale) {
    case 'en':
      return enGB
    case 'en-US':
      return enGB
    case 'en-GB':
      return enGB
    case 'fr':
      return fr
    case 'fr-FR':
      return fr
    default:
      return enGB
  }
}

const languages = [
  {
    locale: 'en-GB',
    name: 'English'
  },
  {
    locale: 'fr',
    name: 'Français'
  }
]

export default {
  getLanguages() {
    return languages
  },

  getTranslation(locale) {
    return resources(locale) || resources('en')
  },

  getLocaleByBrowserLang() {
    const currentLang = navigator.language

    if (currentLang && currentLang.indexOf('fr') > -1) {
      return 'fr'
    }

    return currentLang
  }
}
