import uniqueId from 'lodash/uniqueId'
import fakeShops from './fake-shops'

const data = [
  {
    id: uniqueId(),
    shopId: uniqueId(),
    name: 'Normal checkout',
    shortName: 'NC',
    category: 5,
    serviceTime: 10,
    timeToReach: 10,
    maxTimeToReach: 100,
    slowServiceAlertTime: 14,
    fastServiceAlertTime: 2,
    persons: 8,
    time: 16,
    checkpointCount: 4,
    overchargedTime: 20,
    openingHour: {
      minutes: '0',
      hours: '9'
    },
    closingHour: {
      minutes: '0',
      hours: '20'
    },
    manageAppointments: true,
    maxSimultaneous: 2,
    requestParticipantsNumber: false,
    modes: ['MobileApp', 'SMS'],
    smsCredits: 2371,
    creditsLimitStartDate: Date.now(),
    creditsLimitEndDate: Date.now(),
    globalCreditsLimit: 10000,
    creditsLimit: 5000,
    services: [
      {
        id: uniqueId(),
        name: 'Delivery',
        duration: 10
      }
    ],
    allowCommentsFromUser: true,
    allowFutureAppointments: true,
    suggestSignUp: 'wifi',
    minTimeToFreePlaceAfterEarlyAcceptance: 1,
    parallelServiceDuration: 1,
    blindCopyEmails: '1@1',
    attachIcsFile: true,
    authoriseAppointmentOnWebPortal: true,
    state: 'late',
    stateTime: 8,
    calculateApproachByGPS: true,
    nearServicePointDistance: 25,
    bluetoothRequired: true,
    allowStartAndEndService: true,
    displayReservationQRCode: false,
    sendSMSWhenCalled: true,
    beforeTheCall: true,
    beforeTheCallTime: 10,
    whenCalled: true,
    whenServiceStartedByAgent: true,
    whenServiceEndedByAgent: true,
    nonStopService: false
  },
  {
    id: uniqueId(),
    shopId: uniqueId(),
    name: 'Kitchen expert',
    category: 2,
    serviceTime: 2.5,
    timeToReach: 4.5,
    maxTimeToReach: 6,
    slowServiceAlertTime: 14,
    fastServiceAlertTime: 2,
    persons: 8,
    time: 40,
    checkpointCount: 4,
    overchargedTime: 0,
    openingHour: {
      minutes: 10,
      hours: 1
    },
    closingHour: {
      minutes: 10,
      hours: 1
    },
    manageAppointments: true,
    maxSimultaneous: 2,
    requestParticipantsNumber: false,
    modes: ['MobileApp', 'NFCCard', 'PaperTicket'],
    services: [],
    allowCommentsFromUser: false,
    allowFutureAppointments: false,
    state: 'onTime',
    stateTime: null
  }
]

export function getResponse(data, add) {
  return {
    data: add ? Object.assign({}, data, add) : data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export default data
