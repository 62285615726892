import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import { useTranslation } from 'i18n/translate'
import Dropdown from 'dumb/dropdown'
import Text from 'dumb/text'
import { Button, Divider, IconButton } from '@material-ui/core'
import {
  AddCircleOutline as AddIcon,
  RemoveCircleOutline as RemoveIcon,
  Delete as DeleteIcon
} from '@material-ui/icons'
import Card from 'dumb/card'
import { TextTranslateModel } from 'shared/models'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { TranslationEditProps } from '../types'
import { ActionText, ActionsWrapper, ButtonWithTextWrapper, ConfirmationActions } from 'dumb/action-wrapper'

function TranslationEdit(props: TranslationEditProps) {
  const { tr } = useTranslation()

  const [isConfirmDelete, setConfirmDelete] = useState(false)

  const { control, handleSubmit, formState } = useForm<TextTranslateModel>({
    resolver: yupResolver(Yup.object().shape({ sourceText: Yup.string().required() }) as any),
    defaultValues: props.data,
    mode: 'onChange'
  })

  function onSubmit(data: TextTranslateModel) {
    props.onSave(data)
  }

  function handleTryDelete() {
    setConfirmDelete(true)
  }

  function handleCancelDelete() {
    setConfirmDelete(false)
  }

  function handleConfirmDelete() {
    props.onDelete(props.data.id || '')
  }

  const isValid = !Object.keys(formState.errors).length

  const { sourceText, brand } = tr.translationEdit
  const { translationText, addTranslation } = tr.translationEdit

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card paperStyle={{ overflow: 'hidden' }}>
        <Form>
          <FormGroup>
            <Controller
              name="sourceText"
              control={control}
              render={({ field: f }) => (
                <TextField
                  label={sourceText}
                  placeholder={sourceText}
                  autofocus={true}
                  value={f.value}
                  onChange={f.onChange}
                  attention={!f.value}
                />
              )}
            />
          </FormGroup>
          {props.creation && (
            <FormGroup>
              <Controller
                name="brandId"
                control={control}
                render={({ field: f }) => (
                  <Dropdown
                    ensureValue
                    label={brand}
                    value={f.value}
                    data={props.brands}
                    fullWidth
                    dataConverter={(item) => ({
                      value: String(item.id),
                      text: item.name
                    })}
                    onChange={f.onChange}
                  />
                )}
              />
            </FormGroup>
          )}
          {!props.creation && !!props.brands.find((x) => x.id == props.data.brandId) && (
            <FormGroup style={{ alignItems: 'flex-start' }}>
              <Text type="caption">{brand}</Text>
              <Text type="body-1">{props.brands.find((x) => x.id == props.data.brandId)?.name}</Text>
            </FormGroup>
          )}
          <Divider />
          <FormGroup>
            <Controller
              name="translations"
              control={control}
              render={({ field: f }) => {
                return (
                  <>
                    {f.value.map((tr, i) => (
                      <div style={{ display: 'flex', alignItems: 'baseline' }} key={i}>
                        <Dropdown
                          ensureValue
                          value={tr.destinationLanguage}
                          data={props.config.languages.filter(
                            (lang) =>
                              lang.value === tr.destinationLanguage ||
                              !f.value.some((tr) => tr.destinationLanguage === lang.value)
                          )}
                          style={{ width: 200 }}
                          dataConverter={(item: any) => ({
                            value: item.value,
                            text: item.name
                          })}
                          onChange={(val) => {
                            const newTranslations = f.value
                            newTranslations[i].destinationLanguage = val
                            f.onChange(newTranslations)
                          }}
                        />
                        <TextField
                          style={{ marginLeft: 16, flex: 1 }}
                          placeholder={translationText}
                          multiline
                          value={tr.translationText}
                          onChange={(val) => {
                            const newTranslations = f.value
                            newTranslations[i].translationText = val
                            f.onChange(newTranslations)
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            const newTranslations = f.value
                            newTranslations.splice(i, 1)
                            f.onChange(newTranslations)
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    ))}
                    <div style={{ marginTop: '0.5rem' }}>
                      <Button
                        startIcon={<AddIcon />}
                        disabled={
                          !props.config.languages.find((lang) => {
                            return !f.value.some((tr) => tr.destinationLanguage === lang.value)
                          })
                        }
                        onClick={() => {
                          const destinationLanguage = props.config.languages.find((lang) => {
                            return !f.value.some((tr) => tr.destinationLanguage === lang.value)
                          })

                          f.onChange([
                            ...f.value,
                            { destinationLanguage: destinationLanguage?.value, translationText: '' }
                          ])
                        }}
                      >
                        {addTranslation}
                      </Button>
                    </div>
                  </>
                )
              }}
            />
          </FormGroup>
        </Form>
        {!!props.data.id && (
          <>
            <Divider />
            <Form title={tr.entityEdit.advancedGroup} expandable>
              <FormGroup>
                {!isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.translationEdit.deleteTitle}</ActionText>
                    <Button onClick={handleTryDelete} startIcon={<DeleteIcon />} disabled={props.processing}>
                      {tr.entityEdit.deleteButton}
                    </Button>
                  </ButtonWithTextWrapper>
                )}
                {isConfirmDelete && (
                  <ButtonWithTextWrapper>
                    <ActionText>{tr.entityEdit.confirmDelete}</ActionText>
                    <ConfirmationActions>
                      <Button disabled={props.processing} onClick={handleConfirmDelete}>
                        {tr.entityEdit.yes}
                      </Button>
                      <Button disabled={props.processing} onClick={handleCancelDelete}>
                        {tr.entityEdit.no}
                      </Button>
                    </ConfirmationActions>
                  </ButtonWithTextWrapper>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </Card>
      <ActionsWrapper>
        <Button disabled={!isValid || props.processing} type="submit">
          {props.data.id ? tr.entityEdit.updateButton : tr.entityEdit.createButton}
        </Button>
      </ActionsWrapper>
    </form>
  )
}

export { TranslationEdit }
