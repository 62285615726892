import React, { Component } from 'react'
import { List } from 'dumb/list'
import Dropdown from 'dumb/dropdown'
import Remove from '@material-ui/icons/RemoveCircleOutline'
import Add from '@material-ui/icons/AddCircleOutline'
import translate from 'i18n/translate'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

class AddList extends Component {
  static defaultProps = {
    listDataConverter(item) {
      return {
        id: item.id,
        primaryText: item.name
      }
    },
    dropdownDataConverter(item) {
      return {
        value: item.id,
        text: item.name
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedItem: null,
      autoSelect: props.autoSelect || false
    }
  }

  render() {
    return (
      <section>
        <List
          onSelect={() => {}}
          data={this.props.selectedIds}
          disabled={this.props.disabled}
          dataConverter={(id) => {
            const controls = [
              <IconButton onClick={() => this.handleRemove(id)}>
                <Remove />
              </IconButton>
            ]

            const item = this.props.source.find((x) => String(x.id) === String(id))

            return { ...this.props.listDataConverter(item), controls }
          }}
        />
        {this.renderAddControl()}
      </section>
    )
  }

  renderAddControl() {
    if (this.getFilteredSource().length)
      return (
        <div style={{ margin: 16, display: 'flex' }}>
          <div style={{ marginRight: 16, width: '100%' }}>{this.renderDropdown()}</div>
          {this.props.renderMore && <div style={{ marginRight: 16 }}>{this.props.renderMore()}</div>}
          {this.renderAddButton()}
        </div>
      )
  }

  renderDropdown() {
    return (
      <Dropdown
        disabled={this.props.disabled}
        value={this.getDropdownValue()}
        onChange={(id) => this.handleSelect(id)}
        data={this.getFilteredSource()}
        fullWidth
        dataConverter={(item) => this.dataConverterWrap(item)}
      />
    )
  }

  dataConverterWrap(item) {
    if (item && item.id == null && item.isDefaultItem) {
      return AddList.defaultProps.dropdownDataConverter(item)
    }

    return this.props.dropdownDataConverter(item)
  }

  getDropdownValue() {
    return this.state.selectedItem || (this.state.autoSelect ? this.getFilteredSource()?.[0]?.id : null)
  }

  getFilteredSource() {
    return this.props.source.filter((x) => !this.props.selectedIds.includes(x.id))
  }

  handleSelect(id) {
    this.setState({ selectedItem: id })
  }

  renderAddButton() {
    return (
      <Button disabled={!this.state.selectedItem} onClick={() => this.handleAddItem()} startIcon={<Add />}>
        {this.props.addButtonText}
      </Button>
    )
  }

  handleAddItem() {
    this.setState({ selectedItem: null })
    this.props.onChange([...this.props.selectedIds, this.getDropdownValue()])
  }

  handleRemove(id) {
    this.props.onChange(this.props.selectedIds.filter((x) => x != id))
  }
}

export default translate(AddList)
