import React, { Component } from 'react'
import css from './form.module.scss'
import { withMods } from 'utils/cssm'
import Text from 'dumb/text'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

const mod = withMods(css)

export default class Form extends Component {
  static defaultProps = {
    expandable: false
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: this.props.expanded || !this.props.expandable
    }

    this.wasExpanded = this.state.expanded
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.wasExpanded = this.wasExpanded || nextState.expanded

    if (this.props.expanded != nextProps.expanded)
      this.setState({ expanded: this.props.expanded || !this.props.expandable })

    return true
  }

  render() {
    return (
      <section className={mod.root({ noHeader: !this.hasHeader() })}>
        {this.tryRenderHeader()}
        {this.renderContent()}
      </section>
    )
  }

  renderContent() {
    const expanded = this.state.expanded

    if (!this.wasExpanded) return null

    return (
      <div className={mod.content({ expanded })}>{this.props.children}</div>
    )
  }

  tryRenderHeader() {
    if (!this.hasHeader()) return

    return this.props.expandable
      ? this.renderHeader()
      : this.renderStaticHeader()
  }

  renderStaticHeader() {
    return <div className={css.staticHeader}>{this.renderHeaderContent()}</div>
  }

  renderHeaderContent() {
    if (this.props.headerContent) return this.props.headerContent()

    return (
      <Text type="body-2">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.title}
          {this.props.attention && (
            <div
              style={{
                background: '#ef5350',
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                marginLeft: '4px'
              }}
            />
          )}
        </div>
      </Text>
    )
  }

  hasHeader() {
    return this.props.headerContent || this.props.title
  }

  renderHeader() {
    const icon = this.state.expanded ? <ExpandLess /> : <ExpandMore />

    return (
      <div
        className={mod.header().add(this.props.headerClassName)}
        onClick={() => this.handleToggle()}
      >
        <div className={css.title}>{this.renderHeaderContent()}</div>
        <div>{icon}</div>
      </div>
    )
  }

  handleToggle() {
    this.props.onToggle && this.props.onToggle(!this.state.expanded)
    this.setState({ expanded: !this.state.expanded })
  }
}
