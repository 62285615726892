import React from 'react'
import Base from 'smart/base/base'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { getShopDaysOff, updateShopDaysOff } from 'store/actions/shop-action-creators'
import PageLoader from 'dumb/page-loader'
import { resetEntity } from 'store/actions/entity-action-creators'
import { ShopDaysOff } from 'dumb/shop'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import * as Nav from 'app/nav'

class SmartShopDaysOff extends Base {
  getBreadcrumbs(crumb, data, parents) {
    const shopId = parents && parents.shop.id
    const shopName = parents && parents.shop.name

    return [crumb.home(), crumb.shops(), crumb.shop([shopId], shopName), crumb.daysOff()]
  }

  fetchData() {
    this.props.getDays({ shopId: this.props.match.params.shopId })
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    this.props.dispatch(resetEntity())
  }

  componentDidUpdate() {
    const data = this.props.days.data
    const parents = this.props.days.parents

    if (data === null || this.crumbsDefined) {
      return null
    }

    this.crumbsDefined = true

    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    this.setBreadcrumbs(this.getBreadcrumbs(crumb, data, parents))
  }

  componentDidMount() {
    super.componentDidMount()
    this.fetchData()
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps != this.props) {
      const operation = nextProps.days.lastOperation
      const success = nextProps.days.operationSucceeded

      if (operation === 'update' && success) {
        this.pushPath(Nav.shop(this.props.match.params.shopId))
      }
    }

    return nextProps.days.operationSucceeded
  }

  renderContent() {
    const data = this.props.days.data

    if (data === null || this.props.days.identity !== 'daysOff') {
      return <PageLoader />
    }

    return <ShopDaysOff data={{ ...data }} onSave={this.handleSave} canEdit={this.props.canEdit} />
  }

  handleSave = (data) => {
    this.props.updateDays(data)
  }
}

const stateToProps = (state) => ({
  days: state.entity,
  canEdit: state.user.permissions.canEditShop
})

const actionsToProps = (dispatch) => ({
  getDays: (id) => dispatch(getShopDaysOff(id)),
  updateDays: (data) => dispatch(updateShopDaysOff(data)),
  dispatch
})

export default connect(stateToProps, actionsToProps)(translate(SmartShopDaysOff))
