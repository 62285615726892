import React from 'react'
import * as Nav from 'app/nav'
import { connect } from 'react-redux'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import translate from 'i18n/translate'
import timeZoneFilter from 'smart/time-zone-filter'
import Container from 'dumb/container'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { total } from 'provider'
import { produce } from 'immer'
import UserForm from './transport-company-user-form'
import CircularProgress from '@material-ui/core/CircularProgress'

class TransportCompanyEdit extends React.Component {
  static contextTypes = {
    router: () => null
  }

  state = {
    entity: null,
    operation: false,
    loading: false
  }

  editState = (fn, cb) => this.setState(produce(fn), cb)

  fetchData() {
    this.editState((draft) => {
      draft.loading = true
    })

    return total
      .getTransportCompanyUser({
        companyId: this.getCompanyId(),
        userId: this.getUserId()
      })
      .then((resp) => {
        this.editState((draft) => {
          draft.loading = false
          draft.entity = resp
        })

        return resp
      })
  }

  componentDidMount() {
    this.fetchData().then((entity) => this.setBreadcrumbs(entity))
  }

  setBreadcrumbs(entity) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const parents = entity.parents
    const companyId = this.getCompanyId()
    const userId = this.getUserId()

    const crumbs = [
      crumb.home(),
      crumb.transportCompanies(),
      {
        title: parents.transportCompany.name,
        url: Nav.transportCompanyEdit(companyId)
      },
      {
        title: userId ? entity.data.name : this.props.translation.breadcrumbs.newTransportCompanyUser
      }
    ]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  shouldNotRenderForm() {
    return this.state.operation || !this.state.entity
  }

  render() {
    if (!this.state.entity || !this.state.entity.data) return null

    const { data } = this.state.entity

    return (
      <Container>
        <UserForm
          data={{ ...data }}
          canDelete={this.props.canDeleteTransportCompanyUsers}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          onResetPassword={this.handleResetPassword}
          canEdit={this.props.canEditTransportCompanyUsers}
        />
        {this.state.loading && (
          <CircularProgress style={{ position: 'absolute', left: '50%', marginLeft: '-20px' }} color="secondary" />
        )}
      </Container>
    )
  }

  handleSave = (d) => {
    const { userCreatedMessage, userCreatedErrorMessage, userUpdateMessage, userUpdateErrorMessage } =
      this.props.translation.transportCompanyUserEdit

    this.editState((draft) => {
      draft.entity.data = d
      draft.loading = true
      draft.operation = true
    })

    const data = {
      ...d,
      companyId: this.getCompanyId()
    }

    if (this.getUserId()) {
      total
        .updateTransportCompanyUser(data)
        .then(() => {
          this.editState((draft) => {
            draft.loading = false
          })
          this.pushPath(Nav.transportCompanyEdit(this.getCompanyId()))
          this.props.dispatch(showMessage(userUpdateMessage, 'success'))
        })
        .catch((error) => {
          if (error == 'Error: Conflict')
            this.props.dispatch(showMessage(this.props.translation.userOperationMessages.emailError, 'error'))
          else this.props.dispatch(showMessage(userUpdateErrorMessage, 'error'))

          this.editState((draft) => {
            draft.loading = false
          })
        })
    } else {
      total
        .createTransportCompanyUser(data)
        .then(() => {
          this.editState((draft) => {
            draft.loading = false
          })
          this.pushPath(Nav.transportCompanyEdit(this.getCompanyId()))
          this.props.dispatch(showMessage(userCreatedMessage, 'success'))
        })
        .catch((error) => {
          if (error == 'Error: Conflict')
            this.props.dispatch(showMessage(this.props.translation.userOperationMessages.emailError, 'error'))
          else this.props.dispatch(showMessage(userCreatedErrorMessage, 'error'))

          this.editState((draft) => {
            draft.loading = false
          })
        })
    }
  }

  handleDelete = (data) => {
    const { userDeletedMessage } = this.props.translation.transportCompanyUserEdit

    this.editState((draft) => {
      draft.loading = true
      draft.operation = true
    })

    const requestData = {
      companyId: this.getCompanyId(),
      userId: data
    }

    total
      .deleteTransportCompanyUser(requestData)
      .then(() => {
        this.editState((draft) => {
          draft.loading = false
        })
        this.pushPath(Nav.transportCompanyEdit(this.getCompanyId()))
        this.props.dispatch(showMessage(userDeletedMessage, 'success'))
      })
      .catch(() => {
        this.editState((draft) => {
          draft.loading = false
        })
      })
  }

  handleResetPassword = () => {
    const { userPasswordResetMessage } = this.props.translation.transportCompanyUserEdit

    this.editState((draft) => {
      draft.loading = true
      draft.operation = true
    })

    total
      .resetPasswordTransportCompanyUser({
        companyId: this.getCompanyId(),
        userId: this.getUserId()
      })
      .then(() => {
        this.editState((draft) => {
          draft.loading = false
        })
        this.pushPath(Nav.transportCompanyEdit(this.getCompanyId()))
        this.props.dispatch(showMessage(userPasswordResetMessage, 'success'))
      })
      .catch(() => {
        this.editState((draft) => {
          draft.loading = false
        })
      })
  }

  getCompanyId = () => this.props.match.params.companyId

  getUserId = () => this.props.match.params.userId

  pushPath(path) {
    this.props.history.push(path)
  }
}

const stateToProps = (state) => ({
  canDeleteTransportCompanyUsers: state.user.permissions.canDeleteTransportCompanyUsers,
  canEditTransportCompanyUsers: state.user.permissions.canEditTransportCompanyUsers
})

export default connect(stateToProps)(timeZoneFilter(translate(TransportCompanyEdit)))
