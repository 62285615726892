import { Switch, Redirect } from 'react-router-dom'
import * as Nav from 'app/nav'
import Home from 'smart/home'
import { ShopView, ShopEdit, ShopList, ShopDaysOff } from 'smart/shop'
import { LineList, LineView, LineEdit } from 'smart/line'
import { CheckpointList, CheckpointEdit, CheckpointMonitoring } from 'smart/checkpoint'
import { StaffManagementList, StaffManagementEdit } from 'smart/staff-management'
import { UserList, UserEdit } from 'smart/user'
import MessageTemplates from 'pages/message-templates'
import { TranslationList, TranslationEdit } from 'pages/translations'
import GenericProduct from 'smart/generic-product-total'
import SmartGenericProductEdit from 'smart/generic-product-total/generic-product-edit'
import UnavailabilityAndAppointments from 'smart/unavailability-and-appointments'
import LoadingArmsList from 'smart/loading-arm-total'
import SmartLoadingArmEdit from 'smart/loading-arm-total/loading-arm-edit'
import SmartLoadingAuthorizationsList from 'smart/loading-authorizations'
import CheckpointsUnavailability from 'smart/checkpoints-unavailability'
import CheckpointUnavailabilityEdit from 'smart/checkpoints-unavailability/chekpoint-unavailability-edit'
import LoadingArmsUnavailability from 'smart/loading-arms-unavailability'
import LoadingArmUnavailabilityEdit from 'smart/loading-arms-unavailability/loading-arms-unavailability-edit'
import SmartTotalAppointmentsList from 'smart/total-appointments'
import SmartAppointmentsScheduling from 'smart/appointments-scheduling'
import SmartTotalAppointmentEdit from 'smart/total-appointment'
import TransportCompaniesList from 'smart/transport-companies'
import TransportCompanyEdit from 'smart/transport-companies/transport-company-edit'
import TransportCompanyUser from 'smart/transport-companies/transport-company-user'
import GenericProductsUnavailability from 'smart/generic-products-unavailability'
import SmartGenericProductUnavailabilityEdit from 'smart/generic-products-unavailability/generic-product-unavailability-edit'
import Reports from 'pages/reports'
import Report from 'pages/reports/report'
import { connect } from 'react-redux'
import { WRoute } from 'features/render-router-component'

function MainRouting(props) {
  const permissions = props.permissions

  return (
    <Switch>
      {!permissions.limitedView && <WRoute exact path={Nav.reports()} component={Reports} />}
      {!permissions.limitedView && <WRoute exact path={Nav.report(':templateId')} component={Report} />}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.messageTemplates(':shopId', ':lineId')} component={MessageTemplates} />
      )}
      {!permissions.limitedView && <WRoute exact path={Nav.messageTemplates(':shopId')} component={MessageTemplates} />}
      {!permissions.limitedView && <WRoute exact path={Nav.messageTemplates()} component={MessageTemplates} />}
      {permissions.canAddUser && <WRoute exact path={Nav.createUser()} component={UserEdit} action="Edit" />}
      {permissions.canEditUser && <WRoute exact path={Nav.editUser(':userId')} component={UserEdit} action="Edit" />}
      {permissions.canViewUser && <WRoute exact path={Nav.users()} component={UserList} />}
      {!permissions.limitedView && (
        <WRoute
          exact
          path={Nav.createLineStaffManagement(':shopId', ':lineId')}
          component={StaffManagementEdit}
          action="Edit"
        />
      )}
      {!permissions.limitedView && (
        <WRoute
          exact
          path={Nav.editLineStaffManagement(':shopId', ':lineId', ':managementId')}
          component={StaffManagementEdit}
          action="Edit"
        />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.lineStaffManagement(':shopId', ':lineId')} component={StaffManagementList} />
      )}
      {!permissions.limitedView && (
        <WRoute
          exact
          path={Nav.checkpointMonitoring(':shopId', ':lineId', ':checkpointId')}
          component={CheckpointMonitoring}
        />
      )}
      {permissions.canAddCheckpoint && (
        <WRoute exact path={Nav.createCheckpoint(':shopId', ':lineId')} component={CheckpointEdit} action="Edit" />
      )}
      {permissions.canEditCheckpoint && (
        <WRoute
          exact
          path={Nav.editCheckpoint(':shopId', ':lineId', ':checkpointId')}
          component={CheckpointEdit}
          action="Edit"
        />
      )}
      {permissions.canViewCheckpoint && (
        <WRoute exact path={Nav.checkpoints(':shopId', ':lineId')} component={CheckpointList} />
      )}
      {permissions.canViewCheckpoint && <WRoute exact path={Nav.checkpoints(':shopId')} component={CheckpointList} />}
      {permissions.canAddLine && <WRoute exact path={Nav.createLine(':shopId')} component={LineEdit} action="Edit" />}
      {permissions.canEditLine && (
        <WRoute exact path={Nav.editLine(':shopId', ':lineId')} component={LineEdit} action="Edit" />
      )}
      {permissions.canViewLine && (
        <WRoute exact path={Nav.line(':shopId', ':lineId')} component={LineView} action="View" />
      )}
      {permissions.canViewLine && <WRoute exact path={Nav.lines(':shopId')} component={LineList} />}
      {!permissions.limitedView && <WRoute exact path={Nav.shopDaysOff(':shopId')} component={ShopDaysOff} />}
      {permissions.canAddShop && <WRoute exact path={Nav.createShop()} component={ShopEdit} action="Edit" />}
      {permissions.canEditShop && <WRoute exact path={Nav.editShop(':shopId')} component={ShopEdit} action="Edit" />}
      {permissions.canViewShop && <WRoute exact path={Nav.shop(':shopId')} component={ShopView} action="View" />}
      <WRoute exact path={Nav.shops()} component={ShopList} />
      {!permissions.limitedView && (
        <WRoute exact path={Nav.createTranslation()} component={TranslationEdit} action="Edit" />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.editTranslation(':translationId')} component={TranslationEdit} action="Edit" />
      )}
      {!permissions.limitedView && <WRoute exact path={Nav.translations()} component={TranslationList} />}
      <WRoute exact path={Nav.index()} component={Home} />
      {!permissions.limitedView && <WRoute exact path={Nav.genericProducts()} component={GenericProduct} />}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.genericProductEdit(':genericProductId')} component={SmartGenericProductEdit} />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.genericProductCreate()} component={SmartGenericProductEdit} />
      )}
      <WRoute exact path={Nav.unavailabilityAndAppointments(':shopId')} component={UnavailabilityAndAppointments} />
      {permissions.canAddTransportCompanyUsers && (
        <WRoute
          exact
          path={Nav.transportCompanyUserCreate(':companyId')}
          component={TransportCompanyUser}
          action="Edit"
        />
      )}
      {permissions.canEditTransportCompanyUsers && (
        <WRoute
          exact
          path={Nav.transportCompanyUser(':companyId', ':userId')}
          component={TransportCompanyUser}
          action="Edit"
        />
      )}
      {permissions.canAddTransportCompanies && (
        <WRoute exact path={Nav.transportCompanyCreate()} component={TransportCompanyEdit} action="Edit" />
      )}
      {permissions.canEditTransportCompanies && (
        <WRoute exact path={Nav.transportCompanyEdit(':companyId')} component={TransportCompanyEdit} action="Edit" />
      )}
      {permissions.canViewTransportCompanies && (
        <WRoute exact path={Nav.transportCompanies()} component={TransportCompaniesList} />
      )}
      {!permissions.limitedView && <WRoute exact path={Nav.loadingArms(':shopId')} component={LoadingArmsList} />}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.loadingArmEdit(':shopId', ':loadingArmId')} component={SmartLoadingArmEdit} />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.loadingArmCreate(':shopId')} component={SmartLoadingArmEdit} />
      )}
      <WRoute exact path={Nav.loadingAuthorizations()} component={SmartLoadingAuthorizationsList} />
      {permissions.canAddAppointments && (
        <WRoute
          exact
          path={Nav.totalAppointmentCreate(':shopId')}
          component={SmartTotalAppointmentEdit}
          action="Edit"
        />
      )}
      {permissions.canEditAppointments && (
        <WRoute
          exact
          path={Nav.totalAppointmentEdit(':shopId', ':appointmentToken')}
          component={SmartTotalAppointmentEdit}
          action="Edit"
        />
      )}
      {permissions.canViewAppointments && (
        <WRoute exact path={Nav.totalAppointments(':shopId')} component={SmartTotalAppointmentsList} />
      )}
      {permissions.canViewAppointments && (
        <WRoute
          exact
          path={Nav.totalAppointmentsToCancel(':shopId')}
          render={(props) => <SmartTotalAppointmentsList {...props} toCancel />}
        />
      )}
      {!permissions.limitedView && (
        <WRoute
          exact
          path={Nav.createCheckpointUnavailability(':shopId', ':checkpointId')}
          component={CheckpointUnavailabilityEdit}
        />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.createCheckpointUnavailability(':shopId')} component={CheckpointUnavailabilityEdit} />
      )}
      {!permissions.limitedView && (
        <WRoute
          exact
          path={Nav.editCheckpointUnavailability(':shopId', ':recordId')}
          component={CheckpointUnavailabilityEdit}
        />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.checkpointsUnavailability(':shopId')} component={CheckpointsUnavailability} />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.createLoadingArmUnavailability(':shopId')} component={LoadingArmUnavailabilityEdit} />
      )}
      {!permissions.limitedView && (
        <WRoute
          exact
          path={Nav.editLoadingArmUnavailability(':shopId', ':recordId')}
          component={LoadingArmUnavailabilityEdit}
        />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.loadingArmsUnavailability(':shopId')} component={LoadingArmsUnavailability} />
      )}
      {permissions.canAddAppointments && (
        <WRoute exact path={Nav.appointmentsSchedulingCreate(':shopId')} component={SmartTotalAppointmentEdit} />
      )}
      {permissions.canEditAppointments && (
        <WRoute
          exact
          path={Nav.appointmentsSchedulingEdit(':shopId', ':appointmentToken')}
          component={SmartTotalAppointmentEdit}
        />
      )}
      <WRoute exact path={Nav.appointmentsScheduling(':shopId')} component={SmartAppointmentsScheduling} />
      {!permissions.limitedView && (
        <WRoute exact path={Nav.createProductsUnavailability()} component={SmartGenericProductUnavailabilityEdit} />
      )}
      {!permissions.limitedView && (
        <WRoute
          exact
          path={Nav.editProductsUnavailability(':recordId')}
          component={SmartGenericProductUnavailabilityEdit}
        />
      )}
      {!permissions.limitedView && (
        <WRoute exact path={Nav.productsUnavailability()} component={GenericProductsUnavailability} />
      )}
      <Redirect to={Nav.index()} />
    </Switch>
  )
}

export default connect((state: any) => ({ permissions: state.user.permissions }))(MainRouting)
