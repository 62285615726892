import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface EntityListState {
  error: any
  fetching: boolean
  items: any
  parents: any
  identity: string | null
}

const initialState: EntityListState = {
  error: null,
  fetching: false,
  items: null,
  parents: null,
  identity: null
}

const EntityListSlice = createSlice({
  name: 'entityList',
  initialState,
  reducers: {
    getEntityListRequest: (state) => {
      state.error = null
      state.fetching = true
    },

    getEntityListSuccess: (state, { payload }: PayloadAction<any>) => {
      state.error = null
      state.fetching = false
      state.items = payload.data
      state.parents = payload.parents
      state.identity = payload.identity
    },

    getEntityListFailure: (state, { payload }: PayloadAction<any>) => {
      state.error = payload
      state.fetching = false
    },

    resetEntityList: (state) => {
      state.items = null
      state.parents = null
      state.identity = null
    }
  }
})

export const { getEntityListFailure, getEntityListRequest, getEntityListSuccess, resetEntityList } =
  EntityListSlice.actions

export default EntityListSlice.reducer
