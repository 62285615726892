import uniqueId from 'lodash/uniqueId'
import findIndex from 'lodash/findIndex'
import fakeShops from './fake-shops'

const data = [
  {
    id: uniqueId(),
    code: '13',
    name: 'GenericP1'
  },
  {
    id: uniqueId(),
    code: '37',
    name: 'GenericP2'
  },
  {
    id: uniqueId(),
    code: '24',
    name: 'GenericP3'
  }
]

export function getResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export function update(entity) {
  const index = findIndex(data, { id: entity.id })
  data[index] = entity
}

export default data
