import React, { Component } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'
import amber from '@material-ui/core/colors/amber'
import green from '@material-ui/core/colors/green'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const styles = (theme) => ({
  success: { backgroundColor: green[600] },
  error: { backgroundColor: '#ed0000' },
  info: { backgroundColor: '#374649' },
  warning: { backgroundColor: amber[700] },
  icon: { fontSize: 20, color: '#FFF' },
  iconVariant: { opacity: 0.9, marginRight: theme.spacing(1) },
  message: { display: 'flex', alignItems: 'center', width: '200px' }
})

function MySnackbarContent(props) {
  const { classes, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classes[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent)

export default class Snack extends Component {
  static defaultProps = {
    message: ''
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') return null

    this.props.closeSnack && this.props.closeSnack()
  }

  render() {
    return (
      <React.Fragment>
        <Snackbar
          open={this.props.open}
          autoHideDuration={this.props.display === 'infinite' ? null : 8000}
          onClose={this.handleClose}
          style={{ width: '20rem', margin: '0 auto' }}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={this.props.type || 'info'}
            message={this.props.message}
            style={{ borderRadius: '16px' }}
          />
        </Snackbar>
      </React.Fragment>
    )
  }
}
