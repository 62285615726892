import { Translations } from './translation-types'

function pluralization(n: number, forms: [string, string]) {
  const formIndex = Math.abs(n) > 1 ? 1 : 0
  return forms[formIndex]
}

const tr: Translations = {
  common: {
    title: 'Total: Administration',
    shop: 'Place',
    brand: 'Brand',
    line: 'Line',
    checkpoint: 'Track',
    loadingArm: 'Loading arm',
    genericProduct: 'Generic product',
    gender: 'Gender',
    genderValue(gender) {
      return gender === 1 ? 'Female' : 'Male'
    },
    booleanValue(value) {
      return value ? 'Yes' : 'No'
    },
    female: 'Female',
    male: 'Male',
    customer: 'Customer',
    personsCount(count) {
      return `${count} ${pluralization(count, ['person', 'people'])}`
    },
    search: 'Search',
    upload: 'Upload',
    add: 'Add',
    all: 'All',
    yes: 'Yes',
    no: 'No',
    none: 'None',
    unavailable: 'Unavailable',
    unavailability: 'Unavailability',
    taken: 'Taken',
    date: 'Date',
    time: 'Time'
  },
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday'
  },
  requestMessages: {
    serverError: 'Request to server failed',
    serverSuccess: 'Success'
  },
  login: {
    email: 'Email',
    password: 'Password',
    logIn: 'Log in',
    forgot: 'Forgot your password?',
    noEmail: 'Server error',
    updatePassword: 'The new password was sent to you by email',
    authError: 'Authentification failed',
    emailError: (email = '') => `No user account with email ${email} was found. Please, contact application support.`,
    conditional: `By clicking the "Login" button, I hereby agree to the Terms of Service of the Book'n Load website. To read them, please click`,
    here: 'here',
    link: '/docs/CGU_Booknload_BO_EN.pdf'
  },
  addList: {
    selectItem: 'Select an item'
  },
  entityView: {
    editButton: 'Edit',
    duplicateButton: 'Duplicate',
    lastUpdate(firstName, secondName, dateTime) {
      return `Last updated by ${firstName} ${secondName} on ${dateTime}`
    }
  },
  entityEdit: {
    createButton: 'Create',
    updateButton: 'Update',
    deleteButton: 'Delete',
    cancelButton: 'Cancel',
    confirmDelete: 'Are you sure you want to delete?',
    chars: 'characters',
    advancedGroup: 'Advanced',
    yes: 'Yes',
    no: 'No'
  },
  entityList: {
    loadMore: 'Load more'
  },
  breadcrumbs: {
    home: 'Home',
    shop: 'Place',
    shops: 'Places',
    newShop: 'New place',
    editShop: 'Edit',
    lines: 'Lines',
    line: 'Line',
    newLine: 'New line',
    editLine: 'Edit',
    checkpoints: 'Tracks',
    newCheckpoint: 'New track',
    editCheckpoint: 'Edit the track',
    users: 'Users',
    newUser: 'New user',
    editUser: 'Edit user',
    reports: 'Reports',
    messageTemplates: 'Messages templates',
    daysOff: 'Days off',
    staffManagement: 'Availability',
    editStaffManagement: 'Edit',
    newStaffManagement: 'New',
    appointments: 'Appointments',
    newAppointment: 'New',
    editAppointment: 'Edit',
    counter: 'Counter',
    newCounter: 'New counter',
    editCounter: 'Edit',
    translations: 'Translations',
    newTranslation: 'New',
    editTranslation: 'Edit',
    settings: 'Settings',

    unavailabilityAndAppointments: 'Unavailability and appointments',

    genericProducts: 'Generic products',
    newGenericProduct: 'New generic product',
    editGenericProduct: 'Update generic product',

    loadingArms: 'Loading arms',
    newLoadingArm: 'New loading arm',
    editLoadingArm: 'Update loading arm',

    transportCompanies: 'Transport companies',
    newTransportCompanyUser: 'New user',

    checkpointsUnavailability: 'Tracks unavailability',
    newCheckpointUnavailability: 'New',
    editCheckpointUnavailability: 'Edit',

    loadingArmsUnavailability: 'Loading arms unavailability',
    newLoadingArmUnavailability: 'New',
    editLoadingArmUnavailability: 'Edit',

    loadingAuthorizations: 'Loading authorizations',
    totalAppointments: 'Appointments',

    appointmentsScheduling: 'Appointments scheduling',

    productsUnavailability: 'Generic products unavailabilities',
    editProductUnavailability: 'Edit',
    newProductUnavailability: 'New',

    new: 'New',
    edit: 'Edit'
  },
  home: {
    reports: 'Reports',
    shops(count) {
      return count === 1 ? 'Place' : 'Places'
    },
    users: 'Users',
    messageTemplates: 'Messages templates',
    translations: 'Translations',
    genericProducts: 'Generic products',
    transportCompanies: 'Transport companies',
    loadingAuthorizations: 'Loading authorizations',
    appointmentsScheduling: 'Appointments scheduling',
    productsUnavailabilities: 'Generic products unavailabilities'
  },
  shopList: {
    addButton: 'New place'
  },
  shopView: {
    lines(count) {
      return count === 1 ? 'Line' : 'Lines'
    },
    checkpoints(count) {
      return count === 1 ? 'Track' : 'Tracks'
    },
    name: 'Name',
    description: 'Description',
    position: 'Position',
    resetStatistics: 'Reset statistics',
    daysOff: 'Days off',

    gpsPosition: 'GPS position',

    address: 'Address',
    city: 'City',
    zipCode: 'Zip code',
    location: 'Location',
    moreInfo: 'More info',
    timeZone: 'Time zone',
    expeditionEmail: 'Expedition email',
    messageTemplates: 'Messages templates',
    loadingArms: 'Loading arms',
    unavailabilityAndAppointments: 'Unavailability and appointments',
    limitInDays: 'Number of opening days of the schedule',
    limitInDaysToFollowUp: 'Number of days before reminder email for non-confirmed appointment',
    limitInDaysToCancel: 'Number of days before cancelling a non-confirmed appointment'
  },
  unavailabilityAndAppointments: {
    pointOfServicesUnavailability: 'Tracks unavailability',
    loadingArmsUnavailability: 'Loading arms unavailability',
    appointments: 'Appointments',
    appointmentsToCancel: 'Appointments to cancel'
  },
  genericProductEdit: {
    code: 'Code',
    name: 'Name'
  },
  genericProductOperationMessages: {
    getError: 'Unable to get the generic product',
    createSuccess: 'The generic product was successfully created',
    createError: 'Unable to create the generic product',
    deleteSuccess: 'The generic product was successfully deleted',
    deleteError: 'Unable to delete the generic product',
    updateSuccess: 'The generic product was successfully updated',
    updateError: 'Unable to update the generic product'
  },
  genericProductList: {
    add: 'Add',
    search: 'Search'
  },
  loadingArmsList: {
    add: 'Add',
    search: 'Search',
    genericProducts: 'Generic products: ',
    servicePoints: 'Tracks: '
  },
  loadingArmOperationMessages: {
    getError: 'Unable to get the loading arm',
    createSuccess: 'The loading arm was successfully created',
    createError: 'Unable to create the loading arm',
    deleteSuccess: 'The loading arm was successfully deleted',
    deleteError: 'Unable to delete the loading arm',
    updateSuccess: 'The loading arm was successfully updated',
    updateError: 'Unable to update the loading arm'
  },
  loadingArmEdit: {
    code: 'Code',
    name: 'Name',
    genericProducts: 'Generic products',
    servicePoints: 'Tracks',
    addGenericProduct: 'Add',
    addServicePoint: 'Add',
    priority: 'Arm suggestion priority over all places (bigger the value = higher is the priority)'
  },
  transportCompanyEdit: {
    name: 'Name',
    code: 'SAP code',
    isActive: 'Is active',
    addUser: 'Add user',
    email: 'Email',
    language: 'Language',
    companyCreatedMessage: 'The transport company was created',
    companyDeletedMessage: 'The transport company was deleted',
    companyUpdatedMessage: 'The transport company was updated',
    alreadyExistedEmailMessage: 'Another transport company exists with the same email'
  },
  transportCompanyUserEdit: {
    name: 'Name',
    email: 'Email',
    isActive: 'Is active',
    resetPassword: 'Reset password',
    lastConnection: 'Last connection',
    language: 'Language',
    userCreatedMessage: 'The user was succesfully created',
    userCreatedErrorMessage: 'User creation failed',
    userDeletedMessage: 'The user was succesfully deleted',
    userUpdateMessage: 'The user was updated',
    userUpdateErrorMessage: 'User update failed',
    userPasswordResetMessage: 'The user password was reset'
  },
  uploadOperationMessages: {
    uploadSuccess: 'Updated successfully',
    uploadError: 'Error during import',
    importFinish: 'Import was finished.',
    lanSuccess: 'records imported.',
    lanFailed: 'records failed.'
  },
  loadingAuthorizations: {
    lanAndClient: (lan, client, countryCode) => `${lan} for ${client}, ${countryCode}`,
    products: 'Products:',
    singleProducts: 'Product:',
    product: (qty, measureUnit, name, code) => `${qty} ${measureUnit} of ${name} / ${code}`,
    appointmentTr: 'Appointment',
    appOn: 'on',
    appIn: 'in',
    appA: 'in',
    appAt: 'at',
    validityPeriod(startValidityDate, endValidityDate) {
      return `Valid between ${startValidityDate} and ${endValidityDate}`
    },
    relatedCommercialProducts(commercialProducts) {
      const separatedCP = commercialProducts.join(', ')
      return `Related commercial products: ${separatedCP}`
    },
    deliveredCustomer(clientName, countryCode) {
      return `Delivered customer: ${clientName}, ${countryCode}`
    },
    appointmentTaken: 'Appointment taken on',
    appointmentTakenRange(appointmentDate, appointmentHour) {
      return `${appointmentDate}, at ${appointmentHour}`
    },
    soloProductInfo(product, appointmentDate) {
      return `- at ${appointmentDate} for the product ${product}`
    },
    noAppointmentYet: 'No appointment yet',
    search: 'Search',
    lan: 'LAN:',
    genericProd: (count) => (count > 1 ? `Generic products:` : `Generic product:`),
    productsConcerned: (count) => (count > 1 ? `Commercial products:` : `Commercial product:`)
  },
  totalAppointments: {
    flexiTank: 'Is flexitank',
    orderQuantity: 'Order quantity',
    appIn: 'in',
    appA: 'in',
    appAt: 'at',
    forCustomer: 'for',
    ofDelivery: 'of',
    define: 'To define',
    deliveredCustomer: 'Delivered customer',
    relatedGenericProducts: (count) => (count < 2 ? 'Product' : 'Products'),
    appointmentTaken(appointmentNumber) {
      return `№ ${appointmentNumber}, on`
    },
    appointmentTakenTime(appointmentDate, appointmentHour, shopName, service, arm, single) {
      const translation = [
        `The ${appointmentDate}`,
        single && `, at ${appointmentHour}`,
        single && ` in ${shopName}`,
        single && ` \n ${service}`,
        single && arm && ` - ${arm}`
      ].filter(Boolean) as string[]

      return translation
    },
    soloProductInfo(product, appointmentDate) {
      return `- at ${appointmentDate} for the product ${product}`
    },
    loadingAuthorisationNumber: 'Loading authorisation number',
    transportCompany: 'Transport company',
    driver: 'Driver',
    state: 'State',
    reason: 'Reason',
    internalComment: 'Internal comment',
    declareFinished: 'Finished',
    declareArrived: 'Arrived',
    declareAbsent: 'Absent',
    update: 'Update',
    cancel: 'Cancel',
    search: 'Search',
    cancelAppointmentMessage: 'Cancel the appointment?',
    notFound: 'No appointment found',
    rdvCode: 'RDV code',
    sapCommand: 'SAP order',
    createAppointment: 'Create appointment',
    createdBy(userName, date) {
      return `Created by ${userName} on ${date}`
    },
    updatedBy(userName, date) {
      return `Updated by ${userName} on ${date}`
    },
    dateBy(userName, date) {
      return `on ${date} by ${userName}`
    },
    attachmentsOptional: 'Attachments',
    updateCheckpointSuccess: `Track unavailability was succesfully updated`,
    updateCheckpointError: `Track unavailability update failed`,
    createCheckpointSuccess: `Track unavailability was succesfully created`,
    createCheckpointError: `Track unavailability create failed`,
    deleteCheckpointSuccess: `Track unavailability was deleted`,
    deleteCheckpointError: `Track unavailability delete faile`
  },
  totalAppointmentStatuses: {
    valid: 'Valid',
    toBeCancelled: 'To be cancelled',
    cancelled: 'Cancelled',
    arrived: 'Arrived',
    absent: 'Absent',
    toBeConfirmed: 'Waiting for confirmation',
    finished: 'Finished',
    unknown: 'Unknown'
  },
  unavailabilityEdit: {
    comments: 'Comments',
    beginningOfUnavailability: 'Beginning of unavailability',
    endOfUnavailability: 'End of unavailability'
  },
  totalPlanning: {
    unavailabilityOfServicePoint: 'Unavailability of track'
  },
  shopEdit: {
    name: 'Name',
    namePlaceholder: 'Place name',
    description: 'Description',
    descriptionPlaceholder: 'Place description',
    latitude: 'Latitude',
    latitudePlaceholder: 'Latitude',
    longitude: 'Longitude',
    longitudePlaceholder: 'Longitude',
    brand: 'Brand',
    timeZone: 'Time zone',
    daysOff: 'Days off',
    addDay: 'Add day off',
    add: 'Add',
    repeatDay: 'Repeat every year',
    period: 'Period',
    save: 'Save',
    address: 'Address',
    addressPlaceholder: 'Address',
    city: 'City',
    cityPlaceholder: 'City',
    zipCode: 'Zip code',
    zipCodePlaceholder: 'Zip code',
    expeditionEmail: 'Expedition email',
    expeditionEmailPlaceholder: 'Email',
    expeditionPassword: 'Expedition password',
    expeditionPasswordPlaceholder: 'Password',
    expedition: 'Expedition',
    location: 'Location',
    exportDataIsAvailable: 'Export data model is available',
    limitInDays: 'Future dates proposition limits',
    limitInDaysPlaceholder: 'Days',
    limitInDaysToFollowUp: 'Days before appointment to be confirmed follow up',
    limitInDaysToFollowUpPlaceholder: 'Days',
    limitInDaysToCancel: 'Days to cancel the appointment to be confirmed',
    limitInDaysToCancelPlaceholder: 'Days',
    weekdays: 'Weekdays',
    selectNotWorkingWeekdays: 'Select not working weekdays',
    externalCode: 'External code'
  },
  shopOperationMessages: {
    getError: 'Unable to get the place',
    createSuccess: 'The place was successfully created',
    createError: 'Unable to create the place',
    deleteSuccess: 'The place was successfully deleted',
    deleteError: 'Unable to delete the place',
    updateSuccess: 'The place was successfully updated',
    updateError: 'Unable to update the place',
    resetStatisticsSuccess: 'The statistics successfully resetted',
    resetStatisticsError: 'Unable to reset the statistics',
    updateCheckpointSuccess: `Track unavailability was succesfully updated`,
    updateCheckpointError: `Track unavailability update failed`,
    createCheckpointSuccess: `Track unavailability was succesfully created`,
    createCheckpointError: `Track unavailability create failed`,
    deleteCheckpointSuccess: `Track unavailability was deleted`,
    deleteCheckpointError: `Track unavailability delete faile`,
    updateLoadingArmSuccess: `The arm unavailability was succesfully updated`,
    updateLoadingArmError: `The arm unavailability update has failed`,
    createLoadingArmSuccess: `The arm unavailability was succesfully created`,
    createLoadingArmError: `The arm unavailability create has failed`,
    deleteLoadingArmSuccess: `The arm unavailability was deleted`,
    deleteLoadingArmError: `The arm unavailability delete has failed`
  },
  lineList: {
    addButton: 'New line'
  },
  lineView: {
    name: 'Name',
    category: 'Category',
    serviceTime: 'Average service time',
    timeToReach: 'Average time to reach the track',
    maxTimeToReach: 'Maximum time to reach the track',
    time(minutes) {
      return `${minutes} ${pluralization(minutes, ['minute', 'minutes'])}`
    },
    seconds(seconds) {
      return `${seconds} ${pluralization(seconds, ['second', 'seconds'])}`
    },
    checkpoints(count) {
      return count === 1 ? 'Track' : 'Tracks'
    },
    openingHour: 'Opening hour',
    closingHour: 'Closing hour',
    manageAppointments: 'Manage appointments',
    manageAppointmentsValue(value) {
      return value ? 'Yes' : 'No'
    },
    maxSimultaneous: 'Maximum simultaneous participants accepted',
    maxSimultaneousValue(count) {
      return `${count} ${pluralization(count, ['person', 'people'])}`
    },
    modes: 'Available modes',
    shortName: 'Short name',
    messageTemplates: 'Messages templates',
    monitoring: 'Timeslots monitoring',
    smsCredits: 'SMS credits',
    staffManagement: 'Availability',
    allowFutureAppointments: 'Allow appointments for future date',
    attachIcsFile: 'Attach an ics file to the confirmation email',
    authoriseAppointmentOnWebPortal: 'Authorise the appointment for the same day on web portal',
    blindCopyEmails: 'Email to add in blind copy to appointments confirmations',
    nonStopService: 'Non stop service',
    nonStopServiceValue(value) {
      return value ? 'Yes' : 'No'
    },
    nearServicePointDistance: 'Near track distance',
    nearServicePointDistanceValue(value) {
      return pluralization(value, ['meter', 'meters'])
    },
    parameters: 'Parameters',
    appointmentForNextDay: 'Appointment for next day',
    openingHours: 'Opening hours',
    durations: 'Durations',
    servicesCollection: 'Services collection'
  },
  lineEdit: {
    name: 'Name',
    namePlaceholder: 'Line name',
    category: 'Category',
    serviceTime: 'Average service time',
    timeToReach: 'Average time to reach the track',
    maxTimeToReach: 'Maximum time to reach the track',
    timePlaceholder: 'minutes',
    secondsPlaceholder: 'seconds',
    timeHint(minutes) {
      return pluralization(minutes, ['minute', 'minutes'])
    },
    secondsHint(second) {
      return pluralization(second, ['second', 'seconds'])
    },
    openingHour: 'Opening hour (change applied from next day)',
    closingHour: 'Closing hour (change applied from next day)',
    manageAppointments: 'Manage appointments',
    maxSimultaneous: 'Maximum simultaneous participants accepted',
    maxSimultaneousHint(count) {
      return pluralization(count, ['person', 'people'])
    },
    maxSimultaneousPlaceholder: 'People',
    modes: 'Modes',
    shortName: 'Short name',
    shortNamePlaceholder: 'Short name',
    allowFutureAppointments: 'Allow appointments for future date',
    servicesCollection: 'Services collection',
    serviceName: 'Service name',
    serviceDuration: 'Duration',
    addService: 'Add service',
    attachIcsFile: 'Attach an ics file to the confirmation email',
    authoriseAppointmentOnWebPortal: 'Authorise the appointment for the same day on web portal',
    blindCopyEmails: 'Email to add in blind copy to appointments confirmations',
    blindCopyEmailsPlaceholder: 'Email',
    nearServicePointDistance: 'Near track distance',
    nearServicePointDistancePlaceholder: 'Meters',
    nearServicePointDistanceHint(value) {
      return pluralization(value, ['meter', 'meters'])
    },
    clientsNotificationsGroup: "Client's notifications",
    beforeTheCall: 'Before the call',
    beforeTheCallTime: 'Before',
    whenCalled: 'When called',
    whenServiceStartedByAgent: 'When service is started by an agent',
    whenServiceEndedByAgent: 'When service ended by an agent',
    nonStopService: 'Non stop service',
    parameters: 'Parameters',
    appointmentForNextDay: 'Appointment for next day',
    openingHours: 'Opening hours',
    durations: 'Durations'
  },
  lineSignUpModes: {
    never: 'Never',
    wifi: 'When connected by WIFI',
    always: 'Always'
  },
  lineModes: {
    mobileApp: 'Smartphone',
    sms: 'SMS'
  },
  lineOperationMessages: {
    getError: 'Unable to get the line',
    createSuccess: 'The line was successfully created',
    createError: 'Unable to create the line',
    deleteSuccess: 'The line was successfully deleted',
    deleteError: 'Unable to delete the line',
    updateSuccess: 'The line was successfully updated',
    updateError: 'Unable to update the line'
  },
  checkpointStatus: {
    finished(time, firstName, secondName) {
      return `Finished at ${time} by ${firstName} ${secondName}`
    },
    finishing(time, firstName, secondName) {
      return `Finishing from ${time} by ${firstName} ${secondName}`
    },
    started(time, firstName, secondName) {
      return `Started at ${time} by ${firstName} ${secondName}`
    },
    starting(time, firstName, secondName) {
      return `Starting from ${time} by ${firstName} ${secondName}`
    },
    paused(time, firstName, secondName) {
      return `Paused at ${time} by ${firstName} ${secondName}`
    }
  },
  checkpointList: {
    addButton: 'New track',
    monitoring: 'Monitoring',
    empty: 'There are no track yet'
  },
  checkpointEdit: {
    name: 'Name',
    namePlaceholder: 'Track name',
    description: 'Description',
    descriptionPlaceholder: 'Short description',

    loadingOfFluids: 'Loading of bulk products',
    loadingFluidsFlexitank: 'Loading of bulk products in flexitank',
    loadingOfConventionalCharges: 'Loading of packaged goods'
  },
  checkpointOperationMessages: {
    getError: 'Unable to get the track',
    createSuccess: 'The track was successfully created',
    createError: 'Unable to create the track',
    deleteSuccess: 'The track was successfully deleted',
    deleteError: 'Unable to delete the track',
    updateSuccess: 'The track was successfully updated',
    updateError: 'Unable to update the track'
  },
  checkpointMonitoring: {
    applyButton: 'Apply',
    closingTime: 'Close time',
    startTime: 'Start time',
    time(minutes) {
      return `${minutes} ${pluralization(minutes, ['minute', 'minutes'])}`
    },
    apply: 'Apply',
    updateCheckpointHostSuccessMessage: 'Track settings succesfully applied',
    updateCheckpointHostFailureMessage: ' Failed to apply the track parameters',
    availableAnyHour: 'Available at any hour when opened',
    normalLineHoursAppliedMorning: 'Normal line hours will be applied in the morning'
  },
  userList: {
    addButton: 'New user',
    withInactive: 'With inactives',
    search: 'Search'
  },
  userEdit: {
    firstName: 'First name',
    firstNamePlaceholder: 'First name',
    secondName: 'Last name',
    secondNamePlaceholder: 'Last name',
    email: 'Email',
    emailPlaceholder: 'Email',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    password: 'Password',
    passwordPlaceholder: 'Password',
    interfaceLanguage: 'Language',
    addShopButton: 'Add place',
    shops: 'Places',
    changePassword: 'Change password',
    rights: 'Rights and parameters',
    isAdmin: 'Administrator',
    isActive: 'Is active',
    canManageUserAccounts: 'Manage user accounts',
    canAddAndDeleteShops: 'Create and delete places',
    canUpdateShops: 'Update places',
    manageAppointments: 'Manage appointments',
    manageTransportCompanies: 'Manage transport companies',
    canOpenAndCloseCheckpoints: 'Open and close tracks',
    ruleSecurePasswordInputMessage:
      'The password should be 8 characters long  with at least one small character, one capital character, one digit and one special character',
    canUpdateServicePointStartAndEndHours: 'Update track start and end hours',
    countries: 'Countries (two characters ISO codes separated by semicolumns)',
    allCountries: 'All countries',
    igg: 'IGG',
    limitedView: 'Limited to view of scheduler, loading authorisations, data export'
  },
  userOperationMessages: {
    getError: 'Unable to get the user',
    createSuccess: 'The user was successfully created',
    createError: 'Unable to create the user',
    deleteSuccess: 'The user was successfully deleted',
    deleteError: 'Unable to delete the user',
    updateSuccess: 'The user was successfully updated',
    updateError: 'Unable to update the user',
    passwordNotSecure: 'Set your new password',
    iggError: 'The IGG value is already used',
    emailError: 'This email is already used',
    error: 'Server error'
  },
  userInAppBar: {
    exitButton: 'Exit',
    settingsButton: 'Settings'
  },
  colorLabel: {
    emptyPlaceholder: 'No'
  },
  fileUploader: {
    uploadButton: 'Upload'
  },
  reportsFilter: {
    startDate: 'Start date',
    endDate: 'End date',
    upperDate: 'Upper date',
    date: 'Date'
  },
  customer: {
    customer: 'User',
    applicationUsage: 'Application usage',
    gender: 'Gender',
    email: 'Email',
    phone: 'Phone',
    device: 'Device',
    application: 'Application',
    age: 'Age',
    applicationUsageValue(count, firstUse, lastUse) {
      return `${count} ${pluralization(count, ['time', 'times'])} between ${firstUse} and ${lastUse}`
    },
    genderValue(gender) {
      return gender === 1 ? 'Female' : 'Male'
    },
    customerValue(id, name) {
      return `User ${id}${name ? ', ' + name : ''}`
    }
  },
  time: {
    sec(val) {
      return `${val} sec`
    },
    min(val) {
      return `${val} min`
    },
    seconds(val) {
      return `${val} ${pluralization(val, ['second', 'seconds'])}`
    },
    minutes(val) {
      return `${val} ${pluralization(val, ['minute', 'minutes'])}`
    },
    days(val) {
      return `${val} ${pluralization(val, ['day', 'days'])}`
    },
    daysHint(val) {
      return pluralization(val, ['day', 'days'])
    },
    minutesHint(minutes) {
      return pluralization(minutes, ['minute', 'minutes'])
    },
    minutesPlaceholder: 'minutes'
  },
  conj: {
    and: 'and'
  },
  monitoring: {
    autoRefresh: 'Auto refresh',
    notActive: 'The monitoring will be started\nwith first user connection',
    lineName(name) {
      return `Line: ${name}`
    },
    linePeopleCount(count) {
      return `${count} ${pluralization(count, ['person', 'people'])} in the line`
    },
    lineServedPeopleCount(count) {
      return `${count}  ${pluralization(count, ['person', 'people'])} served today`
    },
    lineAvApproachTime: 'Average approach time',
    lineAvLargeBagService: 'Average large bag service',
    lineAvSmallBagService: 'Average small bag service',
    lineLowerAlertTime: 'Lower alert time',
    lineUpperAlertTime: 'Upper alert time',
    lineOverchargedAlertTime: 'Overcharged alert time',
    lineWaitingTime: 'Waiting time',
    timeslotState: {
      served: 'Served',
      serving: 'Serving',
      late: 'Late',
      waiting: 'Waiting'
    },
    lineState: {
      late(time) {
        return time === 1 ? 'one minute late' : `${time} minutes late`
      },
      onTime() {
        return 'on time'
      },
      inAdvance(time) {
        return time === 1 ? 'one minute in advance' : `${time} minutes in advance`
      }
    },
    lineReservationCapacity(capacity, total) {
      return `${total - capacity} free and reserved ${capacity} out of ${total}`
    },
    positionId(id) {
      return `ID ${id}`
    },
    positionStatusJoined(time) {
      return `Joined at ${time}`
    },
    positionStatusCalled(time, checkpoint) {
      return `Called to the ${checkpoint} at ${time}`
    },
    positionStatusMoving(time, checkpoint) {
      return `Moving to the ${checkpoint} from ${time}`
    },
    positionStatusNearCheckpoint(time, checkpoint) {
      return `Approached to the ${checkpoint} at ${time}`
    },
    positionStatusInService(time, checkpoint) {
      return `In service at the ${checkpoint} from ${time}`
    },
    positionSmallBasket(size) {
      return `Less then ${size} articles`
    },
    positionLargeBasket(size) {
      return `More then ${size} articles`
    },
    positionPeopleAhead(count) {
      return `${count} ${pluralization(count, ['person', 'people'])} ahead`
    },
    positionExpectedServiceTime: 'Expected service time',
    positionServiceTime: 'Service time',
    positionWaitingServiceTime: 'Waiting to start the service',
    positionWaitingToCallTime: 'Waiting to call',
    positionJoinedTime: 'Joined',
    positionCalledTime: 'Called',
    positionApproachedTime: 'Approached',
    positionLastRequest: 'Last request',
    positionAsapWaitingMode: 'ASAP',
    positionAppointmentWaitingMode(from, to) {
      return `Appointed between ${from} and ${to}`
    },
    noPositions: 'Nobody in the line',
    checkpointStatusStarted(dateTime) {
      return `Opened on ${dateTime}`
    },
    checkpointStatusFinished(dateTime) {
      return `Finished on ${dateTime}`
    },
    checkpointStatusPaused(dateTime) {
      return `Paused on ${dateTime}`
    },
    checkpointFinishing: 'Finishing',
    checkpointStarting: 'Starting',
    checkpointWaitingTime: 'Waiting time',
    checkpointServedPeopleCount(count) {
      return `${pluralization(count, ['Person', 'People'])} served`
    },
    checkpointPeopleMovingCount(count) {
      return `${pluralization(count, ['Person', 'People'])} moving`
    },
    checkpointPeopleServingCount(count) {
      return `${pluralization(count, ['Person', 'People'])} serving`
    },
    people(count) {
      return `${count} ${pluralization(count, ['person', 'people'])}`
    },
    peopleWithMobileApp: 'Smartphone',
    peopleWithPaperTicket: 'Ticket',
    peopleWithSms: 'SMS',
    peopleWithNfcCard: 'NFC',
    peopleTotal: 'Total',
    reservation: 'Reservation',
    reservationGroups: 'APPT',
    moreInfo: 'More info',
    offlineTime(time) {
      return `Since ${time}`
    },
    reservationCapacity(capacity, total) {
      return `Reserved ${capacity} out of ${total}`
    }
  },
  messageTemplates: {
    description: 'Description',
    language: 'Language',
    messageType: 'Message type',
    pluralization: 'Plural and singular',
    text: 'Text',
    textPlaceholder: 'Text',
    save: 'Save',
    keywords: 'Merge fields',
    success: 'The message was successfully udpated',
    error: 'Error occured during the message update',
    help: 'Help',
    applyGlobal: 'Apply to current template message',
    parentMessage: 'Parent template message'
  },
  staffManagementEdit: {
    startDate: 'Availability change date',
    startHour: 'Start hour',
    endHour: 'End hour',
    comments: 'Comment',
    commentsPlaceholder: 'comment'
  },
  staffManagement: {
    startDate: 'Availability change date',
    startHour: 'Start hour',
    endHour: 'End hour',
    comments: 'Comment',
    addRecord: 'Add record',
    uploadSuccess: 'Availability data imported with success',
    uploadError: 'Failed import of availability',
    upload: 'Upload availability'
  },
  staffManagementOperationMessages: {
    getError: 'Unable to get the record',
    createSuccess: 'The record was successfully created',
    createError: 'Unable to create the record',
    deleteSuccess: 'The record was successfully deleted',
    deleteError: 'Unable to delete the record',
    updateSuccess: 'The record was successfully updated',
    updateError: 'Unable to update the record'
  },
  appointment: {
    line: 'Line',
    date: 'Date',
    hour: 'Time',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    details: 'Details',
    email: 'Email',
    phone: 'Phone number',
    service: 'Service',
    comments: 'Comments',
    staffComments: 'Internal comments',
    shop: 'Place',
    datePlaceholder: 'Date',
    startHour: 'Start hour',
    endHour: 'End hour',
    namePlaceholder: 'Name',
    emailPlaceholder: 'Email',
    phonePlaceholder: 'Phone number',
    servicePlaceholder: 'Service',
    commentsPlaceholder: 'Comments',
    staffCommentsPlaceholder: 'Internal comments',
    edit: 'Edit',
    next: 'Next',
    create: 'Create',
    lineAndService: 'Line and service',
    dateAndTime: 'Time',
    customer: 'Customer',
    confirmation: 'Confirmation',
    newCustomer: 'New customer'
  },
  appointments: {
    prevDay: 'Previous day',
    nextDay: 'Next day',
    add: 'Add appointment'
  },
  appointmentOperationMessages: {
    getError: 'Unable to get the appointment',
    createSuccess: 'The appointment was successfully created',
    createError: 'Unable to create the appointment',
    deleteSuccess: 'The appointment was successfully deleted',
    deleteError: 'Unable to delete the appointment',
    updateSuccess: 'The appointment was successfully updated',
    updateError: 'Unable to update the appointment',
    timeRangeNoAvailable:
      'The time range you requested is no longer available. This may due to another person just made a reservation or availabilities change. Please, select another time range'
  },
  groupsReservations: {
    uploadInfo(firstName, secondName, dateTime) {
      return `Imported on ${dateTime} by ${firstName} ${secondName}`
    },
    uploadSuccess: 'Reservations imported successfully',
    uploadDuplicateError: 'Unable to import the file. It contains duplicate reservation codes.',
    uploadError: 'File upload error',
    forceUpdateGroupsReservations: 'Sync current day',
    forceUpdateGroupsReservationsSuccessMessage: 'The sync is done',
    forceUpdateGroupsReservationsFailureMessage: 'The sync failed'
  },
  exportData: {
    line: 'Line',
    shop: 'Place',
    allLines: 'All',
    allShops: 'All',
    startDate: 'Start date',
    endDate: 'End date',
    exportButton: 'Export'
  },
  counterOperationMessages: {
    getError: 'Unable to get the counter',
    createSuccess: 'The counter was successfully created',
    createError: 'Unable to create the counter',
    deleteSuccess: 'The counter was successfully deleted',
    deleteError: 'Unable to delete the counter',
    updateSuccess: 'The counter was successfully updated',
    updateError: 'Unable to update the counter'
  },
  translationList: {
    addButton: 'New translation'
  },
  translationEdit: {
    sourceText: 'Source text',
    translationText: 'Translation',
    brand: 'Brand',
    language: 'Destination language',
    addTranslation: 'Add translation',
    deleteTitle: 'Delete translation',
    translationsFor: 'Translations for'
  },
  translationOperationMessages: {
    getError: 'Unable to get the translation',
    createSuccess: 'The translation was successfully created',
    createError: 'Unable to create the translation',
    deleteSuccess: 'The translation was successfully deleted',
    deleteError: 'Unable to delete the translation',
    updateSuccess: 'The translation was successfully updated',
    updateError: 'Unable to update the translation'
  },
  loadingAuthorisationsCountriesEdit: {
    loadingAuthorisationsCountries: 'Loading authorisations countries',
    countries: 'Countries (two characters ISO codes separated by semicolumns)',
    save: 'Save'
  },
  authorizationResearch: {
    research: 'Research',
    search: 'Search',
    yourAuthorizationNumber: 'Your authorization number',
    customerCountryDelivered: 'Customer country delivered',
    loadingWithFlexitank: 'Loading with flexitank',
    update: 'Update',
    create: 'Take an appointment',
    dateAndHour: 'Date and hour',
    attachedDocuments: 'Attached documents',
    securityInformation: 'Security information',
    rdvShortInfo(startDate, startDateHour) {
      return `RDV already taken on ${startDate}, at ${startDateHour}`
    },
    authorizationFound: 'Loading authorisation was found',
    authorizationNotFound: 'Loading authorisation was not found',
    statuses: {
      toBeConfirmed: 'waiting for confirmation',
      absent: 'absent',
      arrived: 'arrived',
      cancelled: 'cancelled',
      toBeCancelled: 'to be cancelled',
      valid: 'confirmed',
      finished: 'finished'
    },
    errorParse: 'Failed to parse products'
  },
  totalAuthorization: {
    authorizationNumber: 'Your authorization number',
    validityPeriod: 'Validity period',
    productsConcerned: (num) => (num < 2 ? 'Commercial product' : 'Commercial products'),
    genericProducts: (num) => (num < 2 ? 'Product' : 'Products'),
    customerDelivered: 'Delivered customer',
    period(startDate, endDate) {
      return `Between ${startDate} and ${endDate}`
    },
    timeWithDate(date, hour) {
      return `at ${date} in ${hour}`
    },
    startDateInvalidText:
      'Sorry, your loading authorisation is beyond the current appointment maximum date. Please try again few days later.',
    endDateInvalidText:
      'Attention, some days of you loading authorisation validity period are not yet available for booking. The proposed date list will be limited.',
    loadingAutorisationExpired: 'Your loading autorisation is expired.'
  },
  totalAppointmentEdit: {
    date: 'Date',
    time: 'Time',
    comment: 'Comment',
    transportCompany: 'Transport company',
    driverFirstName: 'Driver first name',
    driverLastName: 'Driver last name',
    place: 'Place',
    checkpoint: 'Track',
    state: 'Status',
    noDateAvailable: 'No slot available. Please contact your correspondent',
    duing(hours) {
      return `duing ${hours} ${pluralization(hours, ['hour', 'hours'])}`
    }
  },
  countriesCodes: {
    ad: 'Andorra',
    ae: 'United Arab Emirates',
    af: 'Afghanistan',
    ag: 'Antigua and Barbuda',
    ai: 'Anguilla',
    al: 'Albania',
    an: 'Antilles neerlandaises',
    am: 'Armenia',
    ao: 'Angola',
    aq: 'Antarctica',
    ar: 'Argentina',
    as: 'American Samoa',
    at: 'Austria',
    au: 'Australia',
    aw: 'Aruba',
    ax: 'Åland Islands',
    az: 'Azerbaijan',
    ba: 'Bosnia and Herzegovinia',
    bb: 'Barbados',
    bd: 'Bangladesh',
    be: 'Belgium',
    bf: 'Burkina Fasa',
    bg: 'Bulgaria',
    bh: 'Bahrain',
    bi: 'Burundi',
    bj: 'Benin',
    bl: 'Saint-Barthélemy',
    bm: 'Bermuda',
    bn: 'Brunei',
    bo: 'Bolivia',
    bq: 'Bonaire, Sint Eustatius and Saba',
    br: 'Brazil',
    bs: 'Bahamas',
    bt: 'Bhutan',
    bv: 'Bouvet Island',
    bw: 'Botswana',
    by: 'Belarus',
    bz: 'Belize',
    ca: 'Canada',
    cc: 'Cocos (Keeling) Islands',
    cd: 'Democratic Republic of Congo',
    cf: 'Central African Republic',
    cg: 'Congo',
    ch: 'Switzerland',
    ci: "Ivory Coast (Cote d'Ivoire)",
    ck: 'Cook Island',
    cl: 'Chile',
    cm: 'Cameroon',
    cn: 'China',
    co: 'Colombia',
    cr: 'Costa Rica',
    cu: 'Cuba',
    cv: 'Cabo Verde',
    cw: 'Curaçao',
    cx: 'Christmas Island',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    de: 'Germany',
    dj: 'Djibouti',
    dk: 'Denmark',
    dm: 'Dominica',
    do: 'Dominican Republic',
    dz: 'Algeria',
    ec: 'Ecuador',
    ee: 'Estonia',
    eg: 'Egypt',
    eh: 'Western Sahara',
    er: 'Eritrea',
    es: 'Spain',
    et: 'Ethiopia',
    fi: 'Finland',
    fj: 'Fiji',
    fk: 'Falkland Islands',
    fm: 'Federated State of Micronesia',
    fo: 'Faroe Islands',
    fr: 'France',
    ga: 'Gabon',
    gb: 'United Kingdom',
    gd: 'Grenada',
    ge: 'Georgia',
    gf: 'French Guiana',
    gg: 'Guernsey',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gl: 'Greenland',
    gm: 'Gambia',
    gn: 'Guinea',
    gp: 'Guadeloupe',
    gq: 'Equatorial Guinea',
    gr: 'Greece',
    gs: 'South Georgia and the South Sandwich Islands',
    gt: 'Guatemala',
    gu: 'Guam',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    hk: 'Hong Kong',
    hm: 'Heard Island and McDonald Islands',
    hn: 'Honduras',
    hr: 'Croatia',
    ht: 'Haïti',
    hu: 'Hungary',
    id: 'Indonesia',
    ie: 'Ireland',
    il: 'Israël',
    im: 'Isle of Man',
    in: 'India',
    io: 'British Indian Ocean Territory',
    iq: 'Iraq',
    ir: 'Iran ',
    is: 'Iceland',
    it: 'Italia',
    je: 'Jersey',
    jm: 'Jamaica',
    jo: 'Jordan',
    jp: 'Japan',
    ke: 'Kenya',
    kg: 'Kyrgyzstan',
    kh: 'Cambodia',
    ki: 'Kiribati',
    km: 'Comoros',
    kn: 'Saint Kitts and Nevis',
    kp: 'North Korea',
    kr: 'South Korea',
    kw: 'Kuwait',
    ky: 'Cayman Islands',
    kz: 'Kazakhstan',
    la: 'Laos',
    lb: 'Lebanon',
    lc: 'Saint Lucia',
    li: 'Liechtenstein',
    lk: 'Sri Lanka',
    lr: 'Liberia',
    ls: 'Lesotho',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    lv: 'Latvia',
    ly: 'Libya',
    ma: 'Morroco',
    mc: 'Monaco',
    md: 'Moldova',
    me: 'Montenegro',
    mf: 'Saint Martin',
    mg: 'Madagascar',
    mh: 'Marshall Islands',
    mk: 'North Macedonia',
    ml: 'Mali',
    mm: 'Myanmar',
    mn: 'Mongolia',
    mo: 'Macao',
    mp: 'Northern Mariana Islands',
    mq: 'Martinique',
    mr: 'Mauritania',
    ms: 'Montserrat',
    mt: 'Malta',
    mu: 'Mauritius',
    mv: 'Maldives',
    mw: 'Malawi',
    mx: 'Mexico',
    my: 'Malaysia',
    mz: 'Mozambique',
    na: 'Namibia',
    nc: 'New Caledonia',
    ne: 'Niger',
    nf: 'Norfolk Island',
    ng: 'Nigeria',
    ni: 'Nicaragua',
    nl: 'Netherlands (the)',
    no: 'Norway',
    np: 'Nepal',
    nr: 'Nauru',
    nu: 'Niue',
    nz: 'New Zealand',
    om: 'Oman',
    pa: 'Panama',
    pe: 'Peru',
    pf: 'French Polynesia',
    pg: 'Papua New-Guinea',
    ph: 'Philippines (the)',
    pk: 'Pakistan',
    pl: 'Poland',
    pm: 'Saint Pierre et Miquelon',
    pn: 'Pitcairn',
    pr: 'Puerto Rico',
    ps: 'Palestine, State of',
    pt: 'Portugal',
    pw: 'Palau',
    py: 'Paraguay',
    qa: 'Qatar',
    re: 'Réunion',
    ro: 'Romania',
    rs: 'Serbia',
    ru: 'Russia',
    rw: 'Rwanda',
    sa: 'Saudi Arabia',
    sb: 'Solomon Islands',
    sc: 'Seychelles',
    sd: 'Sudan (the)',
    se: 'Sweden',
    sg: 'Singapore',
    sh: 'Saint Helena, Ascension and Tristan da Cunha',
    si: 'Slovenia',
    sj: 'Svalbard and Jan Mayen',
    sk: 'Slovakia',
    sl: 'Sierra Leone',
    sm: 'San Marino',
    sn: 'Senegal',
    so: 'Somalia',
    sr: 'Suriname',
    ss: 'South Sudan',
    st: 'Sao Tome and Principe',
    sv: 'El Salvador',
    sx: 'Sint Maarten (Dutch part)',
    sy: 'Syrian Arab Republic (the) ',
    sz: 'Eswatini ',
    tc: 'Turks and Caicos Islands (the)',
    td: 'Chad',
    tf: 'French Southern and Antarctic Lands French Southern Territories (the) ',
    tg: 'Togo',
    th: 'Thailand',
    tj: 'Tajikistan',
    tk: 'Tokelau',
    tl: 'Timor-Leste',
    tm: 'Turkmenistan',
    tn: 'Tunisia',
    to: 'Tonga',
    tr: 'Turkey',
    tt: 'Trinidad and Tobago ',
    tv: 'Tuvalu',
    tw: 'Taiwan (Province of China)',
    tz: 'Tanzania, the United Republic of',
    ua: 'Ukraine',
    uk: 'United Kingdom',
    ug: 'Uganda',
    um: 'United States Minor Outlying Islands (the)',
    us: 'United States of America (the)',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    va: 'Vatican City',
    vc: 'Saint Vincent and the Grenadines',
    ve: 'Venezuela',
    vg: 'Virgin Islands (British)',
    vi: 'Virgin Islands (US)',
    vn: 'Viet Nam',
    vu: 'Vanuatu',
    wf: 'Wallis and Futuna',
    ws: 'Samoa',
    xx: 'Unknown country',
    ye: 'Yemen',
    yt: 'Mayotte',
    za: 'South Africa',
    zm: 'Zambia',
    zw: 'Zimbabwe',
    zz: 'Several Countries'
  },
  errors: {
    serverError: 'Server error'
  },
  accessMessages: {
    notAuthorised: 'Access not authorised'
  },
  datePicker: {
    ok: 'Ok',
    cancel: 'Cancel'
  },
  timePicker: {
    ok: 'Ok',
    cancel: 'Cancel',
    clear: 'Clear'
  }
}

export default tr
