import React from 'react'
import { setCurrentShop } from 'store/actions/shop-action-creators'
import { hideTimeZone, showTimeZone } from 'store/actions/main-layout-action-creators'

export default function hideZone() {
  return (WrappedComponent) => {
    return class HideTimeZone extends React.Component {
      componentDidMount() {
        this.props.dispatch(hideTimeZone())
        this.props.dispatch(setCurrentShop(this.props.match.params.shopId))
      }

      componentWillUnmount() {
        this.props.dispatch(setCurrentShop(null))
        this.props.dispatch(showTimeZone())
      }

      render() {
        return React.createElement(WrappedComponent, this.props)
      }
    }
  }
}
