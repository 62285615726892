import React from 'react'
import Select from 'react-select'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { ControlLabel } from 'dumb/control-label'
import css from './style.module.scss'

export default class IntegrationReactSelect extends React.Component {
  inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />

  Control = (props) => (
    <TextField
      style={{ minWidth: '150px' }}
      disabled={this.props.disabled}
      InputProps={{
        inputComponent: this.inputComponent,
        inputProps: {
          style: { display: 'flex', padding: 0, height: 'auto' },
          children: props.children
        },
        ...props.innerProps
      }}
    />
  )

  Option = (props) => (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        zIndex: 999999,
        display: 'block'
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )

  Placeholder = (props) => (
    <Typography color="textSecondary"> {props.children} </Typography>
  )

  SingleValue = (props) => <Typography>{props.children}</Typography>

  Menu = (props) => (
    <Paper
      square
      {...props.innerProps}
      style={{ position: 'absolute', left: 0, rigth: 0, zIndex: 1 }}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )

  NoOptionsMessage = (props) => (
    <Typography
      color="textSecondary"
      style={{ fontSize: '1rem', padding: '0.5rem' }}
    >
      {props.children}{' '}
    </Typography>
  )

  render() {
    const suggestions = this.props.list.map((suggestion) => ({
      value: this.props.dataConverter(suggestion).value,
      label: this.props.dataConverter(suggestion).text || ''
    }))

    const components = {
      Control: this.Control,
      Menu: this.Menu,
      NoOptionsMessage: this.NoOptionsMessage,
      Option: this.Option,
      Placeholder: this.Placeholder,
      SingleValue: this.SingleValue
    }

    return (
      <div style={this.props.style}>
        <div className={css.title}>
          <ControlLabel>{this.props.label}</ControlLabel>
          {this.props.attention && <div className={css.attention} />}
        </div>
        <Select
          placeholder={this.props.title}
          options={suggestions}
          components={components}
          value={suggestions.find((s) => s.value === this.props.value)}
          onChange={this.onChangeWrapper}
          disabled={this.props.disabled}
        />
      </div>
    )
  }

  onChangeWrapper = (item) => {
    const value = item.value
    this.props.onChange && this.props.onChange(value)
  }
}
