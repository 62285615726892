import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TimeZoneModel } from 'shared/models'
import { i18n } from 'i18n'

const defaultLocale = i18n.getLocaleByBrowserLang()

type ProfileState = {
  interfaceLocale: string
  timeZone: TimeZoneModel
  permissions: any
  loaded: boolean
  userProfile: any | null
  groupShops: boolean
  singleShop: boolean
}

const initialState: ProfileState = {
  interfaceLocale: defaultLocale,
  timeZone: {
    id: 'UTC',
    offset: 0,
    name: 'UTC'
  },
  loaded: false,
  permissions: {},
  userProfile: null,
  groupShops: true,
  singleShop: false
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserProfile(state, { payload }: PayloadAction<any>) {
      const shops = payload.counters?.shops || 0

      state.groupShops = !shops || shops > 3
      state.singleShop = shops === 1 && !payload.permissions?.canAddAndDeleteShops
      state.loaded = true
      state.userProfile = payload
      state.permissions = payload.permissions
      state.interfaceLocale = payload.interfaceLocale
    },
    setTimeZone(state, action: PayloadAction<TimeZoneModel>) {
      state.timeZone = action.payload
    },
    clearProfile(state) {
      state.loaded = false
      state.userProfile = null
    }
  }
})

export const { setTimeZone, clearProfile, setUserProfile } = profileSlice.actions
export default profileSlice.reducer
