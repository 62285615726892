import React, { Component } from 'react'
import { Form, FormGroup, FormActionGroup } from 'dumb/form'
import Text from 'dumb/text'
import translate from 'i18n/translate'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import TotalAppointmentStatuses from 'provider/total-appointment-statuses'
import Warning from '@material-ui/icons/Warning'

class ResolutionFound extends Component {
  formatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  }

  formatHourOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: 'UTC'
  }

  render() {
    const { validityPeriod, productsConcerned, genericProducts, customerDelivered, period } =
      this.props.translation.totalAuthorization

    const { authorizationFound, authorizationNotFound } = this.props.translation.authorizationResearch

    const d = this.props.data

    if (!d) {
      return <div style={{ margin: '16px' }}>{authorizationNotFound}</div>
    }

    const startValidityPeriodDate = d.startValidityDate
    const endValidityPeriodDate = d.endValidityDate

    const customerDeliveredName = d.clientName
    const customerDeliveredCountryCode = d.country

    const appointment = d.appointment

    return (
      <Form title={authorizationFound}>
        <FormGroup>
          <Text type="caption">{validityPeriod}</Text>
          <Text type="body-1">
            {period(
              this.props.dateFormatter(startValidityPeriodDate, this.formatOptions),
              this.props.dateFormatter(endValidityPeriodDate, this.formatOptions)
            )}
          </Text>
          {this.renderPeriodValidation()}
        </FormGroup>

        {d.products && (
          <FormGroup>
            <Text type="caption">{genericProducts(d.products.length)}</Text>
            <Text type="body-1">{d.products.map((p) => `${p.code} — ${p.name}`).join(', ')}</Text>
          </FormGroup>
        )}

        {appointment && appointment.productAppointments && (
          <FormGroup>
            <Text type="caption">{productsConcerned(appointment.productAppointments.length)}</Text>
            <Text type="body-1">{appointment.productAppointments.map((el) => `${el.product.name}`).join(', ')}</Text>
          </FormGroup>
        )}

        <FormGroup>
          <Text type="caption">{customerDelivered}</Text>
          <Text type="body-1">{`${customerDeliveredName}, ${customerDeliveredCountryCode}`}</Text>
        </FormGroup>
        <FormGroup>{this.renderAppointment(appointment)}</FormGroup>
        <Divider />
        <FormActionGroup>{this.renderActions()}</FormActionGroup>
      </Form>
    )
  }

  renderAppointment(appointment) {
    if (!appointment || !appointment.date) return null

    const { rdvShortInfo, statuses } = this.props.translation.authorizationResearch

    const basis = rdvShortInfo(
      this.props.dateFormatter(appointment.date, this.formatOptions),
      this.props.dateFormatter(appointment.time.from, this.formatHourOptions)
    )

    const timeWithStatuses = {
      ToBeConfirmed: `${basis}, ${statuses.toBeConfirmed}`,
      Absent: `${basis}, ${statuses.absent}`,
      Arrived: `${basis}, ${statuses.arrived}`,
      Cancelled: `${basis}, ${statuses.cancelled}`,
      ToBeCancelled: `${basis}, ${statuses.toBeCancelled}`,
      Valid: `${basis}, ${statuses.valid}`,
      Finished: `${basis}, ${statuses.finished}`,
      Unknown: basis
    }

    const appointmentInfo = timeWithStatuses[appointment.status] || timeWithStatuses.Unknown

    return (
      <Text type="body-1">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Warning style={{ marginRight: '16px' }} />
          {appointmentInfo}
        </div>
      </Text>
    )
  }

  renderActions() {
    const { create, update } = this.props.translation.authorizationResearch
    const d = this.props.data

    const appointment = d && d.appointment ? d.appointment : null

    const buttonLabel =
      appointment &&
      appointment.id &&
      TotalAppointmentStatuses[appointment.status] !== TotalAppointmentStatuses.Cancelled
        ? update
        : create

    return (
      <Button
        disabled={this.props.data.lanStatus === 'Expired' || this.props.data.lanStatus === 'BeyondFutureLimit'}
        onClick={() => this.props.onUpdate(d)}
      >
        {buttonLabel}
      </Button>
    )
  }

  renderPeriodValidation() {
    if (this.props.data.lanStatus === 'Expired')
      return (
        <Text type="body-2" style={{ color: '#f44336', marginTop: 4 }}>
          {this.props.translation.totalAuthorization.loadingAutorisationExpired}
        </Text>
      )

    if (this.props.data.lanStatus === 'BeyondFutureLimit')
      return (
        <Text type="body-2" style={{ color: '#f44336', marginTop: 4 }}>
          {this.props.translation.totalAuthorization.startDateInvalidText}
        </Text>
      )

    if (this.props.data.lanStatus === 'PartiallyValid')
      return (
        <Text type="body-2" style={{ color: '#ff9800', marginTop: 4 }}>
          {this.props.translation.totalAuthorization.endDateInvalidText}
        </Text>
      )
  }
}

export default translate(ResolutionFound)
