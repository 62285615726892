import React, { Component } from 'react'
import css from './form-action-group.module.scss'

export default class FormActionGroup extends Component {
  render() {
    return (
      <section className={css.root} style={this.props.style}>
        {this.props.children}
      </section>
    )
  }
}
