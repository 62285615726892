import React, { Component } from 'react'
import Card from 'dumb/card'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { resetEntity } from 'store/actions/entity-action-creators'
import css from './base-entity.module.scss'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { index } from 'app/nav'
import EntityActions from 'dumb/entity-actions'
import { shallowDiff } from 'utils/object'
import CircularProgress from '@material-ui/core/CircularProgress'
import ModeEdit from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'

export default class BaseEntityView extends Component {
  componentDidMount() {
    if (this.checkViewPermissions() === false) {
      this.pushPath(index())
      return null
    }

    this.fetchEntity(this.props.match.params)
  }

  componentDidUpdate() {
    const data = this.props.entity.data
    const parents = this.props.entity.parents

    if (data === null || data === undefined || this.crumbsDefined) {
      return null
    }

    this.crumbsDefined = true
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    this.setBreadcrumbs(this.getBreadcrumbs(crumb, data, parents))
  }

  componentWillUnmount() {
    this.props.dispatch(resetEntity())
  }

  shouldComponentUpdate(nextProps) {
    return shallowDiff(nextProps, this.props, ['entity', 'timeZoneId'])
  }

  shouldRenderForm() {
    const { entity } = this.props

    const eqIdentity = !this.identity || !entity.identity || this.identity === entity.identity

    return !entity.fetching && entity.data !== null && eqIdentity
  }

  render() {
    if (!this.shouldRenderForm())
      return (
        <div
          style={{
            background: '#fafafa',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )

    return (
      <div className={css.root}>
        <div className={css.container}>{this.renderForm()}</div>
      </div>
    )
  }

  checkEditPermissions() {}
  checkViewPermissions() {}

  renderForm() {
    return (
      <div>
        {this.renderBeforeForm()}
        <Card>
          {this.renderMainForm()}
          {this.renderActions()}
        </Card>
        {this.renderAfterForm()}
      </div>
    )
  }

  renderActions() {
    return (
      <EntityActions
        showButtons={this.checkEditPermissions() !== false}
        buttons={this.renderButtons()}
        updateData={this.props.entity.data?.lastUpdate}
      />
    )
  }

  renderButtons() {
    return (
      <Button onClick={() => this.handleEdit()} startIcon={<ModeEdit />}>
        {this.props.translation.entityView.editButton}
      </Button>
    )
  }

  renderBeforeForm() {}
  renderAfterForm() {}

  pushPath(path) {
    this.props.history.push(path)
  }

  setBreadcrumbs(crumbs) {
    this.props.dispatch(setBreadcrumbs(crumbs))
  }
}
