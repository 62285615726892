import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BrandModel } from 'shared/models'

type BrandsState = {
  fetching: boolean
  loaded: boolean
  items: BrandModel[] | null
}

const initialState: BrandsState = {
  fetching: false,
  loaded: false,
  items: null
}

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setListRequest: (state) => {
      state.loaded = false
      state.fetching = true
    },
    setListSuccess: (state, action: PayloadAction<BrandModel[]>) => {
      state.fetching = false
      state.loaded = true
      state.items = action.payload
    }
  }
})

export const { setListRequest, setListSuccess } = brandsSlice.actions
export default brandsSlice.reducer
