import axios from 'axios'
import { getUrl } from './helpers'

export default {
  upload(formData) {
    return axios.post('uploadFile', formData)
  },

  appointmentAttachmentDownload(id) {
    location.href = getUrl('appointmentAttachmentDownload', { id })
  }
}
