import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface EntityState {
  error: any
  fetching: boolean
  sending: boolean
  lastOperation: string | null
  operationSucceeded: boolean
  data: any
  associated: any
  parents: any
  identity: string | null
}

const initialState: EntityState = {
  error: null,
  fetching: false,
  sending: false,
  lastOperation: null,
  operationSucceeded: false,
  data: null,
  associated: null,
  parents: null,
  identity: null
}

const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    getEntityRequest: (state) => {
      state.error = null
      state.fetching = true
      state.lastOperation = 'get'
      state.operationSucceeded = false
    },
    getEntitySuccess: (state, { payload }: PayloadAction<any>) => {
      state.error = null
      state.fetching = false
      state.identity = payload.identity
      state.data = payload.entity.data
      state.parents = payload.entity.parents
      state.associated = payload.associated
      state.operationSucceeded = true
    },
    getEntityFailure: (state, { payload }: PayloadAction<any>) => {
      state.error = payload
      state.fetching = false
      state.operationSucceeded = false
    },
    resetEntity: (state) => {
      state.data = null
      state.identity = null
      state.associated = null
      state.parents = null
      state.lastOperation = null
      state.operationSucceeded = false
    },
    sendEntityRequest: (state, { payload }) => {
      state.error = null
      state.lastOperation = payload
      state.operationSucceeded = false
    },
    sendEntitySuccess: (state, { payload }: PayloadAction<any>) => {
      state.error = null
      state.sending = false
      state.operationSucceeded = true
      
      // if(payload.entity) {
      //   state.data = payload.entity.data
      // }
    },
    sendEntityFailure: (state, { payload }: PayloadAction<any>) => {
      state.error = payload
      state.sending = false
      state.operationSucceeded = false
    }
  }
})

export const {
  getEntityFailure,
  getEntityRequest,
  getEntitySuccess,
  resetEntity,
  sendEntityFailure,
  sendEntityRequest,
  sendEntitySuccess
} = entitySlice.actions

export default entitySlice.reducer
