import React, { Component } from 'react'
import translate from 'i18n/translate'
import css from './date-picker.module.scss'
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import pickerLocale from 'i18n/picker-locale'

class DatePicker extends Component {
  handleChange = (date) => {
    this.props.onChange(this.applyAsUTC(date))
  }

  applyAsUTC(d) {
    return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
  }

  getValue = () => {
    if (!this.props.value) {
      return undefined
    }

    const localDateOffset = new Date(this.props.value).getTimezoneOffset() * 60000
    const v = this.props.value ? new Date(this.props.value).getTime() : new Date().getTime()
    return new Date(v + localDateOffset)
  }

  render() {
    const { ok, cancel } = this.props.translation.datePicker

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={this.props.pickerLocale}>
        <MuiDatePicker
          style={this.props.style}
          margin={this.props.margin}
          id={this.props.id}
          label={
            (this.props.label || this.props.attention) && (
              <div style={{ display: 'flex' }}>
                {this.props.label}
                {this.props.attention && <div className={css.attention} />}
              </div>
            )
          }
          format="dd/MM/yyyy"
          value={this.getValue()}
          onChange={this.handleChange}
          okLabel={ok}
          cancelLabel={cancel}
          views={['year', 'month', 'date']}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

export default translate(pickerLocale()(DatePicker))
