import React from 'react'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { SubBar } from 'dumb/sub-bar'
import { EnhancedInput } from 'dumb/text-field'
import debounce from 'lodash/debounce'
import LegacyBase from 'smart/base/legacy-base'
import { total } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { List } from 'dumb/list'
import Card from 'dumb/card'
import Button from '@material-ui/core/Button'
import * as Nav from 'app/nav'
import { produce } from 'immer'
import CircularProgress from '@material-ui/core/CircularProgress'
import Add from '@material-ui/icons/AddCircleOutline'

class SmartGenericProduct extends LegacyBase {
  barLines = 1

  state = {
    list: null,
    term: '',
    loading: false
  }

  editState = (fn) => this.setState(produce(fn))

  fetchData = debounce(
    (term) => {
      this.editState((draft) => {
        draft.loading = true
      })

      return total.getGenericProducts({ term }).then((resp) => {
        this.editState((draft) => {
          draft.list = resp
          draft.loading = false
        })

        return resp
      })
    },
    300,
    { leading: true }
  )

  setBreadcrumbs(data) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    const crumbs = [crumb.home(), crumb.genericProducts()]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  componentDidMount() {
    this.fetchData(this.state.term).then((list) => this.setBreadcrumbs(list))
  }

  renderLoading() {}

  renderContent() {
    return (
      <>
        {this.props.canEditShop && (
          <Card>
            <div style={{ margin: '8px' }}>
              <Button onClick={() => this.pushPath(Nav.genericProductCreate())}>
                <Add style={{ marginRight: '0.5rem' }} />
                {this.props.translation.genericProductList.add}
              </Button>
            </div>
          </Card>
        )}
        {this.state.list && (
          <Card>
            <List
              data={this.state.list.data}
              onSelect={(data) => this.onSelect(data)}
              dataConverter={(item) => ({ id: item.id, primaryText: `${item.name}`, secondaryText: item.code })}
            />
          </Card>
        )}
        {!this.state.list && this.state.loading && (
          <CircularProgress
            style={{ position: 'absolute', marginLeft: '-20px', left: '50%', marginTop: '1rem' }}
            color="secondary"
          />
        )}
      </>
    )
  }

  onSelect = (data) => {
    const id = data.id
    this.pushPath(Nav.genericProductEdit(id))
  }

  renderBarContent(props) {
    this.search = (
      <EnhancedInput
        value={this.state.term}
        onChange={this.handleSearch}
        placeholder={this.props.translation.genericProductList.search}
      />
    )

    return (
      <SubBar smallScreen={props.smallScreen} style={{ display: 'flex' }}>
        <div style={{ flex: 1, minWidth: 200, marginRight: 24, padding: '15px 0' }}>{this.search}</div>
        <div style={{ width: '24px', height: '24px' }}>
          {this.state.loading && this.state.list && (
            <CircularProgress style={{ width: '24px', height: '24px' }} color="secondary" />
          )}
        </div>
      </SubBar>
    )
  }

  pushPath = (path) => {
    this.props.history.push(path)
  }

  handleSearch = (term) => {
    this.setState({ term })
    this.fetchData(term)
  }
}

const stateToProps = (state) => ({ canEditShop: state.user.permissions.canEditShop })

export default connect(stateToProps)(translate(SmartGenericProduct))
