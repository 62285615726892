import { connect } from 'react-redux'
import { useTranslation } from 'i18n/translate'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import ExportDataForm from './export-data.form'
import Container from '../../dumb/container'
import Card from '../../dumb/card'
import { shop, line } from 'provider'
import { reports } from 'app/nav'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

function Report(props) {
  const { templateId } = useParams<{ templateId: string }>()
  const [lines, setLines] = useState<any[]>([])
  const [shops, setShops] = useState<any[]>([])

  const { tr } = useTranslation()

  const history = useHistory()

  useEffect(() => {
    fetchTemplates()
    fetchShops()
  }, [])

  function fetchTemplates() {
    shop.getExportTemplates().then((resp) => {
      const template = resp.filter((el) => String(el.value) == String(templateId))

      if (template.length < 1) {
        history.push(reports())
        return
      } else {
        const crumb = getCrumbs(tr.breadcrumbs)
        props.dispatch(setBreadcrumbs([crumb.home(), crumb.reports(), { id: 99, title: template[0].text }]))
      }
    })
  }

  function fetchShops() {
    shop.getShopList().then((resp) => {
      setShops(resp.data)
    })
  }

  function fetchLines(shopId: number) {
    if (!shopId && shopId !== 0) {
      return
    }

    line.getLineList(shopId).then((resp) => {
      setLines(resp.data)
    })
  }

  function handleExport(data: any) {
    shop.exportDataFile(data)
  }

  function handleRequestLines(shopId: number) {
    fetchLines(shopId)
  }

  return (
    <Container>
      <Card>
        <ExportDataForm
          shops={shops}
          lines={lines}
          templateId={templateId}
          onExport={handleExport}
          onRequestLines={handleRequestLines}
        />
      </Card>
    </Container>
  )
}

export default connect()(Report)
