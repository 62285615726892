import uniqueId from 'lodash/uniqueId'
import fakeBrands from './fake-brands'
import fakeTimeZones from './fake-time-zones'

const data = [
  {
    id: uniqueId(),
    name: 'Château de Versailles',
    description: '232, One ave',
    address: '232, Two ave',
    city: 'City',
    latitude: 55.755831,
    longitude: 37.617673,
    brandId: fakeBrands[0].id,
    lineCount: 4,
    checkpointCount: 16,
    daysOffCount: 15,
    notificationEmail: 'john@gmail.com',
    statisticsEstimation: true,
    timeZoneId: fakeTimeZones[3].id,
    daysOff: [],
    monitoringId: 'ed6a3f69-2059-4156-85be-7e8bb9a60cd6',
    allowFutureAppointments: true,
    exportDataIsAvailable: true,
    limitInDays: 5,
    loadingArmsCount: 10,
    limitInDaysToFollowUp: 24,
    limitInDaysToCancel: 11,
    externalCode: 'code test'
  },
  {
    id: uniqueId(),
    name: 'Tours de Notre-Dame de Paris',
    description: '88, Two st',
    latitude: 55.755831,
    longitude: 37.617673,
    priorityPassPoints: '100',
    brandId: fakeBrands[1].id,
    lineCount: 4,
    checkpointCount: 16,
    statisticsEstimation: false,
    timeZoneId: fakeTimeZones[2].id,
    daysOff: [],
    allowFutureAppointments: false,
    exportDataIsAvailable: false,
    limitInDays: 1
  }
]

export function getResponse(data, add) {
  return {
    data: add ? Object.assign({}, data, add) : data
  }
}

export default data
