import React, { Component } from 'react'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import { resetEntity } from 'store/actions/entity-action-creators'
import css from './base-entity.module.scss'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { index } from 'app/nav'
import { shallowDiff } from 'utils/object'
import CircularProgress from '@material-ui/core/CircularProgress'

export default class BaseEntityEdit extends Component {
  componentDidMount() {
    if (this.checkViewPermissions() === false) {
      this.pushPath(index())
      return null
    }

    this.fetchEntity(this.props.match.params)
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps != this.props) {
      const operation = nextProps.entity.lastOperation
      const operationBehavior = this.behavior[operation]
      const error = nextProps.entity.error
      const errorMsg = this.getOperationErrorMsg(operation)

      if (error) {
        this.props.dispatch(showMessage(errorMsg, 'error'))
        return null
      }

      if (!nextProps.entity.operationSucceeded) return null

      if (operationBehavior && operationBehavior.onSuccess) operationBehavior.onSuccess()

      if (operationBehavior && operationBehavior.redirect)
        this.pushPath(operationBehavior.redirect(this.props.match.params))

      const successMsg = this.getOperationSuccessMsg(operation)

      if (successMsg) this.props.dispatch(showMessage(successMsg, 'success'))
    }

    return shallowDiff(nextProps, this.props, ['entity', 'timeZoneId'])
  }

  componentWillUnmount() {
    this.props.dispatch(resetEntity())
  }

  componentDidUpdate() {
    const data = this.props.entity.data
    const parents = this.props.entity.parents

    if (data === null || this.crumbsDefined) return null

    this.crumbsDefined = true
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    this.setBreadcrumbs(this.getBreadcrumbs(crumb, data, parents))
  }

  getOperationSuccessMsg(operation) {
    const msgs = this.operationMessages()
    return msgs && msgs[`${operation}Success`]
  }

  getOperationErrorMsg(operation) {
    const msgs = this.operationMessages()
    return msgs && msgs[`${operation}Error`]
  }

  shouldRenderForm() {
    const { entity } = this.props
    const eqIdentity = !this.identity || !entity.identity || this.identity === entity.identity

    return !entity.fetching && !entity.sending && entity.data !== null && eqIdentity
  }

  render() {
    if (!this.shouldRenderForm())
      return (
        <div
          style={{
            background: '#fafafa',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )

    return (
      <div className={css.root}>
        <div className={css.container}>
          {this.renderBeforeForm()}
          {this.renderMainForm()}
        </div>
      </div>
    )
  }

  renderBeforeForm() {}

  pushPath(path) {
    this.props.history.push(path)
  }

  setBreadcrumbs(crumbs) {
    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  checkViewPermissions() {}
}
