import React, { Component } from 'react'
import Text from 'dumb/text'
import { Form, FormGroup } from 'dumb/form'
import translate from 'i18n/translate'
import Card from 'dumb/card'
import css from './checkpoint-monitoring.module.scss'
import { CheckpointStatus } from 'provider'
import TimePicker from 'dumb/time'
import Toggle from 'dumb/toggle-wrap'
import AlertIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'

class HostComponent extends Component {
  state = {
    hostName: this.props.hostName,
    isAnyHourOpened: false,
    closingTime: this.props.closingTime,
    startTime: this.props.startTime
  }

  zeroTime = {
    hours: 0,
    minutes: 0
  }

  componentDidMount() {
    const closingTime = this.props.closingTime
    const startTime = this.props.startTime

    if (closingTime && startTime) {
      const isZeroTime =
        +closingTime.hours === this.zeroTime.hours &&
        +closingTime.minutes === this.zeroTime.minutes &&
        +startTime.hours === this.zeroTime.hours &&
        +startTime.minutes === this.zeroTime.minutes

      if (isZeroTime) this.handleAnyHourOpened(true)
    }

    this.handleNameChange('')
  }

  render() {
    const { closingTime, startTime, apply, availableAnyHour, normalLineHoursAppliedMorning } =
      this.props.translation.checkpointMonitoring

    const disabledTimePikers = !this.props.canGiveUpdateServicePointStartAndEndHours

    return (
      <Form>
        <FormGroup>
          <Toggle
            disabled={disabledTimePikers}
            toggled={this.state.isAnyHourOpened}
            label={availableAnyHour}
            onToggle={(ev, val) => this.handleAnyHourOpened(val)}
          />
        </FormGroup>
        <FormGroup>
          <div className={css.host} style={{ flexWrap: 'wrap' }}>
            {!this.state.isAnyHourOpened && (
              <React.Fragment>
                <TimePicker
                  disabled={disabledTimePikers}
                  style={{ marginRight: 16 }}
                  label={startTime}
                  value={this.state.startTime}
                  onChange={this.handleStartTimeChange}
                  attention={false}
                  timeZone={this.props.timeZoneId}
                />
                <TimePicker
                  disabled={disabledTimePikers}
                  label={closingTime}
                  value={this.state.closingTime}
                  onChange={this.handleCloseTimeChange}
                  attention={false}
                  timeZone={this.props.timeZoneId}
                />
                <div style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                  <AlertIcon style={{ marginRight: '8px', color: 'rgb(55 70 73 / 54%)' }} />
                  <Text type="body-2">{normalLineHoursAppliedMorning}</Text>
                </div>
              </React.Fragment>
            )}
          </div>
        </FormGroup>
        <div className={css.actions}>
          <Button onClick={this.handleApply} startIcon={<CheckIcon />}>
            {apply}
          </Button>
        </div>
      </Form>
    )
  }

  handleAnyHourOpened = (value) => {
    this.setState({ isAnyHourOpened: value })
  }

  handleApply = () => {
    if (this.state.isAnyHourOpened) {
      this.setState({ startTime: this.zeroTime, closingTime: this.zeroTime }, () => this.props.onApply(this.state))
    } else {
      this.props.onApply(this.state)
    }
  }

  handleNameChange = (value) => {
    this.setState({ hostName: value })
  }

  handleStartTimeChange = (value) => {
    this.setState({ startTime: value })
  }

  handleCloseTimeChange = (value) => {
    this.setState({ closingTime: value })
  }
}

const Host = translate(HostComponent)

class CheckpointMonitoring extends Component {
  render() {
    const d = this.props.data
    const text = this.props.translation.checkpointStatus
    const statusText = {
      [CheckpointStatus.STARTING]: text.starting,
      [CheckpointStatus.STARTED]: text.started,
      [CheckpointStatus.PAUSED]: text.paused,
      [CheckpointStatus.FINISHING]: text.finishing,
      [CheckpointStatus.FINISHED]: text.finished
    }

    let info = d.status
      ? statusText[d.status](
          this.props.dateFormatter(d.statusChange.date, 'time'),
          d.statusChange.user.firstName,
          d.statusChange.user.secondName
        )
      : ''

    return (
      <Card>
        <Form>
          <FormGroup>
            <div className={css.header}>{d.name}</div>
            <div className={css.status}>
              <Text type="body-3">{info}</Text>
            </div>
          </FormGroup>
        </Form>
        <Host
          canGiveUpdateServicePointStartAndEndHours={this.props.canGiveUpdateServicePointStartAndEndHours}
          hostName={d.hostName}
          closingTime={d.closingTime}
          startTime={d.startTime}
          onApply={(val) => this.props.onHostChange(val)}
        />
      </Card>
    )
  }
}

export default translate(CheckpointMonitoring)
