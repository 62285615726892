import React, { Component } from 'react'
import css from './text.module.scss'
import { withMods } from 'utils/cssm'

const mod = withMods(css)

export default class Text extends Component {
  render() {
    const { type, preLine, className, size, fade, style } = this.props

    let cls = mod.root({ type, size, fade, preLine })

    if (className) {
      cls += ` ${className}`
    }

    return (
      <div className={cls} style={style}>
        {this.props.children}
      </div>
    )
  }
}
