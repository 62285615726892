import uniqueId from 'lodash/uniqueId'
import findIndex from 'lodash/findIndex'
import fakeShops from './fake-shops'
import fakeSericePointss from './fake-checkpoints'
import fakeGenericProducts from './fake-generic-products'

const data = [
  {
    id: uniqueId(),
    code: '13',
    name: 'Arm name 1',
    priority: '0',
    genericProducts: [],
    servicePoints: []
  },
  {
    id: uniqueId(),
    code: '37',
    name: 'Arm name 2',
    priority: 0,
    genericProducts: [fakeGenericProducts[1]],
    servicePoints: [fakeSericePointss[2]]
  },
  {
    id: uniqueId(),
    code: '322',
    name: 'Arm name 3',
    genericProducts: [fakeGenericProducts[0], fakeGenericProducts[1]],
    servicePoints: [fakeSericePointss[0], fakeSericePointss[1]]
  }
]

export function getResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export function update(entity) {
  const index = findIndex(data, { id: entity.id })
  data[index] = entity
}

export default data
