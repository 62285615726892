import { createContext } from 'react'
import { Translations } from './traductions/translation-types'

export type TimeFormats = 'time' | 'date' | 'dateTime'

export type DateFormatter = (
  date: string | number | Date | null,
  format?: TimeFormats | Intl.DateTimeFormatOptions,
  noShift?: boolean
) => string

export interface ITranslationContextProps {
  locale: string
  tr: Translations
  dateFormatter: DateFormatter
  timeZoneId: string
  timeZoneOffset: number
  timeFromObject: (time: number, noShift: boolean | undefined, date: number) => string
}

export const TranslateContext = createContext<ITranslationContextProps | null>(null)
