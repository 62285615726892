import React from 'react'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { SubBar } from 'dumb/sub-bar'
import { EnhancedInput } from 'dumb/text-field'
import debounce from 'lodash/debounce'
import LegacyBase from 'smart/base/legacy-base'
import { total } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { List } from 'dumb/list'
import Card from 'dumb/card'
import Button from '@material-ui/core/Button'
import * as Nav from 'app/nav'
import Add from '@material-ui/icons/AddCircleOutline'

class SmartLoadingArmsList extends LegacyBase {
  barLines = 1

  state = {
    list: null,
    term: ''
  }

  fetchData = debounce(
    (term) => {
      return total.getLoadingArms({ shopId: this.getShopId(), term }).then((list) => {
        this.setState({ list })

        return list
      })
    },
    300,
    { leading: true }
  )

  setBreadcrumbs(data) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const parents = data.parents
    const shopName = parents.shop.name
    const shopId = this.getShopId()

    const crumbs = [crumb.home(), crumb.shops(), crumb.shop([shopId], shopName), crumb.loadingArms([shopId])]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  componentDidMount() {
    this.fetchData(this.state.term).then((list) => this.setBreadcrumbs(list))
  }

  renderContent() {
    if (!this.state.list) {
      return null
    }

    const { genericProducts, servicePoints } = this.props.translation.loadingArmsList
    const { none } = this.props.translation.common

    return (
      <React.Fragment>
        {this.props.canEditShop && (
          <Card>
            <div style={{ margin: '8px' }}>
              <Button startIcon={<Add />} onClick={() => this.pushPath(Nav.loadingArmCreate(this.getShopId()))}>
                {this.props.translation.loadingArmsList.add}
              </Button>
            </div>
          </Card>
        )}

        <Card>
          <List
            data={this.state.list.data}
            onSelect={(data) => this.onSelect(data)}
            dataConverter={(item) => {
              const prodsCodes = item.genericProducts?.map((x) => x.code).join(', ') || none
              const services = item.servicePoints?.map((x) => x.name).join(', ') || none
              return {
                id: item.id,
                primaryText: `${item.name} (${item.code})`,
                secondaryText: (
                  <div>
                    <div>{`${genericProducts}${prodsCodes}`}</div>
                    <div>{`${servicePoints}${services}`}</div>
                  </div>
                )
              }
            }}
          />
        </Card>
      </React.Fragment>
    )
  }

  onSelect = (data) => {
    const id = data.id
    this.pushPath(Nav.loadingArmEdit(this.getShopId(), id))
  }

  renderBarContent(props) {
    this.search = (
      <EnhancedInput
        value={this.state.term}
        onChange={this.handleSearch}
        placeholder={this.props.translation.loadingArmsList.search}
      />
    )

    return (
      <SubBar smallScreen={props.smallScreen}>
        <div style={{ flex: 1, minWidth: 200, marginRight: 24, padding: '15px 0' }}>{this.search}</div>
      </SubBar>
    )
  }

  pushPath = (path) => {
    this.props.history.push(path)
  }

  handleSearch = (term) => {
    this.setState({ term })
    this.fetchData(term)
  }

  getShopId() {
    return this.props.match.params.shopId
  }
}

const stateToProps = (state) => ({ canEditShop: state.user.permissions.canEditShop })

export default connect(stateToProps)(translate(SmartLoadingArmsList))
