import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import translate from 'i18n/translate'
import Divider from '@material-ui/core/Divider'
import AddList from 'dumb/add-list'
import Text from 'dumb/text'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'
import { isPositiveInteger } from 'utils/string-test'

class LoadingArmEdit extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'name',
        stateField: 'nameIsValid'
      },
      {
        dataField: 'code',
        stateField: 'codeIsValid'
      },
      {
        dataField: 'priority',
        stateField: 'priorityIsValid',
        validator: (v) => v === '0' || v === 0 || isPositiveInteger(v)
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}

        <EntityActions showButtons={true} buttons={this.renderControls()} updateData={this.state.data.lastUpdate} />
      </Card>
    )
  }

  renderControls() {
    const { createButton, updateButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button id="confirm" onClick={() => this.props.onDelete(this.state.data.id)}>
              {deleteButton}
            </Button>
            <Button id="cancel" onClick={() => this.handleCancelDelete()}>
              {cancelButton}
            </Button>
          </div>
        </div>
      )

    if (!this.state.data.id)
      return (
        <Button disabled={!this.stateIsValid()} id="create" onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    else
      return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()} id="update">
            {updateButton}
          </Button>
          {this.props.canDelete !== false && (
            <Button id="delete" onClick={() => this.handleTryDelete()}>
              {deleteButton}
            </Button>
          )}
        </div>
      )
  }

  handleTryDelete() {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete() {
    this.setState({ confirmDelete: false })
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderMainForm(d) {
    const t = this.props.translation
    const { chars } = t.entityEdit

    return (
      <div>
        <Form>
          <FormGroup>
            <TextField
              label={t.loadingArmEdit.name}
              placeholder={t.loadingArmEdit.namePlaceholder}
              autofocus={true}
              value={d.name}
              onChange={(val) => this.handlePropChange('name', val)}
              attention={!this.state.nameIsValid}
              message={`${d.name ? d.name.length : 0}/200 ${chars}`}
              test={(v) => v.length < 201}
              disabled={!this.props.canEdit}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.loadingArmEdit.code}
              placeholder={t.loadingArmEdit.codePlaceholder}
              autofocus={false}
              value={d.code}
              onChange={(val) => this.handlePropChange('code', val)}
              attention={!this.state.codeIsValid}
              message={`${d.code ? d.code.length : 0}/200 ${chars}`}
              test={(v) => v.length < 201}
              disabled={!this.props.canEdit}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label={t.loadingArmEdit.priority}
              autofocus={false}
              value={String(d.priority) || ''}
              onChange={(val) => this.handlePropChange('priority', val)}
              type="number"
              attention={!this.state.priorityIsValid}
              test={(v) => v === '0' || !v || isPositiveInteger(v)}
              disabled={!this.props.canEdit}
            />
          </FormGroup>
        </Form>
        {this.props.genericProducts && (
          <Form title={t.loadingArmEdit.genericProducts}>
            <AddList
              selectedIds={d.genericProducts.map((prod) => prod.id) || []}
              disabled={!this.props.canEdit}
              source={this.props.genericProducts}
              addButtonText={t.loadingArmEdit.addGenericProduct}
              onChange={(val) => {
                const propds = this.props.genericProducts.filter((gp) => val.find((v) => v === gp.id))
                this.handlePropChange('genericProducts', propds)
              }}
              dropdownDataConverter={(item) => {
                return { value: item.id, text: item.name, secondary: item.code }
              }}
              listDataConverter={(item) => {
                return {
                  id: item.id,
                  primaryText: (
                    <div>
                      {item.name}
                      <Text size={2} fade={2} style={{ display: 'inline-block', marginLeft: 16 }}>
                        {item.code}
                      </Text>
                    </div>
                  )
                }
              }}
            />
          </Form>
        )}
        {this.props.servicePoints && (
          <Form title={t.loadingArmEdit.servicePoints}>
            <AddList
              selectedIds={d.servicePoints.map((sp) => sp.id) || []}
              disabled={!this.props.canEdit}
              source={this.props.servicePoints}
              addButtonText={t.loadingArmEdit.addServicePoint}
              onChange={(val) => {
                const points = this.props.servicePoints.filter((sp) => val.find((v) => v === sp.id))
                this.handlePropChange('servicePoints', points)
              }}
              dropdownDataConverter={(item) => ({ value: item.id, text: item.name, secondary: item.code })}
              listDataConverter={(item) => {
                return {
                  id: item.id,
                  primaryText: (
                    <div>
                      {item.name}
                      <Text size={2} fade={2} style={{ display: 'inline-block', marginLeft: 16 }}>
                        {item.code}
                      </Text>
                    </div>
                  )
                }
              }}
            />
          </Form>
        )}
        <Divider />
      </div>
    )
  }
}

export default translate(LoadingArmEdit)
