import React from 'react'

export default class JeFileEsCircleIcon extends React.PureComponent {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        {...this.props}
      >
        <path
          d="M0 256c0 31.31 5.63 61.31 15.92 89.04L256 367.3l240.08-22.26C506.37 317.31 512 287.31 512 256s-5.63-61.31-15.92-89.04L256 144.7 15.92 166.96A255.45 255.45 0 0 0 0 256z"
          fill="#ffda44"
        />
        <g fill="#d80027">
          <path d="M496.08 166.96C459.9 69.47 366.08 0 256 0S52.1 69.47 15.92 166.96h480.16zM15.92 345.04C52.1 442.53 145.92 512 256 512s203.9-69.47 240.08-166.96H15.92z" />
        </g>
      </svg>
    )
  }
}
