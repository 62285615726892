import React from 'react'
import { ShopList } from 'dumb/shop'
import { shop, createShop } from 'app/nav'
import { connect } from 'react-redux'
import Base from 'smart/base/base'
import translate from 'i18n/translate'
import dataProvider from 'smart/isolated-data-provider'
import Card from 'dumb/card'
import { EnhancedInput } from 'dumb/text-field'
import { SubBar } from 'dumb/sub-bar'
import debounce from 'lodash/debounce'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import Button from '@material-ui/core/Button'

class SmartShopList extends Base {
  getBreadcrumbs(crumb) {
    return [crumb.home(), crumb.shops()]
  }

  renderContent() {
    return (
      <React.Fragment>
        {this.props.canAddShop && (
          <Card>
            <div style={{ padding: '0.5rem' }}>
              <Button startIcon={<AddIcon />} onClick={this.handleAdd}>
                {this.props.translation.shopList.addButton}
              </Button>
            </div>
          </Card>
        )}
        <Card>
          <ShopList data={this.state.filteredShops || this.props.getShops()} onSelect={this.handleSelect} />
          {this.renderActions()}
        </Card>
      </React.Fragment>
    )
  }

  renderActions() {}

  renderBarContent(props) {
    return (
      <SubBar smallScreen={props.smallScreen}>
        <div style={{ height: 64, display: 'flex', alignItems: 'center', flex: 1 }}>
          <div style={{ flex: 1 }}>
            <EnhancedInput
              value={this.state.term}
              onChange={this.handleSearch}
              placeholder={this.props.translation.common.search}
            />
          </div>
        </div>
      </SubBar>
    )
  }

  handleSearch = debounce((val) => {
    this.setState({
      term: val,
      filteredShops: val
        ? this.props.getShops().filter((shop) => shop.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
        : null
    })
  }, 300)

  handleAdd = () => this.pushPath(createShop())

  handleSelect = (data) => this.pushPath(shop(data.id))
}

const stateToProps = (state) => ({ canAddShop: state.user.permissions.canAddShop })

export default dataProvider({ shops: true })(connect(stateToProps)(translate(SmartShopList)))
