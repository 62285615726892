const map = {
  mobileapp: 'mobileApp',
  sms: 'sms',
  nfccard: 'nfcCard',
  paperticket: 'paperTicket'
}

export function getModName(name, tr) {
  const key = map[name.toLowerCase()]
  return tr[key]
}
