import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { user } from 'provider'
import { storeUserProfile } from 'store/actions/user-action-creators'
import { storeUrl } from 'utils/history'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CenterContainer } from 'dumb/center-container'

function StoreUser() {
  return (WrappedComponent) => {
    function StoreUserComponent(props) {
      const [loading, setLoading] = useState(true)

      useEffect(() => {
        user
          .storeProfile()
          .then((profile) => {
            props.dispatch(storeUserProfile(profile))
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })

        return () => {
          storeUrl(props?.location?.pathname)
        }
      }, [])

      if (loading)
        return (
          <CenterContainer>
            <CircularProgress color="secondary" />
          </CenterContainer>
        )

      return <WrappedComponent {...props} />
    }

    return connect((state: any) => ({ loaded: state.user.loaded }))(StoreUserComponent)
  }
}

export default StoreUser
