import React, { Component } from 'react'
import translate from 'i18n/translate'
import { translateState } from 'provider/total-appointment-statuses'
import TotalAppointmentStatuses from 'provider/total-appointment-statuses'
import Link from '../link'
import { Divider } from '@material-ui/core'
import { Form, FormGroup } from 'dumb/form'
import Text from 'dumb/text'
import { withStyles } from '@material-ui/core/styles'
import TotalAppointmentActions from './total-appointment-actions'
import FileIcon from '@material-ui/icons/InsertDriveFile'

class TotalAppointmentItem extends Component {
  render() {
    const {
      relatedGenericProducts,
      loadingAuthorisationNumber,
      transportCompany,
      driver,
      state,
      reason,
      internalComment,
      appointmentTakenTime,
      createdBy,
      updatedBy,
      dateBy,
      sapCommand,
      forCustomer,
      ofDelivery,
      appIn,
      appAt
    } = this.props.translation.totalAppointments

    if (!this.props.data) {
      return null
    }

    const appointmentStatusesTranslation = this.props.translation.totalAppointmentStatuses

    const fullFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone: 'UTC'
    }
    const formatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
    const formatHourOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }

    const d = this.props.data

    const dRework = this.props.data
    const date = d.date
    const files = d.files ? d.files : []
    const clientName = d.clientName
    const countryCode = d.countryCode
    const loadingAuthorizationId = d.loadingAuthorizationId
    const driverName = d.driverName
    const driverSurname = d.driverSurname
    const transportCompanyName = d.transportCompanyName
    const status = d.status
    const internalCommentData = d.internalComment
    const sapCommandData = d.sapCommand
    const cancelReason = d.cancelReason

    const dateFormat = (date) => this.props.dateFormatter(date, formatOptions)
    const dateHourFormat = (date) => this.props.dateFormatter(date, formatHourOptions)

    const cancelledOrAbsent =
      TotalAppointmentStatuses[status] === TotalAppointmentStatuses.Absent ||
      TotalAppointmentStatuses[status] === TotalAppointmentStatuses.Cancelled

    return (
      <Form
        title={`${appointmentTakenTime(
          dateFormat(date),
          dateHourFormat(date),
          d.products?.[0]?.shopName,
          d.products?.[0]?.servicePointName,
          d.products?.[0]?.armCode,
          d.products?.length === 1
        )}`}
      >
        {sapCommandData && (
          <FormGroup>
            <Text type="caption">{sapCommand}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {sapCommandData}
            </Text>
          </FormGroup>
        )}
        <FormGroup>
          <Text type="caption">{loadingAuthorisationNumber}</Text>
          <Text type="body-1" style={{ color: '#374649' }}>
            <Link onClick={() => this.props.onLoadingAuthorizationClick(loadingAuthorizationId, date)}>
              {loadingAuthorizationId} {forCustomer} {clientName}, {countryCode}
            </Link>
          </Text>
        </FormGroup>
        <FormGroup>
          <Text type="caption">{relatedGenericProducts(d.products.length)}</Text>
          {d.products?.length === 1 && (
            <Text type="body-1" style={{ color: '#374649' }}>
              {d.products?.[0]?.product.qty} {d.products?.[0]?.product.measureUnit} {ofDelivery}{' '}
              {d.products?.[0]?.product.name} / {d.products?.[0]?.product.code}
            </Text>
          )}
          {d.products?.length > 1 && (
            <Text type="body-1" style={{ color: '#374649' }}>
              {d.products.map((value, index) => (
                <div key={index} style={{ marginTop: index === 0 ? '0' : '0.5rem' }}>
                  <div>
                    - {appAt} {dateHourFormat(value.appointmentTime)} {appIn} {value.shopName}
                  </div>
                  <div style={{ fontSize: '14px', marginLeft: '0.5rem' }}>
                    {value.servicePointName}
                    {value.armCode && `- ${value.armCode}`}
                  </div>
                  <div style={{ fontSize: '14px', marginLeft: '0.5rem' }}>
                    {value.product.qty} {value.product.measureUnit} {ofDelivery} {value.product.name} /{' '}
                    {value.product.code}
                  </div>
                </div>
              ))}
            </Text>
          )}
        </FormGroup>

        <FormGroup>
          <Text type="caption">{driver}</Text>
          <Text type="body-1" style={{ color: '#374649' }}>
            {driverName && `${driverName} `}
            {driverSurname && <span style={{ textTransform: 'uppercase' }}>{driverSurname}</span>}
            {!driverSurname && !driverName && (
              <Link onClick={() => this.props.onUpdateClick(this.props.data)}>
                {this.props.translation.totalAppointments.define}
              </Link>
            )}
          </Text>
        </FormGroup>

        <FormGroup>
          <Text type="caption">{transportCompany}</Text>
          <Text type="body-1" style={{ color: '#374649' }}>
            {transportCompanyName}
          </Text>
        </FormGroup>

        {internalCommentData && (
          <FormGroup>
            <Text type="caption">{internalComment}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {internalCommentData}
            </Text>
          </FormGroup>
        )}

        {Boolean(files && files.length !== 0) && (
          <FormGroup>
            {files.map((file) => {
              return (
                <Link
                  onClick={() => {
                    this.props.fileLinkClick && this.props.fileLinkClick(file)
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 8,
                    marginLeft: -3
                  }}
                  key={file.id}
                >
                  <FileIcon style={{ marginRight: 8 }} />
                  {file.name}
                </Link>
              )
            })}
          </FormGroup>
        )}

        <FormGroup>
          <Text type="caption">{state}</Text>
          <Text type="body-1" style={{ color: '#374649' }}>
            {translateState(appointmentStatusesTranslation, status) || appointmentStatusesTranslation.unknown}{' '}
            {!!d.updateTime && (
              <span>{dateBy(d.updateUser, this.props.dateFormatter(d.updateTime, fullFormatOptions))}</span>
            )}
          </Text>
        </FormGroup>

        {cancelledOrAbsent && cancelReason && (
          <FormGroup>
            <Text type="caption">{reason}</Text>
            <Text type="body-1" style={{ color: '#374649' }}>
              {cancelReason}
            </Text>
          </FormGroup>
        )}

        <FormGroup>
          {dRework.createdBy && (
            <Text style={{ margin: '8px 0' }} type="body-3">
              {createdBy(dRework.createdBy.user, this.props.dateFormatter(dRework.createdBy.date, fullFormatOptions))}
            </Text>
          )}
          {dRework.updatedBy && (
            <Text style={{ margin: '8px 0' }} type="body-3">
              {updatedBy(dRework.updatedBy.user, this.props.dateFormatter(dRework.updatedBy.date, fullFormatOptions))}
            </Text>
          )}
        </FormGroup>
        {this.props.canEdit && (
          <React.Fragment>
            {!cancelledOrAbsent && <Divider />}
            <TotalAppointmentActions
              onCancelClick={(cancelReason) => this.props.onCancelClick(this.props.data, cancelReason)}
              onUpdateClick={() => this.props.onUpdateClick(this.props.data)}
              onDeclareArrivedClick={() => this.props.onDeclareArrivedClick(this.props.data)}
              onDeclareFinishedClick={() => this.props.onDeclareFinishedClick(this.props.data)}
              onDeclareAbsentClick={() => this.props.onDeclareAbsentClick(this.props.data)}
              appointmentStatus={status}
            />
          </React.Fragment>
        )}
      </Form>
    )
  }
}

const styles = (theme) => ({
  button: { margin: theme.spacing(1) },
  leftIcon: { marginRight: theme.spacing(1) }
})

export default withStyles(styles)(translate(TotalAppointmentItem))
