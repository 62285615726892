const data = [
  {
    id: 'Etc/UTC',
    name: '(UTC) Coordinated Universal Time',
    offset: 0.0
  },
  {
    id: 'Asia/Yekaterinburg',
    name: '(UTC+05:00) Asia/Yekaterinburg',
    offset: 18000000
  },
  {
    id: 'Europe/Paris',
    name: '(UTC+01:00) Europe/Paris',
    offset: 120 * 60 * 1000
  },
  {
    id: 'Australia/Lindeman',
    name: '(UTC+10:00) Australia/Lindeman',
    offset: 36000000
  },
  {
    id: 'America/Bogota',
    name: '(UTC-05:00) America/Bogota',
    offset: -18000000
  }
]

export function getResponse(data, add) {
  return {
    data: add ? Object.assign({}, data, add) : data
  }
}

export default data
