import axios from 'axios'
import * as Nav from 'app/nav'

function combine(data, ...funcs) {
  return funcs.reduce((prev, curr) => curr(prev), data)
}

axios.interceptors.response.use(successDataExtractor, (response) => combine(response, unauthorized, errorThrow))

axios.defaults.baseURL = process.env.APP_API
axios.defaults.headers.common['X-Jefile-Version'] = __APP_VERSION__
axios.defaults.withCredentials = true

function successDataExtractor(response) {
  return Promise.resolve(response.data)
}

function errorThrow(response) {
  return Promise.reject(response)
}

function unauthorized(error) {
  if (error.response && error.response.status === 401) {
    window.location.replace(`#${Nav.login()}`)
  }

  return error
}

export { default as user } from './user'
export { default as shop } from './shop'
export { default as line } from './line'
export { default as checkpoint } from './checkpoint'
export { default as file } from './file'
export { default as brand } from './brand'
export { default as messageTemplates } from './message-templates'
export { default as staffManagement } from './staff-management'
export { default as translations } from './translations'
export { default as total } from './total'
export { default as totalScheduler } from './total-scheduler'
export { default as totalAppointments } from './total-appointments'
