import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TimeZoneModel } from 'shared/models'

type TimeZonesState = {
  fetching: boolean
  loaded: boolean
  items: TimeZoneModel[] | null
}

const initialState: TimeZonesState = {
  fetching: false,
  loaded: false,
  items: null
}

const timeZonesSlice = createSlice({
  name: 'timeZones',
  initialState,
  reducers: {
    setTimeZones: (state, action: PayloadAction<TimeZoneModel[]>) => {
      state.fetching = false
      state.loaded = true
      state.items = action.payload
    }
  }
})

export const { setTimeZones } = timeZonesSlice.actions
export default timeZonesSlice.reducer
