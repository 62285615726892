import uniqueId from 'lodash/uniqueId'

const data = [
  {
    id: uniqueId(),
    name: 'Brand One'
  },
  {
    id: uniqueId(),
    name: 'Brand Two'
  }
]

export function getResponse(data) {
  return {
    data
  }
}

export default data
