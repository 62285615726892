import uniqueId from 'lodash/uniqueId'
import fakeUsers from 'provider/fake-data/fake-users'
import fakeLoadingArms from 'provider/fake-data/fake-loading-arms'
import fakeShops from './fake-shops'

const data = [
  {
    id: uniqueId(),
    loadingArmId: fakeLoadingArms[0].id,
    startDate: Date.now(),
    startTime: {
      minutes: '0',
      hours: '-1'
    },
    endDate: Date.now(),
    endTime: {
      minutes: '0',
      hours: '-2'
    },
    lastUpdate: {
      user: fakeUsers[0],
      date: Date.now()
    }
  },
  {
    id: uniqueId(),
    loadingArmId: fakeLoadingArms[1].id,
    startDate: Date.now(),
    startTime: {
      minutes: '01',
      hours: '00'
    },
    endDate: Date.now(),
    endTime: {
      minutes: '01',
      hours: '00'
    },
    lastUpdate: {
      user: fakeUsers[0],
      date: Date.now()
    }
  },
  {
    id: uniqueId(),
    loadingArmId: fakeLoadingArms[2].id,
    startDate: Date.now(),
    startTime: {
      minutes: '01',
      hours: '00'
    },
    endDate: Date.now(),
    endTime: {
      minutes: '01',
      hours: '00'
    },
    lastUpdate: {
      user: fakeUsers[0],
      date: Date.now()
    }
  }
]

export function getResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export default data
