import React from 'react'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { SubBar } from 'dumb/sub-bar'
import { EnhancedInput } from 'dumb/text-field'
import debounce from 'lodash/debounce'
import { total } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { List } from 'dumb/list'
import Card from 'dumb/card'
import * as Nav from 'app/nav'
import Button from '@material-ui/core/Button/Button'
import LegacyBase from 'smart/base/legacy-base'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar/Avatar'
import CompanyIcon from '@material-ui/icons/business'
import { produce } from 'immer'
import CircularProgress from '@material-ui/core/CircularProgress'

class SmartTransportCompaniesList extends LegacyBase {
  static contextTypes = {
    router: () => null
  }

  state = {
    list: null,
    filteredList: null,
    term: '',
    loading: false
  }

  editState = (fn) => this.setState(produce(fn))

  fetchData() {
    this.editState((draft) => {
      draft.loading = true
    })

    return total.getTransportCompanies().then((resp) => {
      this.editState((draft) => {
        draft.list = resp
        draft.loading = false
      })

      return resp
    })
  }

  setBreadcrumbs() {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)

    const crumbs = [crumb.home(), crumb.transportCompanies()]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  componentDidMount() {
    super.componentDidMount()
    this.fetchData().then((list) => this.setBreadcrumbs(list))
  }

  renderBarContent(props) {
    if (!this.state.list) return null

    return (
      <SubBar smallScreen={props.smallScreen}>
        <div style={{ height: 64, display: 'flex', alignItems: 'center', flex: 1 }}>
          <div style={{ flex: 1 }}>
            <EnhancedInput
              value={this.state.term}
              onChange={this.handleSearch}
              placeholder={this.props.translation.common.search}
            />
          </div>
        </div>
      </SubBar>
    )
  }

  renderLoading() {
    if (this.state.loading) return <CircularProgress color="secondary" />
  }

  renderContent() {
    if (!this.state.list) return null

    const { classes, translation } = this.props

    return (
      <React.Fragment>
        {this.props.canAddTransportCompanies && (
          <Card>
            <Button className={classes.button} onClick={() => this.pushPath(Nav.transportCompanyCreate())}>
              <AddIcon className={classes.leftIcon} />
              {translation.common.add}
            </Button>
          </Card>
        )}
        <Card>
          <List
            data={this.state.filteredList || [...(this.state.list.data || [])].sort(this.sortByCompanyName)}
            onSelect={this.handleSelect}
            dataConverter={(item) => {
              const avatar = (
                <Avatar style={{ backgroundColor: item.isActive ? '#7CB342' : '' }}>
                  <CompanyIcon />
                </Avatar>
              )

              return {
                id: item.id,
                primaryText: item.name,
                secondaryText: '',
                avatar,
                notActive: !this.props.canEditTransportCompanies
              }
            }}
          />
        </Card>
      </React.Fragment>
    )
  }

  handleSearch = debounce((val) => {
    this.setState({
      term: val,
      filteredList: val
        ? this.state.list.data
            .filter((x) => x.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
            .sort(this.sortByCompanyName)
        : null
    })
  }, 300)

  sortByCompanyName(a, b) {
    let nameA = a.name.toUpperCase()
    let nameB = b.name.toUpperCase()

    if (nameA < nameB) return -1

    if (nameA > nameB) return 1

    return 0
  }

  handleSelect = (data) => {
    const id = data.id
    this.pushPath(Nav.transportCompanyEdit(id))
  }

  pushPath = (path) => {
    this.props.history.push(path)
  }
}

const styles = (theme) => ({ button: { margin: theme.spacing(1) }, leftIcon: { marginRight: theme.spacing(1) } })

const stateToProps = (state) => ({
  canAddTransportCompanies: state.user.permissions.canAddTransportCompanies,
  canEditTransportCompanies: state.user.permissions.canEditTransportCompanies
})

export default connect(stateToProps)(withStyles(styles)(translate(SmartTransportCompaniesList)))
