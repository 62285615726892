import React, { Component } from 'react'
import { ShopView } from 'dumb/shop'
import translate from 'i18n/translate'
import { connect } from 'react-redux'
import { getFirstShop } from 'store/actions/shop-action-creators'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import { resetEntity } from 'store/actions/entity-action-creators'
import { editShop } from 'app/nav'
import ShopTiles from 'smart/shop/shop-tiles'
import HomeTitles from './home-tiles'
import { TileContainer } from 'dumb/tile'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ModeEditIcon from '@material-ui/icons/Edit'

class HomeShop extends Component {
  static contextTypes = {
    router: () => null
  }

  componentDidMount() {
    this.props.getEntity()
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.entity !== this.props.entity
  }

  componentWillUnmount() {
    this.props.dispatch(resetEntity())
  }

  shouldNotRender() {
    const { entity } = this.props

    return entity.fetching || entity.data === null || entity.identity !== 'shop'
  }

  render() {
    if (this.shouldNotRender()) {
      return null
    }

    const data = this.props.entity.data

    return (
      <div>
        <Card>
          <ShopView data={data} timeZones={this.props.entity?.associated?.timeZones?.data} />
          <EntityActions
            showButtons={this.props.canEditShop}
            buttons={this.renderButtons()}
            updateData={data.lastUpdate}
          />
        </Card>
        <TileContainer style={{ display: 'block' }}>
          <ShopTiles shop={data} inline />
          <HomeTitles inline />
        </TileContainer>
      </div>
    )
  }

  renderButtons() {
    return (
      <Button onClick={this.handleEdit} startIcon={<ModeEditIcon />}>
        {this.props.translation.entityView.editButton}
      </Button>
    )
  }

  handleEdit = () => {
    const id = this.props.entity.data.id
    this.pushPath(editShop(id))
  }

  pushPath = (path) => this.props.history.push(path)
}

const stateToProps = (state) => ({
  entity: state.entity,
  canEditShop: state.user.permissions.canEditShop
})

const actionsToProps = (dispatch) => ({ getEntity: () => dispatch(getFirstShop()), dispatch })

export default connect(stateToProps, actionsToProps)(translate(withRouter(HomeShop)))
