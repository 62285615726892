import React from 'react'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { SubBar, SubBarControl } from 'dumb/sub-bar'
import { EnhancedInput } from 'dumb/text-field'
import debounce from 'lodash/debounce'
import LegacyBase from 'smart/base/legacy-base'
import { total } from 'provider'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { setBreadcrumbs, showMessage } from 'store/actions/main-layout-action-creators'
import Card from 'dumb/card'
import * as Nav from 'app/nav'
import { DatePicker } from 'dumb/date'
import { produce } from 'immer'
import TotalAppointmentItem from 'dumb/total-appointment/total-appointment-item'
import { setAppointmentsFilter } from 'store/actions/memory-filters-state-actions'
import TotalAppointmentStatuses from 'provider/total-appointment-statuses'
import PageLoader from 'dumb/page-loader'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { file as fileApi } from 'provider'
import hideTimeZone from '../hide-zone'

class SmartTotalAppointmentsList extends LegacyBase {
  barLines = 1

  constructor(props) {
    super(props)

    const search = this.props.location.search
    const parsedSearch = new URLSearchParams(search)

    let startDate = Number.parseInt(parsedSearch.get('startDate') || undefined) || Date.now()
    let endDate = Number.parseInt(parsedSearch.get('endDate') || undefined) || startDate + 1000 * 60 * 60 * 24 * 30
    let term = parsedSearch.get('reservation') || undefined

    if (!term) {
      const filterState = this.props.filterState
      if (filterState) {
        try {
          startDate = filterState.startDate
          endDate = filterState.endDate
          term = filterState.term
        } catch (e) {
          console.warn('Set Filter FAIL. Storage filter removed.')
          this.props.setAppointmentsFilter(null)
        }
      }
    }

    this.state = {
      list: null,
      loading: false,
      filter: {
        term: term || '',
        startDate: startDate,
        endDate: endDate
      }
    }
  }

  fetchData = () => {
    this.editState((draft) => {
      draft.loading = true
    })

    if (this.props.toCancel) {
      return total
        .getAppointmentsToCancel({ shopId: this.getShopId(), ...this.state.filter })
        .then((list) => {
          this.editState((draft) => {
            draft.loading = false
            draft.list = list
          })
          return list
        })
        .catch(() =>
          this.editState((draft) => {
            draft.loading = false
            this.props.showMessage(this.props.translation.requestMessages.serverError, 'error')
          })
        )
    }

    return total
      .getAppointments({ shopId: this.getShopId(), ...this.state.filter })
      .then((list) => {
        this.editState((draft) => {
          draft.loading = false
          draft.list = list
        })
        return list
      })
      .catch(() =>
        this.editState((draft) => {
          draft.loading = false
          this.props.showMessage(this.props.translation.requestMessages.serverError, 'error')
        })
      )
  }

  delayFetchData = debounce(() => this.fetchData(), 300, {
    leading: true
  }).bind(this)

  setBreadcrumbs(data) {
    const crumb = getCrumbs(this.props.translation.breadcrumbs)
    const parents = data.parents
    const shopName = parents.shop.name
    const shopId = this.getShopId()

    const crumbs = [
      crumb.home(),
      crumb.shops(),
      crumb.shop([shopId], shopName),
      crumb.unavailabilityAndAppointments([shopId]),
      crumb.totalAppointments([shopId])
    ]

    this.props.dispatch(setBreadcrumbs(crumbs))
  }

  handleCancelAppointment = (appointment, cancelReason) => {
    const requestBody = {
      token: appointment.token,
      shopId: this.getShopId(),
      status: TotalAppointmentStatuses.Cancelled,
      reason: cancelReason
    }

    total.updateAppointmentStatus(requestBody).then(() => this.fetchData())
  }

  handleDeclareArrivedAppointment = (appointment) => {
    const requestBody = {
      token: appointment.token,
      shopId: this.getShopId(),
      status: TotalAppointmentStatuses.Arrived
    }

    total.updateAppointmentStatus(requestBody).then(() => this.fetchData())
  }

  handleAppointmentStatusChange = (appointment, newStatus) => {
    if (!newStatus) {
      console.warn('No status to send.')
      return
    }
    const requestBody = {
      token: appointment.token,
      shopId: this.getShopId(),
      status: newStatus
    }

    total.updateAppointmentStatus(requestBody).then(() => this.fetchData())
  }

  componentDidMount() {
    this.fetchData().then((list) => this.setBreadcrumbs(list))
  }

  renderContent() {
    if (this.state.loading) {
      return <PageLoader />
    }

    if (!this.state.list) {
      return null
    }

    return (
      <React.Fragment>
        {this.props.canEditAppointments && !this.props.toCancel && (
          <Card>
            <div style={{ margin: 8 }}>
              <Button onClick={() => this.pushPath(Nav.totalAppointmentCreate(this.getShopId()))}>
                <AddIcon style={{ marginRight: 8 }} />
                {this.props.translation.totalAppointments.createAppointment}
              </Button>
            </div>
          </Card>
        )}
        {this.state.list.data.map((value, index, array) => (
          <div style={{ marginBottom: index === array.length - 1 ? '' : 16 }} key={index}>
            <Card>
              <TotalAppointmentItem
                fileLinkClick={(file) => fileApi.appointmentAttachmentDownload(file.id)}
                style={{ padding: 24, lineHeight: '2' }}
                data={value}
                onCancelClick={(appointment, cancelReason) => this.handleCancelAppointment(appointment, cancelReason)}
                onUpdateClick={(appointment) =>
                  this.pushPath(Nav.totalAppointmentEdit(this.getShopId(), appointment.token))
                }
                onDeclareArrivedClick={(appointment) => {
                  this.handleAppointmentStatusChange(appointment, TotalAppointmentStatuses.Arrived)
                }}
                onDeclareFinishedClick={(appointment) => {
                  this.handleAppointmentStatusChange(appointment, TotalAppointmentStatuses.Finished)
                }}
                onDeclareAbsentClick={(appointment) => {
                  this.handleAppointmentStatusChange(appointment, TotalAppointmentStatuses.Absent)
                }}
                onLoadingAuthorizationClick={(loadingAuthorizationId, startDate) =>
                  this.redirectToLoadingAuthorizations(loadingAuthorizationId, startDate)
                }
                canEdit={this.props.canEditAppointments}
              />
            </Card>
          </div>
        ))}
      </React.Fragment>
    )
  }

  renderBarContent(props) {
    const { startDate, endDate } = this.props.translation.reportsFilter

    const searchView = (
      <SubBarControl style={{ margin: '0.5rem 0' }}>
        <EnhancedInput
          value={this.state.filter.term}
          onChange={(value) =>
            this.filterEditState((draft) => {
              draft.filter.term = value
            })
          }
          placeholder={this.props.translation.totalAppointments.search}
        />
      </SubBarControl>
    )

    const startDateView = (
      <SubBarControl title={startDate}>
        <DatePicker
          value={this.state.filter.startDate}
          style={{ marginTop: 0 }}
          onChange={(value) =>
            this.filterEditState((draft) => {
              draft.filter.startDate = value
            })
          }
        />
      </SubBarControl>
    )

    const endDateView = (
      <SubBarControl title={endDate}>
        <DatePicker
          value={this.state.filter.endDate}
          style={{ marginTop: 0 }}
          onChange={(value) =>
            this.filterEditState((draft) => {
              draft.filter.endDate = value
            })
          }
        />
      </SubBarControl>
    )

    return (
      <SubBar smallScreen={props.smallScreen}>
        {startDateView}
        {endDateView}
        {searchView}
      </SubBar>
    )
  }

  editState = (fn, callback) => {
    this.setState(produce(fn), callback)
  }

  filterEditState = (fn) => {
    this.editState(fn, () => {
      this.delayFetchData()

      this.props.setAppointmentsFilter({
        startDate: this.state.filter.startDate,
        endDate: this.state.filter.endDate,
        term: this.state.filter.term
      })
    })
  }

  redirectToLoadingAuthorizations = (loadingAuthorizationId, startDate) => {
    window.open(
      '#' +
        Nav.loadingAuthorizations(this.getShopId()) +
        `?loadingAuthorizationId=${loadingAuthorizationId}&startDate=${startDate}&endDate=${startDate}`
    )
  }

  pushPath = (path) => this.props.history.push(path)

  getShopId = () => this.props.match.params.shopId
}

const stateToProps = (state) => ({
  filterState: state.memoryFiltersState.appointmentsFilter,
  canEditAppointments: state.user.permissions.canEditAppointments
})

const actionsToProps = (dispatch) => ({
  setAppointmentsFilter: (data) => dispatch(setAppointmentsFilter(data)),
  showMessage: (message, type) => dispatch(showMessage(message, type)),
  dispatch
})

export default connect(stateToProps, actionsToProps)(translate(hideTimeZone()(SmartTotalAppointmentsList)))
