import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import translate from 'i18n/translate'
import Toggle from 'dumb/toggle-wrap/toggle'
import Text from 'dumb/text/text'
import Dropdown from 'dumb/dropdown/dropdown'
import Button from '@material-ui/core/Button'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import { isEmail } from 'utils/string-test'

const Languages = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  es: 'Español'
}

class TransportCompanyUserForm extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'name',
        stateField: 'nameIsValid'
      },
      {
        dataField: 'email',
        stateField: 'emailIsValid',
        validator: (val) => isEmail(val)
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}
        {this.props.canEdit && this.renderActions()}
      </Card>
    )
  }

  renderActions() {
    return (
      <EntityActions
        showButtons={true}
        buttons={this.renderControls()}
        updateData={this.state.data ? this.state.data.lastUpdate : ''}
      />
    )
  }

  isNew = () => (this.state.data ? !this.state.data.id : true)

  handleTryDelete() {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete() {
    this.setState({ confirmDelete: false })
  }

  handleDelete() {
    this.props.onDelete(this.state.data.id)
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderControls() {
    const { createButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button onClick={() => this.handleDelete()}>{deleteButton}</Button>
            <Button onClick={() => this.handleCancelDelete()}>{cancelButton}</Button>
          </div>
        </div>
      )

    if (this.isNew()) {
      return (
        <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    } else {
      return this.myRenderEditControls()
    }
  }

  renderEditControls() {
    const { updateButton, deleteButton } = this.props.translation.entityEdit

    return (
      <React.Fragment>
        <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()}>
          {updateButton}
        </Button>
        {this.props.canDelete !== false && <Button onClick={() => this.handleTryDelete()}>{deleteButton}</Button>}
      </React.Fragment>
    )
  }

  renderMainForm(d) {
    const { name, email, isActive, language, lastConnection } = this.props.translation.transportCompanyUserEdit

    const { chars } = this.props.translation.entityEdit

    return (
      <Form>
        <FormGroup>
          <TextField
            label={name}
            placeholder={name}
            autofocus={true}
            value={d.name}
            onChange={(val) => this.handlePropChange('name', val)}
            attention={!this.state.nameIsValid}
            message={`${d.name ? d.name.length : 0}/100 ${chars}`}
            test={(v) => v.length < 101}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label={email}
            placeholder={email}
            value={d.email}
            onChange={(val) => this.handlePropChange('email', val)}
            attention={!this.state.emailIsValid}
            message={`${d.email ? d.email.length : 0}/100 ${chars}`}
            test={(v) => v.length < 101}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
        <FormGroup>
          <Toggle
            toggled={d.isActive}
            label={isActive}
            onToggle={() => this.handlePropChange('isActive', !d.isActive)}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            ensureValue
            label={language}
            value={d.language}
            data={['en', 'fr', 'de', 'es']}
            dataConverter={(item) => {
              return {
                value: item,
                text: Languages[item]
              }
            }}
            onChange={(val) => this.handlePropChange('language', val)}
            disabled={!this.props.canEdit}
          />
        </FormGroup>
        {d.lastConnection && (
          <FormGroup>
            <Text type="caption">{lastConnection}</Text>
            <Text type="body-1">{this.props.dateFormatter(d.lastConnection, 'dateTime')}</Text>
          </FormGroup>
        )}
      </Form>
    )
  }

  myRenderEditControls() {
    return (
      <div style={{ display: 'flex', gap: '0.5rem' }}>
        {this.renderEditControls()}
        <Button onClick={this.props.onResetPassword}>
          {this.props.translation.transportCompanyUserEdit.resetPassword}
        </Button>
      </div>
    )
  }
}

export default translate(TransportCompanyUserForm)
