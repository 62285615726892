import axios from 'axios'

export default {
  getAppointmentConfiguration(data) {
    return axios.get('getAppointmentConfiguration', { params: data })
  },

  searchLoadingAuthorization(data) {
    return axios.get('searchLoadingAuthorizations', { params: data })
  },

  createTotalAppointment(data) {
    return axios.post('createTotalAppointment', data)
  },

  updateTotalAppointment(data) {
    return axios.put('updateTotalAppointment', data)
  },

  getAppointmentCounters(placeId) {
    return axios.get('getAppointmentCounters', { params: { shopId: placeId } })
  }
}
