import uniqueId from 'lodash/uniqueId'
import findIndex from 'lodash/findIndex'
import fakeShops from './fake-shops'
import fakeSericePoints from './fake-checkpoints'
import fakeGenericProducts from './fake-generic-products'
import fakeTransportCompanies from './fake-transport-companies'

const times = [
  {
    id: 't1',
    from: new Date(1970, 0, 0, 10).getTime(),
    to: new Date(1970, 0, 0, 11, 30).getTime(),
    duration: 90
  },
  {
    id: 't2',
    from: new Date(1970, 0, 0, 11, 30).getTime(),
    to: new Date(1970, 0, 0, 13).getTime()
  }
]

const data = [
  {
    id: uniqueId(),
    loadingAuthorizationNumber: '1',
    startValidityDate: Date.now(),
    endValidityDate: Date.now() + 1000 * 60 * 60 * 24,
    startDateIsValid: true,
    endDateIsValid: true,
    clientName: 'Total',
    countryCode: '33',
    country: 'FR',
    products: [
      {
        code: '73004',
        name: 'SPIRDANE D 60',
        qty: 24.0,
        measureUnit: 'TONNES'
      },
      {
        code: '73031',
        name: 'XYLENE.',
        qty: 22.0,
        measureUnit: 'TONNES'
      },
      {
        code: '73024',
        name: 'SOLANE HEPTANE',
        qty: 25.0,
        measureUnit: 'TONNES'
      },
      {
        code: '73430',
        name: 'KERDANE',
        qty: 20.0,
        measureUnit: 'TONNES'
      }
    ],
    lanStatus: 'Valid', //Expired, Valid, PartiallyValid, BeyondFutureLimit
    appointment: {
      id: 'c7fa02798c1c4b74ae3a64a90dc9530e',
      date: new Date(1970, 0, 11).getTime(),
      status: 'Finished',

      productAppointments: [
        {
          product: {
            name: 'SOLANE HEXAN 45',
            code: '71023'
          },
          appointmentTime: 1566478800000
        },
        {
          product: {
            name: 'SOLANE HEXAN 47',
            code: '71024'
          },
          appointmentTime: 1566478800000
        }
      ],

      checkpointId: fakeSericePoints[2].id,
      time: times[0],
      transportCompany: fakeTransportCompanies[0],

      driverFirstName: 'test',
      driverLastName: 'test',
      staffComments: 'test',
      useFlexiTank: false,
      token: 'token1'
    }
  },
  {
    id: uniqueId(),
    loadingAuthorizationNumber: '2',
    startValidityDate: Date.now(),
    endValidityDate: Date.now() + 1000 * 60 * 60 * 24,
    startDateIsValid: true,
    endDateIsValid: true,
    clientName: 'Total',
    countryCode: '33',
    country: 'FR',
    genericProducts: [fakeGenericProducts[2]]
    // appointment: fakeAppointments[1]
  },
  {
    id: uniqueId(),
    loadingAuthorizationNumber: '1339',
    startValidityDate: Date.now(),
    endValidityDate: Date.now() + 1000 * 60 * 60 * 24,
    clientName: 'Total',
    countryCode: '33',
    country: 'FR',
    commercialProducts: 'Prod1, Prod2',
    genericProducts: [fakeGenericProducts[0], fakeGenericProducts[2]]
  }
]

export function getResponse(data) {
  return {
    data: data,
    parents: {
      shop: {
        id: fakeShops[0].id,
        name: fakeShops[0].name
      }
    }
  }
}

export function update(entity) {
  const index = findIndex(data, { id: entity.id })
  data[index] = entity
}

export { times }
export default data
