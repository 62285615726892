import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import translate from 'i18n/translate'
import Dropdown from 'dumb/dropdown'
import last from 'lodash/last'
import { produce } from 'immer'
import { TextField } from 'dumb/text-field'
import { translateState } from 'provider/total-appointment-statuses'
import Autocomplete from 'dumb/autocomplete'
import { connect } from 'react-redux'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'
import { RadioButtonGroup, RadioButton } from 'dumb/radio-button'
import { CircularProgress } from '@material-ui/core'

class AppointmentEditForm extends Validation {
  validateConfig() {
    return [
      {
        dataField: 'driverLastName',
        stateField: 'driverLastNameIsValid',
        calcState: (val, state) => {
          if (val == '' && state.data.driverFirstName != '')
            return {
              driverLastNameIsValid: false,
              driverFirstNameIsValid: true
            }
          if (val != '' && state.data.driverFirstName == '')
            return {
              driverLastNameIsValid: true,
              driverFirstNameIsValid: false
            }

          return {
            driverLastNameIsValid: true,
            driverFirstNameIsValid: true
          }
        }
      },
      {
        dataField: 'driverFirstName',
        stateField: 'driverFirstNameIsValid',
        calcState: (val, state) => {
          if (val == '' && state.data.driverLastName != '')
            return {
              driverLastNameIsValid: true,
              driverFirstNameIsValid: false
            }

          if (val != '' && state.data.driverLastName == '')
            return {
              driverLastNameIsValid: false,
              driverFirstNameIsValid: true
            }

          return {
            driverLastNameIsValid: true,
            driverFirstNameIsValid: true
          }
        }
      },
      {
        dataField: 'transportCompany',
        stateField: 'transportCompanyIsValid',
        calcState: (val, state) => {
          return { transportCompanyIsValid: Boolean(val) }
        }
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}
        {this.props.canEdit && this.renderActions()}
      </Card>
    )
  }

  renderActions() {
    return (
      <EntityActions
        showButtons={true}
        buttons={this.renderControls()}
        updateData={this.state.data ? this.state.data.lastUpdate : ''}
      />
    )
  }

  isNew = () => (this.state.data ? !this.state.data.id : true)

  handleTryDelete() {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete() {
    this.setState({ confirmDelete: false })
  }

  handleDelete() {
    this.props.onDelete(this.state.data.id)
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderControls() {
    const { createButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <Button onClick={() => this.handleDelete()}>{deleteButton}</Button>
          <Button onClick={() => this.handleCancelDelete()}>{cancelButton}</Button>
        </div>
      )

    if (this.isNew()) {
      return (
        <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    } else return this.renderEditControls()
  }

  renderEditControls() {
    const { updateButton, deleteButton } = this.props.translation.entityEdit

    return (
      <React.Fragment>
        <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()}>
          {updateButton}
        </Button>
        {this.props.canDelete !== false && <Button onClick={() => this.handleTryDelete()}>{deleteButton}</Button>}
      </React.Fragment>
    )
  }

  editState = (fn, cb) => this.setState(produce(fn), cb)

  async componentDidUpdate(prevProps) {
    if (prevProps.config !== this.props.config) await this.autoFilling()

    if (this.state.data.status == 'ToBeConfirmed' && this.state.data.driverLastName && this.state.data.driverFirstName)
      await this.handlePropChangeAsync('status', 'Valid')

    if (this.state.data.status == 'Valid' && (!this.state.data.driverLastName || !this.state.data.driverFirstName))
      await this.handlePropChangeAsync('status', 'ToBeConfirmed')
  }

  async componentDidMount() {
    if (!this.props.data.status) await this.handlePropChangeAsync('status', 'ToBeConfirmed')

    await this.autoFilling()
  }

  async autoFilling() {
    if (!this.props.data.date) {
      const cfgDates = this.props.config.dates || []

      if (cfgDates.length !== 0) await this.handleAppointmentDateChange(cfgDates[0].date)
    }
  }

  handleAppointmentDateChange = async (value) => {
    if (this.state.data.date === value) return

    await this.handlePropChangeAsync('date', value)

    const selectedDate = this.props.config.dates.find((d) => value === d.id)
    await this.handleAppointmentTimeChange(selectedDate.times[0])
  }

  handleAppointmentTimeChange = async (value) => {
    if (this.state.data.time && this.state.data.time.id == value.id) return

    await this.handlePropChange('time', value)

    const selectedDate = this.props.config.dates.find((d) => this.state.data.date === d.id)
    const selectedTime = selectedDate ? selectedDate.times.find((t) => value.id === t.id) : null
    const checkpoints = selectedTime
      ? this.props.config.checkpoints.filter((c) =>
          selectedTime.availableCheckpointsIds ? selectedTime.availableCheckpointsIds.includes(c.id) : false
        )
      : null
    const selectedCheckpointDefault = Boolean(selectedTime)
      ? checkpoints.find((c) => c.id == selectedTime.selectedCheckpointId)
      : null

    await this.handlePropChange('checkpointId', selectedCheckpointDefault.id)
  }

  sortByName = (a, b) => {
    const nameA = a.toLowerCase()
    const nameB = b.toLowerCase()

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1

    return 0
  }

  renderMainForm(d) {
    if (!this.props.config || !d) {
      return null
    }

    const multiprod = this.props.config.isMultiProductsAppointment || false
    const tr = this.props.translation.totalAppointmentEdit
    const selectedDate = this.props.config.dates.find((date) => d.date === date.date)
    const selectedTime = selectedDate ? selectedDate.times.find((t) => (d.time ? d.time.id === t.id : false)) : null
    const { loadingWithFlexitank } = this.props.translation.authorizationResearch
    const { yes, no } = this.props.translation.common

    const checkpoints = selectedTime
      ? this.props.config.checkpoints
          .map((c) => {
            const place = this.props.config.places.find((p) => p.id == c.placeId) || {}
            const newCheckpoint = { ...c, place: { ...place } }
            return newCheckpoint
          })
          .filter((c) => {
            return selectedTime.availableCheckpointsIds ? selectedTime.availableCheckpointsIds.includes(c.id) : false
          })
          .sort((a, b) => this.sortByName(a.place.name, b.place.name))
      : null

    return (
      <Form>
        <FormGroup>
          <RadioButtonGroup
            style={{ flexWrap: 'wrap' }}
            label={loadingWithFlexitank}
            name={'useFlexiTankIndex'}
            onChange={(val) => {
              const value = val === '1' ? true : false
              this.handlePropChange('useFlexiTank', value)
              this.props.flexiTankChange(value)
            }}
            value={this.props.useFlexiTank ? '1' : '2'}
            ensureValue="2"
            disabled={!!this.props.isRequestingSilent}
            row
          >
            <RadioButton
              value="1"
              label={yes}
              style={{ width: 'auto', marginRight: 24 }}
              iconStyle={{ marginRight: 12 }}
              disabled={!!this.props.isRequestingSilent}
            />
            <RadioButton
              value="2"
              label={no}
              style={{ width: 'auto', marginRight: 24 }}
              iconStyle={{ marginRight: 12 }}
              disabled={!!this.props.isRequestingSilent}
            />
          </RadioButtonGroup>
        </FormGroup>
        {this.props.isRequestingSilent && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </div>
        )}
        <FormGroup>
          <Dropdown
            label={tr.date}
            value={selectedDate ? selectedDate.id : null}
            data={this.props.config.dates}
            disabled={!!this.props.isRequestingSilent}
            dataConverter={(item) => {
              const date = this.props.dateFormatter(item.date)
              const startTime = this.props.dateFormatter(item.times[0].from, 'time')
              const endTime = this.props.dateFormatter(last(item.times).to, 'time')

              return {
                value: item.id,
                text: `${date} ${startTime} - ${endTime}`
              }
            }}
            onChange={this.handleAppointmentDateChange}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label={tr.time}
            value={selectedTime ? selectedTime.id : null}
            data={selectedDate ? selectedDate.times : []}
            disabled={!!this.props.isRequestingSilent}
            dataConverter={(item) => {
              const startTime = this.props.dateFormatter(item.from, 'time')
              const endTime = this.props.dateFormatter(item.to, 'time')
              const duration = Math.ceil(item.duration / 60)

              return {
                value: item.id,
                text: `${startTime} - ${endTime}${duration && multiprod ? ', ' + tr.duing(duration) : ''}`
              }
            }}
            onChange={(data, i) => this.handleAppointmentTimeChange(selectedDate.times.find((t) => t.id === data))}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label={tr.checkpoint}
            value={d.checkpointId}
            data={checkpoints ? checkpoints : []}
            disabled={!!this.props.isRequestingSilent}
            dataConverter={(item) => {
              return {
                value: item.id,
                text: `${item.place ? item.place.name : 'PLACE_LOAD_ERROR'} - ${item.name}`
              }
            }}
            onChange={(data, i) => this.handlePropChange('checkpointId', data)}
          />
        </FormGroup>
        <FormGroup>
          <Autocomplete
            label={tr.transportCompany}
            title={'-'}
            list={this.props.config.transportCompanies}
            disabled={!!this.props.isRequestingSilent}
            value={
              d.transportCompany
                ? this.props.config.transportCompanies.find((tc) => tc.id == d.transportCompany.id)
                : null
            }
            onChange={(val) => this.handlePropChange('transportCompany', val)}
            attention={!this.state.transportCompanyIsValid}
            dataConverter={(item) => {
              return {
                value: item,
                text: item.name
              }
            }}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label={tr.comment}
            placeholder={tr.comment}
            value={d.staffComments}
            onChange={(data) => this.handlePropChange('staffComments', data)}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label={tr.driverFirstName}
            placeholder={tr.driverFirstName}
            value={d.driverFirstName}
            onChange={(data) => this.handlePropChange('driverFirstName', data)}
            attention={!this.state.driverFirstNameIsValid}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label={tr.driverLastName}
            placeholder={tr.driverLastName}
            value={d.driverLastName}
            onChange={(data) => this.handlePropChange('driverLastName', data)}
            attention={!this.state.driverLastNameIsValid}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label={tr.state}
            value={d.status}
            data={['ToBeConfirmed', 'Valid', 'ToBeCancelled', 'Cancelled', 'Absent', 'Arrived', 'Finished']}
            dataConverter={(item) => {
              return {
                value: item,
                text: translateState(this.props.translation.totalAppointmentStatuses, item)
              }
            }}
            onChange={(data) => this.handlePropChange('status', data)}
          />
        </FormGroup>
      </Form>
    )
  }
}

export default connect()(translate(AppointmentEditForm))
