import { useCallback, useEffect, useState } from 'react'
import { TranslationList } from './dumb/translation-list'
import { editTranslation, createTranslation } from 'app/nav'
import { useTranslation } from 'i18n/translate'
import { BrandFilter } from './dumb/brand-filter'
import { setBreadcrumbs } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import { AppBar, Toolbar } from '@material-ui/core'
import { AddCircleOutline as AddIcon } from '@material-ui/icons'
import Card from 'dumb/card'
import PageLoader from 'dumb/page-loader'
import { SearchInput } from 'dumb/search-input'
import { TextTranslateModel } from 'shared/models'
import { useHistory, useLocation } from 'react-router'
import { translations, brand as brandAPI } from 'provider'
import { BarContainer, BarContent } from './styled'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { useQuery } from 'react-query'
import { CenterContainer } from 'dumb/center-container'
import Container from 'dumb/container'
import Button from '@material-ui/core/Button'
import { FormActionGroup } from 'dumb/form'

function TranslationListSmart(props: any) {
  const history = useHistory()
  const location = useLocation()

  const [list, setList] = useState<TextTranslateModel[] | null>(null)
  const [brandId, setBrandId] = useState<string | number | null>(
    new URLSearchParams(location.search).get('brand') || null
  )
  const [term, setTerm] = useState<string>(new URLSearchParams(location.search).get('term') || '')
  const { tr } = useTranslation()

  const { data: brands } = useQuery({
    queryKey: ['brands'],
    queryFn: ({ signal }) => brandAPI.getBrandList({ signal })
  })

  useEffect(() => {
    const crumb = getCrumbs(tr.breadcrumbs)
    props.dispatch(setBreadcrumbs([crumb.home(), crumb.translations()]))

    getTranslationList(term, brandId)
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()
    if (term) {
      params.set('term', term)
    }
    if (brandId) {
      params.set('brand', String(brandId))
    }

    history.replace({ search: params.toString() })
  }, [term, brandId])

  async function getTranslationList(term: string, brandId: string | number | null) {
    try {
      const res = await translations.getTranslationList({ term, brandId })
      setList(res.data)
    } catch (err) {
      console.warn(err)
    }
  }

  const getTranslationListDebounce = useCallback(debounce(getTranslationList, 300), [])

  function handleCreateTrnslation() {
    history.push(createTranslation())
  }

  function handleEditTrnslation(data: TextTranslateModel) {
    history.push(editTranslation(String(data.id)))
  }

  function handleChangeTerm(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTerm(ev.target.value)
    getTranslationListDebounce(ev.target.value, brandId)
  }

  function handleTermClear() {
    setTerm('')
    getTranslationList('', brandId)
  }

  function handleBrandChange(data: { brandId: string | number }) {
    setBrandId(data.brandId)
    getTranslationList(term, data.brandId)
  }

  if (!brands?.data || list === null) {
    return (
      <CenterContainer>
        <PageLoader />
      </CenterContainer>
    )
  }

  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={2} style={{ top: '4rem', borderRadius: 0 }}>
        <Toolbar>
          <BarContainer>
            <BarContent>
              {!!brands?.data && <BrandFilter brandId={brandId} brands={brands?.data} onChange={handleBrandChange} />}
              <SearchInput
                style={{ flex: 1 }}
                value={term}
                onChange={handleChangeTerm}
                placeholder={tr.userList.search}
                autoFocus
                onClear={handleTermClear}
              />
            </BarContent>
          </BarContainer>
        </Toolbar>
      </AppBar>
      <Container style={{ minHeight: 'auto' }}>
        <Card>
          <FormActionGroup style={{ padding: '0.5rem' }}>
            <Button startIcon={<AddIcon />} onClick={handleCreateTrnslation}>
              {tr.translationList.addButton}
            </Button>
          </FormActionGroup>
        </Card>
        <Card>
          <TranslationList data={list} brands={brands?.data || []} onSelect={handleEditTrnslation} />
        </Card>
      </Container>
    </>
  )
}

export default connect()(TranslationListSmart)
