import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ShopsReducerState {
  error: any
  fetching: boolean
  loaded: boolean
  items: any
  parents: any
  currentShopId: string | number | null
}

const initialState: ShopsReducerState = {
  error: null,
  fetching: false,
  loaded: false,
  items: null,
  parents: null,
  currentShopId: null
}

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    getShopListRequest: (state) => {
      state.error = null
      state.fetching = true
      state.loaded = false
    },

    getShopListSuccess: (state, { payload }: PayloadAction<any>) => {
      state.error = null
      state.fetching = false
      state.loaded = true
      state.items = payload.data
    },

    getLineListSuccess: (state, { payload }: PayloadAction<any>) => {
      state.items = state.items.map((item) => {
        if (item.id == payload.parents.shop.id) {
          item.lines = payload.data
        }

        return item
      })
    },

    getCheckpointListSuccess: (state, { payload }: PayloadAction<any>) => {
      state.items = state.items.map((item) => {
        if (item.id == payload.parents.shop.id) {
          item.checkpoints = payload.data
        }

        return item
      })
    },

    invalidateIsolatedShopList: (state) => {
      state.error = null
      state.fetching = false
      state.loaded = false
      state.items = null
    },

    setCurrentShop: (state, { payload }: PayloadAction<any>) => {
      state.currentShopId = payload
    }
  }
})

export const {
  getCheckpointListSuccess,
  getLineListSuccess,
  getShopListRequest,
  getShopListSuccess,
  invalidateIsolatedShopList,
  setCurrentShop
} = shopsSlice.actions

export default shopsSlice.reducer
