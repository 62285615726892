import React from 'react'
import LoginForm from 'dumb/login-form'
import css from './login.module.scss'
import { loginOpenAuth } from 'provider/auth'
import * as Nav from 'app/nav'
import { invalidateIsolatedShopList } from 'store/actions/shop-action-creators'
import { connect } from 'react-redux'
import { extractStoredUrl } from 'utils/history'
import { showMessage } from 'store/actions/main-layout-action-creators'
import translate from 'i18n/translate'

class Login extends React.Component {
  componentDidMount() {
    this.props.dispatch(invalidateIsolatedShopList())

    let params = window.location.hash.split('/')

    if (params[2] == 'error-email') {
      this.props.dispatch(showMessage(this.props.translation.login.emailError(params[3]), 'error'))
    } else if (params[2] == 'error') {
      this.props.dispatch(showMessage(this.props.translation.login.authError, 'error'))
    }

    window.location.hash = '#/login'
  }

  onSubmit = () => {
    if (loginOpenAuth()) {
      this.props.history.push(extractStoredUrl() || Nav.index())
    }
  }

  render() {
    return (
      <section className={css.root}>
        <LoginForm onSubmit={() => this.onSubmit()} />
      </section>
    )
  }
}

export default connect()(translate(Login))
