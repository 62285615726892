import React, { Component } from 'react'
import Tile, { TileContainer } from 'dumb/tile'
import * as Nav from 'app/nav'
import { connect } from 'react-redux'
import translate from 'i18n/translate'
import { withRouter } from 'react-router-dom'
import TemplateIcon from '@material-ui/icons/Message'
import LinearIcon from '@material-ui/icons/LinearScale'
import BasketIcon from '@material-ui/icons/ShoppingBasket'
import DaysOffIcon from '@material-ui/icons/Event'
import RDVIcon from '@material-ui/icons/Contacts'
import ScheduleIcon from '@material-ui/icons/Schedule'
import StorageIcon from '@material-ui/icons/Storage'

class HomeTiles extends Component {
  static contextTypes = {
    router: () => null
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.shop !== this.props.shop
  }

  render() {
    const { shop, inline } = this.props

    const shopId = shop.id

    const { lines, checkpoints, daysOff, unavailabilityAndAppointments, messageTemplates, loadingArms } =
      this.props.translation.shopView

    return (
      <TileContainer inline={inline}>
        {this.props.canViewLine && (
          <Tile
            title={lines(shop.lineCount)}
            count={shop.lineCount}
            iconClass={LinearIcon}
            onClick={() => this.pushPath(Nav.lines(shopId))}
          />
        )}
        {this.props.canViewCheckpoint && (
          <Tile
            title={checkpoints(shop.checkpointCount)}
            count={shop.checkpointCount}
            iconClass={BasketIcon}
            onClick={() => this.pushPath(Nav.checkpoints(shopId))}
          />
        )}
        {!this.props.limitedView && (
          <Tile
            count={shop.daysOffCount}
            title={daysOff}
            iconClass={DaysOffIcon}
            onClick={() => this.pushPath(Nav.shopDaysOff(shopId))}
          />
        )}
        {!this.props.limitedView && (
          <Tile
            title={messageTemplates}
            iconClass={TemplateIcon}
            onClick={() => this.pushPath(Nav.messageTemplates(shopId))}
          />
        )}
        {!this.props.limitedView && (
          <Tile
            title={unavailabilityAndAppointments}
            iconClass={RDVIcon}
            onClick={() => this.pushPath(Nav.unavailabilityAndAppointments(shopId))}
          />
        )}
        {!this.props.limitedView && (
          <Tile
            title={loadingArms}
            iconClass={StorageIcon}
            count={shop.loadingArmsCount}
            onClick={() => this.pushPath(Nav.loadingArms(shopId))}
          />
        )}
        <Tile
          title={this.props.translation.home.appointmentsScheduling}
          iconClass={ScheduleIcon}
          onClick={() => this.pushPath(Nav.appointmentsScheduling(shopId))}
        />
        {this.props.children}
      </TileContainer>
    )
  }

  pushPath = (path) => this.props.history.push(path)
}

const stateToProps = (state) => ({
  canViewLine: state.user.permissions.canViewLine,
  canViewCheckpoint: state.user.permissions.canViewCheckpoint,
  limitedView: state.user.permissions.limitedView
})

export default connect(stateToProps)(translate(withRouter(HomeTiles)))
