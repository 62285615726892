import React, { Component } from 'react'
import Tile, { TileContainer } from 'dumb/tile'
import { connect } from 'react-redux'
import * as Nav from 'app/nav'
import translate from 'i18n/translate'
import { withRouter } from 'react-router-dom'
import TemplateIcon from '@material-ui/icons/Message'
import TranslateIcon from '@material-ui/icons/Translate'
import PeopleIcon from '@material-ui/icons/People'
import ShopIcon from '@material-ui/icons/Domain'
import TransportCompanyIcon from '@material-ui/icons/LocalShipping'
import GenericProductsIcon from '@material-ui/icons/LocalGasStation'
import LoadingAuthorizationsIcon from '@material-ui/icons/CompassCalibration'
import LocalGasStationUnavailable from 'dumb/icons/local-gas-station-unavailable'
import AssignmentIcon from '@material-ui/icons/Assignment'

class HomeTiles extends Component {
  shouldComponentUpdate(nextProps) {
    return ['groupShops', 'counters', 'canViewUser'].some((x) => nextProps[x] !== this.props[x])
  }

  render() {
    const { counters, canViewUser, groupShops, inline, limitedView, canViewTransportCompanies } = this.props

    const {
      users,
      shops,
      messageTemplates,
      translations,
      genericProducts,
      transportCompanies,
      loadingAuthorizations,
      productsUnavailabilities,
      reports
    } = this.props.translation.home

    return (
      <TileContainer inline={inline}>
        {groupShops && (
          <Tile
            title={shops(counters.shops)}
            count={counters.shops}
            iconClass={ShopIcon}
            onClick={() => this.pushPath(Nav.shops())}
          />
        )}
        {canViewUser && !limitedView && (
          <Tile title={users} iconClass={PeopleIcon} onClick={() => this.pushPath(Nav.users())} />
        )}
        {!limitedView && (
          <Tile
            title={messageTemplates}
            iconClass={TemplateIcon}
            onClick={() => this.pushPath(Nav.messageTemplates())}
          />
        )}
        {!limitedView && (
          <Tile title={translations} iconClass={TranslateIcon} onClick={() => this.pushPath(Nav.translations())} />
        )}
        {!limitedView && (
          <Tile
            title={genericProducts}
            iconClass={GenericProductsIcon}
            count={counters.genericProducts}
            onClick={() => this.pushPath(Nav.genericProducts())}
          />
        )}
        {!limitedView && (
          <Tile
            title={productsUnavailabilities}
            iconClass={LocalGasStationUnavailable}
            onClick={() => this.pushPath(Nav.productsUnavailability())}
          />
        )}
        {canViewTransportCompanies && (
          <Tile
            title={transportCompanies}
            iconClass={TransportCompanyIcon}
            count={counters.transportCompanies}
            onClick={() => this.pushPath(Nav.transportCompanies())}
          />
        )}
        <Tile
          title={loadingAuthorizations}
          iconClass={LoadingAuthorizationsIcon}
          onClick={() => this.pushPath(Nav.loadingAuthorizations())}
        />

        <Tile title={reports} iconClass={AssignmentIcon} onClick={() => this.pushPath(Nav.reports())} />
      </TileContainer>
    )
  }

  pushPath = (path) => this.props.history.push(path)
}

const stateToProps = (state) => ({
  groupShops: state.user.groupShops,
  counters: state.user.userProfile.counters,
  canViewUser: state.user.permissions.canViewUser,
  canViewTransportCompanies: state.user.permissions.canViewTransportCompanies,
  limitedView: state.user.permissions.limitedView
})

export default connect(stateToProps)(translate(withRouter(HomeTiles)))
