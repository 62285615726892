import { useEffect, useState } from 'react'
import { TranslationEdit } from './dumb/translation-edit'
import { index, translations } from 'app/nav'
import { useTranslation } from 'i18n/translate'
import { setBreadcrumbs, showMessage, MessageTypes } from 'store/actions/main-layout-action-creators'
import { getCrumbs } from 'utils/@breadcrumds/breadcrumbs-helper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CenterContainer } from 'dumb/center-container'
import Container from 'dumb/container'
import { TextTranslateModel } from 'shared/models'
import { useHistory, useRouteMatch } from 'react-router'
import { translations as translationsApi, brand as brandAPI } from 'provider'
import { connect } from 'react-redux'
import { useQuery } from 'react-query'

function SmartTranslationEdit(props: any) {
  const { params } = useRouteMatch<{ translationId?: string }>()
  const history = useHistory()

  const { tr } = useTranslation()
  const [data, setData] = useState<TextTranslateModel>()
  const [config, setConfig] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)

  const { data: brands } = useQuery({
    queryKey: ['brands'],
    queryFn: ({ signal }) => brandAPI.getBrandList({ signal })
  })

  const isNew = !params.translationId

  useEffect(() => {
    fetchData()
    initBreadcrumbs()
  }, [])

  async function fetchData() {
    setLoading(true)

    try {
      const [config, res] = await Promise.all([
        translationsApi.getTranslationConfig(),
        translationsApi.getTranslation(params.translationId)
      ])

      setConfig(config.data)
      setData(res.data as TextTranslateModel)
      setLoading(false)
    } catch (err) {
      history.push(index())
    }
  }

  function initBreadcrumbs() {
    const crumb = getCrumbs(tr.breadcrumbs)

    props.dispatch(
      setBreadcrumbs(
        params.translationId
          ? [crumb.home(), crumb.translations(), crumb.editTranslation([params.translationId])]
          : [crumb.home(), crumb.translations(), crumb.newTranslation()]
      )
    )
  }

  async function handleSave(data: TextTranslateModel) {
    setProcessing(true)

    if (isNew) {
      try {
        await translationsApi.createTranslation(data)
        props.dispatch(showMessage(tr.translationOperationMessages.createSuccess, MessageTypes.Success))
        history.push(translations())
      } catch (err) {
        props.dispatch(showMessage(tr.translationOperationMessages.createError, MessageTypes.Error))
        setProcessing(false)
      }
    } else {
      try {
        await translationsApi.updateTranslation(data)
        props.dispatch(showMessage(tr.translationOperationMessages.updateSuccess, MessageTypes.Success))
        history.push(translations())
      } catch (err) {
        props.dispatch(showMessage(tr.translationOperationMessages.updateError, MessageTypes.Error))
        setProcessing(false)
      }
    }
  }

  async function handleDelete(id: string | number) {
    setProcessing(true)

    try {
      await translationsApi.deleteTranslation(id)
      props.dispatch(showMessage(tr.translationOperationMessages.deleteSuccess, MessageTypes.Success))
      history.push(translations())
    } catch (err) {
      props.dispatch(showMessage(tr.translationOperationMessages.deleteError, MessageTypes.Error))
      setProcessing(false)
    }
  }

  if (loading) {
    return (
      <CenterContainer>
        <CircularProgress color="secondary" />
      </CenterContainer>
    )
  }

  if (!brands?.data || !config || !data) {
    return null
  }

  return (
    <Container>
      <TranslationEdit
        data={data}
        brands={brands?.data || []}
        config={config}
        creation={isNew}
        onSave={handleSave}
        onDelete={handleDelete}
        processing={processing}
      />
    </Container>
  )
}

export default connect()(SmartTranslationEdit)
