import { useEffect, useRef, useState } from 'react'
import { BreadcrumbProps, Crumb } from './types'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import { Breadcrumbs, ChevronIconStyled, CrumbEl, CrumbLink, CrumbsContainer, More, Root, Tail } from './styled'
import { MenuItem, IconButton, Popover } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useIntersectionList } from 'shared/utils/use-intersection-list'

export function Breadcrumb(props: BreadcrumbProps) {
  const history = useHistory()

  const [itited, setItited] = useState(false)

  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined)
  const rootRef = useRef<HTMLDivElement>(null)

  const { hiddenCount, crumbRefFn } = useIntersectionList({ list: props.crumbs, root: rootRef.current || undefined })

  useEffect(() => {
    setItited(true)
  }, [])

  useEffect(() => {
    setMenuAnchor(undefined)
  }, [props.crumbs])

  function handleClickUrl(url?: string) {
    return () => history.push(url || '')
  }

  function openMenu(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setMenuAnchor(ev.currentTarget)
  }

  function closeMenu() {
    setMenuAnchor(undefined)
  }

  function renderCrumbs(crumbs: Crumb[], refCallback?: (ref: HTMLElement, index: number) => void) {
    return crumbs.map((crumb, index, { length }) => {
      const isLast = index === length - 1
      const postfix = refCallback ? 'shadow' : 'real'
      const key = `${crumb.id}-${index}-${postfix}`

      const refCallbackHandler = (e: HTMLElement | null) => {
        if (e) {
          refCallback?.(e, index)
        }
      }

      if (isLast) {
        return <Tail key={key} ref={refCallbackHandler} children={crumb.title} />
      }

      return (
        <CrumbEl key={key} ref={refCallbackHandler}>
          <CrumbLink style={{ textDecoration: 'none' }} to={crumb.url || ''} children={crumb.title} />
          <ChevronIconStyled />
        </CrumbEl>
      )
    })
  }

  const crumbsCollapsed = props.crumbs.slice(0, hiddenCount)
  const crumbsVisible = props.crumbs.slice(hiddenCount, props.crumbs.length)

  if (!itited) {
    return null
  }

  return (
    <Root>
      {!!crumbsCollapsed.length && (
        <More>
          <IconButton onClick={openMenu}>
            <MoreIcon fontSize="small" style={{ color: 'black' }} />
          </IconButton>
          <Popover
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={closeMenu}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            {crumbsCollapsed.map((crumb, index) => (
              <MenuItem style={{ minHeight: '3rem' }} key={`${crumb.id}-${index}`} onClick={handleClickUrl(crumb.url)}>
                {crumb.title}
              </MenuItem>
            ))}
          </Popover>
        </More>
      )}
      <Breadcrumbs $collapsed={!!crumbsCollapsed?.length} ref={rootRef}>
        <CrumbsContainer $shadow>{renderCrumbs(props.crumbs, crumbRefFn)}</CrumbsContainer>
        <CrumbsContainer>{renderCrumbs(crumbsVisible)}</CrumbsContainer>
      </Breadcrumbs>
    </Root>
  )
}
