import React from 'react'
import { Form, FormGroup } from 'dumb/form'
import { TextField } from 'dumb/text-field'
import { DatePicker } from 'dumb/date'
import translate from 'i18n/translate'
import TimePicker from 'dumb/time'
import { Validation } from 'utils/@validation'
import Card from 'dumb/card'
import EntityActions from 'dumb/entity-actions'
import Button from '@material-ui/core/Button'

class StaffManagementEdit extends Validation {
  componentDidMount() {
    this.handlePropChange('numberOfStaff', 1)
  }

  validateConfig() {
    return [
      {
        dataField: 'startHour',
        stateField: 'startHourIsValid'
      },
      {
        dataField: 'endHour',
        stateField: 'endHourIsValid'
      },
      {
        dataField: 'startDate',
        stateField: 'startDateIsValid'
      }
    ]
  }

  render() {
    return (
      <Card>
        {this.renderMainForm(this.state.data)}

        <EntityActions showButtons={true} buttons={this.renderControls()} updateData={this.state.data.lastUpdate} />
      </Card>
    )
  }

  renderControls() {
    const { createButton, updateButton, deleteButton, cancelButton, confirmDelete } = this.props.translation.entityEdit

    if (this.state.confirmDelete)
      return (
        <div>
          <div style={{ margin: '0 8px 16px' }}>{confirmDelete}</div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button id="confirm" onClick={() => this.props.onDelete(this.state.data.id)}>
              {deleteButton}
            </Button>
            <Button id="cancel" onClick={() => this.handleCancelDelete()}>
              {cancelButton}
            </Button>
          </div>
        </div>
      )

    if (!this.state.data.id)
      return (
        <Button disabled={!this.stateIsValid()} id="create" onClick={() => this.handleSave()}>
          {createButton}
        </Button>
      )
    else
      return (
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button disabled={!this.stateIsValid()} onClick={() => this.handleSave()} id="update">
            {updateButton}
          </Button>
          {this.props.canDelete !== false && (
            <Button id="delete" onClick={() => this.handleTryDelete()}>
              {deleteButton}
            </Button>
          )}
        </div>
      )
  }

  handleTryDelete() {
    this.setState({
      confirmDelete: true
    })
  }

  handleCancelDelete() {
    this.setState({
      confirmDelete: false
    })
  }

  handleSave() {
    this.props.onSave(this.state.data)
  }

  renderMainForm(d) {
    const { startDate, startHour, endHour, comments, commentsPlaceholder } = this.props.translation.staffManagementEdit

    return (
      <Form>
        <FormGroup>
          <DatePicker
            style={{ width: '100%' }}
            label={startDate}
            placeholder={'Date'}
            value={d.startDate}
            onChange={(val) => this.handlePropChange('startDate', +val)}
            attention={!this.state.startDateIsValid}
          />
        </FormGroup>
        <FormGroup>
          <TimePicker
            style={{ width: '100%' }}
            label={startHour}
            value={d.startHour}
            onChange={(val) => this.handlePropChange('startHour', val)}
            timeZone={this.props.timeZoneId}
            date={d.startDate}
            attention={!this.state.startHourIsValid}
          />
        </FormGroup>
        <FormGroup>
          <TimePicker
            style={{ width: '100%' }}
            label={endHour}
            value={d.endHour}
            onChange={(val) => this.handlePropChange('endHour', val)}
            timeZone={this.props.timeZoneId}
            date={d.startDate}
            attention={!this.state.endHourIsValid}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label={comments}
            placeholder={commentsPlaceholder}
            value={d.comments}
            onChange={(val) => this.handlePropChange('comments', val)}
          />
        </FormGroup>
      </Form>
    )
  }
}

export default translate(StaffManagementEdit)
